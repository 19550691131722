<template>
  <Modal @close="$emit('close')" :title="title" size="lg">
    <div v-if="data !== null" class="invoice">
      <div class="invoice-container">
        <div class="invoice-header">
          <div>
            <a href="https://mtarget.co/" target="_blank">
              <img class="logo-mtarget" src="https://files.mailtarget.co/58532f2314fcb933b55e7113/logo-mtarget-invoice.1576814951048.png" alt="MTARGET">
            </a>
          </div>
          <div class="d-flex align-items-center">
            <template v-if="data.proforma">
              <div class="text">PROFORMA INVOICE</div>
            </template>
            <template v-else-if="data.createdFrom !== 'CMS' && !data.paid">
              <div class="text">SUMMARY</div>
            </template>
            <template v-else>
              <div v-if="data.paid" class="status paid">PAID</div>
              <div v-else class="status unpaid">UNPAID</div>
              <div class="text">INVOICE</div>
            </template>
          </div>
        </div>
        <div class="invoice-recipient">
          <div class="billed-to">
            <div class="title">Billed To</div>
            <div>{{ data.receiptData.name }}</div>
            <div>{{ data.receiptData.companyName }}</div>
            <div v-html="data.receiptData.address"></div>
          </div>
        </div>
        <div class="invoice-date">
          <div v-if="!(data.proforma || (data.createdFrom !== 'CMS' && !data.paid))" class="invoice-no">
            <div class="title">Invoice Number</div>
            <div>{{ data.invoice }}</div>
          </div>
          <div class="billing-date">
            <div class="title">Billing Date</div>
            <div>{{ data.createdAt | dtmType('DD MMM YYYY')}}</div>
          </div>
          <div v-if="data.paidDate !== null" class="paid-date" :class="data.paid ? 'paid' : 'unpaid'">
            <div class="title">Paid Date</div>
            <div>{{ data.paidDate | dtmType('DD MMM YYYY') }}</div>
          </div>
        </div>
        <!-- billing payasyougo -->
        <div class="invoice-detail" v-if="isPAYG">
          <!-- <div class="title">Subscription Information</div> -->
          <table>
            <thead>
              <tr>
                <th style="width:120px">Description</th>
                <th style="width:190px">Detail Service</th>
                <th>Email Price</th>
                <th>Email Used</th>
                <th>Price</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td class="text-capitalize">
                  <div>{{ (data.packet.type).split('_').join(' ') }}</div>
                  <div>Min. Fair Usage {{ data.packet.minFairUsage | curr }}</div>
                </td>
                <td>
                  <div>{{ data.period }}</div>
                </td>
                <td>IDR {{ data.packet.emailPrice | curr(2) }}</td>
                <td>{{ data.emailSent | curr }}</td>
                <td class="text-right">IDR {{ data.subTotal | curr(2) }}</td>
              </tr>
              <tr class="custom-feature"> <!-- add 'available' class if there is custom features -->
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
              <tr v-if="data.packet.extraQuota > 0">
                <td>Extra Email</td>
                <td colspan="3">
                  <div class="text-capitalize">{{ data.packet.extraPlan }}</div>
                  <div>{{ data.packet.extraQuota | curr }} Email Quota</div>
                  <div v-if="data.packet.extraPlan === 'bulk'">{{ data.packet.bulkStart | dtmType('DD MMM YYYY') }} - {{ data.packet.bulkEnd | dtmType('DD MMM YYYY') }}</div>
                </td>
                <td class="text-right">IDR {{ data.packet.extraPrice | curr(2) }}</td>
              </tr>
              <tr v-for="(service, i) in data.items" :key="i">
                <td>{{ service.name }}</td>
                <td colspan="3">One Time</td>
                <td class="text-right">IDR {{ service.price | curr(2) }}</td>
              </tr>
              <tr>
                <td colspan="5">
                <hr>
                </td>
              </tr>
              <!-- info promo -->
              <tr v-if="data.promoData !== null && data.promoData.type === 'DISCOUNT'">
                <td colspan="3">Promo <span class="lowercase">{{ data.promoData.type }} {{ data.promoData.discount }}%</span></td>
                <td></td>
                <td class="text-right">- IDR {{ ((totalNoPromo * data.subscription) * (data.promoData.discount/100)) | curr(2) }}</td>
              </tr>
              <tr v-if="data.promoData !== null && data.promoData.type === 'VOUCHER'">
                <td colspan="3">Promo <span class="lowercase">{{ data.promoData.type }}</span></td>
                <td></td>
                <td class="text-right">- IDR {{ data.promoData.voucher | curr(2) }}</td>
              </tr>
              <tr v-if="data.promoData !== null && data.promoData.type === 'PACKET'">
                <td colspan="3">Promo <span class="lowercase">feature</span> <span class="text-capitalize">{{ data.promoData.packetName }}</span></td>
                <td></td>
                <td class="text-right">- IDR 0</td>
              </tr>
              <!-- end of info promo -->
              <tr>
                <td colspan="4">Product Price</td>
                <td class="text-right">IDR {{ data.subTotal | curr(2) }}</td>
              </tr>
              <tr v-if="data.tax > 0">
                <td colspan="4">PPN {{ data.taxPercentage }}%</td>
                <td class="text-right">IDR {{ data.tax | curr(2) }}</td>
              </tr>
              <tr>
                <td colspan="5">
                  <hr>
                </td>
              </tr>
              <tr class="total">
                <td colspan="2" class="text-total">Total</td>
                <td colspan="3" class="price text-right">IDR {{ data.total | curr(2) }}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <!-- billing payasyougo -->

        <!-- billing regular -->
        <div class="invoice-detail" v-if="(data.invoiceType==='regular' && !isPAYG) || data.invoiceType==='addon'">
          <!-- <div class="title">Subscription Information</div> -->
          <table>
            <thead>
              <tr>
                <th style="width:120px">Description</th>
                <th style="width:300px">Detail Service</th>
                <th>Price</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td class="text-capitalize">{{ planName(data.packet.type) }}</td>
                <td>
                  <div>{{ data.packet.totalEmailSentLimit | curr }} Email Quota</div>

                  <div v-if="data.periodType === 'YEARLY'">Annual (<template v-if="(data.subscription / 12) > 0">{{ Math.floor(data.subscription / 12) }} Year</template><template v-if="(data.subscription % 12) > 0"> {{ data.subscription % 12 }} Month</template>)</div>
                  <div v-else-if="data.periodType === 'QUARTERLY'">Quarterly ({{ data.subscription / 3 }} Quarter)</div>
                  <div v-else-if="data.periodType === 'MONTHLY'">Monthly ({{ data.subscription }} Month)</div>
                  <template v-else>
                    <div v-if="data.subscription >= 12">Annual (<template v-if="(data.subscription / 12) > 0">{{ Math.floor(data.subscription / 12)}} Year</template><template v-if="(data.subscription % 12) > 0"> {{ data.subscription % 12 }} Month</template>)</div>
                    <div v-else>Monthly ({{ data.subscription }} Month)</div>
                  </template>

                  <div>{{ data.period }}</div>
                </td>
                <td class="text-right">IDR {{ (packetPrice - calculateAddOnPrice) | curr(2) }}</td>
              </tr>
              <tr class="custom-feature"> <!-- add 'available' class if there is custom features -->
                <td></td>
                <td></td>
                <td></td>
              </tr>
              <tr v-for="(item, key) in data.addons" :key="key">
                <td>Add-Ons</td>
                <td>{{ item.quota | curr(0) }} {{ item.name }}</td>
                <td class="text-right">IDR {{ item.price | curr(2) }}</td>
              </tr>
              <tr v-if="data.packet.extraQuota > 0">
                <td>Extra Email</td>
                <td>
                  <div class="text-capitalize">{{ data.packet.extraPlan }}</div>
                  <div>{{ data.packet.extraQuota | curr }} Email Quota</div>
                  <div v-if="data.packet.extraPlan === 'bulk'">{{ data.packet.bulkStart | dtmType('DD MMM YYYY') }} - {{ data.packet.bulkEnd | dtmType('DD MMM YYYY') }}</div>
                </td>
                <td class="text-right">IDR {{ data.packet.extraPrice | curr(2) }}</td>
              </tr>
              <tr v-for="(service, i) in data.items" :key="i">
                <td>{{ service.name }}</td>
                <td>One Time</td>
                <td class="text-right">IDR {{ service.price | curr(2) }}</td>
              </tr>
              <tr><td colspan="3"><hr></td></tr>
              <!-- info promo -->
              <tr v-if="data.promoData !== null && data.promoData.type === 'DISCOUNT'">
                <td>Promo <span class="lowercase">{{ data.promoData.type }} {{ data.promoData.discount }}%</span></td>
                <td></td>
                <td class="text-right">- IDR {{ (totalNoPromo * (data.promoData.discount/100)) | curr(2) }}</td>
              </tr>
              <tr v-if="data.promoData !== null && data.promoData.type === 'VOUCHER'">
                <td>Promo <span class="lowercase">{{ data.promoData.type }}</span></td>
                <td></td>
                <td class="text-right">- IDR {{ data.promoData.voucher | curr(2) }}</td>
              </tr>
              <tr v-if="data.promoData !== null && data.promoData.type === 'PACKET'">
                <td>Promo <span class="lowercase">feature</span> <span class="text-capitalize">{{ data.promoData.packetName }}</span></td>
                <td></td>
                <td class="text-right">- IDR 0</td>
              </tr>
              <template v-if="data.note && data.note !== ''">
                <tr v-if="data.promoData !== null"><td colspan="3"><hr></td></tr>
                <tr>
                  <td colspan="3">
                    <span>Note: {{ data.note }}</span>
                  </td>
                </tr>
                <tr><td colspan="3"><hr></td></tr>
              </template>
              <!-- end of info promo -->
              <tr>
                <td colspan="2">Product Price</td>
                <td class="text-right">IDR {{ data.subTotal | curr(2) }}</td>
              </tr>
              <tr v-if="data.tax > 0">
                <td colspan="2">PPN {{ data.taxPercentage }}%</td>
                <td class="text-right">IDR {{ data.tax | curr(2) }}</td>
              </tr>
              <tr>
                <td colspan="3">
                  <hr>
                </td>
              </tr>
              <tr class="total">
                <td colspan="2" class="text-total">Total Price</td>
                <td class="price text-right">IDR {{ data.total | curr(2) }}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <!-- billing regular -->
        <!-- billing extra quota -->
        <div class="invoice-detail" v-if="data.invoiceType==='extra' || data.invoiceType==='bulk'">
          <!-- <div class="title">Subscription Information</div> -->
          <table>
            <thead>
              <tr>
                <th style="width:120px">Description</th>
                <th v-if="data.invoiceType==='bulk'">Detail Service</th>
                <th>Price</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td class="text-capitalize">{{ data.quota | curr }} {{ data.type }} quota</td>
                <td v-if="data.invoiceType==='bulk'">{{data.period}}</td>
                <td class="text-right">IDR {{ data.basePrice | curr(2) }}</td>
              </tr>
              <tr class="custom-feature">
                <td></td>
                <td v-if="data.invoiceType==='bulk'"></td>
                <td></td>
              </tr>
              <tr>
                <td :colspan="data.invoiceType==='bulk'?3:2">
                  <hr>
                </td>
              </tr>
              <!-- info promo -->
              <tr v-if="data.promoData !== null && data.promoData.type === 'DISCOUNT'">
                <td>Promo <span class="lowercase">{{ data.promoData.type }} {{ data.promoData.discount }}%</span></td>
                <td v-if="data.invoiceType==='bulk'"></td>
                <td class="text-right">- IDR {{ (data.basePrice * (data.promoData.discount/100)) | curr(2) }}</td>
              </tr>
              <tr v-if="data.promoData !== null && data.promoData.type === 'VOUCHER'">
                <td>Promo <span class="lowercase">{{ data.promoData.type }}</span></td>
                <td v-if="data.invoiceType==='bulk'"></td>
                <td class="text-right">- IDR {{ data.promoData.voucher | curr(2) }}</td>
              </tr>

              <tr>
                <td>Product Price</td>
                <td v-if="data.invoiceType==='bulk'"></td>
                <td class="text-right">IDR {{ data.subTotal | curr(2) }}</td>
              </tr>
              <tr v-if="data.tax > 0">
                <td>PPN {{ data.taxPercentage }}%</td>
                <td v-if="data.invoiceType==='bulk'"></td>
                <td class="text-right">IDR {{ data.tax | curr(2) }}</td>
              </tr>
              <tr>
                <td :colspan="data.invoiceType==='bulk'?3:2">
                  <hr>
                </td>
              </tr>
              <tr class="total">
                <td class="text-total">Total Price</td>
                <td :colspan="data.invoiceType === 'bulk'?2:1" class="price text-right" :class="data.invoiceType === 'bulk'?'text-right':''">IDR {{ data.total | curr(2) }}</td>
              </tr>
              <!-- end of info promo -->
            </tbody>
          </table>
        </div>
        <!-- end of billing extra quota -->
        <!-- billing domain -->
        <div class="invoice-detail" v-if="data.invoiceType==='domain'">
          <!-- <div class="title">Subscription Information</div> -->
          <table>
            <thead>
              <tr>
                <th style="width:120px">Description</th>
                <th style="width:300px">Period</th>
                <th>Price</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td class="text-capitalize">Domain</td>
                <td>
                  <div>{{ data.packet.domain }}</div>
                  <div>{{ data.subscription / 12 }} Year</div>
                  <div>{{ data.period }}</div>
                </td>
                <td class="text-right">IDR {{ data.domainPrice | curr(2) }}</td>
              </tr>
              <tr class="custom-feature"> <!-- add 'available' class if there is custom features -->
                <td></td>
                <td></td>
                <td></td>
              </tr>
              <tr v-if="data.packet.idProtection">
                <td>Additional Service</td>
                <td>ID Protection</td>
                <td class="text-right">IDR {{ data.idProtectionPrice | curr(2) }}</td>
              </tr>
              <tr>
                <td colspan="3">
                <hr>
                </td>
              </tr>
              <!-- info promo -->
              <tr v-if="data.promoData !== null && data.promoData.type === 'DISCOUNT'">
                <td>Promo <span class="lowercase">{{ data.promoData.type }} {{ data.promoData.discount }}%</span></td>
                <td></td>
                <td v-if="data.packet.idProtection" class="text-right">- IDR {{ ((data.domainPrice + data.idProtectionPrice) * (data.promoData.discount/100)) | curr(2) }}</td>
                <td v-else class="text-right">- IDR {{ (data.domainPrice * (data.promoData.discount/100)) | curr(2) }}</td>
              </tr>
              <tr v-if="data.promoData !== null && data.promoData.type === 'VOUCHER'">
                <td>Promo <span class="lowercase">{{ data.promoData.type }}</span></td>
                <td></td>
                <td class="text-right">- IDR {{ data.promoData.voucher | curr(2) }}</td>
              </tr>
              <tr v-if="data.promoData !== null && data.promoData.type === 'PACKET'">
                <td>Promo <span class="lowercase">feature</span> <span class="text-capitalize">{{ data.promoData.packetName }}</span></td>
                <td></td>
                <td class="text-right">- IDR 0</td>
              </tr>
              <!-- end of info promo -->
              <tr>
                <td colspan="2">Product Price</td>
                <td class="text-right">IDR {{ data.subTotal | curr(2) }}</td>
              </tr>
              <tr v-if="data.tax > 0">
                <td colspan="2">PPN {{ data.taxPercentage }}%</td>
                <td class="text-right">IDR {{ data.tax | curr(2) }}</td>
              </tr>
              <tr>
                <td colspan="3">
                  <hr>
                </td>
              </tr>
              <template v-if="data.packet.requireDocument">
                <tr v-if="data.promoData !== null"><td colspan="3"><hr></td></tr>
                <tr>
                  <td colspan="3">
                    <span class="text-italic">Note: This domain require action to upload some documents for verification ({{ data.packet.requiredDocuments.join(', ') }})</span>
                  </td>
                </tr>
                <tr><td colspan="3"><hr></td></tr>
              </template>
              <tr class="total">
                <td colspan="2" class="text-total">Total Price</td>
                <td class="price text-right">IDR {{ data.total | curr(2) }}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <!-- billing domain -->
        <div style="border-bottom: 2px dashed #ff9700; margin: 32px 0 8px;"></div>
        <div class="invoice-note">
          <div class="method">
            <div :class="data.paid ? 'paid' : 'unpaid'">
              <div class="title">Payment Method</div>
              <template v-if="data.result !== null">
                <template v-if="data.result.method.toLowerCase() === 'manual_transfer'">
                  <p>Please complete your payment by transfer via ATM / KlikPay / M-Banking to our BCA account number:</p>
                  <div class="bca-mtarget">
                    <div>BCA Menara Palma</div>
                    <div class="no-rek">5425129799</div>
                    <div>a.n PT Target Sukses Sinergi</div>
                  </div>
                </template>
                <template v-if="data.result.method.toLowerCase() === 'bank_transfer'">
                  <p>Please make payments with instructions that we have sent in the email along with the payment code.</p>
                </template>
              </template>
              <template v-if="data.result === null">
                <p>You have not selected a payment method.</p>
              </template>
            </div>
          </div>
          <div v-if="!data.proforma" class="note">
            <div class="title">Note</div>
            <p v-if="isMinFairUsage">You will be charged with the minimum fee, because your email delivery is below the minimum number set.</p>
            <p v-if="data.result !== null && data.result.method === 'CREDIT_CARD'">After that the payment will be made automatically through your Credit Card.</p>
            <p v-if="!isPAYG" :class="data.paid ? 'paid' : 'unpaid'">Invoice is valid for up to 14 days from the date the invoice was sent.</p>
            <p>If the bill is deducted by PPH Article 23, please send the proof of tax cuts Article 23 for service at 2% of the total payment to our office or send by email to finance@mtarget.co.</p>
            <p v-if="!isPAYG && data.result !== null && data.result.method.toLowerCase() === 'manual_transfer'">Please send proof of payment on the Confirm Payment page to activate your subscription.</p>
          </div>
        </div>
        <div class="invoice-footer">
          <div>
            <div>PT. Target Sukses Sinergi</div>
            <div>info@mtarget.co</div>
          </div>
          <div class="address">
            <div>88 Office Tower</div>
            <div>JL. Casablanca Kav. 88 Jakarta Selatan 12870.</div>
          </div>
        </div>
      </div>
    </div>
    <template v-slot:footer>
      <button @click="$emit('close')" type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
    </template>
  </Modal>
</template>

<script>
  import Modal from "@/components/partial/Modal"

  export default {
    components: { Modal },
    props: {
      title: { default: '' },
      data: { default: null }
    },
    computed: {
      isPAYG () {
        if (this.data === null) return false
        return (this.data.type === 'PAYASYOUGO')
      },
      isMinFairUsage () {
        if (this.data === null) return false
        if (this.data.type !== 'PAYASYOUGO') return false
        return ((this.data.packet.emailPrice * this.data.packet.minFairUsage) >= this.data.total)
      },
      packetPrice () {
        if (this.data === null) return 0
        else if (this.data.invoiceType === 'extra') return 0
        return this.data.packet.price * this.data.subscription
      },
      totalNoPromo () {
        if (this.data === null) return 0
        if (this.data.invoiceType === 'extra') return 0
        if (this.data.promoData !== null) {
          let totalItems = this.data.items.reduce((prev, val) => {
            prev += val.price
            return prev
          }, 0)
          return this.data.packet.price * this.data.subscription + totalItems
        }
        return this.data.total
      },
      calculateAddOnPrice () {
        return this.data.addons.reduce((accum, item) => accum + item.price, 0)
      }
    },
    methods: {
      planName (val) {
        if (val === undefined) return ''
        return val.replace(/_/g,' ')
      }
    }
  }
</script>

<style scoped>
  .invoice {
    background-color: white;
    margin-left: auto;
    margin-right: auto;
    overflow-x: auto;
    /* width: 1241px;
    height: 1330px; */
    font-family: 'Roboto', sans-serif;
    padding-left: 16px;
    padding-right: 16px;
    font-size: 14px;
    max-width: 600px;
  }
  .invoice p {
    font-size: 14px;
  }
  .invoice .title {
    color: #ff9700;
    font-weight: bold;
    margin-bottom: 16px;
  }
  .invoice > .invoice-container {
    /* width: 921px; */
    margin-left: auto;
    margin-right: auto;
    position: relative;
    height: 100%;
  }
  .invoice .invoice-header {
    padding-top: 36px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  .invoice .invoice-header .logo-mtarget {
    height: 42px;
    margin-top: 12px;
  }
  .invoice .invoice-header .status {
    color: #ffffff;
    border-radius: 3px;
    padding: 4px 16px;
  }
  .invoice .invoice-header .status.paid {
    background-color: #2f9f62;
    font-size: 12px;
  }
  .invoice .invoice-header .status.unpaid {
    background-color: #eb5a46;
    font-size: 12px;
  }
  .invoice .invoice-header .text {
    margin-left: 24px;
    font-size: 30px;
    line-height: 64px;
  }
  .invoice .invoice-recipient {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-top: 40px;
  }
  .invoice .invoice-date {
    display: flex;
    flex-wrap: wrap;
    margin-top: 20px;
  }
  .invoice .invoice-date > div {
    margin-top: 32px;
  }
  .invoice .invoice-date .invoice-no {
    min-width: 33%;
  }
  .invoice .invoice-date .paid-date {
    min-width: 33%;
  }
  .invoice .invoice-date .billing-date {
    min-width: 33%;
  }
  .invoice .invoice-detail {
    margin-top: 64px;
  }
  .invoice table {
    width: 100%;
    border-collapse: collapse;
  }
  .invoice table thead {
    text-align: left;
    background-color: #FFF082;
  }
  .invoice table tbody .total {
    font-size: 24px;
    font-weight: bold;
  }
  .invoice table tbody .total .text-total {
    width: 200px;
  }
  .invoice table tbody .total .price {
    width: 280px;
    color: #ff9700;
  }
  .invoice table thead th,
  .invoice table tbody td {
    padding: 8px;
  }
  .invoice .invoice-note {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding-bottom: 160px;
  }
  .invoice .invoice-note .method,
  .invoice .invoice-note .note {
    margin-top: 32px;
    width: 260px;
  }
  .invoice .invoice-note .method .bca-mtarget {
    border: 2px solid #becece;
    padding: 0 16px;
  }
  .invoice .invoice-note .method .bca-mtarget > .no-rek {
    font-size: 24px;
    font-weight: bold;
  }
  .invoice .invoice-note .method .bca-mtarget > div {
    margin: 16px 0;
  }
  .invoice .invoice-footer {
    display: flex;
    justify-content: space-between;
    position: absolute;
    bottom: 0;
    width: 100%;
    margin: 40px 0;
    border-top: 2px dashed #ff9700;
    padding-top: 40px;
  }
  .invoice .invoice-footer .address {
    text-align: right;
  }
  .invoice table tbody tr.custom-feature.available,
  .invoice .invoice-recipient .paid-date.paid,
  .invoice .invoice-note .method > .unpaid,
  .invoice .invoice-note .note p.unpaid {
    display: block;
  }
  .invoice table tbody tr.custom-feature,
  .invoice .invoice-recipient .paid-date.unpaid,
  .invoice .invoice-note .method > .paid,
  .invoice .invoice-note .note p.paid {
    display: none;
  }
</style>
