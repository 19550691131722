<template>
  <Modal
    class="login-block"
    title="Continue your Session"
    :fullscreen="true"
    :allowClose="false"
  >
    <VeeOb class="kt-form" tag="form" ref="form" @submit.prevent="login">
      <div class="form-group">
        <p class="kt-badge kt-badge--success kt-badge--xl kt-badge--inline">{{ email }}</p>
      </div>
      <Vee class="form-group" tag="div" rules="required|min:8" name="Password" v-slot="{ errors }">
        <input v-model="form.password" class="form-control" type="password" placeholder="Password" name="password">
        <span class="text-warning">{{ errors[0] }}</span>
      </Vee>
    </VeeOb>
    <template v-slot:footer>
      <button @click.prevent="logout" class="btn btn-secondary">Log Out</button>
      <button @click="login" class="btn btn-primary">Log In</button>
    </template>
  </Modal>
</template>
<script>
  import Modal from '@/components/partial/Modal'
  import logo from '@/assets/images/logo.png'
  import bg from '@/assets/template/media/bg/bg-1.jpg'
  import fn from '@/library/fn'
  import user from '@/factory/user'
  import store from '@/store'

  export default {
    components: {Modal},
    data () {
      return {
        logo,
        bg,
        form: {
          email: null,
          password: null
        }
      }
    },
    computed: {
      userData () { return store.state.userData },
      email () { return this.userData.email }
    },
    methods: {
      logout () {
        fn.logout()
        this.$router.push('/login')
      },
      login () {
        this.form.email = this.email
        this.$refs.form.validate()
          .then(res => {
            if (!res){
              fn.notyWarn('validation')
              return Promise.reject()
            }
            return user.login(this.form)
          })
          .catch(e => void e)
      }
    }
  }
</script>

<style lang="scss" scoped>
  .login-block {
    z-index: 1051;
  }
</style>