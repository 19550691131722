<template>
  <div>
    <HeaderMobile :show-menu="showMenu" />

    <!-- end:: Header Mobile -->
    <div class="kt-grid kt-grid--hor kt-grid--root">
      <div class="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--ver kt-page">

        <MainMenu v-if="showMenu" />
        <div class="kt-aside-overlay" v-if="mobileAsideMaximize" @click="hideMob"></div>
        <!-- end:: Aside -->
        <div class="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor kt-wrapper" id="kt_wrapper">

          <!-- begin:: Header -->
          <slot name="header">
            <Header
              :crumb="crumb"
              :title="title"
              :logout="logout"
              @change-password="(x) => { changePasswordData = x }"
              @setlog="setlog"
              :show-menu="showMenu"  />
          </slot>
          <!-- end:: Header -->
          <div class="kt-content  kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor" id="kt_content" :class="[contentClass]">
            
            <!-- begin:: Content Head -->

            <slot name="sub-subheader">
              <SubHeader :title="subtitle" :has-subtitle="hasSubtitle" :isSticky="isSubHeaderSticky">
                <template v-slot:sub>
                  <slot name="left-menu"></slot>
                </template>
                <slot name="right-menu"></slot>
              </SubHeader>
            </slot>

            <!-- end:: Content Head -->

            <!-- begin:: Content -->
            <div class="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid main-content">

              <!--Begin::Dashboard 1-->

              <!--Begin::Row-->
              <slot>
                <p>Loading data...</p>
              </slot>

              <!--End::Row-->
              <!--End::Dashboard 1-->
            </div>

            <!-- end:: Content -->
          </div>

          <!-- begin:: Footer -->
          <Footer />
          <!-- end:: Footer -->
        </div>
      </div>
    </div>

    <ModalChangePassword
      v-if="changePasswordData !== null"
      :data="changePasswordData"
      @change-success="setlog(true)"
      @close="changePasswordData = null" />
    <LoginBlock v-if="sessionExpired" />
    <Confirmation v-if="logout" @close="logout = false" message="Are you sure to logout?">
      <router-link to="/logout" class="btn btn-primary">Logout Now</router-link>
    </Confirmation>
    <Loading v-if="loading"/>
  </div>
</template>

<script>
  import HeaderMobile from '@/components/layout/HeaderMobile'
  import SubHeader from '@/components/layout/SubHeader'
  import Header from '@/components/layout/Header'
  import Footer from '@/components/layout/Footer'
  import MainMenu from '@/components/layout/MainMenu'
  import store from '@/store'
  import LoginBlock from '@/components/login/LoginBlock'
  import Confirmation from '@/components/partial/Confirmation'
  import Loading from '@/components/partial/Loading'
  import ModalChangePassword from '@/components/user/ModalChangePassword'

  import '@/assets/template/sass/style.scss'
  import '@/assets/template/sass/global/layout/aside/skins/dark.scss'
  import '@/assets/template/sass/global/layout/brand/skins/dark.scss'
  import '@/assets/template/sass/global/layout/header/skins/menu/light.scss'
  import '@/assets/template/sass/global/layout/header/skins/base/light.scss'
  import 'flatpickr/dist/flatpickr.css'
  import '@/assets/scss/style.scss'
  
  export default {
    components: {
      Confirmation,
      Loading,
      LoginBlock,
      HeaderMobile,
      Header,
      Footer,
      SubHeader,
      MainMenu,
      ModalChangePassword
    },
    props: {
      title: {
        default: 'Dashboard'
      },
      hasSubtitle: {
        default: true
      },
      subtitle: {
        default: ''
      },
      crumb: {
        default: undefined
      },
      detail: {
        default: null
      },
      showMenu: {
        default: true
      },
      loading: {
        default: false
      },
      isSubHeaderSticky: {
        default: false
      },
      contentClass: {
        default: ''
      },
      pageAsModal: {
        default: false
      }
    },
    data () {
      return {
        logout: false,
        changePasswordData: null
      }
    },
    created () {
      this.$store.commit('SET_ASIDE_ENABLE', this.showMenu)
    },
    mounted () {
      if (this.$props.pageAsModal) document.body.classList.add('bg-white')
    },
    beforeDestroy() {
      if (this.$props.pageAsModal) document.body.classList.remove('bg-white')
    },
    computed: {
      mobileAsideMaximize () { return store.state.mobileAsideMaximize },
      sessionExpired () { return store.state.sessionExpired }
    },
    methods: {
      setlog (val) {
        this.logout = val
      },
      hideMob () {
        store.commit('SET_MOB_MENU', false)
      }
    }
  }
</script>

<style lang="scss" scoped>
  .main-content {
    min-height: calc(100vh - 228px);
  }
  @media (max-width: 1024px) {
    .main-content {
      min-height: calc(100vh - 182px);
    }
  }
</style>
