<template>
  <div>
    <div class="kt-portlet kt-portlet--mobile rounded-12">
      <div class="kt-portlet__head kt-portlet__head--lg border-bottom">
        <div class="kt-portlet__head-label">
          <h3 class="kt-portlet__head-title">
            <span class="text-bold text-gray-700">
              Client Detail
            </span>
          </h3>
        </div>
      </div>
      <div class="kt-portlet__body kt-portlet__body--md">
        <div class="d-flex">
          <div class="bg-red d-flex justify-content-center align-items-center" style="background: #E5EAEE; width: 64px; height: 64px; border-radius: 16px;">
            <NewUserSvg style="width: 24px; height: 24px;"/>
          </div>
          <div class="pl-4 pt-2">
            <h6 class="text-gray-800 font-weight-600" style="font-size: 18px;">
              {{ data.email }}
            </h6>
            <span class="text-caption text-sm font-weight-500">
              {{ data.name }}
            </span>
          </div>
        </div>
        <div class="row">
          <div class="col-3 text-sm font-weight-600 py-4">
            <div>Company</div>
            <div class="font-weight-500 pt-2 text-caption">{{ data.company || 'Empty' }}</div>
          </div>
          <div class="col-3 text-sm font-weight-600 py-4">
            <div>Phone</div>
            <div class="font-weight-500 pt-2 text-caption">{{ data.phone || 'Empty' }}</div>
          </div>
          <div class="col-3 text-sm font-weight-600 py-4">
            <div>Website</div>
            <div class="font-weight-500 pt-2 text-caption">{{ data.website || 'Empty' }}</div>
          </div>
          <div class="col-3 text-sm font-weight-600 py-4">
            <div>Registered</div>
            <div class="font-weight-500 pt-2 text-caption">{{ data.register | dtmType('DD MMM YYYY HH:mm') }}</div>
          </div>
          <div class="col-3 text-sm font-weight-600 py-4">
            <div>Industry</div>
            <div class="font-weight-500 pt-2 text-caption">{{ data.industry || 'Empty' }}</div>
          </div>
          <div class="col-3 text-sm font-weight-600 py-4">
            <div>Estimated Email Sent</div>
            <div class="font-weight-500 pt-2 text-caption">{{ data.emailSent || 'Empty' }}</div>
          </div>
          <div class="col-3 text-sm font-weight-600 py-4">
            <div>User Type</div>
            <div class="font-weight-500 pt-2 text-caption">{{ data.userType }}</div>
          </div>
          <div class="col-3 text-sm font-weight-600 py-4">
            <div>Last Login</div>
            <div class="font-weight-500 pt-2 text-caption">{{ data.lastLogin | dtmType('DD MMM YYYY HH:mm') }}</div>
          </div>
          <div class="col-3 text-sm font-weight-600 py-4">
            <div>Production</div>
            <div class="font-weight-500 pt-2">
              <span :class="textColor(data.productionStatus)">
                {{ data.productionStatus }}
              </span>
              <span v-if="data.sentProductionAt" class="text-caption"> - Since {{ data.sentProductionAt | dtmType('DD MMM YYYY HH:mm') }}</span>
            </div>
          </div>
        </div>
        <div class="col-12 px-0 my-4">
          <div v-if="data.note === null || data.note === ''" @click="setCompanyNoteView" class="cursor-pointer d-flex justify-content-center rounded attachment-button py-4 text-caption">
            <i class="flaticon2-pen pr-2"></i> Add Note
          </div>
          <div v-else class="company-note">
            <div class="note-title px-2">
              <div>
                <span class="kt-badge kt-badge--inline kt-badge--pill kt-badge--warning-light text-bold text-warning">
                  Note
                </span>
              </div>
              <div>
                <span style="overflow: visible; position: relative; width: 110px;">
                  <a
                    @click.prevent="editNote"
                    title="Edit Note"
                    class="btn btn-sm btn-clean btn-icon btn-icon-md cursor-pointer">
                    <EditSvg />
                  </a>
                  <a
                    @click.prevent="companyNoteView = data"
                    title="Detail Note"
                    class="btn btn-sm btn-clean btn-icon btn-icon-md cursor-pointer">
                    <i class="flaticon-eye"></i>
                  </a>
                  <a
                    @click.prevent="companyNoteData = data"
                    title="Delete Note"
                    class="btn btn-sm btn-clean btn-icon btn-icon-md cursor-pointer">
                    <TrashSvg class="custom-delete-icon" />
                  </a>
                </span>
              </div>
            </div>
            <div class="note-body p-2">
              <div class="text-caption font-weight-500 text-overflow-ellipsis">{{ data.note }}</div>
              <div v-if="data.noteAttachment" class="mt-3">
                <a @click="imageUrl = data.noteAttachment" class="note-attachment">
                  <i class="la la-link"></i> Attachment
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Usage :profileData="data" />

    <ModalCompanyNote
      v-if="companyNoteView !== null"
      :company="companyNoteView"
      product="mailtarget"
      @save-success="companyNoteSaved"
      @close="companyNoteView = null" />

    <Confirmation
      v-if="companyNoteData !== null"
      @close="companyNoteData = null"
      :message="`Are you sure that you want to <strong>DELETE NOTE</strong> of this Client?`">
      <button @click="deleteClientNote" type="button" class="btn btn-primary">
        Yes
      </button>
    </Confirmation>

    <ViewFile
      v-if="imageUrl !== ''"
      :url="imageUrl"
      @close="imageUrl = ''" />
  </div>
</template>

<script>
  import NewUserSvg from '@/components/svg/NewUserSvg'
  import ModalCompanyNote from "@/components/company/ModalCompanyNote"
  import EditSvg from '@/components/svg/EditSvg.vue'
  import TrashSvg from '@/components/svg/TrashSvg.vue'
  import Confirmation from "@/components/partial/Confirmation"
  import fn from '@/library/fn'
  import mailtarget from '@/factory/mailtarget'
  import ViewFile from "@/components/partial/ViewFile"
  import Usage from './Usage.vue'

  export default {
    components: {
      NewUserSvg,
      ModalCompanyNote,
      EditSvg,
      TrashSvg,
      Confirmation,
      ViewFile,
      Usage,
    },
    props: {
      companyId: {
        default: 'companyId'
      },
      userId: {
        default: 'userId'
      },
      data: {
        default: {},
      },
    },
    data() {
      return {
        companyNoteView: null,
        companyNoteData: null,
        imageUrl: '',
      }
    },
    methods: {
      companyNoteSaved () {
        this.companyNoteView = null
        this.imageUrl = ''
        this.$emit('reload-data')
      },
      textColor(val) {
        if (val === 'None') return 'text-caption'
        else if (val === 'Pending') return 'text-warning'
        else if (val === 'Ready') return 'text-brand'
        else if (val === 'Used') return 'text-success'
        return 'text-dark-green'
      },
      editNote () {
        let company = Object.assign({}, this.data)
        company.noteMode = 'edit'
        this.companyNoteView = company
      },
      setCompanyNoteView () {
        this.companyNoteView = {}
      },
      deleteClientNote () {
        mailtarget.deleteClientNote(this.userId)
          .then((res) => {
            if (res) {
              fn.notySuccess("Company note successfully deleted.")
              this.companyNoteData = null
              this.$emit('reload-data')
            } else {
              this.companyNoteData = null
              fn.notyWarn("Failed to delete company note")
            }
          })
      },
    }
  }
</script>