<template>
  <div class="row">
    <div class="col-12">
      <div class="kt-portlet kt-portlet--mobile">
        <div class="kt-portlet__head kt-portlet__head--lg border-bottom">
          <div class="kt-portlet__head-label">
            <h3 class="kt-portlet__head-title">
              <span class="text-bold text-gray-700">
                Extra Quota Usage
              </span>
            </h3>
          </div>

          <div class="kt-portlet__head-toolbar my-1">
            <div class="kt-portlet__head-wrapper">
              <div class="kt-portlet__head-actions d-flex">
                <button @click="$emit('create-extra')" class="btn btn-primary btn-icon-sm">
                  <i class="la la-plus"></i> Add Quota
                </button>
              </div>
            </div>
          </div>
        </div>

        <!-- Bulk Quota -->
        <div class="kt-portlet__body kt-portlet__body--md">
          <div v-if="companyQuota !== null">
            <div class="form-group">
              <ExtraQuota
                class="mb-4"
                status="active"
                icon="la la-envelope"
                type="Bulk Email Quota"
                title-time="Last Updated"
                :percentage="companyQuota.bulkQuotaUsed/companyQuota.bulkQuota*100"
                :time="companyQuota.bulkQuotaPeriodEnd"
                :used="companyQuota.bulkQuotaUsed"
                :quota="companyQuota.bulkQuota"
                :unpaid="isUnpaidBulk"
                @tab-billing="$emit('tab-billing')" />
            </div>
            <div class="form-group">
              <ExtraQuota
                class="my-4"
                status="active"
                icon="la la-envelope"
                type="Email"
                title-time="Last Updated"
                :percentage="companyQuota.extraEmailQuotaUsed/companyQuota.extraEmailQuota*100"
                :time="companyQuota.lastUpdate"
                :used="companyQuota.extraEmailQuotaUsed"
                :quota="companyQuota.extraEmailQuota"
                :unpaid="isUnpaidEmail"
                @tab-billing="$emit('tab-billing')" />
            </div>
            <div class="form-group">
              <ExtraQuota
                class="my-4"
                status="active"
                icon="la la-file-image-o"
                type="Landing Page"
                title-time="Last Updated"
                :percentage="companyQuota.extraPageQuotaUsed/companyQuota.extraPageQuota*100"
                :time="companyQuota.lastUpdate"
                :used="companyQuota.extraPageQuotaUsed"
                :quota="companyQuota.extraPageQuota"
                :unpaid="isUnpaidPage"
                @tab-billing="$emit('tab-billing')" />
            </div>
            <div>
              <ExtraQuota
                class="mt-4"
                status="active"
                icon="la la-list"
                type="Form"
                title-time="Last Updated"
                :percentage="companyQuota.extraFormQuotaUsed/companyQuota.extraFormQuota*100"
                :time="companyQuota.lastUpdate"
                :used="companyQuota.extraFormQuotaUsed"
                :quota="companyQuota.extraFormQuota"
                :unpaid="isUnpaidForm"
                @tab-billing="$emit('tab-billing')" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import ExtraQuota from '@/components/partial/ExtraQuota'
  import payment from '@/factory/payment'

  export default {
    components: { ExtraQuota },
    data() {
      return {
        companyQuota: null,
        inactiveQuota: []
      }
    },
    computed: {
      companyId () { return this.$route.params.companyId },
      isUnpaidBulk () { 
        let val = false
        if(this.inactiveQuota.length > 0) {
          this.inactiveQuota.forEach(x => {
            if(x.type === 'bulk email') val = true
          })
        }
        return val
      },
      isUnpaidEmail () { 
        let val = false
        if(this.inactiveQuota.length > 0) {
          this.inactiveQuota.forEach(x => {
            if(x.type === 'email') val = true
          })
        }
        return val
      },
      isUnpaidPage () { 
        let val = false
        if(this.inactiveQuota.length > 0) {
          this.inactiveQuota.forEach(x => {
            if(x.type === 'page') val = true
          })
        }
        return val
      },
      isUnpaidForm () { 
        let val = false
        if(this.inactiveQuota.length > 0) {
          this.inactiveQuota.forEach(x => {
            if(x.type === 'form') val = true
          })
        }
        return val
      }
    },
    created () {
      this.getCompanyQuota()
      this.getInactiveQuota()
    },
    methods: {
      getCompanyQuota () {
        payment.getQuota(this.companyId)
          .then((res) => {
            this.companyQuota = res
          })
          .catch(err => this.$e(err))
      },
      getInactiveQuota () {
        payment.inactiveExtraQuota(this.companyId)
          .then((res) => {
            this.inactiveQuota = res
          })
          .catch(err => this.$e(err))
      }
    }
  }
</script>
