const useConfig = () => {
  return {
    menu: {
      enable: true,
      dashboard: true,
      company: true,
      conversion: false,
      userGrowth: true,
      funnelReport: true,
      payment: true,
      promo: true,
      customerService: true,
      support: true,
      package: true,
      clientLogo: true,
      template: true,
      dataSurvey: true
    },
    more: {
      enable: true,
      support: true
    },
    developer: {
      enable: true,
      scenario: true,
      blockedIp: true,
      invalidDomain: true,
      cmsActivity: true,
      setting: true,
      user: true
    },
    features: {
      dashboard: {},
      company: {},
      userGrowth: {},
      funnelReport: {},
      payment: {},
      promo: {},
      customerService: {},
      package: {},
      clientLogo: {},
      template: {},
      dataSurvey: {},
      scenario: {},
      clockedIp: {},
      invalidDomain: {},
      cmsActivity: {},
      setting: {},
      user: {}
    }
  }
}

export default useConfig
