<template>
  <div v-if="$route.name !== 'clients'" class="row mb-4">
    <div class="col-8">
      <div class="kt-portlet kt-portlet--mobile h-100 mb-0">
        <div class="kt-portlet__head kt-portlet__head--lg border-b-0">
          <div class="kt-portlet__head-label flex-wrap">
            <h3 class="kt-portlet__head-title">{{ appName }} Quota Usage</h3><i class="text-caption fs-10px pl-2">
            *last update at {{ dataQuota.updatedOn | dtmType('DD MMM YYYY') }}</i>
          </div>
        </div>
        <div class="kt-portlet__body pt-0">
          <div class="row">
            <div class="col-4">
              <div class="card-reseller-usage card-reseller-usage-warning">
                <div class="card-title">Email(s) Left</div>
                <div class="count">{{ dataQuota.quotaLeft | curr }}</div>
              </div>
              <div v-if="showAddQuotaOnEmailLeft" class="mt-2 text-gray-600">
                <strong>{{ quotaLeftOnPercent | curr }}%</strong> From total quota
              </div>
              <div v-else class="mt-2 text-gray-600">
                <strong class="text-danger">{{ quotaLeftOnPercent | curr }}%</strong> From total quota,
                <a @click="showModalBuyQuota = true" class="btn-link cursor-pointer">Add Quota</a>
              </div>
            </div>
            <div class="col-4">
              <div class="card-reseller-usage card-reseller-usage-success">
                <div class="card-title">Total Email Allocated</div>
                <div class="count">{{ dataQuota.quotaAllocated | curr }}</div>
              </div>
              <div class="mt-2 text-gray-600"><router-link :to="`/email-allocated/${appName}`">View detail</router-link></div>
            </div>
            <div class="col-4">
              <div class="card-reseller-usage card-reseller-usage-primary">
                <div class="card-title">Total Email Quota</div>
                <div class="count">{{ dataQuota.total | curr }}</div>
              </div>
              <div class="mt-2 text-gray-600">Last purchased at {{ dataQuota.lastPurchasedAt | dtmType('DD MMM YYYY') }},
                <router-link :to="`/history-quota/${appName}`">view detail</router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-4">
      <div class="kt-portlet kt-portlet--mobile h-100 mb-0">
        <div class="kt-portlet__head kt-portlet__head--lg border-b-0">
          <div class="kt-portlet__head-label flex-wrap">
            <h3 class="kt-portlet__head-title">Total User</h3>
          </div>
        </div>
        <div class="kt-portlet__body pt-0">
          <div class="card-reseller-usage card-reseller-usage-primary">
            <div class="card-title">User</div>
            <div class="count">{{ dataQuota.totalUser | curr }}</div>
          </div>
          <div class="mt-2 text-gray-600">Since {{ dataQuota.createdAt | dtmType('DD MMM YYYY') }}</div>
        </div>
      </div>

    </div>

    <ModalBuyQuota v-if="showModalBuyQuota" title="Add Quota" @close="showModalBuyQuota = false" />
  </div>
</template>

<script>
  import ModalBuyQuota from "@/components/whitelabel/ModalBuyQuota.vue"

  export default {
    props: {
      appName: {},
      dataQuota: {
        default () {
           return {
             total: 0,
             quotaLeft: 0,
             quotaAllocated: 0,
             updatedOn: 0,
             lastPurchasedAt: 0,
             createdAt: 0,
             updatedAt: 0,
             totalUser: 0,
           }
        }
      },
    },
    components: {
      ModalBuyQuota,
    },
    data () {
      return {
        showModalBuyQuota: false
      }
    },
    computed: {
      quotaLeftOnPercent () {
        if (this.dataQuota.total === 0) return 0
        return (this.dataQuota.quotaLeft / this.dataQuota.total) * 100
      },
      showAddQuotaOnEmailLeft () {
        if (this.dataQuota.total === 0) return false
        return (this.dataQuota.quotaLeft / this.dataQuota.total) * 100 > 10
      }
    },
  }
</script>