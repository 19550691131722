<template>
  <Layout :has-subtitle="false" :loading="loading" :show-menu="false">
    <template slot="header">
      <div id="kt_header" class="kt-header kt-grid__item px-4">
        <div class="kt-header__topbar">
          <div class="kt-header__topbar-item kt-header__topbar-item--user">
            <div class="kt-header__topbar-wrapper">
              <router-link :to="`/dashboard/mailtarget`" class="kt-header__topbar-user">
                <div class="d-flex align-items-center"><i class="text-gray-800 flaticon2-cross"></i></div>
              </router-link>
            </div>
          </div>
        </div>
        
        <div class="kt-header-menu-wrapper">
          <div id="kt_header_menu" class="kt-header-menu kt-header-menu-mobile  kt-header-menu--layout-default">
            <div class="d-flex align-items-center">
              <h4 class="text-gray-700">Create Mailtarget User</h4>
            </div>
          </div>
        </div>
        
        <div/>
      </div>
    </template>

    <VeeOb class="kt-form" tag="form" ref="form">
      <div class="card-user-creation mb-4">
        <div class="mb-4">
          <p class="text-bold mb-1">User detail</p>
          <p class="text-gray-70">Complete user information</p>
        </div>
        <Vee class="form-group" tag="div" rules="required|email" name="Email" v-slot="{ errors }">
          <label>Email Address <span class="text-red-60">*</span></label>
          <input v-model="form.email" placeholder="Email" type="text" class="form-control" name="email">
          <span class="text-warning">{{ errors[0] }}</span>
        </Vee>
        <Vee class="form-group" tag="div" rules="required" name="Name" v-slot="{ errors }">
          <label>Full Name <span class="text-red-60">*</span></label>
          <input v-model="form.name" placeholder="Name" type="text" class="form-control" name="name">
          <span class="text-warning">{{ errors[0] }}</span>
        </Vee>
        <Vee class="form-group" tag="div" rules="required|numeric" name="Phone" v-slot="{ errors }">
          <label>Phone Number <span class="text-red-60">*</span></label>
          <div class="phone-number-input-group">
            <input disabled placeholder="+62" type="text" class="form-control phone-country-code">
            <input v-model="form.phone" placeholder="Phone Number" type="text" class="form-control phone-number" name="phone">
          </div>
          <span class="text-warning">{{ errors[0] }}</span>
        </Vee>
      </div>
      <div class="card-user-creation mb-4">
        <div class="mb-4">
          <p class="text-bold mb-1">Business detail</p>
          <p class="text-gray-70">Complete user business information</p>
        </div>
        <div class="business-detail-form">
          <Vee class="form-group" tag="div" rules="required" name="Company Name" v-slot="{ errors }">
            <label>Company Name <span class="text-red-60">*</span></label>
            <input v-model="form.companyName" placeholder="Company Name" type="text" class="form-control h-43px" name="companyName">
            <span class="text-warning">{{ errors[0] }}</span>
          </Vee>
          <Vee class="form-group" tag="div" rules="required|max:50" name="Website" v-slot="{ errors }">
            <label>Website (URL) <span class="text-red-60">*</span></label>
            <input v-model="form.website" placeholder="http:// or https://" type="text" class="form-control h-43px" name="website">
            <span class="text-warning">{{ errors[0] }}</span>
          </Vee>
          <Vee class="form-group" tag="div" rules="required" name="Industry" v-slot="{ errors }">
            <label>Industry <span class="text-red-60">*</span></label>
            <MultiSelect
              v-model="form.industry"
              :options="companyIndustryOption"
              :show-labels="false"
              :searchable="true"
              name="industry" />
            <span class="text-warning">{{ errors[0] }}</span>
          </Vee>
          <Vee class="form-group" tag="div" rules="required" name="City" v-slot="{ errors }">
            <label>Country <span class="text-red-60">*</span></label>
            <MultiSelect
              v-model="form.country"
              :options="countryOptions"
              :show-labels="false"
              :searchable="true"
              @input="getCity"
              name="contry" />
            <span class="text-warning">{{ errors[0] }}</span>
          </Vee>
          <Vee class="form-group" tag="div" rules="required" name="City" v-slot="{ errors }">
            <label>City <span class="text-red-60">*</span></label>
            <MultiSelect
              v-model="form.city"
              :options="cityOptions"
              :show-labels="false"
              :searchable="true"
              name="city" />
            <span class="text-warning">{{ errors[0] }}</span>
          </Vee>
          <Vee class="form-group" tag="div" rules="required" name="Zip" v-slot="{ errors }">
            <label>Postal Code <span class="text-red-60">*</span></label>
            <input v-model="form.zipCode" placeholder="12345" type="text" class="form-control h-43px">
            <span class="text-warning">{{ errors[0] }}</span>
          </Vee>
        </div>
        <Vee class="form-group" tag="div" rules="required" name="Address" v-slot="{ errors }">
          <label>Address <span class="text-red-60">*</span></label>
          <textarea rows="3" v-model="form.address" placeholder="Jln..." class="form-control" />
          <span class="text-warning">{{ errors[0] }}</span>
        </Vee>
      </div>
    </VeeOb>
    <div class="submission-button-group d-flex justify-content-between">
      <button @click="$router.push('/dashboard/mailtarget')" type="button" class="btn btn-upload btn-outline-primary btn-icon-sm">Cancel</button>
      <button @click="createClient" type="button" class="btn btn-upload btn-primary btn-icon-sm">Create User</button>
    </div>
  </Layout>
</template>

<script>
  import Layout from "@/components/Layout.vue"
  import common from "@/factory/common"
  import MultiSelect from 'vue-multiselect'
  import mailtarget from "@/factory/mailtarget"
  import fn from "@/library/fn"

  export default {
    components: {
      Layout,
      MultiSelect
    },
    data() {
      return {
        loading: false,
        form: {
          email: '',
          name: '',
          phone: '',
          companyName: '',
          website: '',
          industry: '',
          country: '',
          city: '',
          zipCode: '',
          address: '',
          reseller: '',
        },
        companyIndustryOption: [
          'Retail', 
          'Finance', 
          'NGO', 
          'Agency', 
          'Other',
        ],
        countryOptions: [],
        cityOptions: [],
      }
    },
    created () {
      common.getCountry()
        .then((res) => this.countryOptions = res)
        .catch((err) => this.$e(err))
    },
    methods: {
      getCity () {
        common.getCity(this.form.country)
          .then((res) => this.cityOptions = res)
          .catch((err) => this.$e(err))
      },
      createClient () {
        this.$refs.form.validate()
          .then(res => {
            if (!res){
              return Promise.reject('validation')
            }
            return mailtarget.createPartnerClient('jatis', this.form)
          })
          .then(() => {
            fn.notySuccess("New client for \"Jatis\" successfully created.")
            this.$router.push('/dashboard/mailtarget')
          })
          .catch((err) => this.$e(err))
      }
    },
  }
</script>

<style scoped lang="scss">
  .card-user-creation {
    background-color: #fff;
    max-width: 600px;
    margin: 0 auto;
    padding: 24px;
    border: 1px solid #E5EAEE;
    border-radius: 6px;
  }
  .submission-button-group {
    max-width: 600px;
    margin: 0 auto;
  }
  .phone-number-input-group {
    display: flex;
  }
  .phone-country-code {
    width: 60px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .phone-number {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .business-detail-form {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    column-gap: 24px;
  }
  .h-43px  {
    height: 43px;
    font-size: 16px;
  }
  textarea {
    resize: none;
  }
</style>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
