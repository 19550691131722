<template>
  <Modal :title="title" @close="$emit('close')">
    <VeeOb ref="form" tag="div">
      <Vee tag="div" rules="required|min:3" name="Company Name" v-slot="{ errors }" class="form-group">
        <label>Company Name *</label>
        <input type="text" v-model="form.company" class="form-control">
        <span class="text-warning">{{ errors[0] }}</span>
      </Vee>
      <Vee tag="div" rules="required|min:3" name="Owner Email" v-slot="{ errors }" class="form-group">
        <label>Owner Email *</label>
        <input disabled type="text" v-model="form.email" class="form-control">
        <span class="text-warning">{{ errors[0] }}</span>
      </Vee>
      <Vee tag="div" rules="required|min:3" name="Legal Name" v-slot="{ errors }" class="form-group">
        <label>Legal Name *</label>
        <input type="text" v-model="form.legalName" class="form-control">
        <span class="text-warning">{{ errors[0] }}</span>
      </Vee>
      <Vee tag="div" rules="required|min:3" name="Legal Address" v-slot="{ errors }" class="form-group">
        <label>Legal Address *</label>
        <input type="text" v-model="form.address" class="form-control">
        <span class="text-warning">{{ errors[0] }}</span>
      </Vee>
      <Vee tag="div" rules="required|digits:15" name="NPWP" v-slot="{ errors }" class="form-group">
        <label>NPWP *</label>
        <input type="text" v-model="form.npwp" class="form-control" @keypress="onlyNumber">
        <span class="text-warning">{{ errors[0] }}</span>
      </Vee>
    </VeeOb>
    <template v-slot:footer>
      <button @click.prevent="$emit('close')" type="button" class="btn btn-secondary" :class="{wait: saving}" data-dismiss="modal">Close</button>
      <button @click.prevent="update" type="button" class="btn btn-primary" :class="{wait: saving}" :disabled="disabled">Update</button>
    </template>
  </Modal>
</template>

<script>
  import Modal from '@/components/partial/Modal'
  import payment from '@/factory/payment'
  import fn from '@/library/fn'
  
  export default {
    components: { Modal },
    data () {
      return {
        title: 'Update company legal data',
        saving: false,
        form: {
          name: '',
          email: '',
          company: '',
          address: '',
          legalName: '',
          npwp: ''
        },
        disabled: false
      }
    },
    computed: {
      companyId () { return this.$route.params.companyId }
    },
    created () {
      this.get()
    },
    methods: {
      get () {
        payment.getPaymentSetting(this.companyId).then(res => {
          this.form.name = res.name
          this.form.email = res.email
          this.form.company = res.company
          this.form.address = res.address
          this.form.legalName = res.legalName
          this.form.npwp = res.npwp
        }).catch(err => this.$e(err))
      },
      update () {
        this.disabled = true
        this.$refs.form.validate()
          .then(res => {
            if (!res){
              fn.notyWarn('validation')
              return Promise.reject()
            }
          })
          .then(() => {
            this.editPaymentSetting()
          })
          .catch(err => this.$e(err))
          .finally(() => {
            this.disabled = false
          })
      },
      editPaymentSetting () {
        this.saving = true
        this.form.teamId = this.companyId
        payment.editPaymentSetting(this.form)
          .then(() => {
            this.$emit('success-update')
            fn.notySuccess('Company data successfully updated.')
          })
          .catch(err => this.$e(err))
          .finally(() => {
            this.saving = false
          })
      },
      onlyNumber ($event) {
        let key = ($event.keyCode ? $event.keyCode : $event.which)
        if ((key < 48 || key > 57) && key !== 46 && key !== 45) {
          $event.preventDefault()
        }
      }
    }
  }
</script>
