<template>
  <div class="input-group">
    <input v-model="searchText" @keydown.enter="search" type="text" class="form-control" placeholder="Search">
    <div class="input-group-append">
      <button @click="search" class="btn" style="z-index: 1;" :class="[btnClass]" type="button">Go!</button>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      value: { default: null },
      btnClass: { default: 'btn-primary' }
    },
    data () {
      return {
        searchText: null
      }
    },
    watch: {
      value (val) { if (val !== this.searchText) this.searchText = val },
      searchText (val) { this.$emit('input', val) }
    },
    created () {
      this.init()
    },
    methods: {
      init () {
        this.searchText = this.value
      },
      search () {
        this.$emit('search', 's', this.searchText)
      }
    }
  }
</script>
