<template>
  <Modal :title="title" @close="$emit('close')">
    <p>Are you sure you want to <strong>EXTEND TRIAL</strong> for this Company?</p><br>
    <div class="form-group">
      <div class="row align-items-center">
        <div class="col-12 col-sm-4">
          <label>End Date</label>
        </div>
        <div class="col-12 col-sm">
          <DatePicker v-model="endDate" :customConfig="dateConfig" />
        </div>
      </div>
    </div>
    <template v-slot:footer>
      <button @click.prevent="$emit('close')" type="button" class="btn btn-secondary" :class="{wait: saving}" data-dismiss="modal">Close</button>
      <button @click.prevent="extend" type="button" class="btn btn-primary" :class="{wait: saving}">Extend Trial</button>
    </template>
  </Modal>
</template>

<script>
  import Modal from '@/components/partial/Modal'
  import DatePicker from '@/components/partial/DatePicker'
  import moment from 'moment'
  import payment from '@/factory/payment'
  import fn from '@/library/fn'

  export default {
    components: { Modal, DatePicker },
    props: ['company'],
    data () {
      return {
        title: 'Extend Plan',
        endDate: null,
        dateConfig: {
          enableTime: false
        },
        saving: false
      }
    },
    computed: {
      companyId () { return this.$route.params.companyId }
    },
    methods: {
      init () {
        let now = moment().format('x')
        if (now < this.company.expiredDate) {
          this.dateConfig.minDate = new Date(this.company.expiredDate)
        }
      },
      extend () {
        let data = {
          teamId: this.companyId,
          periodEnd: this.endDate
        }
        this.saving = true
        payment.extendTrial(data)
          .then(() => {
            this.saving = false
            fn.notySuccess("Free trial packet successfully extended.")
            this.$emit('extend-trial-success')
          })
          .catch((err) => {
            this.saving = false
            this.$e(err)
          })
      }
    }
  }
</script>
