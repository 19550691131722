import store from '@/store'

export default {
  computed: {
    userRoles () { return store.getters.userRoles },
    roleAdmin () { return this.userRoles.includes('ADMIN') },
    roleDeveloper () { return this.userRoles.includes('DEVELOPER') },
    roleGrowth () { return this.userRoles.includes('GROWTH') },
    roleRevenueAdmin () {return this.userRoles.includes('REVENUE ADMIN') },
    roleRevenue () { return (this.userRoles.includes('REVENUE') || this.userRoles.includes('REVENUE ADMIN')) },
    roleFinance () { return this.userRoles.includes('FINANCE') },
    roleSupport () { return this.userRoles.includes('SUPPORT') },
    roleOnlySupport () { return (this.roleSupport && this.userRoles.length === 1) }
  }
}
