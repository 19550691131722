<template>
  <div :class="{'input-group': config.wrap}">
    <slot name="button">
      <div v-if="config.wrap" class="input-group-prepend">
        <button type="button" class="btn btn-secondary ml-0" :class="customBtnClass" data-toggle>
          <i class="la la-calendar"></i> Custom
        </button>
      </div>
    </slot>
    <Flatpicker
      v-if="show"
      ref="flatp"
      v-model="date"
      :id="`flatpickr-${randomId}`"
      class="form-control pointer"
      :class="customClass"
      :config="config"
      :placeholder="placeholder"
      :disabled="disabled"
      :hidden="pickerHidden"
      @on-change="setValue"
      @on-close="onClose"
    />
    <slot name="button-append"></slot>
  </div>
</template>

<script>
  import Flatpicker from 'vue-flatpickr-component'
  import moment from 'moment'
  import confirmDatePlugin from 'flatpickr/dist/plugins/confirmDate/confirmDate'

  const randomId = Math.random().toString(36).substring(7)

  export default {
    components: { Flatpicker },
    props: {
      value: { default: null },
      customConfig: { default: null },
      customBtnClass: { default: '' },
      customClass: { default: '' },
      pickerHidden: { default: false },
      forceIncrement: { default: false },
      timestamp: { default: true },
      placeholder: { default: 'Select Date' },
      disabled: { default: false },
    },
    data () {
      return {
        randomId,
        config: {
          altInput: true,
          // altFormat: 'Y-m-d',
          // dateFormat: 'U',
          altFormat: "YYYY-MM-DD",
          dateFormat: "x",
          mode: 'single', // single, multiple, range
          enableTime: false,
          time_24hr: false,
          minDate: null,
          maxDate: null,
          formatDate: (date, format) => { // (date, format, locale)
            return moment(date).format(format)
          }
        },
        show: false,
        date: null
      }
    },
    watch: {
      date (val) {
        let date = ""
        if (!['', null].includes(val)) {
          const timeArr = val.split(" to ")
          if (timeArr.length === 1)
            date = moment(this.parse(timeArr[0])).format(this.config.altFormat)
          else
            date = timeArr.map(x => {
              return moment(this.parse(x)).format(this.config.altFormat)
            }).join(" to ")
        }
        this.$emit('update:val', date)
      },
      value: {
        deep: true,
        handler(val, old) {
          if (this.$refs.flatp && !this.$refs.flatp.fp.isOpen) {
            if (this.config.mode === 'range') {
              const dateVal = this.setDate(val)
              if (String(val) !== String(old) && this.isRangeChange(dateVal)) {
                this.$refs.flatp.fp.setDate(dateVal, true)
              }
            } else {
              if (String(val) !== String(this.date)) {
                const dateVal = this.setDate(val)
                this.$refs.flatp.fp.setDate(dateVal, true)
              }
            }
          }
        }
      }
    },
    mounted () {
      this.init()
    },
    methods: {
      init() {
        if (this.customConfig) {
          this.config = {...this.config, ...this.customConfig}

          if(this.config.enableTime) {
            this.config.plugins = [new confirmDatePlugin({
              confirmText: 'Ok',
              confirmIcon: ``,
              showAlways: false,
            })]
          }

          if (this.config.maxDate === 'today') {
            this.config.maxDate = this.parse(moment().endOf('day').format(this.config.dateFormat))
          }

          this.config.onOpen = [
            (selectedDates, dateStr, instance) => {
              if (!!instance.config.allowInput && instance.config.enableTime) {
                instance.hourElement.setAttribute("readonly", "readonly")
                instance.minuteElement.setAttribute("readonly", "readonly")
              }
            }
          ]

          if (this.forceIncrement) {
            this.config.onClose = [
              (selectedDates, dateStr, instance) => {
                setTimeout(() => {
                  const interval = instance.config.minuteIncrement
                  const d = instance.latestSelectedDateObj
                  if (d === undefined) return
                  const mins = d.getMinutes()

                  if (mins % interval) {
                    d.setMinutes(interval * Math.round(mins/ interval))
                  }

                  instance.setDate(d, false)
                })
              }
            ]
          }
        }

        this.show = true

        setTimeout(() => {
          if (this.$refs.flatp) {
            const dateVal = this.setDate(this.value)
            this.$refs.flatp.fp.setDate(dateVal, true)
          }
        })
      },
      isRangeChange(newDate = []) {
        if (!this.date)
          return this.date !== newDate

        const range = this.date.split(' to ')

        if (range.length === 1) {
          return newDate[0] !== this.parse(range[0])
        } else {
          return this.date !== newDate.join(' to ')
        }
      },
      parse(val) {
        if (['x', 'X'].includes(this.config.dateFormat))
          return parseInt(val)
        return val
      },
      // START SET DATE
      setDate(val) {
        if (this.config.mode === 'range') {
          return this.setDateRange(val)
        } else {
          return this.setDateSingle(val)
        }
      },
      setDateRange (val) {
        if (!val || (Array.isArray(val) && val.length < 1)) {
          return []
        } else if (!Array.isArray(val)) {
          return [this.parse(val), this.parse(val)]
        } else {
          if (this.config.dateFormat === 'x') {
            return [
              this.parse(val[0]),
              moment(this.parse(val[1] || val[0])).format('X') * 1000
            ]
          } else {
            return [this.parse(val[0]), this.parse(val[1] || val[0])]
          }
        }
      },
      setDateSingle (val) {
        if (!val) { // !val && val !== 0
          return null
        } else {
          return this.parse(val)
        }
      },
      // END SET DATE
      // SET VALUE
      setValue () {
        setTimeout(() => {
          if (this.config.mode === 'range') {
            this.setValueRange()
          } else {
            this.setValueSingle()
          }
        })
      },
      setValueRange () {
        if (this.$refs.flatp && this.$refs.flatp.fp.isOpen) {
          return
        } else if (!this.date) {
          this.$emit('input', [])
        } else {
          const range = this.date.split(' to ')

          if (String(range) === String(this.value)) return

          if (range.length === 1 && !this.config.enableTime) {
            range[0] = moment(this.parse(range[0])).startOf('day').format(this.config.dateFormat)
            if (this.config.dateFormat === 'x') {
              range[1] = moment(this.parse(range[0])).endOf('day').format('X') * 1000
            } else {
              range[1] = moment(this.parse(range[0])).endOf('day').format(this.config.dateFormat)
            }
          } else if (this.config.enableTime) {
            if (range.length === 1) range[1] = range[0]
            range[0] = moment(this.parse(range[0])).format(this.config.dateFormat)
            range[1] = moment(this.parse(range[1])).format(this.config.dateFormat)
          } else {
            range[0] = moment(this.parse(range[0])).startOf('day').format(this.config.dateFormat)
            if (this.config.dateFormat === 'x') {
              range[1] = moment(this.parse(range[1])).endOf('day').format('X') * 1000
            } else {
              range[1] = moment(this.parse(range[1])).endOf('day').format(this.config.dateFormat)
            }
          }

          this.$emit('input', [this.parse(range[0]), this.parse(range[1])])
        }
      },
      setValueSingle () {
        this.$emit('input', this.parse(this.date))
      },
      // END SET VALUE
      onClose () {
        if(this.config.enableTime) {
          this.setValue()
        }
      }
    }
  }
</script>

<style scoped>
  ::placeholder {
    color: #C5C7CA;
  }
</style>
