<template>
  <Modal :title="subtitle" @close="close" size="lg">

    <div class="row">
      <div class="col-12">
        <div class="kt-portlet kt-portlet--mobile">
          <div class="kt-portlet__head kt-portlet__head--lg flex-wrap">
            <div class="kt-portlet__head-label flex-wrap my-1">
              <h3 class="kt-portlet__head-title">
                Info Template
              </h3>
            </div>
          </div>

          <div v-if="data !== null" class="kt-portlet__body kt-portlet__body">
            <div class="form-group">
              <div class="row">
                <div class="col-12 col-sm-4">
                  <span>Name</span>
                </div>
                <div class="col-12 col-sm-8">
                  <span class="word-break-all">: {{ data.name }}</span>
                </div>
              </div>
            </div>
            <div class="form-group">
              <div class="row">
                <div class="col-12 col-sm-4">
                  <span>Category</span>
                </div>
                <div class="col-12 col-sm-8">
                  <span class="word-break-all">: {{ data.category }}</span>
                </div>
              </div>
            </div>
            <div class="form-group">
              <div class="row">
                <div class="col-12 col-sm-4">
                  <span>Language</span>
                </div>
                <div class="col-12 col-sm-8">
                  <span class="word-break-all">: {{ data.language }}</span>
                </div>
              </div>
            </div>
            <div class="form-group">
              <div class="row">
                <div class="col-12 col-sm-4">
                  <span>Status</span>
                </div>
                <div class="col-12 col-sm-8">
                  <span class="kt-badge kt-badge--inline kt-badge--pill"
                    :class="{
                      'kt-badge--primary': data.status === 'PENDING',
                      'kt-badge--info': data.status === 'REQUEST',
                      'kt-badge--warning': data.status === 'REVIEWED',
                      'kt-badge--success': data.status === 'APPROVED',
                      'kt-badge--danger': data.status === 'REJECTED',
                      'kt-badge--dark': data.status === 'DELETED'
                    }">
                    {{ data.status }}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="kt-portlet kt-portlet--mobile">
          <div class="kt-portlet__body kt-portlet__body--fit bg-conversation">
            <div class="conversation-container">
              <div class="kt-portlet kt-portlet--mobile ticket-item-chat mb-0" :class="{'is-admin': !data.member}">
                <div class="kt-portlet__body kt-portlet__body--sm">
                  <div class="item-chat-box">
                    <div class="chat-detail">
                      <div class="chat-name">{{ data.name }}</div>
                      <div class="chat-message">{{ data.body }}</div>
                      <div v-if="data.attachment" class="chat-image">
                        <a @click="$emit('show-image', data.attachment)" class="text-primary cursor-pointer">
                          {{ data.attachment.name }}
                        </a>
                      </div>
                      <div class="chat-date">
                        <span>{{ data.createdAt | dtm }}</span>
                      </div>
                      <div class="text-right">
                        <button @click="copy" class="btn btn-md btn-primary">Copy Message</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <ViewFile
      v-if="fileView !== null"
      :url="fileView.url"
      :name="fileView.name"
      :type="fileView.type"
      @close="fileView = null" />
  </Modal>
</template>

<script>
  import Modal from "@/components/partial/Modal"
  import ViewFile from "@/components/partial/ViewFile"
  import fn from '@/library/fn'

  export default {
    components: {
      Modal,
      ViewFile
    },
    props: ['data'],
    data() {
      return {
        loading: false,
        fileView: null
      }
    },
    computed: {
      ticketId () { return this.$route.params.ticketId },
      subtitle () { return (this.data !== null) ? 'Template: ' + this.data.name : '' }
    },
    methods: {
      close () {
        this.$emit('close')
      },
      copy () {
        const el = document.createElement('textarea')
        el.value = this.data.message
        el.setAttribute('readonly', '')
        el.style.position = 'absolute'
        el.style.left = '-9999px'
        document.body.appendChild(el)
        el.select()
        document.execCommand('copy')
        document.body.removeChild(el)
        fn.notySuccess("Message copied.")
      }
    }
  }
</script>
<style lang="scss" scoped>
  @import "~@/assets/scss/_config.scss";

  .ticket-item-chat {
    border: 2px solid #f5f5f5;
    // margin-right: 2rem;
    &.is-admin {
      // border-top: 4px solid kt-get($kt-state-colors, warning, base);
      margin-right: 0;
      // margin-left: 2rem;
    }
    .item-chat-box {
      display: flex;
      // padding: 1rem;
      .chat-detail {
        flex: 1;
        .chat-name {
          font-size: 16px;
          font-weight: 500;
          a {
            font-size: 12px;
          }
        }
        .chat-message {
          font-size: 14px;
          margin-top: 4px;
          margin-bottom: 16px;
          border: 1px solid kt-get($kt-base-colors, label, 1);
          background-color: kt-get($kt-base-colors, shape, 1);
          border-radius: 4px;
          padding: 8px;
        }
        .chat-image {
          margin-top: 4px;
        }
        .chat-date {
          text-align: right;
          margin-top: 4px;
        }
      }
    }
  }
  .bg-conversation {
    background-color: #fafafa;
  }
  .conversation-container {
    // max-height: 600px;
    // overflow-y: auto;
  }
</style>
