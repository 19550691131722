import moment from "moment"

export default {
  data () {
    return {
      range: 'All Time',
      rangeOptions: [
        'All Time',
        'Last 1 Day',
        'Last 7 Days',
        'Last 30 Days',
        'Last 3 Months',
        'Last 6 Months',
        'Last 9 Months',
        'Last 1 Year',
        'Custom Range'
      ],
      period: [],
      customRange: {
        mode: 'range',
        enableTime: false,
        minDate: null,
        maxDate: null
      }
    }
  },
  computed: {
    moreThen30Days () {
      if (['Last 7 Days', 'Last 30 Days'].includes(this.range)) return false
      if (this.range === 'Custom Range') {
        if (this.period === null) return true
        if (this.period.length === 0) return true
        const start = this.period[0] || 0
        const end = this.period[1] || 0
        return moment(end).diff(start, 'days') > 30
      }
      return true
    }
  },
  mounted () {
    // this.initRange()
  },
  methods: {
    initRange () {
      this.setPeriod(this.range)
    },
    selectRange (selectedRange) {
      this.range = selectedRange
      this.setPeriod(selectedRange)
    },
    setPeriod (range) {
      switch (range) {
        case 'All Time':
          this.period = []
          break
        case 'Custom Range':
          this.period = []
          break
        case 'Today':
          this.period = [
            this.stamp(0),
            this.stamp()
          ]
          break
        case 'Last 1 Day':
          this.period = [
            this.stamp(1),
            this.stamp()
          ]
          break
        case 'Last 7 Days':
          this.period = [
            this.stamp(7),
            this.stamp()
          ]
          break
        case 'Last 14 Days':
          this.period = [
            this.stamp(14),
            this.stamp()
          ]
          break
        case 'Last 30 Days':
          this.period = [
            this.stamp(30),
            this.stamp()
          ]
          break
        case 'Last 3 Months':
          this.period = [
            this.stamp(90),
            this.stamp()
          ]
          break
        case 'Last 6 Months':
          this.period = [
            this.stamp(180),
            this.stamp()
          ]
          break
        case 'Last 9 Months':
          this.period = [
            this.stamp(270),
            this.stamp()
          ]
          break
        case 'Last 1 Year':
          this.period = [
            this.stamp(365),
            this.stamp()
          ]
          break
        default:
          this.period = []
          break
      }
    },
    setRange (dates) {
      this.period = dates
      if (dates[1] === this.stamp()) {
        if (dates[0] === this.stamp(0)) {
          this.range = 'Today'
        } else if (dates[0] === this.stamp(1)) {
          this.range = 'Last 1 Day'
        } else if (dates[0] === this.stamp(7)) {
          this.range = 'Last 7 Days'
        } else if (dates[0] === this.stamp(14)) {
          this.range = 'Last 14 Days'
        } else if (dates[0] === this.stamp(30)) {
          this.range = 'Last 30 Days'
        } else if (dates[0] === this.stamp(90)) {
          this.range = 'Last 3 Months'
        } else if (dates[0] === this.stamp(180)) {
          this.range = 'Last 6 Months'
        } else if (dates[0] === this.stamp(270)) {
          this.range = 'Last 9 Months'
        } else if (dates[0] === this.stamp(365)) {
          this.range = 'Last 1 Year'
        } else {
          this.range = 'Custom Range'
        }
      } else if (dates[0] === 0 || dates[0] === undefined) {
        this.range = 'All Time'
      } else {
        this.range = 'Custom Range'
      }
    },
    stamp (day) {
      if (day === undefined) return parseInt(moment().endOf('day').format('X')) * 1000
      else if (day === 0) return parseInt(moment().startOf('day').format('X')) * 1000
      else if (day > 0) return parseInt(moment().subtract(day, 'd').startOf('day').format('X')) * 1000
    },
    getLastCount (range) {
      if (range === 'All Time') return null
      else if (range === 'Today') return 0
      else if (range === 'Last 1 Day') return 1
      else if (range === 'Last 7 Days') return 7
      else if (range === 'Last 30 Days') return 30
      else if (range === 'Last 3 Months') return 90
      else if (range === 'Last 6 Months') return 180
      else if (range === 'Last 9 Months') return 270
      else if (range === 'Last 1 Year') return 365
      else return 0
    },
    getLastRange (last = null) {
      if (last === null) return `All Time`
      else if ([0].includes(last)) return `Today`
      else if ([1].includes(last)) return `Last ${last} Day`
      else if ([7, 30].includes(last)) return `Last ${last} Days`
      else if ([90, 180, 270].includes(last)) return `Last ${last/30} Months`
      else if ([365].includes(last)) return 'Last 1 Year'
      else return 'Custom Range'
    }
  }
}
