<template>
  <div>
    <slot></slot>
  </div>
</template>

<script>
  import '@/assets/template/sass/style.scss'
  import '@/assets/scss/style.scss'

  export default {
    props: {
      title: {
        default: null
      }
    }
  }
</script>