<template>
  <Modal :title="title" @close="$emit('close')">
    <template>
      <div class="form-group">
        <div class="row align-items-center">
          <div class="col-12 col-sm-4">
            <span>Cut Off Period</span>
          </div>
          <div class="col-6 col-sm-4">
            <input type="text" disabled value="Monthly, every" class="form-control">
          </div>
          <div class="col-6 col-sm-4">
            <select v-model="cutOffPeriod" class="form-control" :disabled="true">
              <option v-for="i in 28" :key="i" :value="i">{{ i }}</option>
            </select>
          </div>
        </div>
      </div>
      <div class="form-group">
        <div class="row align-items-center">
          <div class="col-12 col-sm-4">
            <span>Price /email</span>
          </div>
          <div class="col-6 col-sm-4">
            <InputCurrency v-model="emailPrice" :toFixed="2" :disabled="true" />
          </div>
          <div class="col-6 col-sm-4">
            <input type="text" class="form-control" :value="`/email`" disabled>
          </div>
        </div>
      </div>
      <div class="form-group">
        <div class="row align-items-center">
          <div class="col-12 col-sm-4">
            <span>Min. Fair Usage</span>
          </div>
          <div class="col-12 col-sm-8">
            <InputCurrency v-model="minFairUsage" :disabled="true" prefix="" :toFixed="0" />
          </div>
        </div>
      </div>
      <div class="form-group">
        <div class="row align-items-center">
          <div class="col-12 col-sm-4">
            <span>Minimum Payment</span>
          </div>
          <div class="col-12 col-sm-8">
            <div class="row">
              <div class="col-12 col-sm">
                <InputCurrency :value="minPricePayAsYouGo" :disabled="true" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="form-group">
        <div class="row align-items-center">
          <div class="col-12 col-sm-4">
            <span>Email Sent</span>
          </div>
          <div class="col-12 col-sm-8">
            <InputCurrency v-model="emailSent" prefix="" :toFixed="0" />
          </div>
        </div>
      </div>
      <div class="form-group">
        <div class="row align-items-center">
          <div class="col-12 col-sm-4">
            <label>Payment Fee</label>
          </div>
          <div class="col-12 col-sm-8">
            <div class="row">
              <div class="col-12 col-sm">
                <InputCurrency :value="paymentVee" :disabled="true" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
    <template v-slot:footer>
      <button @click.prevent="$emit('close')" type="button" class="btn btn-secondary" :class="{wait: saving}" data-dismiss="modal">Close</button>
      <button @click.prevent="edit" type="button" class="btn btn-primary" :class="{wait: saving}">Yes</button>
    </template>
  </Modal>
</template>

<script>
  import Modal from '@/components/partial/Modal'
  import InputCurrency from '@/components/partial/InputCurrency'
  import payment from '@/factory/payment'
  import fn from '@/library/fn'

  export default {
    components: {
      Modal,
      InputCurrency
    },
    props: ['data'],
    data () {
      return {
        title: 'Edit Billing Pay As You Go',
        cutOffPeriod: 21,
        emailPrice: 0,
        minFairUsage: 0,
        emailSent: 0,
        saving: false
      }
    },
    computed: {
      minPricePayAsYouGo () { return this.emailPrice * this.minFairUsage },
      paymentVee () { return this.emailPrice * this.emailSent }
    },
    created () {
      this.init()
    },
    methods: {
      init () {
        this.cutOffPeriod = this.data.packet.cutOffPeriod
        this.emailPrice = this.data.packet.emailPrice
        this.minFairUsage = this.data.packet.minFairUsage
        this.emailSent = this.data.emailSent
      },
      edit () {
        let data = {
          billingId: this.data.invoice,
          emailSent: this.emailSent
        }
        this.saving = true
        payment.editBillingPAYG(data)
          .then((res) => {
            if (res) {
              fn.notySuccess("Billing successfully edited.")
              this.$emit('edit-success')
            } else {
              fn.notyError("Failed to edit Billing.")
              this.$emit('close')
            }
            this.saving = false
          })
          .catch((err) => {
            this.saving = false
            this.$e(err)
          })
      }
    }
  }
</script>
