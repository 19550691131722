<template>
  <ul class="nav nav-tabs nav-tabs-line" role="tablist">
    <li class="nav-item cursor-pointer" v-for="(data, i) in tabs" :key="i" :class="itemClass">
      <a @click="selectTab(data)" class="nav-link" :class="{active: data[activeKey] === active}" data-toggle="tab" role="tab">
        <slot name="item" :tab="data">
          <i v-if="icon" class="la" :class="data.icon"></i> {{ data.menu }}
        </slot>
      </a>
    </li>
  </ul>
</template>

<script>
  export default {
    props: {
      tabs: {
        type: Array,
        default: () => []
      },
      active: {
        type: String
      },
      icon: {
        default: false
      },
      itemClass: {
        default: null
      },
      activeKey: {
        default: 'menu'
      }
    },
    methods: {
      selectTab (tab) {
        this.$emit('select', tab)
      }
    }
  }
</script>

<style lang="scss">
  .cursor-pointer {
    cursor: pointer;
  }
  .nav-tabs.nav-tabs-line .nav-item{
    margin-right: 58px;
  }
</style>
