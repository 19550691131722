import { loggedGet, loggedDel, loggedPost } from '@/library/ajax'
const apiUrl = process.env.VUE_APP_API_URL

export default {
  getPromo: (filter) => loggedGet(apiUrl + '/promo', filter),
  savePromo: (data) => loggedPost(apiUrl + '/promo', data),
  getDetailPromo: (code) => loggedGet(apiUrl + '/promo/detail', { code }),
  deletePromo: (id) => loggedDel(apiUrl + '/promo/delete', { id }),
  getPromoUsage: (filter) => loggedGet(apiUrl + '/promo/usage', filter),
  checkPromo: (code, teamId) => loggedGet(apiUrl + '/promo/check', { code, teamId }),
  applyPromo: (invoice, promoCode) => loggedGet(apiUrl + '/promo/apply', { invoice, promoCode }),
}
