import { loggedGet, loggedPost, loggedDel } from '@/library/ajax'
const apiUrl = process.env.VUE_APP_API_URL

export default {
  getCompany: (filter) => loggedGet(apiUrl + '/company', filter),
  deleteAccount: (companyId, password) => loggedDel(`${apiUrl}/company/${companyId}`, { password }),
  exportCompanyList: (filter) => loggedGet(apiUrl + '/company/export', filter),
  getCompanyDetail: (teamId) => loggedGet(apiUrl + '/company/detail', { teamId }),
  setCompanyNote: (formData) => loggedPost(apiUrl + '/company/note', formData),
  deleteCompanyNote: (teamId) => loggedDel(apiUrl + '/company/note', { teamId }),
  setMemberLimit: (teamId, limit) => loggedPost(apiUrl + '/company/member/limit', { teamId, limit }),
  getMember: (teamId) => loggedGet(apiUrl + '/company/member', { teamId }),
  inviteMember: (teamId, email, role) => loggedPost(apiUrl + '/company/member/invite', { teamId, email, role }),
  editMember: (data) => loggedPost(apiUrl + '/company/member/edit', data),
  deleteMember: (teamId, email) => loggedDel(apiUrl + '/company/member/delete', { teamId, email }),
  ownerMember: (memberId) => loggedPost(apiUrl + '/company/member/owner', { memberId }),
  getToken: (memberId, filter = {}) => {
    filter.memberId = memberId
    return loggedGet(apiUrl + '/company/member/token', filter)
  },
  getTokenPayment: (teamId) => loggedGet(apiUrl + '/company/member/token/payment', { teamId }),
  generateToken: (memberId) => loggedPost(apiUrl + '/company/member/token', { memberId }),
  generateTokenPayment: (teamId) => loggedPost(apiUrl + '/company/member/token/payment', { teamId }),
  deleteToken: (tokenId) => loggedDel(apiUrl + '/company/member/token', { tokenId }),
  getDomain: (teamId) => loggedGet(apiUrl + '/company/domain', { teamId }),
  saveDomain: (teamId, domain) => loggedPost(apiUrl + '/company/domain/add', { teamId, domain }),
  deleteDomain: (permittedDomainId) => loggedDel(apiUrl + '/company/domain/delete', { permittedDomainId }),
  getCustomDomain: (filter) => loggedGet(apiUrl + '/company/custom-domain', filter),
  verifyCustomDomain: (teamId, domainId, docs = []) => loggedPost(apiUrl + '/company/custom-domain/verify', { teamId, domainId, docs }),
  activateCustomDomain: (teamId, domainId) => loggedPost(apiUrl + '/company/custom-domain/activate', { teamId, domainId }),
  documentCustomDomain: (teamId, domainId) => loggedGet(apiUrl + '/company/custom-domain/upload-url', { teamId, domainId }),
  getSender: (teamId) => loggedGet(apiUrl + '/company/sender', { teamId }),
  getEmailSentToTeam: (teamId) => loggedGet(apiUrl + '/company/' + teamId + '/send-suggestion', {}),
  saveSender: (teamId, data = {}) => {
    data.teamId = teamId
    return loggedPost(apiUrl + '/company/sender/add', data)
  },
  disableSender: (teamId, senderId, disable = true) => loggedPost(apiUrl + '/company/sender/disable', { teamId, senderId, disable }),
  verifySender: (teamId, senderId) => loggedPost(apiUrl + '/company/sender/verify', { teamId, senderId }),
  deleteSender: (teamId, senderId) => loggedDel(apiUrl + '/company/sender/delete', { teamId, senderId }),
  getCompanyActivity: (teamId, filter = {}) => {
    filter.teamId = teamId
    return loggedGet(apiUrl + '/company/activity', filter)
  },
  getActionOptions: () => loggedGet(apiUrl + '/company/activity/actions', {}),
  getReferencesOptions: () => loggedGet(apiUrl + '/company/activity/references', {}),
  enableDowngrade: (teamId, enable = false) => loggedPost(apiUrl + '/company/enable-downgrade', { teamId, enable }),
  setInternal: (teamId, internal = false) => loggedPost(apiUrl + '/company/set-internal', { teamId, internal }),
  clearHardbounce: (teamId) => loggedPost(apiUrl + '/company/hardbounce/clean', { teamId }),
  deleteHardbounce: (teamId) => loggedPost(apiUrl + '/company/hardbounce/delete', { teamId }),
  warnCompany: (teamId) => loggedPost(apiUrl + '/company/warn', { teamId }),
  suspendCompany: (teamId, suspend = true) => loggedPost(apiUrl + '/company/suspend', { teamId, suspend }),
  setGracePeriod: (teamId, gracePeriod) => loggedPost(apiUrl + '/company/set-grace-period', { teamId, gracePeriod }),
  emailStatistic: (teamId, start = 0, end = 0, type = 'ALL', extra = null, bulk = null) => loggedGet(apiUrl + '/company/email-statistic', { teamId, start, end, type, extra, bulk }),
  paymentHistory: (teamId) => loggedGet(apiUrl + '/company/paid-list', { teamId }),
  setCompanyPIC: (teamId, picId, pic) => loggedPost(apiUrl + '/company/pic', { teamId, picId, pic }),
  getUserState: (teamId, page, size) => loggedGet(apiUrl + '/company/user-state', { teamId, page, size }),
  verifyOwner: (teamId) => loggedPost(apiUrl + '/company/verify-owner', { teamId }),
  exportEmailStatistic: (teamId, start = 0, end = 0, type = 'ALL', extra = null, bulk = null) => loggedGet(apiUrl + '/company/email-statistic/export', { teamId, start, end, type, extra, bulk }),
  addAttachment: (file) => loggedPost(apiUrl + '/company/file-meeting', file),
  deleteAttachment: (fileId) => loggedDel(apiUrl + '/company/file-meeting', fileId)
}
