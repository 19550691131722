<template>
  <div class="kt-portlet kt-portlet--mobile">
    <div class="kt-portlet__head kt-portlet__head--lg border-bottom">
      <div class="kt-portlet__head-label">
        <h3 class="kt-portlet__head-title font-weight-bold">
          Client Usage
        </h3>
      </div>
    </div>

    <div class="kt-portlet__body kt-portlet__body--lg">
      <div class="mb-4">
        <div class="row">
          <div class="col px-4 my-4 border-right">
            <div class="row">
              <div class="col-auto">
                <EmailSvg />
              </div>
              <div class="col">
                <h5>Email</h5>
                <h3>{{ quota.emailUsed }}</h3>
              </div>
            </div>
          </div>
          <div class="col px-4 my-4 border-right">
            <div class="row">
              <div class="col-auto">
                <ContactSvg/>
              </div>
              <div class="col">
                <h5>Contact</h5>
                <h3>{{ quota.contactUsed }}</h3>
              </div>
            </div>
          </div>
          <div class="col px-4 my-4 border-right">
            <div class="row">
              <div class="col-auto">
                <FormSvg />
              </div>
              <div class="col">
                <h5>Form</h5>
                <h3>{{ quota.formPublishUsed }}</h3>
              </div>
            </div>
          </div>
          <div class="col px-4 my-4 border-right">
            <div class="row">
              <div class="col-auto">
                <LandingPageSvg />
              </div>
              <div class="col">
                <h5>Landing Page</h5>
                <h3>{{ quota.pagePublishUsed }}</h3>
              </div>
            </div>
          </div>
          <div class="col px-4 my-4">
            <div class="row">
              <div class="col-auto">
                <MediaSvg />
              </div>
              <div class="col">
                <h5>Media</h5>
                <h3>{{ quota.mediaUsed }}</h3>
              </div>
            </div>
          </div>
        </div>
      </div>

      <ul class="nav nav-pills nav-pills-sm nav-pills-label nav-pills-bold mb-4" role="tablist">
        <li class="nav-item">
          <a class="nav-link cursor-pointer" @click="tab = 'regular'" :class="{active: (tab === 'regular')}" data-toggle="tab" role="tab" :aria-selected="(tab === 'regular')">
            Regular
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link cursor-pointer" @click="tab = 'extra'" :class="{active: (tab === 'extra')}" data-toggle="tab" role="tab" :aria-selected="(tab === 'extra')">
            Extra Quota
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link cursor-pointer" @click="tab = 'bulk'" :class="{active: (tab === 'bulk')}" data-toggle="tab" role="tab" :aria-selected="(tab === 'bulk')">
            Bulk Quota
          </a>
        </li>
      </ul>

      <div class="tab-content">
        <div v-if="tab === 'regular'" class="tab-pane" :class="{active: (tab === 'regular')}" id="usage-tab-regular" :aria-expanded="(tab === 'regular')">
          <EmailStatistic :key="tab" />
        </div>
        <div v-else-if="tab === 'extra'" class="tab-pane" :class="{active: (tab === 'extra')}" id="usage-tab-extra-quota" :aria-expanded="(tab === 'extra')">
          <EmailStatistic :key="tab" :extra="true" />
        </div>
        <div v-else-if="tab === 'bulk'" class="tab-pane" :class="{active: (tab === 'bulk')}" id="usage-tab-bulk-quota" :aria-expanded="(tab === 'bulk')">
          <EmailStatistic :key="tab" :bulk="true" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import EmailStatistic from "@/components/company/EmailStatistic.vue"
  import payment from "@/factory/payment"
  import EmailSvg from '@/components/svg/EmailSvg.vue'
  import ContactSvg from '@/components/svg/ContactSvg.vue'
  import FormSvg from '@/components/svg/FormSvg.vue'
  import LandingPageSvg from '@/components/svg/LandingPageSvg.vue'
  import MediaSvg from '@/components/svg/MediaSvg.vue'

  export default {
    components: {
      EmailStatistic,
      EmailSvg,
      ContactSvg,
      FormSvg,
      LandingPageSvg,
      MediaSvg
    },
    data () {
      return {
        tab: 'regular',
        quota: {}
      }
    },
    computed: {
      companyId () { return this.$route.params.companyId }
    },
    mounted() {
      this.getCompanyQuota()
    },
    methods: {
      getCompanyQuota () {
        payment.getQuota(this.companyId)
          .then((res) => {
            this.quota = res
          })
          .catch(err => this.$e(err))
      }
    }
  }
</script>
