<template>
  <Modal :title="isAdd ? 'Add Note' : isEdit ? 'Edit Note' : 'Detail Note'" @close="$emit('close')">
    <VeeOb ref="form" tag="div">
      <Vee tag="div" rules="required" name="Note" v-slot="{ errors }" class="form-group">
        <label>Note</label>
        <textarea v-model="note" :disabled="!isAdd && !isEdit" class="form-control"></textarea>
        <span class="text-warning">{{ errors[0] }}</span>
      </Vee>
      <div v-if="isAdd" class="form-group">
        <label>Note Attachment</label>
        <input @change="fileChange" type="file" class="form-control">
      </div>
      <div v-if="!isAdd" class="form-group">
        <template v-if="noteAttachment">
          <label>Note Attachment</label>
          <template v-if="isImage">
            <img :src="noteAttachment" alt="Note Attachment" class="img-fluid">
          </template>
          <div class="text-center mt-4">
            <a :href="noteAttachment" target="_blank" class="text-primary">
              <template v-if="isImage">
                View image original size
              </template>
              <template v-else>
                Open file in new Tab
              </template>
            </a>
          </div>
        </template>
        <div v-if="isEdit" class="mt-4">
          <label>Edit Attachment</label>
          <input @change="fileChange" type="file" class="form-control">
        </div>
      </div>
    </VeeOb>
    <template v-slot:footer>
      <button @click.prevent="$emit('close')" type="button" class="btn btn-secondary" :class="{wait: saving}" data-dismiss="modal">Close</button>
      <button v-if="isAdd || isEdit" @click.prevent="validateSave" type="button" class="btn btn-primary" :class="{wait: saving}">Save</button>
    </template>
  </Modal>
</template>

<script>
  import Modal from '@/components/partial/Modal'
  import company from '@/factory/company'
  import fn from '@/library/fn'
  import mailtarget from '@/factory/mailtarget'

  export default {
    components: {
      Modal
    },
    props: {
      company: {
        default: {}
      },
      product: {
        default: 'MTARGET',
      },
    },
    data () {
      return {
        note: '',
        noteAttachment: '',
        uploadFile: null,
        saving: false
      }
    },
    computed: {
      companyId () { return this.$route.params.companyId },
      isAdd () { return JSON.stringify(this.company) === '{}'},
      isEdit () { return !this.isAdd && this.company.noteMode === 'edit' },
      isImage () {
        if (typeof this.noteAttachment === 'string') {
          return 'jpg,jpeg,png'.indexOf(this.noteAttachment.split('.')[this.noteAttachment.split('.').length - 1].toLowerCase()) > -1
        }
        return false
      }
    },
    created () {
      this.init()
    },
    methods: {
      init () {
        if (!this.isAdd) {
          this.note = this.company.note
          this.noteAttachment = this.company.noteAttachment || ''
        }
      },
      fileChange (e) {
        let files = e.target.files || e.dataTransfer.files
        this.uploadFile = files[0]
      },
      validateSave() {
        this.$refs.form.validate()
          .then(valid => {
            if(!valid) {
              fn.notyWarn('validation')
              return Promise.reject()
            }
          })
          .then(() => {
            this.save()
          })
          .catch(() => {})
      },
      save () {
        this.saving = true
        if(this.product === 'MTARGET'){
          let data = new FormData()
          data.append('teamId', this.companyId)
          data.append('note', this.note)
          data.append('file', this.uploadFile)
          company.setCompanyNote(data)
            .then((res) => {
              if (res) fn.notySuccess("Company note successfully updated.")
              else fn.notyWarn("Failed to update company note.")
              this.saving = false
              this.$emit('save-success')
            })
            .catch((err) => {
              this.saving = false
              fn.err(err)
            })
        } else if (this.product === 'mailtarget') {
          let data = new FormData()
          data.append('note', this.note)
          data.append('noteAttachment', this.uploadFile)
          mailtarget.setClientNote(this.$route.params.userId, data)
            .then((res)=>{
              if (res) fn.notySuccess("Company note successfully updated.")
              else fn.notyWarn("Failed to update company note.")
              this.saving = false
              this.$emit('save-success')
            })
            .catch((err) => {
              this.saving = false
              fn.err(err)
            })
        } 
      }
    }
  }
</script>
