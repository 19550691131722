<template>
  <Loading v-if="isInit" />
  <div v-else>
    <QuotaUsage appName="MTARGET" :dataQuota="dataQuota" />
    <div class="row">
      <div class="col-12">
        <div class="kt-portlet kt-portlet--mobile">
          <div class="kt-portlet__head kt-portlet__head--lg d-block">
            <div class="d-flex justify-content-between">
              <div class="kt-portlet__head-label flex-wrap my-3">
                <h3 class="kt-portlet__head-title">Clients List</h3>
              </div>
              <div class="kt-portlet__head-toolbar my-3">
                <button @click="exportData" type="button" class="btn btn-upload btn-outline-primary btn-icon-sm">
                  <UploadSvg class="mr-2" />
                  Export
                </button>
              </div>
            </div>
            <div class="d-flex justify-content-between">
              <div class="kt-portlet__head-label flex-wrap my-1">
                <div class="dropdown dropdown-inline my-1">
                  <button
                    type="button"
                    class="btn btn-default btn-icon-sm dropdown-toggle"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false">
                    {{ filter.status || 'All Status' }}
                  </button>
                  <div class="dropdown-menu dropdown-menu-left">
                    <ul class="kt-nav">
                      <li class="kt-nav__section kt-nav__section--first">
                        <span class="kt-nav__section-text">
                          Choose an option
                        </span>
                      </li>
                      <div v-if="$route.query.from === 'expired-soon' || $route.query.from === 'grace-period'" >
                        <li class="kt-nav__item">
                          <a class="kt-nav__link">
                            <span class="kt-nav__link-text">{{ filter.status }}</span>
                          </a>
                        </li>
                      </div>
                      <div v-else>
                        <li @click="filter.status = ''" class="kt-nav__item">
                          <a class="kt-nav__link">
                            <span class="kt-nav__link-text">All Status</span>
                          </a>
                        </li>
                        <li v-for="(data, i) in statusOptions" :key="i" @click="filter.status = data" class="kt-nav__item">
                          <a class="kt-nav__link">
                            <span class="kt-nav__link-text">{{ data }}</span>
                          </a>
                        </li>
                      </div>
                    </ul>
                  </div>
                </div>
                <div class="dropdown dropdown-inline my-1 ml-2">
                  <button
                    type="button"
                    class="btn btn-default btn-icon-sm dropdown-toggle"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false">
                    {{ filter.period || 'All Subscription' }}
                  </button>
                  <div class="dropdown-menu dropdown-menu-right">
                    <ul class="kt-nav">
                      <li class="kt-nav__section kt-nav__section--first">
                        <span class="kt-nav__section-text">
                          Choose an option
                        </span>
                      </li>
                      <li @click="filter.period = ''" class="kt-nav__item">
                        <a class="kt-nav__link">
                          <span class="kt-nav__link-text">All Subscription</span>
                        </a>
                      </li>
                      <li v-for="(data, i) in periodOptions" :key="i" @click="filter.period = data" class="kt-nav__item">
                        <a class="kt-nav__link">
                          <span class="kt-nav__link-text">{{ data }}</span>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <button @click="navigate('p', 1)" type="button" class="btn btn-brand btn-elevate btn-icon-sm my-1 ml-2">
                  Apply
                </button>
              </div>
              <div class="kt-portlet__head-toolbar my-1">
                <div class="kt-portlet__head-wrapper">
                  <div class="kt-portlet__head-actions d-flex">
                    <div class="mr-1">
                      <Search @search="navigate" v-model="filter.search" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div v-if="list.length > 0" class="kt-portlet__body kt-portlet__body--fit">
            <div class="table-responsive pl-4">
              <table class="table table-hover">
                <thead>
                  <tr>
                    <th @click="sort('name')" class="sortable" :class="{active: (filter.sort.indexOf('name') > -1)}">
                      <span>Company <i class="la la-unsorted"></i><i class="sort-arrow" :class="sortArrow"></i></span>
                    </th>
                    <th>Contact</th>
                    <th>Quota Usage</th>
                    <th @click="sort('createdAt')" class="sortable" :class="{active: (filter.sort.indexOf('createdAt') > -1)}">
                      <span>Register Date <i class="la la-unsorted"></i><i class="sort-arrow" :class="sortArrow"></i></span>
                    </th>
                    <th>Status</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="item in list" :key="item.id">
                    <td class="align-middle ml-2">
                      <div @click.prevent="gotoDetail(item)" class="text-gray-700 font-weight-600 pointer">
                        {{ item.name }}
                      </div>
                      <div @click.prevent="gotoDetail(item)" class="font-weight-500 text-caption pointer">
                        {{ truncatedWord(item.ownerName) }}
                      </div>
                    </td>
                    <td class="align-middle">
                      <div class="text-gray-700 font-weight-600">
                        {{ truncatedWord(item.ownerEmail) }}
                      </div>
                      <div class="font-weight-500 text-caption">
                        {{ item.phone }}
                      </div>
                    </td>
                    <td class="align-middle text-gray-700">
                      <div class="font-weight-700">
                        {{ (item.emailLimit - item.emailUsage) | curr }} email(s) left
                      </div>
                      <div class="font-weight-500 text-caption">
                        from {{ item.emailLimit | curr }}
                      </div>
                    </td>
                    <td class="align-middle">
                      <div class="font-weight-500">
                        {{ item.createdAt | dtmType('DD MMM YYYY HH:mm') }}
                      </div>
                    </td>
                    <td class="align-middle font-weight-600">
                      <span v-if="filter.status !== 'Grace Period'" class="kt-badge kt-badge--inline kt-badge--rounded" :class="item.active ? 'kt-badge--unified-success' : 'kt-badge--unified-danger'">
                        {{ item.active ? 'Active' : 'Inactive' }}
                      </span>
                      <span v-else class="kt-badge kt-badge--inline kt-badge--rounded kt-badge--unified-success">
                        Active
                      </span>
                    </td>
                    <td class="align-middle">
                      <div>
                        <a
                          @click.prevent="gotoDetail(item)"
                          title="Detail Company"
                          class="btn-sm btn-clean btn-icon btn-icon-md cursor-pointer">
                          <span class="icon-bg bg-blue">
                            <SettingsSvg />
                          </span>
                        </a>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <div v-else class="my-4">
            <div class="d-flex flex-column justify-content-center align-items-center">
              <DocumentSvg class="w-image my-4" />
              <span class="text-caption my-4">
                There are no Client list
              </span>
            </div>
          </div>

          <div v-if="list.length > 0" class="kt-portlet__foot kt-portlet__foot--sm">
            <Pagination @navigate="navigate" :filter="filter" />
          </div>
        </div>
      </div>
    </div>

    <Confirmation
      v-if="exportSuccess"
      title="Export Success"
      @close="exportSuccess = false"
      :message="`Export data will be sent automatically to your email. This process may take a few moments. You can continue your activities, thank you.`">
    </Confirmation>
  </div>
</template>

<script>
  import Confirmation from "@/components/partial/Confirmation"
  import Search from '@/components/partial/Search'
  import Pagination from '@/components/partial/Pagination'
  import PaginationMixin from '@/components/PaginationMixin'
  import whitelabel from '@/factory/whitelabel'
  import SettingsSvg from '@/components/svg/SettingsSvg.vue'
  import DocumentSvg from '@/components/svg/DocumentSvg.vue'
  import QuotaUsage from '@/components/whitelabel/QuotaUsage.vue'
  import UploadSvg from '@/components/svg/UploadSvg.vue'
  import Loading from "@/components/Loading.vue"

  export default {
    mixins: [PaginationMixin],
    components: {
      Loading,
      Confirmation,
      Search,
      Pagination,
      SettingsSvg,
      DocumentSvg,
      QuotaUsage,
      UploadSvg,
    },
    data() {
      return {
        title: "Client",
        subtitle: "Client List",
        loading: false,
        isInit: true,
        filter: {
          page: 1,
          size: 10,
          sort: '-createdAt',
          search: '',
          status: "",
          country: "",
          city: "",
          referralCode: '',
          internalFilter: false,
          expiredStart: 0,
          expiredEnd: 0,
          registerStart: 0,
          registerEnd: 0,
          graceStart: 0,
          graceEnd: 0,
          pic: "",
          byUsageSize: false,
          leng: 0,
          count: 0,
          packet: [],
          period: "",
          from: '',
        },
        dataQuota: {
          total: 0,
          quotaLeft: 0,
          quotaAllocated: 0,
          updatedOn: 0,
          lastPurchasedAt: 0,
          createdAt: 0,
          updatedAt: 0,
          totalUser: 0,
        },
        list: [],
        packageOptions: ['Basic', 'Advanced', 'Custom'],
        periodOptions: ['Monthly', 'Quarterly', 'Yearly'],
        statusOptions: ['Active', 'Inactive'],
        exportSuccess: false,
        period: [],
        range: '',
        showModalBuyQuota: false
      }
    },
    watch:{
      period(val, oldVal){
        // check if the value is changed
        if (val[0] !== oldVal[0]) {
          if (this.$route.query.from == 'grace-period') {
            this.filter.graceStart = val[0]
            this.filter.graceEnd = val[1]
            this.$router.push({path: this.$route.path, query: this.filter})
          }
          if (this.$route.query.from === 'usage-by-size') {
            this.filter.registerStart = val[0]
            this.filter.registerEnd = val[1]
            this.$router.push({path: this.$route.path, query: this.filter})
          }
          this.get()
        }
      },
      '$route.query': {
        handler: function (val) {
          if(!val.hasOwnProperty('from')) {
            this.get()
          }
        },
        deep: true
      }
    },
    created () {  
      if (this.$route.query.from === 'grace-period') {
        this.period[0] = parseInt(this.$route.query.graceStart) || 0
        this.period[1] = parseInt(this.$route.query.graceEnd) || 0
      }
      if (this.$route.query.from === 'usage-by-size') {
        this.period[0] = parseInt(this.$route.query.registerStart) || 0
        this.period[1] = parseInt(this.$route.query.registerEnd) || 0
      }
      this.get(true)
    },
    methods: {
      get (init = false) {
        this.filter.registerStart = parseInt(this.$route.query.registerStart) || 0
        this.filter.registerEnd = parseInt(this.$route.query.registerEnd) || 0
        this.filter.expiredStart = parseInt(this.$route.query.expiredStart) || 0
        this.filter.expiredEnd = parseInt(this.$route.query.expiredEnd) || 0
        this.filter.graceStart = parseInt(this.$route.query.graceStart) || 0
        this.filter.graceEnd = parseInt(this.$route.query.graceEnd) || 0
        this.filter.byUsageSize = this.$route.query.byUsageSize
        this.filter.status = this.$route.query.status 
        let filter = Object.assign({}, this.filter)
        filter.packet = this.filter.packet.join(',')
        whitelabel.getCompany(filter)
          .then((res) => {
            this.list = res.data
            this.filter.leng = res.data.length
            this.filter.count = res.count
            if (init) return whitelabel.getResellerQuota('jatis', 'mtarget')
          })
          .then((res) => {
            this.dataQuota = Object.assign(this.dataQuota, res)
            return whitelabel.getResellerTotalUser('jatis')
          })
          .then((res) => {
            this.dataQuota.totalUser = res.total
            this.dataQuota.createdAt = res.firstRegisterAt
          })
          .catch(err => this.$e(err))
          .finally(() => this.isInit = false)
      },
      gotoDetail (data) {
        return this.$router.push(`/clients/mtarget/jatis/${data.companyId}`)
      },
      exportData () {
        let filter = Object.assign({}, this.filter)
        filter.packet = this.filter.packet.join(',')
        whitelabel.getExportCompany(filter)
          .then(() => {
            this.exportSuccess = true
          })
          .catch(err => this.$e(err))
      },
      // method to reset filter and show all client list
      truncatedWord(word) {
        return word.length > 32 ? word.substring(0, 32) + '...' : word
      }
    }
  }
</script>