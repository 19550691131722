<script>
import {defineComponent} from "vue"
import LoadingSpinner from "@/components/svg/LoadingSpinner.vue"


export default defineComponent({
  components: {LoadingSpinner}
})
</script>

<template>
  <section class="middle-page">
    <LoadingSpinner class="loading" />
    <p class="mt-4">Preparing relevant data for you...</p>
  </section>
</template>

<style scoped>
  .loading {
    width: 48px;
    height: 48px;
  }
  .middle-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 10rem 0;
  }
</style>