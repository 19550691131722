import { loggedGet, loggedPost, loggedDel } from "@/library/ajax"

const apiUrl = process.env.VUE_APP_API_MAILTARGET_URL

export default {
  getClientList: (reselerName, filter) => loggedGet(`${apiUrl}/reseller/client-list/${reselerName}` , filter),
  getWhitelabelApiKey: (resellerId, companyId, filter) => loggedGet(`${apiUrl}/reseller/api-key/${resellerId}/${companyId}` , filter),
  createWhitelabelApiKey: (resellerId, companyId, payload) => loggedPost(`${apiUrl}/reseller/api-key/${resellerId}/${companyId}` , payload),
  getClientDetail: (companyId) => loggedGet(`${apiUrl}/crm/client-detail/${companyId}`),
  getClientSubscription: (id) => loggedGet(`${apiUrl}/crm/subscription/${id}`),
  getTeamUsageProd: (id) => loggedGet(`${apiUrl}/crm/client-detail/${id}/usage/production`),
  getTeamUsageSandbox: (id) => loggedGet(`${apiUrl}/crm/client-detail/${id}/usage/sandbox`),
  getTeamMembers: (id) => loggedGet(`${apiUrl}/crm/company/${id}/member`),
  getResellerTotalUser: (partnerName) => loggedGet(`${apiUrl}/reseller/${partnerName}/user-stats`),
  getResellerQuotaStats: () => loggedGet(`${apiUrl}/reseller/quota/stats`),
  deleteResellerApiKey: (companyId, apiKeyId) => loggedDel(`${apiUrl}/reseller/api-key/${companyId}/${apiKeyId}`),
  createPartnerClient: (partnerSlug, payload) => loggedPost(`${apiUrl}/register-partner/${partnerSlug}`, payload),
  getTeamUsageAnalytics: (id, payload) => loggedPost(`${apiUrl}/crm/client-detail/${id}/usage/analytic`, payload),
}
