<template>
  <div>
    <!-- <div class="kt-portlet kt-portlet--mobile">
      <div class="kt-portlet__body p-3 rounded-lg">
        <ul class="nav nav-pills nav-pills-sm nav-pills-label nav-pills-bold mb-0" role="tablist">
          <li class="nav-item">
            <a class="nav-link cursor-pointer" @click="tab = 'subscription'" :class="{active: (tab === 'subscription')}" data-toggle="tab" role="tab" :aria-selected="(tab === 'subscription')">
              Client Subscription
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link cursor-pointer" @click="tab = 'temporary'" :class="{active: (tab === 'temporary')}" data-toggle="tab" role="tab" :aria-selected="(tab === 'temporary')">
              Feature Access <span v-if="hasTemporaryFeatures" class="kt-badge kt-badge--warning kt-badge--dot mt-n2"></span>
            </a>
          </li>
        </ul>
      </div>
    </div> -->

    <div :class="{'d-none': (tab !== 'subscription')}">
      <Plan
        v-if="false"
        :key="reloadList.keyPlan"
        :reload-plan="reloadList.plan"
        @show-features="(x) => { $emit('currentFeatures', x) }"
        @detail-promo="(x) => { $emit('promoData', x) }"
        @confirm-subscription="(x) => { $emit('subscriptionData', x) }"
        @confirm-addon="(x) => { $emit('addonData', x) }"
        @confirm-edit="(x) => { $emit('packetEditData', x) }"
        @confirm-edit-trial="(x) => { $emit('trialEditData', x) }"
        @select-upgrade="(x) => { upgradeMode = x }" />
      
      <PlanOut :currentPlan="currentPlan" @close="$emit('upgradePlan', true)"  />

      <AddOns
        :currentPlan="currentPlan"
        @show-features="(x) => { $emit('currentFeatures', x) }" />

      <ExtraQuota
        v-if="!upgradeMode"
        @create-extra="$emit('showExtraQuota', true)"
        @tab-billing="$emit('saveExtraSuccess')" />
    </div>

    <!-- <div :class="{'d-none': (tab !== 'temporary')}">
      <TemporaryFeatures
        :currentPlan="currentPlan"
        :temporaryData="temporaryData"
        @reload-temporary="getTemporary" />
    </div> -->
  </div>
</template>

<script>
  import Plan from './Plan.vue'
  import PlanOut from './PlanOut.vue'
  import ExtraQuota from './ExtraQuota.vue'
  import AddOns from './AddOns.vue'
  // import TemporaryFeatures from './TemporaryFeatures.vue'
  import payment from '@/factory/payment'

  export default {
    components: {
      Plan,
      PlanOut,
      ExtraQuota,
      AddOns,
      // TemporaryFeatures,
    },
    props: {
      reloadList: {
        default: () => ({
          keyPlan: null,
          plan: null
        })
      }
    },
    data () {
      return {
        tab: 'subscription',
        upgradeMode: '',
        currentPlan: null,
        temporaryData: null,
      }
    },
    computed: {
      companyId () { return this.$route.params.companyId },
      hasTemporaryFeatures () {
        if (this.temporaryData === null) return false
        return this.temporaryData.features.length > 0
      },
    },
    watch: {
      reloadList: {
        deep: true,
        handler() {
          this.upgradeMode = ''
        }
      }
    },
    created () {
      this.init()
    },
    methods: {
      init () {
        this.getCompanyPlan()
        this.getTemporary()
      },
      getCompanyPlan () {
        payment.getPlan(this.companyId)
          .then((res) => {
            this.currentPlan = res
          })
          .catch(err => this.$e(err))
      },
      getTemporary () {
        payment.getTemporaryFeatures({teamId: this.companyId})
          .then((res) => {
            this.temporaryData = res
          })
          .catch(err => this.$e(err))
      },
    }
  }
</script>
