<template>
  <LayoutBasic>
    <div class="kt-grid kt-grid--ver kt-grid--root">
      <div class="kt-grid kt-grid--hor kt-grid--root kt-login kt-login--v2 kt-login--signin" id="kt_login">
        <div class="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor">
          <div class="kt-grid__item kt-grid__item--fluid kt-login__wrapper">
            <div class="kt-login__header">
              <div class="kt-login__logo">
                <router-link to="/">
                  <img :src="logo">
                </router-link>
              </div>
            </div>
            <div class="kt-login__container">
              <div class="kt-login__signin">
                <div class="kt-login__head">
                  <h3 class="kt-login__title">Welcome back!</h3>
                  <p class="kt-login__desc">Log in to Dashboard</p>
                </div>
                <VeeOb class="kt-form" tag="form" ref="form" @submit.prevent="login">
                  <Vee class="input-group" tag="div" rules="required|email" name="Email" v-slot="{ errors }">
                    <input v-model="form.email" class="form-control" type="text" placeholder="Email" name="email" autocomplete="off">
                    <span class="text-danger mt-1">{{ errors[0] }}</span>
                  </Vee>
                  <Vee class="input-group" tag="div" rules="required|min:8" name="Password" v-slot="{ errors }">
                    <input v-model="form.password" class="form-control" type="password" placeholder="Password" name="password">
                    <span class="text-danger mt-1">{{ errors[0] }}</span>
                  </Vee>

                  <div class="kt-login__actions">
                    <button class="btn kt-login__btn-primary" :disabled="pending || disableLogin">Log In</button>
                  </div>
                </VeeOb>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
  </LayoutBasic>
</template>

<script>
  import '@/assets/template/sass/pages/login/login-2.scss'
  import fn from '@/library/fn'
  import user from '@/factory/user'
  import logomtarget from '@/assets/images/logo.png'
  import logojatis from '@/assets/images/jatis-dark.png'
  import bg from '@/assets/template/media/bg/bg-1.jpg'
  import LayoutBasic from '@/components/LayoutBasic'

  const email = process.env.VUE_APP_API_TEST_EMAIL || null
  const pass = process.env.VUE_APP_API_TEST_PASS || null

  export default {
    components: {LayoutBasic},
    data () {
      return {
        logomtarget,
        logojatis,
        bg,
        pending: false,
        form: {
          email: email,
          password: pass
        }
      }
    },
    computed: {
      company () { return process.env.VUE_APP_COMPANY },
      logo () { 
        if (this.company === 'JATIS') return logojatis
        return logomtarget
      },
      disableLogin () {
        if (this.form.email === null || this.form.password === null || this.form.email === '' || this.form.password === '') return true
        return false
      }
    },
    methods: {
      login () {
        this.pending = true
        if (!this.disableLogin) {
          this.$refs.form.validate()
            .then(res => {
              if (!res){
                fn.notyWarn('validation')
                return Promise.reject()
              }
              return user.login(this.form)
            })
            .then(() => {
              let redirect = this.$route.query.redirect
              if (redirect !== undefined) {
                this.$router.push(redirect)
              } else {
                this.$router.push('/dashboard')
              }
            })
            .catch(e => void e)
            .finally(() => {
              this.pending = false
            })
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
  .kt-grid--root {
    min-height: 100vh;
  }
  .kt-login__logo img {
    max-width: 200px;
  }
</style>