<template>
  <div>
    <div class="mb-4">
      <div class="row pb-4">
        <div class="col-auto d-flex align-items-center">
          <h5>Email Usage</h5>
        </div>
        <div class="col">
            <div class="row justify-content-end">
              <div class="col-auto pl-0">
                  <button type="button" @click="exportData" class="btn btn-upload btn-outline-primary btn-icon-sm"><UploadSvg class="asd"/> Export </button>
              </div>
            </div>
          </div>
      </div>
      <div class="row">
        <div class="col-auto d-flex align-items-center">
          <h6>Total Email Sent: {{ total }}</h6>
        </div>
        <div class="col">
          <div class="row justify-content-end">
            <div class="col-auto">
              <div class="dropdown dropdown-inline">
                <button
                  type="button"
                  class="btn btn-default btn-icon-sm dropdown-toggle"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false">
                  {{ type | replace }}
                </button>
                <div class="dropdown-menu dropdown-menu-left">
                  <ul class="kt-nav">
                    <li class="kt-nav__section kt-nav__section--first">
                      <span class="kt-nav__section-text">
                        Choose Email Type
                      </span>
                    </li>
                    <li v-for="(data, i) in typeOption" :key="i" @click="type = data" class="kt-nav__item">
                      <a class="kt-nav__link">
                        <span class="kt-nav__link-text">{{ data | replace }}</span>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="col-auto pl-0">
              <LastTimeRange v-model="period" />
            </div>
            <!-- <div class="col-auto pl-0">
              <button type="button" @click="exportData" class="btn btn-outline-primary btn-icon-sm"><i class="la la-download"></i> Export </button>
            </div> -->
          </div>
        </div>
      </div>
    </div>
    <div class="chart-container">
      <LineChart :chartData="chartData" :options="options" />
      <LoadingCircle v-if="loading" />
    </div>
  </div>
</template>

<script>
  import LineChart from '@/components/partial/charts/LineChart'
  import LoadingCircle from '@/components/partial/LoadingCircle'
  import company from '@/factory/company'
  import moment from 'moment'
  import fn from '@/library/fn'
  import LastTimeRange from '@/components/partial/LastTimeRange.vue'
  import UploadSvg from '@/components/svg/UploadSvg.vue'

  export default {
    components: { LineChart, LoadingCircle, LastTimeRange, UploadSvg },
    props: ['extra', 'bulk'],
    data() {
      return {
        chartData: null,
        options: null,
        period: null,
        type: 'All',
        typeOption: ['All', 'Email_Marketing', 'AB_Testing', 'Automation', 'Transactional'],
        total: '',
        data: null,
        loading: false
      }
    },
    computed: {
      companyId () { return this.$route.params.companyId },
      title () { 
          if (this.extra) { return '(Extra Quota)' }
          else if (this.bulk) { return '(Bulk Quota)' }
          else return ''
       }
    },
    watch: {
      type () { this.getData() },
      period () { if (this.range !== 'Custom Range') this.getData() }
    },
    methods: {
      getData () {
        this.loading = true
        let start = this.period[0] || 0
        let end = this.period[1] || 0
        company.emailStatistic(this.companyId, start, end, this.type, !!this.extra, !!this.bulk)
          .then((res) => {
            this.data = res
            this.setData(res)
          })
          .catch((err) => {
            this.loading = false
            this.$e(err)
          })
      },
      setData (res) {
        let labels = []
        let data = []
        for (let i = 0; i < res.data.length; i++) {
          let format = (this.moreThen30Days) ? 'MMM YYYY' : 'D MMM YY'
          labels.push(moment(res.data[i].date).format(format))
          data.push(res.data[i].count)
        }
        this.total = res.count
        this.chartData = {
          labels: labels,
          datasets: [{
            label: "Email Statistic",
            fill: false,
            backgroundColor: "#5d78ff",
            borderColor: "#5d78ff",
            data: data
          }]
        }
        this.options = {
          responsive: true,
          maintainAspectRatio: false,
          title: {
            display: false,
            text: "Chart.js Line Chart"
          },
          tooltips: {
            mode: "index",
            intersect: false
          },
          hover: {
            mode: "nearest",
            intersect: true
          },
          legend: {
            display: false
          },
          scales: {
            yAxes: [{
              gridLines: {
                borderDash: [5, 5]
              },
              ticks: {
                beginAtZero: true
              }
            }],
            xAxes: [{
              gridLines: {
                display: false
              }
            }],
            x: {
              display: true,
              scaleLabel: {
                display: true,
                labelString: "Month"
              }
            },
            y: {
              display: true,
              scaleLabel: {
                display: true,
                labelString: "Value"
              }
            }
          }
        }
        this.loading = false
      },
      exportData () {
        this.loading = true
        let start = this.period[0] || 0
        let end = this.period[1] || 0
        company.exportEmailStatistic(this.companyId, start, end, this.type, !!this.extra, !!this.bulk).then(() => {
          fn.notySuccess('Export data succeesfully generated. Check your email inbox to download the data.')
          this.loading = false
        }).catch((err) => {
          fn.notyError('Export data failed. Please try again or report to dev team.')
          this.loading = false
          fn.err(err)
        })
      }
    }
  }
</script>

<style lang="less" scoped>
.btn-export {
  margin-left: 24px;
}
</style>
