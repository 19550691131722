import { loggedDel, loggedGet, loggedPost } from '@/library/ajax'

const apiUrl = process.env.VUE_APP_API_URL

const whatsapp = {
  getWhatsapp (companyId) {
    return loggedGet(apiUrl + '/whatsapp', { companyId })
  },
  createWhatsapp (data = {}) {
    return loggedPost(apiUrl + '/whatsapp/create', data)
  },
  updateWhatsapp (data) {
    return loggedPost(apiUrl + '/whatsapp/update', data)
  },
  activateWhatsapp (companyId, id) {
    return loggedPost(apiUrl + '/whatsapp/activate', { companyId, id })
  },
  deleteWhatsapp (companyId, id) {
    return loggedDel(apiUrl + '/whatsapp/delete', { companyId, id })
  },
  getWhatsappDocs (companyId) {
    return loggedGet(apiUrl + '/whatsapp/doc', { companyId })
  },
  uploadDocument (category, formData) {
    return loggedPost(apiUrl + '/whatsapp/doc/' + category, formData)
  }
}

export default whatsapp