<template>
  <Modal @close="$emit('close')" title="Promo Detail">
    <div v-if="detail !== null">
      <div class="row">
        <div class="col-12 col-sm-6">
          <div class="form-group">
            <label>Name :</label>
            &nbsp;
            <span>{{ detail.name }}</span>
          </div>
          <div class="form-group">
            <label>Code :</label>
            &nbsp;
            <span>{{ detail.code }}</span>
          </div>
          <div class="form-group">
            <label>Type :</label>
            &nbsp;
            <span>{{ detail.type }}</span>
          </div>
          <template>
            <div v-if="detail.type === 'VOUCHER'" class="form-group">
              <label>Voucher :</label>
              &nbsp;
              <span>{{ detail.voucher | curr }}</span>
            </div>
            <template v-if="detail.type === 'DISCOUNT'">
              <div class="form-group">
                <label>Discount :</label>
                &nbsp;
                <span>{{ detail.discount }}</span>
              </div>
              <div class="form-group">
                <label>Max Discount :</label>
                &nbsp;
                <span>{{ detail.maxDiscount | curr }}</span>
              </div>
            </template>
          </template>
          <div class="form-group">
            <label>Start Date :</label>
            &nbsp;
            <span>{{ detail.startDate | dtm }}</span>
          </div>
          <div class="form-group">
            <label>Expired Date :</label>
            &nbsp;
            <span>{{ detail.expiredDate | dtm }}</span>
          </div>
          <div class="form-group">
            <label>Created by :</label>
            &nbsp;
            <span>{{ detail.creator }}</span>
          </div>
          <div class="form-group mb-0">
            <label>Promo Link :</label>
            &nbsp;
            <span>{{ detail.promoLink }}</span>
          </div>
        </div>
        <div class="col-12 col-sm-6">
          <div class="form-group">
            <label>Packet :</label>
            &nbsp;
            <span>{{ packetValid }}</span>
          </div>
          <div class="form-group">
            <label>Subscription Period :</label>
            &nbsp;
            <span v-if="detail.period == 1">Monthly</span>
            <span v-else-if="detail.period == 2">Annually</span>
            <span v-else>All Period</span>
          </div>
          <div class="form-group">
            <label>Active ?</label>
            &nbsp;
            <span>{{ detail.active ? 'Yes' : 'No' }}</span>
          </div>
          <div class="form-group">
            <label>Unique ?</label>
            &nbsp;
            <span>{{ detail.unique ? 'Yes' : 'No' }}</span>
          </div>
          <div class="form-group">
            <label>Add-On ?</label>
            &nbsp;
            <span>{{ detail.addOn ? 'Yes' : 'No' }}</span>
          </div>
          <div class="form-group">
            <label>Allow Extend ?</label>
            &nbsp;
            <span>{{ detail.extend ? 'Yes' : 'No' }}</span>
          </div>
          <div class="form-group">
            <label>Used ?</label>
            &nbsp;
            <span>{{ detail.used ? 'Yes' : 'No' }}</span>
          </div>
          <div v-if="detail.used" class="form-group">
            <label>Used Count :</label>
            &nbsp;
            <span>{{ detail.usedCount }}</span>
          </div>
          <div class="form-group">
            <label>Private Promo ?</label>
            &nbsp;
            <span>{{ detail.privatePromo ? 'Yes' : 'No' }}</span>
          </div>
          <div v-if="detail.privatePromo" class="form-group mb-0">
            <label>Email Recipient :</label>
            &nbsp;
            <span>{{ detail.emails.toString() }}</span>
          </div>
        </div>
      </div>
    </div>
    <template v-slot:footer>
      <button @click="$emit('close')" type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
    </template>
  </Modal>
</template>

<script>
  import Modal from "@/components/partial/Modal"
  import common from '@/factory/common'

  export default {
    components: { Modal },
    props: {
      detail: {
        default: null
      }
    },
    data () {
      return {
        allPacket: []
      }
    },
    computed: {
      packetValid () {
        if (this.detail === null) return 'All Packet'
        let packet = this.allPacket.filter((val) => {
          return val.packetId === this.detail.packet
        })
        return (packet.length > 0) ? packet[0].type : 'All Packet'
      }
    },
    methods: {
      getAllPacket () {
        common.getPacket()
          .then((res) => {
            this.allPacket = res.data
            this.getDetail()
          })
          .catch(err => this.$e(err))
      }
    }
  }
</script>