<template>
  <div>
    <Overview
      v-if="company !== null"
      :company="company"
      @token-payment="(x) => { $emit('tokenPaymentData', x) }"
      @add-note="$emit('companyNoteView', {})"
      @detail-note="(x) => { $emit('companyNoteView', x) }"
      @image-note="(x) => { $emit('receiptUrl', x) }"
      @delete-note="(x) => { $emit('companyNoteData', x) }"
      @set-pic="(x) => { $emit('companyPICdata', x) }" />
    
    <Usage />
  </div>
</template>

<script>
import Overview from './Overview.vue'
import Usage from './Usage.vue'

export default {
  components: {
    Overview,
    Usage
  },
  props: {
    company: {
      default: () => ({})
    }
  }
}
</script>
