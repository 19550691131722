<template>
  <div class="kt-portlet kt-portlet--mobile">
    <div class="kt-portlet__head kt-portlet__head--lg border-bottom">
      <div class="kt-portlet__head-label">
        <h3 class="kt-portlet__head-title">
          <span class="text-bold text-gray-700">
            Client Detail
          </span>
        </h3>
      </div>
    </div>

    <div class="kt-portlet__body kt-portlet__body--md pb-0">
      <div v-if="false && company !== null" class="row">
        <div class="col-12 col-sm-3 text-center">
          <div class="p-3">
            <div>
              <span class="kt-badge kt-badge--xl kt-badge--rounded kt-badge--bold kt-badge--unified-success">
                <h2>{{ oneLetter }}</h2>
              </span>
            </div>
            <div class="mt-1 bold">{{ company.name }}</div>
            <div class="mt-3 text-capitalize">{{ company.packet | replace }}</div>
            <div class="mt-1">Expired Date = {{ company.expiredDate | dtmType('DD MMM YYYY HH:mm') }}</div>
            <div class="mt-3">
              <router-link :to="`/company/${companyId}/statistic`" class="btn btn-md btn-outline-brand btn-block">
                Company Statistik
              </router-link>
            </div>
            <div class="mt-2">
              <button @click="showTokenPayment" :disabled="loading" class="btn btn-md btn-outline-success btn-block">
                Token Payment
              </button>
            </div>
            <div class="mt-2">
              <button v-if="company.note === ''" @click="$emit('add-note')" class="btn btn-outline-warning btn-icon-md btn-block">
                <i class="la la-plus"></i> Add Note
              </button>
              <div v-else class="company-note">
                <div class="note-title p-2">
                  <div>
                    <span class="kt-badge kt-badge--inline kt-badge--pill kt-badge--warning">
                      Note
                    </span>
                  </div>
                  <div>
                    <span style="overflow: visible; position: relative; width: 110px;">
                      <a
                        @click.prevent="editNote"
                        title="Edit Note"
                        class="btn btn-sm btn-clean btn-icon btn-icon-md cursor-pointer">
                        <i class="flaticon2-pen"></i>
                      </a>
                      <a
                        @click.prevent="$emit('detail-note', company)"
                        title="Detail Note"
                        class="btn btn-sm btn-clean btn-icon btn-icon-md cursor-pointer">
                        <i class="flaticon-eye"></i>
                      </a>
                      <a
                        @click.prevent="$emit('delete-note', company)"
                        title="Delete Note"
                        class="btn btn-sm btn-clean btn-icon btn-icon-md cursor-pointer">
                        <i class="flaticon2-trash text-danger"></i>
                      </a>
                    </span>
                  </div>
                </div>
                <div class="note-body p-2">
                  <div>{{ company.note.slice(0, 50) }} {{ company.note.length > 50 ? '...' : '' }}</div>
                  <div v-if="company.noteAttachment" class="mt-3">
                    <a @click="$emit('image-note', company.noteAttachment)" class="note-attachment">
                      <i class="la la-link"></i> Attachment
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div class="mt-2">
              <button @click="$emit('set-pic', company)" :disabled="loading" class="btn btn-md btn-outline-info btn-block">
                PIC
              </button>
            </div>
          </div>
        </div>
        <div class="col-12 col-sm-9 border-left">
          <div v-if="quota !== null" class="border-bottom p-3">
            <div class="row">
              <div class="col-12"><strong>Company Detail</strong></div>
              <div class="col-12 mt-1">
                <div class="row">
                  <div class="col-12 col-sm-6">
                    <div>Email Used: {{ quota.emailUsed | curr }}<template v-if="!quota.payAsYouGo">/{{ quota.emailLimit | curr }}</template></div>
                    <div>Page Published: {{ quota.pagePublishUsed | curr }}/{{ quota.pagePublishLimit | curr }}</div>
                    <div>Form Published: {{ quota.formPublishUsed | curr }}/{{ quota.formPublishLimit | curr }}</div>
                    <div>Media Used: {{ quota.mediaUsed | curr }}</div>
                  </div>
                  <div class="col-12 col-sm-6">
                    <div>Contact Used: {{ quota.contactUsed | curr }}</div>
                    <div>Member Since: {{ company.createdAt | dtmType('DD MMM YYYY HH:mm') }}</div>
                    <div>Pay As You Go: <strong>{{ quota.payAsYouGo ? 'Yes' : 'No' }}</strong></div>
                    <div>Status: <strong>{{ company.activeAccount ? 'Active' : 'Suspend' }}</strong></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="border-bottom p-3">
            <div class="row">
              <div class="col-12"><strong>Company Address</strong></div>
              <div class="col-12 mt-1">
                <div class="row">
                  <div class="col-12 col-sm-6">
                    <div>Address: {{ company.address }}</div>
                    <div>City: {{ company.city }}</div>
                    <div>Country: {{ company.country }}</div>
                  </div>
                  <div class="col-12 col-sm-6">
                    <div>Zip Code: {{ company.zipCode }}</div>
                    <div>Time Zone: {{ company.timezone }}</div>
                    <div>Language: </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="p-3">
            <div class="row">
              <div class="col-12"><strong>Owner Detail</strong></div>
              <div class="col-12 mt-1">
                <div class="row">
                  <div class="col-12 col-sm-6">
                    <div>Name: {{ company.ownerName }}</div>
                    <div>Email: {{ company.ownerEmail }}</div>
                    <div>Phone: {{ company.phone }}</div>
                  </div>
                  <div class="col-12 col-sm-6">
                    <div>Slug: {{ company.slug }}</div>
                    <div>Website: {{ company.website }}</div>
                    <div>PIC: {{ company.pic }}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <template v-if="company !== null">
        <div class="mb-4 pb-4">
          <div class="row">
            <div class="col-xl-3 col-sm-3 text-center">
              <img v-if="company.logo" :src="company.logo" alt="logo" class="img-fluid">
              <span v-else class="kt-badge kt-badge--xl kt-badge--rounded kt-badge--bold kt-badge--unified-success w-100 h-100">
                <h1 style="font-size: 100px;">{{ oneLetter }}</h1>
              </span>
            </div>
            <div class="col-sm pl-5">
              <div class="row mb-3 align-items-center">
                <div class="col-auto"><h4 class="mb-0 text-capitalize">{{ company.name }}</h4></div>
              </div>
              <div class="row mb-3">
                <div class="col-3 font-weight-500">
                  <div class="pb-2 pt-3">
                    <div class="pb-2">Status</div>
                    <div>
                      <span v-if="company.activeAccount" class="btn btn-bold btn-pill btn-font-sm btn-label-success px-3 py-1">Active</span>
                      <span v-else class="btn btn-bold btn-sm btn-font-sm btn-label-danger">Suspend</span>
                    </div>
                  </div>
                  <div class="font-weight-500 pt-2">
                    <div class="pb-2 pt-2">Type</div>
                    <div class="text-caption">
                      {{ company.paid ? 'Paid User' : 'Free User' }}
                    </div>
                  </div>
                </div>
                <div class="col-3 font-weight-500 border-left pl-4 mt-3">
                  <div>
                    <div class="pb-2">Subscription</div>
                    <div class="text-caption pb-2">
                      {{ company.subscription >= 12 ? 'Annually' : 'Monthly' }}
                    </div>
                  </div>
                  <div class="font-weight-500 text-capitalize pt-3">
                    <div class="pt-2">Package</div>
                    <div class="text-caption pt-2">
                      {{ company.packet | replace }}
                    </div>
                  </div>
                </div>
                <!-- <div class="col-3 font-weight-500 border-left pl-4 mt-3">
                  <div>
                    <div class="pb-2">
                      Pay As You Grow
                    </div>
                    <div class="text-caption pb-2">
                      {{ company.payAsYouGo ? 'Yes' : 'No' }}
                    </div>
                  </div>
                  <div class="pt-3">
                    <div class="font-weight-500 pt-1">PIC </div>
                    <div>
                      <div>
                        <Multiselect
                          @input="onChange"
                          class="multiselect-placeholder text-capitalize"
                          v-model="pic"
                          :options="picList"
                          :searchable="true"
                          :multiple="false"
                          track-by="name"
                          label="name"
                          :show-labels="false"
                          placeholder="Select PIC" />
                      </div>
                    </div>
                  </div>
                </div> -->
                <div class="col-3 font-weight-500 border-left pl-4 mt-3">
                  <div>
                    <div class="pb-2">Registered Date</div>
                    <div class="text-caption pb-2">{{ company.createdAt | dtmType('DD MMM YYYY HH:mm') }}</div>
                  </div>
                  <div class="pt-2">
                    <div class="pt-2">Last Login</div>
                    <div v-if="company.lastLogin === 0" class="text-caption pt-2">-</div>
                    <div v-else class="text-caption pt-2">{{ company.lastLogin | dtmType('DD MMM YYYY HH:mm') }}</div>
                  </div>
                </div>
              </div>
              <!-- <div class="row mb-3">
                <div class="col-auto border-right pr-4">
                  <span class="text-bold">Registered: </span>
                  <span class="ml-4">{{ company.createdAt | dtmType('DD MMM YYYY HH:mm') }}</span>
                </div>
                <div class="col-auto pl-4">
                  <span class="text-bold">Expired: </span>
                  <span class="ml-4">{{ company.expiredDate | dtmType('DD MMM YYYY HH:mm') }}</span>
                </div>
              </div> -->
            </div>
            <div class="col-12 my-4">
              <div>
                <div v-if="company.note === ''" class="cursor-pointer d-flex justify-content-center rounded attachment-button py-4 text-caption" @click="$emit('add-note')">
                  <i class="flaticon2-pen pr-2"></i> Add Note
                </div>
                <div v-else class="company-note">
                  <div class="note-title px-2">
                    <div>
                      <span class="kt-badge kt-badge--inline kt-badge--pill kt-badge--warning-light text-bold text-warning">
                        Note
                      </span>
                    </div>
                    <div>
                      <span style="overflow: visible; position: relative; width: 110px;">
                        <a
                          @click.prevent="editNote"
                          title="Edit Note"
                          class="btn btn-sm btn-clean btn-icon btn-icon-md cursor-pointer">
                          <EditSvg />
                        </a>
                        <a
                          @click.prevent="$emit('detail-note', company)"
                          title="Detail Note"
                          class="btn btn-sm btn-clean btn-icon btn-icon-md cursor-pointer">
                          <i class="flaticon-eye"></i>
                        </a>
                        <a
                          @click.prevent="$emit('delete-note', company)"
                          title="Delete Note"
                          class="btn btn-sm btn-clean btn-icon btn-icon-md cursor-pointer">
                          <TrashSvg class="custom-delete-icon" />
                        </a>
                      </span>
                    </div>
                  </div>
                  <div class="note-body p-2">
                    <div class="text-caption font-weight-500">{{ truncateNote(company.note) }}</div>
                    <div v-if="company.noteAttachment" class="mt-3">
                      <a @click="$emit('image-note', company.noteAttachment)" class="note-attachment">
                        <i class="la la-link"></i> Attachment
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-md-6">
            <div class="form-group">
              <div class="row">
                <div class="col-12 col-md-4">
                  <div class="d-flex align-items-center h-100 text-bold">Legal Name</div>
                </div>
                <div class="col-12 col-md-8">
                  <input v-model="company.legalName" type="text" class="form-control" disabled>
                </div>
              </div>
            </div>
            <div class="form-group">
              <div class="row">
                <div class="col-12 col-md-4">
                  <div class="d-flex align-items-center h-100 text-bold">Owner</div>
                </div>
                <div class="col-12 col-md-8">
                  <input v-model="company.ownerName" type="text" class="form-control" disabled>
                </div>
              </div>
            </div>
            <div class="form-group">
              <div class="row">
                <div class="col-12 col-md-4">
                  <div class="d-flex align-items-center h-100 text-bold">Email</div>
                </div>
                <div class="col-12 col-md-8">
                  <input v-model="company.ownerEmail" type="text" class="form-control" disabled>
                </div>
              </div>
            </div>
            <div class="form-group">
              <div class="row">
                <div class="col-12 col-md-4">
                  <div class="d-flex align-items-center h-100 text-bold">Slug</div>
                </div>
                <div class="col-12 col-md-8">
                  <input v-model="company.slug" type="text" class="form-control" disabled>
                </div>
              </div>
            </div>
            <div class="form-group">
              <div class="row">
                <div class="col-12 col-md-4">
                  <div class="d-flex align-items-center h-100 text-bold">Website</div>
                </div>
                <div class="col-12 col-md-8">
                  <input v-model="company.website" type="text" class="form-control" disabled>
                </div>
              </div>
            </div>
            <div class="form-group">
              <div class="row">
                <div class="col-12 col-md-4">
                  <div class="d-flex align-items-center h-100 text-bold">Industry</div>
                </div>
                <div class="col-12 col-md-8">
                  <input v-model="company.industry" type="text" class="form-control" disabled>
                </div>
              </div>
            </div>
            <div class="form-group">
              <div class="row">
                <div class="col-12 col-md-4">
                  <div class="d-flex align-items-center h-100 text-bold">NPWP</div>
                </div>
                <div class="col-12 col-md-8">
                  <input v-model="company.npwp" type="text" class="form-control" disabled>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 col-md-6">
            <div class="form-group">
              <div class="row">
                <div class="col-12 col-md-4">
                  <div class="d-flex align-items-center h-100 text-bold">Phone</div>
                </div>
                <div class="col-12 col-md-8">
                  <input v-model="company.phone" type="text" class="form-control" disabled>
                </div>
              </div>
            </div>
            <div class="form-group">
              <div class="row">
                <div class="col-12 col-md-4">
                  <div class="d-flex align-items-center h-100 text-bold">Country</div>
                </div>
                <div class="col-12 col-md-8">
                  <input v-model="company.country" type="text" class="form-control" disabled>
                </div>
              </div>
            </div>
            <div class="form-group">
              <div class="row">
                <div class="col-12 col-md-4">
                  <div class="d-flex align-items-center h-100 text-bold">City</div>
                </div>
                <div class="col-12 col-md-8">
                  <input v-model="company.city" type="text" class="form-control" disabled>
                </div>
              </div>
            </div>
            <div class="form-group">
              <div class="row">
                <div class="col-12 col-md-4">
                  <div class="d-flex align-items-center h-100 text-bold">Zip Code</div>
                </div>
                <div class="col-12 col-md-8">
                  <input v-model="company.zipCode" type="text" class="form-control" disabled>
                </div>
              </div>
            </div>
            <div class="form-group">
              <div class="row">
                <div class="col-12 col-md-4">
                  <div class="d-flex align-items-center h-100 text-bold">Timezone</div>
                </div>
                <div class="col-12 col-md-8">
                  <input v-model="company.timezone" type="text" class="form-control" disabled>
                </div>
              </div>
            </div>
            <div class="form-group">
              <div class="row">
                <div class="col-12 col-md-4">
                  <div class="d-flex align-items-center h-100 text-bold">Legal Address</div>
                </div>
                <div class="col-12 col-md-8">
                  <input v-model="company.legalAddress" type="text" class="form-control" disabled>
                </div>
              </div>
            </div>
            <div class="form-group">
              <div class="row">
                <div class="col-12 col-md-4">
                  <div class="d-flex align-items-center h-100 text-bold">Address</div>
                </div>
                <div class="col-12 col-md-8">
                  <input v-model="company.address" type="text" class="form-control" disabled>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
  import payment from '@/factory/payment'
  import company from '@/factory/company'
  import user from '@/factory/user'
  // import Multiselect from 'vue-multiselect'
  import fn from '@/library/fn'
  import EditSvg from '@/components/svg/EditSvg.vue'
  import TrashSvg from '@/components/svg/TrashSvg.vue'

  export default {
    components: { 
      // Multiselect, 
      EditSvg, TrashSvg },
    props: ['company'],
    data() {
      return {
        picList: [],
        pic: null,
        picEditMode: false,
        quota: null,
        loading: false
      }
    },
    created () {
      this.getPic()
      this.getCompanyQuota()
    },
    computed: {
      appUrl () { return process.env.VUE_APP_MTARGET_APP_URL },
      companyId () { return this.$route.params.companyId },
      oneLetter () {
        if (this.company === null) return ''
        if (this.company.name === '') return ''
        return this.company.name[0].toUpperCase()
      }
    },
    // watch: {
      // pic() {
        // this.updatePic()
        // if (this.picEditMode) 
      // }
    // },
    methods: {
      getPic () {
        // const filter = {
        //   page: 1,
        //   size: 100,
        //   sort: 'name',
        //   search: '',
        //   leng: 0,
        //   count: 0
        // }
        const filterPic = {
          sort: '-createdAt',
          forPic: true
        }
        user.getUsers(filterPic)
          .then((res) => {
            const pic = res.data.filter(item => item.roles.includes('REVENUE'))
            pic.forEach(x => x.name.split(' ').map(y => {
            return y.charAt(0).toUpperCase() + y.slice(1).toLowerCase()
            }).join(' '))
            this.picList = pic
            this.pic = this.picList.find(x => x.email === this.company.pic) || null
            this.picEditMode = this.pic === null
          })
          .catch(err => this.$e(err))
      },
      onChange() {
        this.updatePic()
      },
      updatePic () {
        this.loading = true
        const picId = (this.pic === null) ? '' : this.pic.id
        const pic = (this.pic === null) ? '' : this.pic.email
        company.setCompanyPIC(this.companyId, picId, pic)
          .then((res) => {
            if (res) fn.notySuccess("Company PIC successfully updated.")
            else fn.notyWarn("Failed to update company PIC.")
            this.loading = false
            this.picEditMode = false
          })
          .catch((err) => {
            this.$e(err)
            this.loading = false
          })
      },
      getCompanyQuota () {
        payment.getQuota(this.companyId)
          .then((res) => {
            this.quota = res
          })
          .catch(err => this.$e(err))
      },
      showTokenPayment () {
        this.getTokenPayment(true)
      },
      getTokenPayment (autoGenerate = false) {
        this.loading = true
        company.getTokenPayment(this.companyId)
          .then((res) => {
            this.loading = false
            if (res === "null" && autoGenerate) {
              this.generateTokenPayment()
            }
            if (res !== "null") {
              this.$emit('token-payment', `${this.appUrl}/pay/${res}`)
            }
          })
          .catch((err) => {
            this.$e(err)
            this.loading = false
          })
      },
      generateTokenPayment () {
        this.loading = true
        company.generateTokenPayment(this.companyId)
          .then(() => {
            this.loading = false
            this.getTokenPayment()
          })
          .catch((err) => {
            this.$e(err)
            this.loading = false
          })
      },
      editNote () {
        let company = Object.assign({}, this.company)
        company.noteMode = 'edit'
        this.$emit('detail-note', company)
      },
      truncateNote (val) {
        return val.length > 169 ? val.substring(0, 169) + '...' : val
      }
    }
  }
</script>