<template>
  <div class="row">
    <div class="col-12">
      <div class="kt-portlet kt-portlet--mobile">
        <div class="kt-portlet__head kt-portlet__head--lg border-bottom">
          <div class="kt-portlet__head-label">
            <h3 class="kt-portlet__head-title">
              <span class="text-bold text-gray-700">
                Add-Ons
              </span>
            </h3>
          </div>
          <div v-if="currentPlan !== null" class="kt-portlet__head-toolbar my-1">
            <div class="kt-portlet__head-wrapper">
              <div class="kt-portlet__head-actions d-flex">
                <button @click="$emit('show-features', currentPlan.features)" class="btn text-blue-default text-sm font-weight-500">
                  Show Detail
                </button>
              </div>
            </div>
          </div>
        </div>
        <div v-if="currentPlan !== null" class="kt-portlet__body kt-portlet__body--md pt-5 pb-0">
          <div class="row">
            <div class="col-3 text-sm font-weight-600 pb-5">
              <div class="d-flex"><AddOnSvg class="my-auto mr-2" /> <span>Email Sender</span></div>
              <div class="px-4">
                <span class="pl-1">{{ getValue(currentPlan.features, 'Email Sender') | curr }}</span>
                <span v-if="getValue(currentPlan.features, 'Email Sender') !== 'Unlimited'" class="text-caption font-weight-500 pl-1">Sender Name</span>
              </div>
            </div>
            <div class="col-3 text-sm font-weight-600 pb-5">
              <div class="d-flex"><AddOnSvg class="my-auto mr-2" /> <span>Landing Page Builder</span></div>
              <div class="px-4">
                <span class="pl-1">{{ getValue(currentPlan.features, 'Landing Page Builder') | curr }}</span>
                <span v-if="getValue(currentPlan.features, 'Landing Page Builder') !== 'Unlimited'" class="text-caption font-weight-500 pl-1">Page</span>
              </div>
            </div>
            <div class="col-3 text-sm font-weight-600 pb-5">
              <div class="d-flex"><AddOnSvg class="my-auto mr-2" /> <span>URL Shortener</span></div>
              <div class="px-4">
                <span class="pl-1">{{ getValue(currentPlan.features, 'Shortener Url') | curr }}</span>
                <span v-if="getValue(currentPlan.features, 'Shortener Url') !== 'Unlimited'" class="text-caption font-weight-500 pl-1">Url</span>
              </div>
            </div>
            <div class="col-3 text-sm font-weight-600 pb-5">
              <div class="d-flex"><AddOnSvg class="my-auto mr-2" /> <span>Team Management</span></div>
              <div class="px-4">
                <span class="pl-1">{{ getValue(currentPlan.features, 'Team Management') | curr }}</span>
                <span v-if="getValue(currentPlan.features, 'Team Management') !== 'Unlimited'" class="text-caption font-weight-500 pl-1">Team Member</span>
              </div>
            </div>
            <div class="col-3 text-sm font-weight-600 pb-5">
              <div class="d-flex"><AddOnSvg class="my-auto mr-2" /> <span>Email Validation Credit</span></div>
              <div class="px-4">
                <span class="pl-1">{{ getValue(currentPlan.features, 'Purify') | curr }}</span>
                <span v-if="getValue(currentPlan.features, 'Purify') !== 'Unlimited'" class="text-caption font-weight-500 pl-1">Credit</span>
              </div>
            </div>
            <div class="col-3 text-sm font-weight-600 pb-5">
              <div class="d-flex"><AddOnSvg class="my-auto mr-2" /> <span>Interactive Form</span></div>
              <div class="px-4">
                <span class="pl-1">{{ getValue(currentPlan.features, 'Interactive Form') | curr }}</span>
                <span v-if="getValue(currentPlan.features, 'Interactive Form') !== 'Unlimited'" class="text-caption font-weight-500 pl-1">Form</span>
              </div>
            </div>
            <div class="col-3 text-sm font-weight-600 pb-5">
              <div class="d-flex"><AddOnSvg class="my-auto mr-2" /> <span>DIA Tokens</span></div>
              <div class="px-4">
                <span class="pl-1">{{ getValue(currentPlan.features, 'DIA') | curr }}</span>
                <span v-if="getValue(currentPlan.features, 'DIA') !== 'Unlimited'" class="text-caption font-weight-500 pl-1">Token</span>
              </div>
            </div>
            <div class="col-3 text-sm font-weight-600 pb-5">
              <div class="d-flex"><AddOnSvg class="my-auto mr-2" /> <span>Storage Capacity</span></div>
              <div class="px-4">
                <span class="pl-1">{{ getValue(currentPlan.features, 'File Manager') | curr }}</span>
                <span v-if="getValue(currentPlan.features, 'File Manager') !== 'Unlimited'" class="text-caption font-weight-500 pl-1">GB</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import AddOnSvg from '@/components/svg/AddOnSvg.vue'

  export default {
    components: {
      AddOnSvg
    },
    props: {
      currentPlan: { default: null }
    },
    data () {
      return {}
    },
    methods: {
      getValue (features = [], ftName) {
        let limit = null
        const feature = features.find(x => x.name === ftName) || null

        if (ftName === 'Email Sender') {
          if (feature !== null) {
            limit = feature.subFeatures.find(x => x.text === 'Sender Limit') || null
          }
        } else if (ftName === 'Landing Page Builder') {
          if (feature !== null) {
            limit = feature.subFeatures.find(x => x.text === 'Publish Limit') || null
          }
        } else if (ftName === 'Shortener Url') {
          if (feature !== null) {
            limit = feature.subFeatures.find(x => x.text === 'Url Limit') || null
          }
        } else if (ftName === 'Team Management') {
          if (feature !== null) {
            limit = feature.subFeatures.find(x => x.text === 'Member Limit') || null
          }
        } else if (ftName === 'Email Marketing') {
          if (feature !== null) {
            limit = feature.subFeatures.find(x => x.text === 'Email Sent Limit') || null
          }
        } else if (ftName === 'Interactive Form') {
          if (feature !== null) {
            limit = feature.subFeatures.find(x => x.text === 'Publish Limit') || null
          }
        } else if (ftName === 'DIA') {
          if (feature !== null) {
            limit = feature.subFeatures.find(x => x.text === 'Token Limit') || null
          }
        } else if (ftName === 'File Manager') {
          if (feature !== null) {
            limit = feature.subFeatures.find(x => x.text === 'Storage Limit') || null
          }
        }

        const limitValue = (limit === null) ? 0 : limit.limit
        
        if (limitValue === -1) return 'Unlimited'
        else if (ftName === 'File Manager') return limitValue / (1024 * 1024 * 1024)
        return limitValue
      }
    }
  }

</script>