<template>
  <Modal @close="$emit('close')" title="Change Password">
    <VeeOb ref="form" tag="div">
      <div class="form-group">
        <label>Full Name</label>
        <input v-model="form.name" :disabled="true" type="text" class="form-control">
      </div>
      <div class="form-group">
        <label>Email</label>
        <input v-model="form.email" :disabled="true" type="text" class="form-control">
      </div>
      <div class="form-group">
        <label>Old Password</label>
        <input v-model="form.oldPassword" type="password" class="form-control">
      </div>
      <Vee tag="div" rules="required|min:8" name="New Password" v-slot="{ errors }" class="form-group">
        <label>New Password</label>
        <input v-model="form.newPassword" @keypress.enter="changePassword" type="password" class="form-control">
        <span class="text-danger">{{ errors[0] }}</span>
      </Vee>
    </VeeOb>
    <template v-slot:footer>
      <button @click="$emit('close')" :disabled="disabled" type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
      <button @click="changePassword" type="button" class="btn btn-primary" :class="{wait: disabled}" :disabled="disabled">Change Password</button>
    </template>
  </Modal>
</template>

<script>
  import Modal from "@/components/partial/Modal"
  import user from '@/factory/user'
  import fn from '@/library/fn'

  export default {
    components: { Modal },
    props: ['data'],
    data () {
      return {
        form: {
          name: '',
          email: '',
          oldPassword: '',
          newPassword: '',
          roles: []
        },
        rolesOptions: [
          'ADMIN',
          'DEVELOPER',
          'GROWTH',
          'REVENUE ADMIN',
          'REVENUE',
          'FINANCE',
          'SUPPORT'
        ],
        disabled: false
      }
    },
    created () {
      this.init()
    },
    methods: {
      init () {
        this.form.name = this.data.name
        this.form.email = this.data.email
        this.form.roles = this.data.roles
      },
      changePassword () {
        this.disabled = true
        this.$refs.form.validate()
          .then(res => {
            if (!res){
              fn.notyWarn('validation')
              return Promise.reject()
            }
            return user.changePassword(this.form)
          })
          .then((res) => {
            this.disabled = false
            if (res) {
              fn.notySuccess("Password successfully changed.")
              this.$emit('change-success')
              this.$emit('close')
            } else {
              fn.notySuccess("Failed to change password.")
            }
          })
          .catch(e => void e)
          .finally(() => {
            this.disabled = false
          })
      }
    }
  }
</script>
