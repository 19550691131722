import { loggedDel, loggedDl, loggedGet, loggedPost, loggedPut } from "@/library/ajax"

const apiUrl = process.env.VUE_APP_API_URL

export default {
  getPaymentPAYG: (filter) => loggedGet(apiUrl + '/payment/payasyougo', filter),
  getPaymentRegular: (filter) => loggedGet(apiUrl + '/payment/regular', filter),
  getPaymentExtra: (filter) => loggedGet(apiUrl + '/payment/extra', filter),
  getPaymentDomain: (filter) => loggedGet(apiUrl + '/payment/domain', filter),
  exportPaygPayment: (filter) => loggedGet(apiUrl + '/payment/payasyougo/export', filter),
  exportRegularPayment: (filter) => loggedGet(apiUrl + '/payment/regular/export', filter),
  exportExtraPayment: (filter) => loggedGet(apiUrl + '/payment/extra/export', filter),
  exportDomainPayment: (filter) => loggedGet(apiUrl + '/payment/domain/export', filter),
  getPayment: (filter) => loggedGet(apiUrl + '/payment', filter),
  getDetailPayment: (paymentId) => loggedGet(apiUrl + '/payment/detail', { paymentId }),
  getQuota: (teamId) => loggedGet(apiUrl + '/payment/quota', { teamId }),
  extendPlan: (data) => loggedPost(apiUrl + '/payment/plan/extend', data),
  getBillingPAYG: (filter) => loggedGet(apiUrl + '/payment/billing/payasyougo', filter),
  getBillingRegular: (filter) => loggedGet(apiUrl + '/payment/billing/regular', filter),
  getBillingExtra: (filter) => loggedGet(apiUrl + '/payment/billing/extra-quota', filter),
  getBillingDomain: (filter) => loggedGet(apiUrl + '/payment/billing/domain', filter),
  activateBilling: (formData) => loggedPost(apiUrl + '/payment/plan/activate', formData),
  activateAddOn: (formData) => loggedPost(apiUrl + '/payment/add-on/activate', formData),
  activateExtraQuota: (formData) => loggedPost(apiUrl + '/payment/extra-quota/activate', formData),
  activateDomain: (formData) => loggedPost(apiUrl + '/payment/domain/activate', formData),
  getPlan: (teamId) => loggedGet(apiUrl + '/payment/plan', { teamId }),
  savePlan: (data) => loggedPost(apiUrl + '/payment/plan', data),
  createInvoice: (data) => loggedPost(apiUrl + '/payment/plan/v2', data),
  calculateInvoice: (data) => loggedPost(apiUrl + '/payment/plan/calculate', data),
  getDetailInvoice: (invoice) => loggedGet(apiUrl + `/payment/plan/${invoice}`, {}),
  getPlanHistory: (teamId) => loggedGet(apiUrl + `/payment/plan/history`, { teamId }),
  createProforma: (data) => loggedPost(apiUrl + '/payment/plan/proforma', data),
  saveAddOn: (data) => loggedPost(apiUrl + '/payment/add-on', data),
  changePacket: (data) => loggedPost(apiUrl + '/payment/change-packet', data),
  editBilling: (data) => loggedPost(apiUrl + '/payment/edit-billing', data),
  editBillingPAYG: (data) => loggedPost(apiUrl + '/payment/edit-billing-payasyougo', data),
  editBillingExtra: (data) => loggedPost(apiUrl + '/payment/edit-billing-extra', data),
  deleteBilling: (data) => loggedDel(apiUrl + '/payment/billing', data),
  deleteBillingExtra: (data) => loggedDel(apiUrl + '/payment/extra-quota', data),
  downloadInvoice: (invoice, companyId) => loggedDl(apiUrl + '/payment/billing/download/' + invoice, { companyId }),
  uploadTaxInvoice: (billingId, data) => loggedPost(apiUrl + `/payment/billing/${billingId}/tax-invoice`, data),
  inactiveExtraQuota: (teamId) => loggedGet(apiUrl + '/payment/extra-quota/inactive', { teamId }),
  saveExtraQuota: (data) => loggedPost(apiUrl + '/payment/extra-quota', data),
  extendTrial: (data) => loggedPost(apiUrl + '/payment/trial/extend', data),
  changeTrial: (data) => loggedPost(apiUrl + '/payment/change-trial', data),
  getPaymentSetting: (teamId) => loggedGet(apiUrl + '/payment/setting', { teamId }),
  editPaymentSetting: (data) => loggedPut(apiUrl + '/payment/setting', data),
  createTemporaryFeatures: (data) => loggedPost(apiUrl + '/payment/temporary-features', data),
  getTemporaryFeatures: (data) => loggedGet(apiUrl + '/payment/temporary-features', data),
  deleteTemporaryFeatures: (data) => loggedDel(apiUrl + '/payment/temporary-features', data),
}
