<template>
  <div class="kt-portlet kt-portlet--mobile">
    <div class="kt-portlet__head kt-portlet__head--lg border-bottom">
      <div class="kt-portlet__head-label">
        <h3 class="kt-portlet__head-title font-weight-bold">
          <span>Client Usage</span>
          <span class="pl-2 kt-font-regular">
            <i class="la la-exclamation-circle text-blue-20 font-weight-bold"/>
            All packages will utilize shared quotas in both environment
          </span>
        </h3>
      </div>
    </div>

    <div class="kt-portlet__body kt-portlet__body--lg">

      <ul class="nav nav-pills nav-pills-sm nav-pills-label nav-pills-bold mb-4" role="tablist">
        <li class="nav-item">
          <a class="nav-link cursor-pointer" @click="setEnv('production')" :class="{active: (tab === 'production')}" data-toggle="tab" role="tab" :aria-selected="(tab === 'regular')">
            Production
          </a>
        </li>
      </ul>

      <div class="tab-content">
        <div v-if="tab === 'production'" class="tab-pane" :class="{active: (tab === 'production')}" id="usage-tab-regular" :aria-expanded="(tab === 'regular')">
          <EmailStatistic
            @periodChanged="(e) => periodChanged(e)"
            :firstSent="profileData.sentProductionAt" 
            :chart="chartDataProd" 
            :usage="usageProd" 
            :dataType="tab"
            :loading="chartLoading" />
        </div>
        <div v-if="tab === 'sandbox'" class="tab-pane" :class="{active: (tab === 'sandbox')}" id="usage-tab-extra-quota" :aria-expanded="(tab === 'extra')">
          <EmailStatistic
            @periodChanged="(e) => periodChanged(e)"
            :firstSent="profileData.sentSandboxAt" 
            :chart="chartDataSbox"
            :usage="usageSandbox"
            :dataType="tab"
            :loading="chartLoading" 
            :extra="true" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import EmailStatistic from "./EmailStatistic.vue"
  import mailtarget from "@/factory/mailtarget"
  import moment from "moment/moment"

  export default {
    components: {
      EmailStatistic,
    },
    props: ['profileData'],
    data () {
      return {
        chartDataProd: {
          labels: ['1 Jan 00', '1 Feb 00', '1 Mar 00', '1 Jan 00', '1 Feb 00', '1 Mar 00', '1 Mar 00'],
          datasets: [{
            label: "Email Statistic",
            fill: false,
            backgroundColor: "#5d78ff",
            borderColor: "#5d78ff",
            data: [0, 1, 2]
          }]
        },
        filter: {
          precision: 'DAY',
        },
        chartDataSbox: this.chartDataProd,
        tab: 'production',
        quota: {},
        usageProd: {
          plan : "Sandbox",
          startDate : 1708560000000,
          endDate : 1716336000000,
          cutOffDate : 0,
          minimumUsage : 0,
          usage : 2,
          quota : 3000
        },
        usageSandbox: {
          plan : "Sandbox",
          startDate : 1708560000000,
          endDate : 1716336000000,
          cutOffDate : 0,
          minimumUsage : 0,
          usage : 2,
          quota : 3000
        },
        chartFormat: 'D MMM YY',
        chartLoading: true,
      }
    },
    computed: {
      companyId () { return this.$route.params.companyId }
    },
    mounted() {
      this.getCompanyQuota()
    },
    methods: {
      getCompanyQuota () {
        this.loading = true
        mailtarget.getTeamUsageProd(this.companyId)
          .then((res) => {
            this.usageProd = res
            return mailtarget.getTeamUsageSandbox(this.companyId)
          })
          .then((res) => {
            this.usageSandbox = res
          })
          .catch((err) => {
            this.$e(err)
          })
          .finally(() => {
            this.loading = false
          })
      },
      getChartData () {
        this.chartLoading = true
        mailtarget.getTeamUsageAnalytics(this.companyId, this.filter)
          .then((response) => {
            const res = response[0].list
            const labels = []
            const data = []
            for (let i = 0; i < res.length; i++) {
              labels.push(moment(res[i].ts).format(this.chartFormat))
              data.push(res[i].injection)
            }
            this.chartDataProd = {
              labels: labels,
              datasets: [{
                label: "Email Sent",
                fill: false,
                backgroundColor: "#5d78ff",
                borderColor: "#5d78ff",
                data: data
              }]
            }
            this.chartDataSbox = this.chartDataProd
          })
          .catch((err) => {
            this.$e(err)
          })
          .finally(() => {
            this.chartLoading = false
          })
      },
      setEnv(env) {
        this.tab = env
        this.filter = {
          ...this.filter,
          sandbox: env === 'sandbox'
        }
        return this.getChartData()
      },
      periodChanged(period) {
        const lessThanEqual48h = (period[1] || 1) - (period[0] || 0) <= (86400000 * 2)
        this.filter = {
          precision: lessThanEqual48h ? 'HOUR' : 'DAY',
          startDate: lessThanEqual48h ? (period[0] || 0) - (24 * 60 * 60 * 1000) : (period[0] || 0),
          endDate: (period[1] || 1) - (24 * 60 * 60 * 1000)
        }
        if (lessThanEqual48h) {
          this.chartFormat = 'DD MMM HH:mm'
        } else if (this.moreThen30Days) {
          this.chartFormat = 'MMM YYYY'
        } else {
          this.chartFormat = 'D MMM YY'
        }
        return this.getChartData()
      }
    }
  }
</script>
