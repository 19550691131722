var render = function render(){var _vm=this,_c=_vm._self._c;return _c('Modal',{attrs:{"title":_vm.subtitle,"size":"lg"},on:{"close":_vm.close}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"kt-portlet kt-portlet--mobile"},[_c('div',{staticClass:"kt-portlet__head kt-portlet__head--lg flex-wrap"},[_c('div',{staticClass:"kt-portlet__head-label flex-wrap my-1"},[_c('h3',{staticClass:"kt-portlet__head-title"},[_vm._v(" Info Template ")])])]),(_vm.data !== null)?_c('div',{staticClass:"kt-portlet__body kt-portlet__body"},[_c('div',{staticClass:"form-group"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 col-sm-4"},[_c('span',[_vm._v("Name")])]),_c('div',{staticClass:"col-12 col-sm-8"},[_c('span',{staticClass:"word-break-all"},[_vm._v(": "+_vm._s(_vm.data.name))])])])]),_c('div',{staticClass:"form-group"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 col-sm-4"},[_c('span',[_vm._v("Category")])]),_c('div',{staticClass:"col-12 col-sm-8"},[_c('span',{staticClass:"word-break-all"},[_vm._v(": "+_vm._s(_vm.data.category))])])])]),_c('div',{staticClass:"form-group"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 col-sm-4"},[_c('span',[_vm._v("Language")])]),_c('div',{staticClass:"col-12 col-sm-8"},[_c('span',{staticClass:"word-break-all"},[_vm._v(": "+_vm._s(_vm.data.language))])])])]),_c('div',{staticClass:"form-group"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 col-sm-4"},[_c('span',[_vm._v("Status")])]),_c('div',{staticClass:"col-12 col-sm-8"},[_c('span',{staticClass:"kt-badge kt-badge--inline kt-badge--pill",class:{
                    'kt-badge--primary': _vm.data.status === 'PENDING',
                    'kt-badge--info': _vm.data.status === 'REQUEST',
                    'kt-badge--warning': _vm.data.status === 'REVIEWED',
                    'kt-badge--success': _vm.data.status === 'APPROVED',
                    'kt-badge--danger': _vm.data.status === 'REJECTED',
                    'kt-badge--dark': _vm.data.status === 'DELETED'
                  }},[_vm._v(" "+_vm._s(_vm.data.status)+" ")])])])])]):_vm._e()]),_c('div',{staticClass:"kt-portlet kt-portlet--mobile"},[_c('div',{staticClass:"kt-portlet__body kt-portlet__body--fit bg-conversation"},[_c('div',{staticClass:"conversation-container"},[_c('div',{staticClass:"kt-portlet kt-portlet--mobile ticket-item-chat mb-0",class:{'is-admin': !_vm.data.member}},[_c('div',{staticClass:"kt-portlet__body kt-portlet__body--sm"},[_c('div',{staticClass:"item-chat-box"},[_c('div',{staticClass:"chat-detail"},[_c('div',{staticClass:"chat-name"},[_vm._v(_vm._s(_vm.data.name))]),_c('div',{staticClass:"chat-message"},[_vm._v(_vm._s(_vm.data.body))]),(_vm.data.attachment)?_c('div',{staticClass:"chat-image"},[_c('a',{staticClass:"text-primary cursor-pointer",on:{"click":function($event){return _vm.$emit('show-image', _vm.data.attachment)}}},[_vm._v(" "+_vm._s(_vm.data.attachment.name)+" ")])]):_vm._e(),_c('div',{staticClass:"chat-date"},[_c('span',[_vm._v(_vm._s(_vm._f("dtm")(_vm.data.createdAt)))])]),_c('div',{staticClass:"text-right"},[_c('button',{staticClass:"btn btn-md btn-primary",on:{"click":_vm.copy}},[_vm._v("Copy Message")])])])])])])])])])])]),(_vm.fileView !== null)?_c('ViewFile',{attrs:{"url":_vm.fileView.url,"name":_vm.fileView.name,"type":_vm.fileView.type},on:{"close":function($event){_vm.fileView = null}}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }