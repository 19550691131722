<script>
  import Pagination from "@/components/partial/Pagination.vue"
  import DocumentSvg from "@/components/svg/DocumentSvg.vue"
  import PaginationMixin from "@/components/PaginationMixin"
  import mailtarget from "@/factory/mailtarget"
  import ModalAddApiKey from "@/components/whitelabel/ModalAddApiKey.vue"
  import CopySvg from "@/components/svg/CopySvg.vue"
  import Confirmation from "@/components/partial/Confirmation.vue"
  import fn from "@/library/fn"
  import TrashSvg from "@/components/svg/TrashSvg.vue"
  
  
  export default {
    mixins: [PaginationMixin],
    name: "TabApiManagement",
    components: {TrashSvg, Confirmation, CopySvg, ModalAddApiKey, DocumentSvg, Pagination},
    data() {
      return {
        loading: false,
        filter: {
          page: 1,
          size: 10,
          leng: 0,
          count: 0,
        },
        selectedApiKey: null,
        list: [],
        apiKeyCreationModal: false,
      }
    },
    computed: {
      companyId () { return this.$route.params.companyId },
    },
    created() {
      this.get()
    },
    methods: {
      get () {
        mailtarget.getWhitelabelApiKey('jatis', this.companyId, this.filter)
          .then((res) => {
            this.list = res.apiKeys
            this.filter.count = res.count
          })
          .catch((err) => {
            this.$e(err)
          })
          .finally(() => this.apiKeyCreationModal = false)
      },
      truncatedWord(word) {
        return word.length > 32 ? word.substring(0, 32) + '...' : word
      },
      deleteApiKey () {
        mailtarget.deleteResellerApiKey(this.companyId, this.selectedApiKey.id)
          .then(() => {
            fn.notySuccess(`${this.selectedApiKey.name} API Key deleted!`)
            this.selectedApiKey = null
            return this.get()
          })
          .catch((err) => {
            this.$e(err)
          })
      },
      copy (item) {
        const el = document.createElement('textarea')
        el.value = item
        el.setAttribute('readonly', '')
        el.style.position = 'absolute'
        el.style.left = '-9999px'
        document.body.appendChild(el)
        el.select()
        document.execCommand('copy')
        document.body.removeChild(el)
        fn.notySuccess("API Key copied.")
      },
    }
  }
</script>

<template>
  <div class="row">
    <div class="col-12">
      <div class="kt-portlet kt-portlet--mobile rounded-12">
        <div class="kt-portlet__head kt-portlet__head--lg border-bottom">
          <div class="kt-portlet__head-label">
            <h3 class="kt-portlet__head-title">
            <span class="text-bold text-gray-700">
              API Key List
            </span>
            </h3>
          </div>
          <div class="d-flex align-items-center">
            <button @click="apiKeyCreationModal = true" type="button" class="btn btn-brand btn-elevate btn-icon-sm my-1 ml-1">
              <i class="la la-plus pr-0"></i> Create API Key
            </button>
          </div>
        </div>

        <div v-if="list.length > 0" class="kt-portlet__body kt-portlet__body--fit">
          <div class="table-responsive pl-4">
            <table class="table table-hover">
              <thead>
              <tr>
                <th>API Key Name</th>
                <th>Key</th>
                <th>Created at</th>
                <th>Subaccount</th>
                <th>Permission</th>
                <th>Action</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="item in list" :key="item.id">
                <td class="align-middle">
                  <div class="text-gray-700 font-weight-500">
                    {{ truncatedWord(item.name) }}
                  </div>
                </td>
                <td class="align-middle">
                  <div @click="copy(item.key)" class="text-gray-700 font-weight-500">
                    {{ item.key }} <span class="pointer"><CopySvg /></span>
                  </div>
                </td>
                <td class="align-middle">
                  <div class="text-gray-700 font-weight-500">
                    {{ item.createdAt | dtmType('DD MMM YYYY HH:mm') }}
                  </div>
                </td>
                <td class="align-middle">
                  <div class="text-gray-700 font-weight-500">
                    {{ item.subAccountName || 'Primary account' }}
                  </div>
                </td>
                <td class="align-middle">
                  <div class="text-gray-700 font-weight-500">
                    {{ item.permissionType === 'ALL' ? 'Full access' : 'Partial access' }}
                  </div>
                </td>
                <td class="align-middle">
                  <div>
                    <a
                      @click.prevent="() => {selectedApiKey = item}"
                      title="Detail Company"
                      class="btn-sm btn-clean btn-icon btn-icon-md cursor-pointer">
                        <span class="icon-bg bg-blue">
                          <TrashSvg />
                        </span>
                    </a>
                  </div>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div v-else class="my-4">
          <div class="d-flex flex-column justify-content-center align-items-center">
            <DocumentSvg class="w-image my-4" />
            <span class="text-caption my-4">
              There are no API Key list
            </span>
          </div>
        </div>

        <div v-if="list.length > 0" class="kt-portlet__foot kt-portlet__foot--sm">
          <Pagination @navigate="navigate" :filter="filter" />
        </div>
      </div>
    </div>

    <Confirmation
      v-if="selectedApiKey"
      @close="selectedApiKey = null"
      :message="`Are you sure you want to delete <strong>${selectedApiKey.name}</strong> API Key?`">
      <button @click="deleteApiKey" type="button" class="btn btn-danger">
        Delete
      </button>
    </Confirmation>

    <ModalAddApiKey
      v-if="apiKeyCreationModal"
      :companyId="companyId"
      partnerId="jatis"
      @created="get"
      @close="apiKeyCreationModal = false"/>
  </div>
</template>