import { loggedGet } from '@/library/ajax'

const apiUrl = process.env.VUE_APP_API_URL

const whitelabel = {
  getCompany (filter) {
    return loggedGet(apiUrl + '/reseller', filter)
  },
  getExportCompany (filter) {
    return loggedGet(apiUrl + '/reseller/export', filter)
  },
  getResellerQuota (partnerName, appName) {
    return loggedGet(`${apiUrl}/reseller/quota/${partnerName}/${appName}`)
  },
  getResellerTotalUser (partnerName) {
    return loggedGet(apiUrl + `/reseller/${partnerName}/user-stats`)
  },
  getResellerQuotaHistory (partnerName, appName, filter) {
    return loggedGet(`${apiUrl}/reseller/quota/${partnerName}/${appName}/history`, filter)
  },
  getResellerQuotaStats () {
    return loggedGet(apiUrl + '/reseller/quota/stats')
  },
  getResellerQuotaStatsExport () {
    return loggedGet(apiUrl + '/reseller/quota/export')
  }
}

export default whitelabel