<template>
  <div class="modal fade show " :class="{'modal-fullscreen' : fullscreen }" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" style="padding-right: 15px; display: block;" aria-modal="true">
    <div class="modal-dialog" :class="`modal-${size}`" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">{{ title }}</h5>
          <div class="d-flex">
            <slot name="status-header"></slot>
            <button v-if="allowClose" @click="close" type="button" class="close" data-dismiss="modal" aria-label="Close">
            </button>
          </div>
        </div>
        <div class="modal-body">
          <slot></slot>
        </div>
        <div class="modal-footer">
          <slot name="footer">
            <button v-if="allowClose" @click="$emit('close')" type="button" class="btn btn-primary" data-dismiss="modal">Close</button>
          </slot>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  export default {
    props: {
      fullscreen: {
        default: false
      },
      allowClose: {
        default: true
      },
      size: {
        default: 'md' // sm, md, lg
      },
      title: {
        default: 'Title'
      },
      level: {
        default: 0
      }
    },
    methods: {
      close () { this.$emit('close') }
    },
    mounted () {
      if (this.level === 0) document.body.classList.add('modal-open')
    },
    beforeDestroy() {
      if (this.level === 0) document.body.classList.remove('modal-open')
    }
  }
</script>

<style lang="scss" scoped>
  .modal {
    background-color: rgba(0, 0, 0, 0.2);
    padding-bottom: 300px;
  }
  .modal-footer {
    justify-content: space-between;
  }
  .modal .modal-content .modal-header .modal-title {
    font-weight: 700;
    color: #5E6278;
  }
</style>