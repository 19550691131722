<template>
  <Modal :title="title" @close="$emit('close')">
    <p class="text-center">Are you sure you want to <strong>EXTEND PLAN</strong> for this client?</p>
    <div>
      <div class="form-group row" :class="{'mb-0': !showAdvanceExtend}">
        <span class="col-12 col-sm-4 col-form-label">Advance Option</span>
        <div class="col">
          <InputSwitch v-model="showAdvanceExtend" class="kt-switch--sm" />
        </div>
      </div>
    </div>
    <div v-if="showAdvanceExtend">
      <div class="form-group">
        <div class="row align-items-center">
          <div class="col-12 col-sm-4">
            <label>Promo Code</label>
          </div>
          <div class="col-12 col-sm">
            <div class="row">
              <div class="col-12 col-sm">
                <input v-model="promoCode" @keydown.enter="checkPromoCode" type="text" class="form-control">
              </div>
              <div class="col-12 col-sm-4">
                <button @click="checkPromoCode" class="btn btn-primary btn-block">Check</button>
              </div>
            </div>
          </div>
        </div>
        <div v-if="detailPromo !== null" class="row">
          <div class="col-12 col-sm-4">
          </div>
          <div class="col-12 col-sm">
            <div class="row">
              <div class="col-12">
                <div>
                  {{ detailPromo.type }} : 
                  <span v-if="detailPromo.type === 'VOUCHER'" class="text-bold">{{ detailPromo.voucher | curr }}</span>
                  <span v-if="detailPromo.type === 'DISCOUNT'" class="text-bold">{{ detailPromo.discount }}% (Max: {{ detailPromo.maxDiscount }})</span>
                </div>
                <div><a class="cursor-pointer text-success" @click="$emit('detail-promo', detailPromo)">Click here</a> to see promo detail.</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="form-group">
        <div class="row align-items-center">
          <div class="col-12 col-sm-4">
            <label>End Date</label>
          </div>
          <div v-if="showDate" class="col-12 col-sm">
            <DatePicker v-model="endDate" :customConfig="dateConfig" />
          </div>
        </div>
      </div>
      <div class="form-group">
        <div class="row align-items-center">
          <div class="col-12 col-sm-4">
            <label>Subscription Period</label>
          </div>
          <div class="col-6 col-sm-4">
            <select v-model="subscriptionPeriod" class="form-control">
              <option :value="1">Monthly</option>
              <!-- <option :value="3">Quarterly</option> -->
              <option :value="12">Annually</option>
            </select>
          </div>
          <div class="col-6 col-sm-4">
            <select v-model="subscription" class="form-control">
              <option v-for="(data, i) in subscriptionOption" :key="i" :value="data">{{ data }} {{ (subscriptionPeriod === 1) ? 'Month' : (subscriptionPeriod === 3) ? 'Quarterly' : 'Year' }}</option>
            </select>
          </div>
        </div>
      </div>
      <!-- <div class="border-top border-aqua mb-4"></div>
      <div class="form-group mb-0">
        <div class="mb-3">
          <h5>Extra Service</h5>
        </div>
        <div v-if="extraService.length > 0" class="row">
          <div class="col-sm-6">
            <label>Name</label>
          </div>
          <div class="col-sm-6">
            <label>Price</label>
          </div>
        </div>
        <div v-for="(service, i) in extraService" :key="i" class="row mb-2">
          <div class="col-sm-6">
            <input v-model="service.name" type="text" class="form-control">
          </div>
          <div class="col-sm-6">
            <div class="row">
              <div class="col">
                <InputCurrency v-model="service.price" @keypress="onlyNumber" />
              </div>
              <div class="d-flex align-items-center">
                <button @click="deleteService(i)" class="btn btn-sm btn-clean btn-icon btn-icon-md">
                  <i class="flaticon2-trash text-danger"></i>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <button @click="addService" class="btn btn-sm btn-outline-success">Add Service</button>
          </div>
        </div>
      </div> -->
    </div>
    <template v-slot:footer>
      <button @click.prevent="$emit('close')" type="button" class="btn btn-secondary" :class="{wait: saving}" data-dismiss="modal">Close</button>
      <button @click.prevent="extend" type="button" class="btn btn-primary" :class="{wait: saving}">Yes</button>
    </template>
  </Modal>
</template>

<script>
  import Modal from '@/components/partial/Modal'
  import InputSwitch from '@/components/partial/InputSwitch'
  import DatePicker from '@/components/partial/DatePicker'
  // import InputCurrency from '@/components/partial/InputCurrency'
  import payment from '@/factory/payment'
  import promo from '@/factory/promo'
  import fn from '@/library/fn'
  import moment from 'moment'

  export default {
    components: {
      Modal,
      InputSwitch,
      DatePicker,
      // InputCurrency,
    },
    props: ['company'],
    data () {
      return {
        title: 'Extend Plan',
        showAdvanceExtend: false,
        endDate: null,
        promoCode: '',
        detailPromo: null,
        dateConfig: {
          enableTime: false
        },
        showDate: false,
        saving: false,
        subscriptionPeriod: null,
        subscription: 1,
        extraService: []
      }
    },
    computed: {
      companyId () { return this.$route.params.companyId },
      subscriptionOption () {
        return (this.subscriptionPeriod === 1) ? [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11] : (this.subscriptionPeriod === 3) ? [1, 2, 3, 4] : [1, 2, 3, 4, 5]
      }
    },
    watch: {
      subscriptionPeriod (val, old) { if (old !== null) this.subscription = 1 }
    },
    created () {
      this.init()
    },
    methods: {
      init () {
        let now = moment().format('x')
        if (now < this.company.expiredDate) {
          this.dateConfig.minDate = new Date(this.company.expiredDate)
        }
        this.showDate = true
        this.subscriptionPeriod = (this.company.subscription < 12) ? 1 : 12
        this.subscription = (this.subscriptionPeriod === 1) ? this.company.subscription : (this.subscriptionPeriod === 3) ? this.company.subscription/3 : this.company.subscription/12
      },
      checkPromoCode () {
        promo.checkPromo(this.promoCode.toUpperCase(), this.companyId)
          .then((res) => {
            this.detailPromo = res
          })
          .catch((err) => {
            this.detailPromo = null
            this.$e(err)
          })
      },
      onlyNumber ($event) {
        let key = ($event.keyCode ? $event.keyCode : $event.which)
        if ((key < 48 || key > 57) && key !== 46 && key !== 45) {
          $event.preventDefault()
        }
      },
      addService () {
        this.extraService.push({name: '', price: 0})
      },
      deleteService (index) {
        this.extraService.splice(index, 1)
      },
      extend () {
        let data = {
          teamId: this.companyId
        }
        if (this.showAdvanceExtend) {
          data.endDate = this.endDate
          data.promoCode = this.promoCode
          data.period = (this.subscriptionPeriod === 1) ? this.subscription : (this.subscriptionPeriod === 3) ? this.subscription*3 : this.subscription*12
          this.extraService.forEach((val) => {
            val.price = parseInt(val.price)
          })
          data.items = this.extraService
        }
        this.saving = true
        payment.extendPlan(data)
          .then(() => {
            this.saving = false
            fn.notySuccess("Billing successfully extended.")
            this.$emit('extend-success')
          })
          .catch((err) => {
            this.saving = false
            this.$e(err)
          })
      }
    }
  }
</script>
