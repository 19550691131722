import { loggedGet, loggedPost, loggedDel } from '@/library/ajax'
const apiUrl = process.env.VUE_APP_API_URL

export default {
  getVersion: () => loggedGet(apiUrl + '/setting/version', {}),
  saveVersion: (data) => loggedPost(apiUrl + '/setting/version', data),
  getFee: () => loggedGet(apiUrl + '/setting/fee', {}),
  saveFee: (data) => loggedPost(apiUrl + '/setting/fee', data),
  getInvalidDomain: () => loggedGet(apiUrl + '/setting/invalid-domain', {}),
  getBlockedIp: (filter) => loggedGet(apiUrl + '/setting/blocked-ip', filter),
  deleteBlockedIp: (id) => loggedDel(apiUrl + '/setting/blocked-ip/delete', { id })
}
