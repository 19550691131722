<template>
  <div class="loading-absolute">
    <div class="loading-circle" :style="overlay">
      <div v-if="requireClick" @click="$emit('load')" class="click-to-load">
        <span>Click to Load Data</span>
      </div>
      <img v-else :src="`/assets/images/icon-loading-${color}.svg`" :style="imgSize" alt="Loading" />
    </div>
  </div>
</template>

<script>
  export default {
    name: 'LoadingCircle',
    props: {
      oColor: {
        default: '#0abb87'
      },
      oOpacity: {
        default: '0.1'
      },
      color: {
        default: 'grey' // grey,orange
      },
      size: {
        default: 75
      },
      requireClick: {
        default: false
      }
    },
    computed: {
      overlay () {
        let result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(this.oColor)
        if (result) {
          return {
            'background-color': `rgba(${parseInt(result[1], 16)},${parseInt(result[2], 16)},${parseInt(result[3], 16)},${this.oOpacity})`
          }
        }
        return null
      },
      imgSize () {
        return { width: `${this.size}px` }
      }
    }
  }
</script>

<style lang="scss" scoped>
  @import "~@/assets/scss/_config.scss";

  .loading-absolute {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    .loading-circle {
      height: 100%;
      position: relative;
      img, .click-to-load {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
      }
      .click-to-load {
        cursor: pointer;
        color: kt-get($kt-state-colors, success, base);
        font-size: 16px;
        border: 1px solid kt-get($kt-state-colors, success, base);
        border-radius: 3px;
        padding: 4px;
        &:hover {
          border: 2px solid kt-get($kt-state-colors, success, base);
          font-weight: 500;
        }
      }
    }
  }
</style>
