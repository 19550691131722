<template>
  <div class="row">
    <div class="col-12">
      <div class="kt-portlet kt-portlet--mobile">
        <div class="kt-portlet__head kt-portlet__head--lg border-bottom">
          <div class="kt-portlet__head-label">
            <h3 class="kt-portlet__head-title">
              <span class="text-bold text-gray-700">
                Subscription Detail
              </span>
            </h3>
          </div>
          <div class="kt-portlet__head-toolbar my-1">
            <div class="kt-portlet__head-wrapper">
              <div class="kt-portlet__head-actions d-flex">
                <!-- <button v-if="!showHistory" @click="getHistories" class="btn text-blue-default text-sm font-weight-500">
                  Show History
                </button>
                <button v-else @click="hideHistory" class="btn text-blue-default text-sm font-weight-500">
                  Hide History
                </button> -->
                <!-- <router-link :to="`/clients/update-plan/${companyId}?tab=subscription`" type="button" class="btn btn-primary btn-icon-sm ml-2">
                  <i class="la la-arrow-circle-up"></i>
                  Upgrade Plan
                </router-link> -->
                <button @click="$emit('close')" type="button" class="btn btn-primary btn-icon-sm ml-2">
                  <i class="la la-arrow-circle-up"></i>
                  Upgrade Plan
                </button>
              </div>
            </div>
          </div>
        </div>

        <div v-if="currentPlan !== null">
          <!-- <div class="background-success-active text-sm font-weight-bold text-white d-flex align-items-center py-2 pl-4">
            Current Subscription
            <Verifiedv2Svg class="ml-1" />
          </div> -->

          <div class="kt-portlet__body kt-portlet__body--md pt-5 pb-0">
            <div v-if="currentPlan.type.toUpperCase() === 'PAYASYOUGO'" class="row">
              <div class="col-3">
                <div class="text-sm font-weight-600 pb-5">
                  <div>Package</div>
                  <div class="text-caption font-weight-500 pt-4 text-capitalize">{{ currentPlan.packet }}</div>
                </div>
                <div class="text-sm font-weight-600 pb-5">
                  <div>Min.Fair Usage</div>
                  <div class="text-caption font-weight-500 pt-4">{{ currentPlan.minFairUsage | curr }} Emails</div>
                </div>
              </div>
              <div class="col-3">
                <div class="text-sm font-weight-600 pb-5">
                  <div>Subscription Period</div>
                  <div class="text-caption font-weight-500 pt-4">Monthly</div>
                </div>
                <div class="text-sm font-weight-600 pb-5">
                  <div>Price /email</div>
                  <div class="text-caption font-weight-500 pt-4">{{ currentPlan.emailPrice | curr }}</div>
                </div>
              </div>
              <div class="col-3">
                <div class="text-sm font-weight-600 pb-5">
                  <div>Cut off</div>
                  <div class="text-caption font-weight-500 pt-4">Every {{ currentPlan.cutOffPeriod }}</div>
                </div>
                <div class="text-sm font-weight-600 pb-5">
                  <div>Estimated Price</div>
                  <div class="text-caption font-weight-500 pt-4">IDR {{ (currentPlan.emailPrice * currentPlan.minFairUsage) | curr(2) }}</div>
                </div>
              </div>
              <div class="col-3">
                <div class="text-sm font-weight-600 pb-5">
                  <div>Minimum Price</div>
                  <div class="text-caption font-weight-500 pt-4">IDR {{ (currentPlan.emailPrice * currentPlan.minFairUsage) | curr(2) }}</div>
                </div>
              </div>
            </div>
            <div v-else class="row">
              <div class="col-3">
                <div class="text-sm font-weight-600 pb-5">
                  <div>Package</div>
                  <div class="text-caption font-weight-500 pt-4 text-capitalize">{{ currentPlan.packet }}</div>
                </div>
                <div class="text-sm font-weight-600 pb-5">
                  <div>Email Quota</div>
                  <div class="text-caption font-weight-500 pt-4">{{ currentPlan.emailLimit | curr }} {{ getPerPeriod(currentPlan.subscription, currentPlan.periodType) }}</div>
                </div>
              </div>
              <div class="col-3">
                <div class="text-sm font-weight-600 pb-5">
                  <div>Start Date</div>
                  <div v-if="currentPlan.startDate" class="text-caption font-weight-500 pt-4">{{ currentPlan.startDate | dtmType('DD MMM YYYY HH:mm') }}</div>
                  <div v-else class="text-caption font-weight-500 pt-4">-</div>
                </div>
                <div class="text-sm font-weight-600 pb-5">
                  <div>End Date</div>
                  <div v-if="currentPlan.endDate" class="text-caption font-weight-500 pt-4">{{ currentPlan.endDate | dtmType('DD MMM YYYY HH:mm') }}</div>
                  <div v-else class="text-caption font-weight-500 pt-4">-</div>
                </div>
              </div>
              <div class="col-3">
                <div class="text-sm font-weight-600 pb-5">
                  <div>Subscription Period</div>
                  <div v-if="currentPlan.periodType" class="text-caption font-weight-500 pt-4 text-capitalize">{{ currentPlan.periodType | lower }}</div>
                  <div v-else class="text-caption font-weight-500 pt-4">-</div>
                </div>
                <div class="text-sm font-weight-600 pb-5">
                  <div>Contact Limit</div>
                  <div v-if="currentPlan.contactLimit === -1" class="text-caption font-weight-500 pt-4">Unlimited</div>
                  <div v-else class="text-caption font-weight-500 pt-4">{{ currentPlan.contactLimit | curr }}</div>
                </div>
              </div>
              <div class="col-3">
                <div class="text-sm font-weight-600 pb-5">
                  <div>Monthly Price</div>
                  <div v-if="currentPlan.subscription >= 12" class="text-caption font-weight-500 pt-4">IDR {{ currentPlan.yearlyPrice | curr(2) }}</div>
                  <div v-else class="text-caption font-weight-500 pt-4">IDR {{ currentPlan.monthlyPrice | curr(2) }}</div>
                </div>
                <div class="text-sm font-weight-600 pb-5">
                  <div>Subtotal Price</div>
                  <div class="text-caption font-weight-500 pt-4">IDR {{ currentPlan.subTotal | curr(2) }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div v-if="showHistory">
          <div class="background-gray-600 text-sm font-weight-bold text-white d-flex align-items-center py-2 pl-4">
            Previous Subscription
          </div>

          <div v-for="(history, i) in list" :key="i" class="kt-portlet__body kt-portlet__body--md pt-5 pb-0 border-bottom">
            <div v-if="history.type.toUpperCase() === 'PAYASYOUGO'" class="row">
              <div class="col-3">
                <div class="text-sm font-weight-600 pb-5">
                  <div>Package</div>
                  <div class="text-caption font-weight-500 pt-4 text-capitalize">{{ history.packet }}</div>
                </div>
                <div class="text-sm font-weight-600 pb-5">
                  <div>Min.Fair Usage</div>
                  <div class="text-caption font-weight-500 pt-4">{{ history.minFairUsage | curr }} Emails</div>
                </div>
              </div>
              <div class="col-3">
                <div class="text-sm font-weight-600 pb-5">
                  <div>Subscription Period</div>
                  <div class="text-caption font-weight-500 pt-4">Monthly</div>
                </div>
                <div class="text-sm font-weight-600 pb-5">
                  <div>Price /email</div>
                  <div class="text-caption font-weight-500 pt-4">{{ history.emailPrice | curr }}</div>
                </div>
              </div>
              <div class="col-3">
                <div class="text-sm font-weight-600 pb-5">
                  <div>Cut off</div>
                  <div class="text-caption font-weight-500 pt-4">Every {{ history.cutOffPeriod }}</div>
                </div>
                <div class="text-sm font-weight-600 pb-5">
                  <div>Estimated Price</div>
                  <div class="text-caption font-weight-500 pt-4">IDR {{ (history.emailPrice * history.minFairUsage) | curr(2) }}</div>
                </div>
              </div>
              <div class="col-3">
                <div class="text-sm font-weight-600 pb-5">
                  <div>Minimum Price</div>
                  <div class="text-caption font-weight-500 pt-4">IDR {{ (history.emailPrice * history.minFairUsage) | curr(2) }}</div>
                </div>
              </div>
            </div>
            <div v-else class="row">
              <div class="col-3">
                <div class="text-sm font-weight-600 pb-5">
                  <div>Package</div>
                  <div class="text-caption font-weight-500 pt-4 text-capitalize">{{ history.packet }}</div>
                </div>
                <div class="text-sm font-weight-600 pb-5">
                  <div>Email Quota</div>
                  <div class="text-caption font-weight-500 pt-4">{{ history.emailLimit | curr }} {{ getPerPeriod(history.subscription, history.periodType) }}</div>
                </div>
              </div>
              <div class="col-3">
                <div class="text-sm font-weight-600 pb-5">
                  <div>Start Date</div>
                  <div v-if="history.startDate" class="text-caption font-weight-500 pt-4">{{ history.startDate | dtmType('DD MMM YYYY HH:mm') }}</div>
                  <div v-else class="text-caption font-weight-500 pt-4">-</div>
                </div>
                <div class="text-sm font-weight-600 pb-5">
                  <div>End Date</div>
                  <div v-if="history.endDate" class="text-caption font-weight-500 pt-4">{{ history.endDate | dtmType('DD MMM YYYY HH:mm') }}</div>
                  <div v-else class="text-caption font-weight-500 pt-4">-</div>
                </div>
              </div>
              <div class="col-3">
                <div class="text-sm font-weight-600 pb-5">
                  <div>Subscription Period</div>
                  <div v-if="history.periodType" class="text-caption font-weight-500 pt-4 text-capitalize">{{ history.periodType | lower }}</div>
                  <div v-else class="text-caption font-weight-500 pt-4">-</div>
                </div>
                <div class="text-sm font-weight-600 pb-5">
                  <div>Contact Limit</div>
                  <div v-if="history.contactLimit === -1" class="text-caption font-weight-500 pt-4">Unlimited</div>
                  <div v-else class="text-caption font-weight-500 pt-4">{{ history.contactLimit | curr }}</div>
                </div>
              </div>
              <div class="col-3">
                <div class="text-sm font-weight-600 pb-5">
                  <div>Monthly Price</div>
                  <div v-if="history.subscription >= 12" class="text-caption font-weight-500 pt-4">IDR {{ history.yearlyPrice | curr(2) }}</div>
                  <div v-else class="text-caption font-weight-500 pt-4">IDR {{ history.monthlyPrice | curr(2) }}</div>
                </div>
                <div class="text-sm font-weight-600 pb-5">
                  <div>Subtotal Price</div>
                  <div class="text-caption font-weight-500 pt-4">IDR {{ history.subTotal | curr(2) }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
  // import Verifiedv2Svg from '@/components/svg/Verifiedv2Svg.vue'
  import payment from '@/factory/payment'
  import fn from '@/library/fn'

  export default {
    components: {
      // Verifiedv2Svg
    },
    props: {
      currentPlan: { default: null }
    },
    data () {
      return {
        showHistory: false,
        list: [],
      }
    },
    computed: {
      companyId () { return this.$route.params.companyId },
    },
    methods: {
      getHistories () {
        payment.getPlanHistory(this.companyId)
          .then((res) => {
            this.list = res
            this.showHistory = (this.list.length > 0)
            if (!this.showHistory) fn.notySuccess('There is no history')
          })
          .catch(err => this.$e(err))
      },
      hideHistory () {
        this.showHistory = false
        this.list = []
      },
      getPerPeriod (subscription, periodType) {
        if (!periodType) return `/${subscription/1} month`
        else if (periodType.toUpperCase() === 'MONTHLY') return `/${subscription/1} month`
        else if (periodType.toUpperCase() === 'QUARTERLY') return `/${subscription/3} qurter`
        else if (periodType.toUpperCase() === 'YEARLY') return `/${subscription/12} year`
        return ''
      }
    }
  }
</script>
