<template>
  <LayoutBasic class="kt-grid kt-grid--ver kt-grid--root">
    <div
      class="kt-grid__item kt-grid__item--fluid kt-grid kt-error-v1"
      :style="`background-image: url(${bg1});`">
      <div class="kt-error-v1__container">
        <h1 class="kt-error-v1__number">404</h1>
        <p class="kt-error-v1__desc">
          OOPS! This page is not exist, <router-link to="/">Back to home</router-link>
        </p>
      </div>
    </div>
  </LayoutBasic>
</template>

<script>
  import LayoutBasic from '@/components/LayoutBasic'
  import '@/assets/template/sass/pages/error/error-1.scss'
  import bg1 from '@/assets/template/media/error/bg1.jpg'

  export default {
    components: {LayoutBasic},
    data () {
      return {
        bg1
      }
    }
  }
</script>