<template>
  <Modal :title="'Upload Tax Invoice'" @close="$emit('close')">
    <div class="row pb-4">
      <div class="col-4 font-weight-500">
        <div class="text-gray-800 pb-3">Invoice Number</div>
        <div class="text-caption">{{ data.invoice }}</div>
      </div>
      <div v-if="['REQUESTED', 'UPLOADED'].includes(data.taxRequestStatus)" class="col-4 font-weight-500">
        <div class="text-gray-800 pb-3">Requested on</div>
        <div class="text-caption">{{ data.createdAt | dtmType('DD MMM YYYY HH:mm') }}</div>
      </div>
      <div v-if="data.taxRequestStatus === 'UPLOADED'" class="col-4 font-weight-500">
        <div class="text-gray-800 pb-3">Uploaded on</div>
        <div class="text-caption">{{ data.taxUploadedAt | dtmType('DD MMM YYYY HH:mm') }}</div>
      </div>
    </div>

    <div v-if="state === 'uploaded' && !!data.taxInvoiceUrl" class="background-gray-100 p-4 d-flex justify-content-center">
      <span class="d-flex align-items-center">
        <PdfSvg />
      </span>
      <div class="d-flex flex-column font-size-xs ml-3 text-gray-70">
        <span class="font-weight-500">
          {{ truncatedTitle(data.taxInvoiceUrl.split('/')[data.taxInvoiceUrl.split('/').length - 1]) }}
        </span>
      </div>
      <div class="d-flex align-items-center text-gray-70 ml-5">
        <span @click="downloadFileUrl(data.taxInvoiceUrl, data.taxInvoiceUrl.split('/')[data.taxInvoiceUrl.split('/').length - 1])" class="cursor-pointer">
          <i class="flaticon-download" />
        </span>
        <span @click="openImageUrl(data.taxInvoiceUrl, data.taxInvoiceUrl.split('/')[data.taxInvoiceUrl.split('/').length - 1])" class="cursor-pointer ml-4">
          <i class="flaticon-eye" />
        </span>
      </div>
    </div>

    <div v-else-if="state === 'selected' && fileType === 'pdf'" class="background-gray-100 p-4 d-flex justify-content-center">
      <span class="d-flex align-items-center">
        <PdfSvg v-if="fileType === 'pdf'" />
        <!-- <PptSvg v-else-if="fileType === 'presentation'" />
        <DocSvg v-else-if="fileType === 'word'" />
        <XlsSvg v-else-if="fileType === 'sheet'" />
        <ImageSvg v-else-if="fileType === 'image'" /> -->
        <QuestionDocs v-else />
      </span>
      <div class="d-flex flex-column font-size-xs ml-3 text-gray-70">
        <span class="font-weight-500">
          {{ truncatedTitle(fileUpload.name) }}
        </span>
        <div>
          <span class="font-weight-normal">
            {{ fileSizing(fileUpload.size) }} . {{ fileUpload.lastModified | dtmType('DD MMM YYYY') }}
          </span>
        </div>
      </div>
      <div class="d-flex align-items-center text-gray-70 ml-5">
        <span @click="downloadFile(fileUpload)" class="cursor-pointer">
          <i class="flaticon-download" />
        </span>
        <span @click="openImage(fileUpload)" class="cursor-pointer ml-4">
          <i class="flaticon-eye" />
        </span>
      </div>
    </div>

    <div v-else-if="state === 'selected' && fileType !== 'pdf'">
      <input @change="uploadInput" id="not-support" type="file" accept="application/pdf" class="d-none">
      <div v-if="state === 'selected' && fileType !== 'pdf'" @click="uploadFile('not-support')" class="d-flex justify-content-center upload-placeholder p-4 cursor-pointer text-caption text-xs align-items-center">
        <span class="d-flex align-items-center">
          <PdfSvg v-if="fileType === 'pdf'" />
          <QuestionDocs v-else />
        </span>
        <div class="d-flex flex-column font-size-xs ml-3 text-gray-70">
          <span class="font-weight-500">
            {{ truncatedTitle(fileUpload.name) }}
          </span>
          <div>
            <span class="font-weight-normal">
              {{ fileSizing(fileUpload.size) }} . {{ fileUpload.lastModified | dtmType('DD MMM YYYY') }}
            </span>
          </div>
        </div>
        <div class="d-flex align-items-center text-gray-70 ml-3 px-5">
          <span @click="downloadFile(fileUpload)" class="cursor-pointer">
            <i class="flaticon-download" />
          </span>
          <span @click="deleteFile" class="cursor-pointer ml-4">
            <i class="flaticon2-trash text-danger-50" />
          </span>
        </div>
      </div>
      <div class="text-center">
        <span class="text-danger-50 text-xs font-weight-500">
          Your uploaded document it’s not supported, Please use file document with PDF format
        </span>
      </div>
    </div>

    <div v-else-if="state === 'edit'" @click="uploadFile('edit-zone')" class="upload-placeholder p-4 cursor-pointer text-caption text-xs d-flex flex-column align-items-center">
      <input @change="uploadInput" id="edit-zone" type="file" accept="application/pdf" class="d-none">
      <div class="d-flex">
        <span class="d-flex align-items-center">
          <PdfSvg v-if="fileType === 'pdf'" />
          <!-- <PptSvg v-else-if="fileType === 'presentation'" />
          <DocSvg v-else-if="fileType === 'word'" />
          <XlsSvg v-else-if="fileType === 'sheet'" />
          <ImageSvg v-else-if="fileType === 'image'" /> -->
          <QuestionDocs v-else />
        </span>
        <div class="d-flex flex-column font-size-xs ml-3 text-gray-70">
          <span class="font-weight-500">
            {{ truncatedTitle(fileUpload.name) }}
          </span>
          <div>
            <span class="font-weight-normal">
              {{ fileSizing(fileUpload.size) }} . {{ fileUpload.lastModified | dtmType('DD MMM YYYY') }}
            </span>
          </div>
        </div>
        <div class="d-flex align-items-center text-gray-70 ml-5 px-5">
          <span @click="deleteFile" class="cursor-pointer ml-4">
            <i class="flaticon2-trash text-danger-50" />
          </span>
        </div>
      </div>
      <div v-if="fileType !== 'pdf'" class="text-center">
        <span class="text-danger-50 text-xs font-weight-500">
          Your uploaded document it’s not supported, Please use file document with PDF format
        </span>
      </div>
    </div>

    <div v-else-if="state === null" @click="uploadFile('drop-zone')" @dragover.prevent="bgDrag = true" @dragleave.prevent="bgDrag = null" @drop.prevent="dropHandler" class="upload-placeholder p-4 cursor-pointer text-caption text-xs d-flex flex-column align-items-center" :class="{'background-gray-100': bgDrag}">
      <input @change="uploadInput" id="drop-zone" type="file" accept="application/pdf" class="d-none">
      <DocumentSvg class="mb-2" />
      Drag & Drop or Browse your document here
    </div>

    <template v-slot:footer>
      <button @click.prevent="$emit('close')" type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
      <div v-if="state === 'edit'">
        <button @click="cancelEdit" class="btn btn-md btn-outline-primary btn-icon-sm mr-3">Cancel</button>
      </div>
      <div v-else-if="state !== 'uploaded'">
        <button v-if="fileUpload" @click="editFile" class="btn btn-md btn-outline-primary btn-icon-sm mr-3">Edit</button>
        <button @click="submit" :disabled="fileType !== 'pdf'" type="button" class="btn btn-primary">Submit</button>
      </div>
    </template>
  </Modal>
</template>

<script>
  import Modal from '@/components/partial/Modal'
  import DocumentSvg from '@/components/svg/DocumentSvg.vue'
  // import PptSvg from '@/components/svg/PptSvg.vue'
  // import DocSvg from '@/components/svg/DocSvg.vue'
  // import ImageSvg from '@/components/svg/ImageSvg.vue'
  import PdfSvg from '@/components/svg/PdfSvg.vue'
  // import XlsSvg from '@/components/svg/XlsSvg.vue'
  import QuestionDocs from '@/components/svg/QuestionDocs.vue'
  import payment from '@/factory/payment'
  import fn from '@/library/fn'

  export default {
    components: {
      Modal,
      DocumentSvg,
      // PptSvg,
      // DocSvg,
      // ImageSvg,
      PdfSvg,
      // XlsSvg,
      QuestionDocs
    },
    props: {
      data: { default: null }
    },
    data() {
      return {
        state: null, // selected, uploaded
        bgDrag: null,
        allTypes: ['image', 'pdf', 'word', 'presentation', 'sheet'],
        fileType: null,
        fileUpload: null,
        fromEdit: false
      }
    },
    created () {
      this.init()
    },
    methods: {
      init () {
        if (this.data.taxRequestStatus === 'UPLOADED') this.state = 'uploaded'
      },
      uploadFile(id) {
        let element = document.getElementById(id)
        if (id === 'edit-zone' && this.fromEdit) return
        else if (id === 'edit-zone' && !this.fromEdit) return element.click()
        else if (id === 'drop-zone' && !this.fromEdit) return element.click()
        else if (id === 'not-support') return element.click()
        this.fromEdit = false
      },
      uploadInput (el) {
        const files = el.target.files || el.dataTransfer.files
        this.fileType = this.allTypes.find(x => files[0].type.includes(x)) || null
        this.fileUpload = files[0]
        if (this.state !== 'edit') this.state = 'selected'
      },
      dropHandler(el) {
        el.preventDefault()
        const files = el.target.files || el.dataTransfer.files
        this.fileType = this.allTypes.find(x => files[0].type.includes(x)) || null
        this.fileUpload = files[0]
        this.state = 'selected'
      },
      submit () {
        if (!this.fileUpload) return
        const data = new FormData()
        data.append('file', this.fileUpload)
        payment.uploadTaxInvoice(this.data.invoice, data)
          .then(res => {
            if (res) {
              fn.notySuccess("Tax invoice successfully uploaded.")
              this.$emit('upload-success')
              this.$emit('close')
            }
          })
          .catch(() => {})
      },
      editFile () {
        this.state = 'edit'
      },
      deleteFile () {
        this.fileUpload = null
        this.state = null
        this.fromEdit = true
      },
      cancelEdit () {
        this.state = 'selected'
      },
      downloadFile (file) {
        const url = URL.createObjectURL(file)
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', file.name)
        link.click()
      },
      downloadFileUrl (url, name) {
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', name)
        link.click()
      },
      openImage (file) {
        const blob = new Blob ([file], {type: file.type})
        const blobUrl = URL.createObjectURL(blob)
        window.open(blobUrl, '_blank')
      },
      openImageUrl (url) {
        const link = document.createElement('a')
        link.href = url
        link.target = '_blank'
        link.click()
      },
      truncatedTitle(val) {
        const maxLength = 30
        if (val.length > maxLength) return val.substring(0, maxLength) + '...'
        return val
      },
      fileSizing (val) {
        let result = val / 1000
        if (result < 1000) return `${result.toFixed(2)} KB`
        return `${(result / 1000).toFixed(2)} MB`
      }
    }
  }
</script>