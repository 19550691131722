<template>
  <Modal @close="$emit('close')" :title="title">
    <div class="text-center my-5">
      Are you sure want to <strong>{{ title }}</strong> {{ extendedText }} ?
    </div>
    <template v-slot:footer>
      <button @click="$emit('close')" type="button" class="btn btn-secondary" data-dismiss="modal">Cancel</button>
      <button @click="mailto" type="button" class="btn btn-primary">Yes</button>
    </template>
  </Modal>
</template>
<script>
  import Modal from "@/components/partial/Modal"

  export default {
    props: {
      title: {
        default: 'Buy Quota'
      },
      extendedText: {
        default: ''
      },
      company: {
        default: ''
      },
    },
    components: {
      Modal,
    },
    data() {
      return {
        appTitle: '',
      }
    },
    computed: {
      email () { return process.env.VUE_APP_EMAIL_CONTACT_US },
      appName () { return this.$route.params.appName.toLowerCase() },
    },
    methods: {
      mailto () {
        if (this.appName === 'mtarget') this.appTitle = 'MTARGET'
        if (this.appName === 'mailtarget') this.appTitle = 'Mailtarget'
        window.location.assign(`mailto:${this.email}?Subject=` + encodeURIComponent(`${this.appTitle} ${this.title} Jatis ${this.company}`));
      }
    }
  }
</script>