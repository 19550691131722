<template>
  <Modal @close="$emit('close')" title="Add Extra Quota">
    <div class="text-center my-5">
      Are you sure want to <strong>Add Quota</strong> {{ companyName }} ?
    </div>
    <template v-slot:footer>
      <button @click="$emit('close')" type="button" class="btn btn-secondary" data-dismiss="modal">Cancel</button>
      <button @click="mailto" type="button" class="btn btn-primary">Yes</button>
    </template>
  </Modal>
</template>
<script>
  import Modal from "@/components/partial/Modal"

  export default {
    props: {
      companyName: {
        default: ''
      },
    },
    components: {
      Modal,
    },
    data() {
      return {
      }
    },
    computed: {
      company () { return process.env.VUE_APP_COMPANY.toLowerCase() },
      email () { return process.env.VUE_APP_EMAIL_CONTACT_US },
    },
    watch:{},
    created () { 
    },
    methods: {
      mailto () {
        window.location.assign(`mailto:${this.email}?Subject=` + encodeURIComponent(`Add quota ${this.company}`));
      }
    }
  }
</script>