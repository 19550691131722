export default {
  data () {
    return {
      filter: {
        count: 0,
        sort: '-createdAt',
        page: 1,
        leng: 0,
        size: 10,
        search: null
      },
      defaultOrder: '-createdAt'
    }
  },
  created () {
    this.navigate(null)
  },
  computed: {
    sortArrow () {
      return (this.filter.sort.slice(0, 1) === '-') ? 'la la-sort-amount-desc' : 'la la-sort-amount-asc'
    },
    isEmpty () {
      return this.list.length < 1 && this.filter.search === null && this.filter.page === 1
    }
  },
  methods: {
    sort (val) {
      let order = 'ASC'
      let current = this.filter.sort
      if (this.filter.sort.slice(0, 1) === '-') {
        order = 'DESC'
        current = this.filter.sort.slice(1)
      }
      if (current === val && order === 'ASC') val = '-' + val
      this.filter.sort = val
      this.navigate('p', 1)
    },
    navigate (q, pl) {
      switch (q) {
        case null:
          this.filter.sort = this.$route.query.sort || this.defaultOrder
          this.filter.page = parseInt(this.$route.query.page) || 1
          this.filter.size = parseInt(this.$route.query.size) || this.filter.size
          this.filter.search = this.$route.query.search || null
          break
        case 'p':
          this.filter.page = pl === undefined ? 1 : pl
          break
        case 's':
          this.filter.search = pl || null
          this.filter.page = 1
          break
        case 'r':
          this.filter.size = pl || 10
          this.filter.page = 1
          break
      }
      if (q !== null) {
        this.$router.push({ path: this.$route.path, query: this.filter })
      }
      this.get()
    }
  }
}