<template>
  <Modal @close="$emit('close')" :title="title" :size="size">
    <div>
      <template v-if="isImage">
        <img :src="fileUrl" :alt="fileName" class="img-fluid">
      </template>
      <div class="text-center mt-4">
        <a :href="fileUrl" target="_blank" class="text-primary">
          <template v-if="isImage">
            View image original size
          </template>
          <template v-else>
            Open file in new Tab
          </template>
        </a>
      </div>
    </div>
    <template v-slot:footer>
      <button @click="$emit('close')" type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
      <slot></slot>
    </template>
  </Modal>
</template>

<script>
  import Modal from "@/components/partial/Modal"

  export default {
    components: { Modal },
    props: {
      title: { default: 'View Image' },
      size: { default: 'md' }, // sm,md,lg
      url: { default: null },
      name: { default: '' },
      type: { default: 'all' },
      imageMode: { default: 'link' }, // link,file
      file: { default: null } // imageMode==file
    },
    data () {
      return {}
    },
    computed: {
      isImage () {
        if (this.type === 'all' && typeof this.url === 'string') {
          return 'jpg,jpeg,png'.indexOf(this.url.split('.')[this.url.split('.').length - 1].toLowerCase()) > -1
        }
        return (this.type.toLowerCase().indexOf('image') > -1)
      },
      isPdf () {
        if (this.type === 'all' && typeof this.url === 'string') {
          return 'pdf'.indexOf(this.url.split('.')[this.url.split('.').length - 1]) > -1
        }
        return false
      },
      fileUrl () {
        if (this.isImage || this.imageMode === 'link') return this.url
        return this.url
      },
      fileName () {
        if (this.isImage || this.imageMode === 'link') return this.name
        return this.name
      }
    }
  }
</script>
