<template>
  <div class="kt-subheader kt-grid__item" :class="isSticky ? 'kt-subheader-sticky' : ''" id="kt_subheader"
    :style="subtitleStyle">
    <div v-if="hasSubtitle" class="kt-container  kt-container--fluid" style="border-bottom: 1px solid #EFF1F4;">
      <div class="kt-subheader__main">
        <h3 class="kt-subheader__title">
          <span @click="clickReference" class="cursor-pointer text-muted font-weight-500">{{$route.meta.reference !==
      undefined ? $route.meta.reference : $route.name.replaceAll('-', ' ') }}</span>
          <span class="px-3 text-gray-30">/</span>
          <span v-if="!hasSubDetail" class="font-weight-500 cursor-pointer" :class="$route.meta.subTitle || hasSubDetail ? 'text-muted' : 'text-gray-800'">{{$route.meta.title !== undefined ? $route.meta.title : $route.name.replaceAll('-', ' ') }}</span>
          <span v-else class="text-gray-800 font-weight-500">
            <slot name="subDetail"></slot>
          </span>
          <span v-if="$route.meta.subTitle" class="px-3 text-gray-30">/</span>
          <span>{{ $route.meta.subTitle }}</span>
        </h3>
        <slot name="sub"></slot>
      </div>
      <div class="kt-subheader__toolbar">
        <slot></slot>
      </div>
      <slot name="subHeader"></slot>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      title: {
        default: 'Dashboard'
      },
      hasSubtitle: {
        default: true
      },
      hasSubDetail: {
        default: false
      },
      isSticky: {
        default: false
      }
    },
    computed: {
      subtitleStyle () { return (!this.hasSubtitle) ? { minHeight: 'auto' } : null },
      appName () { return this.$route.params.appName },
    },
    methods: {
      clickReference () {
        if (this.$route.meta.reference === 'Dashboard' && this.$route.path !== '/dashboard') this.$router.push(`/dashboard/${this.appName}`)
      },
    }
  }
</script>