<template>
  <Layout :title="title" :has-subtitle="false" :loading="loading" :show-menu="false">

    <template v-if="companyData" v-slot:sub-subheader>
      <SubHeader :title="subtitle" :hasSubDetail="true" class="mb-0">
        <template v-slot:subDetail>
          <h3 class="kt-subheader__title cursor-pointer">
            <span class="text-gray-800 font-weight-500">{{ companyData.name }}</span>
          </h3>
        </template>
      </SubHeader>
      <div class="kt-subheader kt-grid__item" id="kt_subheader-sub" :style="{ minHeight: '45px' }">
        <div class="kt-container kt-container--fluid">
          <Tabs class="nav-tabs-bold nav-tabs-line-success nav-tabs-line-2x flex-grow-1 mb-0" :tabs="tabs"
            :active="activeTab" @select="changeTab" />
        </div>
      </div>
    </template>

    <div class="row">
      <div class="col-12">
        <template v-if="activeTab === 'Profile'">
          <TabProfile :company="companyData" @tokenPaymentData="(x) => { tokenPaymentData = x }"
            @companyNoteView="(x) => { companyNoteView = x }" @receiptUrl="(x) => { receiptUrl = x }"
            @companyNoteData="(x) => { companyNoteData = x }" @companyPICdata="(x) => { companyPICdata = x }" />
        </template>
        <!-- <template v-if="activeTab === 'User State'">
          <UserState
            :reloadList="reloadList.userState"
            @editSubscription="changeTab({menu: 'Subscription'})"
            @show-detail="(x) => { detailBillingData = x }"
            @extend="(x) => { extendPlanData = x }"
            @extendTrial="(x) => { extendTrialData = x }" />
        </template> -->
        <!-- <template v-if="activeTab === 'Team Member'">
          <TeamMember
            :reloadList="reloadList.member"
            @add="modalMemberAdd"
            @edit="modalMemberEdit"
            @delete="confirmMemberDelete"
            @owner="confirmMemberOwner"
            @token="(x) => { tokenData = x }" />
        </template> -->
        <template v-if="activeTab === 'Subscription'">
          <TabSubscription :reload-list="reloadList" @currentFeatures="(x) => { currentFeatures = x }"
            @promoData="(x) => { promoData = x }" @subscriptionData="(x) => { subscriptionData = x }"
            @addonData="(x) => { addonData = x }" @packetEditData="(x) => { packetEditData = x }"
            @trialEditData="(x) => { trialEditData = x }" @showExtraQuota="(x) => { showExtraQuota = x }"
            @saveExtraSuccess="saveExtraSuccess" @upgradePlan="(x) => { showBuyUpgradePlan = x }" />
        </template>
        <!-- <template v-if="activeTab === 'Extra Quota'">
        </template> -->
        <!-- <template v-if="activeTab === 'Billing'">
          <TabBilling
            :reload-list="reloadList"
            @receiptUrl="(x) => { receiptUrl = x }"
            @detailBillingData="(x) => { detailBillingData = x }"
            @billingDeleteData="(x) => { billingDeleteData = x }"
            @billingPaygData="(x) => { billingPaygData = x }"
            @extendPlanData="(x) => { extendPlanData = x }"
            @billingRegularData="(x) => { billingRegularData = x }"
            @billingExtraData="(x) => { billingExtraData = x }"
            @billingDomainData="(x) => { billingDomainData = x }"
            @confirmActivateBilling="confirmActivateBilling"
            @billingUploadData="(x) => { uploadTaxData = x }" />
        </template> -->
        <!-- <template v-if="activeTab === 'Domain and Sender'">
          <template>
            <Domain
              :reloadList="reloadList.domain"
              @add="showAddDomain = true"
              @delete="(x) => { domainData = x }" />
          </template>
          <template>
            <Sender
              :reloadList="reloadList.sender"
              @add="showAddSender = true"
              @disable="(x) => { senderDisable = x }"
              @verify="(x) => { senderVerify = x }"
              @delete="(x) => { senderDelete = x }" />
          </template>
          <template>
            <CustomDomain
              :reloadList="reloadList.customDomain"
              @verify="(x) => { customDomainVerify = x }"
              @activate="(x) => { customDomainActivate = x }"
              @document="(x) => { customDomainDocument = x }" />
          </template>
          <template>
            <EmailSentToTeam
              :reloadList="reloadList.emailTeam" />
          </template>
        </template> -->
        <!-- <template v-if="activeTab === 'Activity'">
          <TabActivity />
        </template> -->
        <!-- <template v-if="activeTab === 'Meeting Log' && companyData !== null">
          <TabMeetingLog :companyId="companyData.companyId" />
        </template> -->
        <!-- <template v-if="activeTab === 'WhatsApp'">
          <WhatsApp
            :reload-list="reloadList.whatsapp"
            @add="showAddWhatsapp = true"
            @activate="(x) => { whatsappData = x }"
            @delete="(x) => { whatsappDelete = x }"
            @goToDetail="(x) => { whatsappTemplate = x }" />
        </template> -->
        <!-- <template v-if="activeTab === 'Setting'">
          <TabSetting
            :reloadList="reloadList"
            @showUpdateLegal="(x) => { showUpdateLegal = x }"
            :company="companyData"
            :dataEnableDowngrade="dataEnableDowngrade"
            :dataInternalUser="dataInternalUser"
            :verifiedData="verifiedData"
            @dataEnableDowngrade="(x) => { dataEnableDowngrade = x }"
            @dataInternalUser="(x) => { dataInternalUser = x }"
            @confirmClearHardbounce="confirmClearHardbounce = true "
            @confirmDeleteHardbounce="confirmDeleteHardbounce = true"
            @confirmEmailWarning="confirmEmailWarning = true"
            @confirmSuspend="(x) => { confirmSuspend = x }"
            @showGracePeriod="showGracePeriod = true"
            @verifyEmail="confirmVerifyEmail = true"
            @addDomain="showAddDomain = true"
            @deleteDomain="(x) => { domainData = x }"
            @showAddSender="showAddSender = true"
            @senderDisable="(x) => { senderDisable = x }"
            @senderVerify ="(x) => { senderVerify = x }"
            @senderDelete="(x) => { senderDelete= x }"
            @verifyCustom="(x) => { customDomainVerify = x }" 
            @activate="(x) => { customDomainActivate = x }"
            @document="(x) => { customDomainDocument = x }"
            @modalMemberAdd="modalMemberAdd"
            @modalMemberEdit="modalMemberEdit"
            @confirmMemberDelete="confirmMemberDelete"
            @confirmMemberOwner="confirmMemberOwner"
            @delete-company="confirmDeleteAccount"
            />
            hide token function from tab setting
            @tokenData="(x) => { tokenData = x }"
        </template> -->
      </div>
    </div>

    <!-- Overview -->
    <template>
      <ModalTokenPayment v-if="tokenPaymentData !== null" :token="tokenPaymentData" @close="tokenPaymentData = null" />

      <ModalCompanyNote v-if="companyNoteView !== null" :company="companyNoteView" @save-success="companyNoteSaved"
        @close="companyNoteView = null" />

      <ModalCompanyPIC v-if="companyPICdata !== null" :company="companyPICdata" @save-success="companyPicSaved"
        @close="companyPICdata = null" />

      <Confirmation v-if="companyNoteData !== null" @close="companyNoteData = null"
        :message="`Are you sure that you want to <strong>DELETE NOTE</strong> of this Company?`">
        <button @click="deleteCompanyNote" type="button" class="btn btn-primary">
          Yes
        </button>
      </Confirmation>
    </template>

    <!-- User State -->
    <template>
      <ModalExtendTrial v-if="extendTrialData !== null" :company="companyData" @close="extendTrialData = null"
        @extend-trial-success="extendTrialSuccess" />
    </template>

    <!-- Team Member -->
    <template>
      <ModalMember v-if="showManageMember" :mode="memberMode" :data="memberData" @save-success="saveMemberSuccess"
        @close="showManageMember = false" />

      <Confirmation v-if="showDeleteMember" @close="showDeleteMember = false"
        :message="`Are you sure you want to delete member <strong>${memberData.email}</strong>?`">
        <button @click="deleteMember" type="button" class="btn btn-brand btn-brand-crm">
          Yes
        </button>
      </Confirmation>

      <Confirmation v-if="showOwnerMember" @close="showOwnerMember = false"
        :message="`Are you sure you want to set owner <strong>${memberData.email}</strong>?`">
        <button @click="setOwnerMember" type="button" class="btn btn-brand btn-brand-crm">
          Yes
        </button>
      </Confirmation>

      <!-- hide token function -->
      <!-- <Token
        v-if="tokenData !== null"
        :tokenData="tokenData"
        :reloadList="reloadList.token"
        @delete="(x) => { tokenDelete = x }"
        @close="tokenData = null" /> -->

      <Confirmation v-if="tokenDelete !== null" @close="tokenDelete = null"
        :message="`Are you sure you want to delete this token?`">
        <button @click="deleteToken" type="button" class="btn btn-brand btn-brand-crm">
          Yes
        </button>
      </Confirmation>
    </template>

    <!-- Plan -->
    <template>
      <ModalBuyUpgradePlan v-if="showBuyUpgradePlan" :company-name="companyData.name"
        @close="showBuyUpgradePlan = false" />

      <Modal v-if="currentFeatures !== null" title="Current Features" size="lg" @close="currentFeatures = null">
        <Features v-model="currentFeatures" :disabled="true" :open="true" />
        <template v-slot:footer>
          <button @click.prevent="currentFeatures = null" type="button" class="btn btn-secondary"
            data-dismiss="modal">Close</button>
        </template>
      </Modal>

      <Confirmation v-if="subscriptionData !== null" @close="subscriptionData = null"
        :message="`Are you sure that you want to <strong>CHANGE SUBSCRIPTION</strong> of this Company?`">
        <button @click="saveSubscription" type="button" class="btn btn-primary">
          Yes
        </button>
      </Confirmation>

      <Confirmation v-if="addonData !== null" @close="addonData = null"
        :message="`Are you sure that you want to <strong>Update ADD-ON</strong> for this Company?`">
        <button @click="saveAddOn" type="button" class="btn btn-primary">
          Yes
        </button>
      </Confirmation>

      <Confirmation v-if="packetEditData !== null" @close="packetEditData = null"
        :message="`Are you sure that you want to <strong>Edit Packet</strong> for this Company?`">
        <button @click="editPacket" type="button" class="btn btn-primary">
          Yes
        </button>
      </Confirmation>

      <Confirmation v-if="trialEditData !== null" @close="trialEditData = null"
        :message="`Are you sure that you want to <strong>Update Trial Packet</strong> for this Company?`">
        <button @click="editTrial" type="button" class="btn btn-primary">
          Yes
        </button>
      </Confirmation>
    </template>

    <!-- Extra Quota -->
    <template>
      <!-- <ModalExtraQuota
        v-if="showExtraQuota"
        @detail-promo="(x) => { promoData = x }"
        @save-success="saveExtraSuccess"
        @close="showExtraQuota = false" /> -->

      <ModalBuyExtraQuota v-if="showExtraQuota" :company-name="companyData.name" @close="showExtraQuota = false" />
    </template>

    <!-- Billing History -->
    <template>
      <ModalExtendPlan v-if="extendPlanData !== null" :company="companyData" @detail-promo="(x) => { promoData = x }"
        @extend-success="extendSuccess" @close="extendPlanData = null" />

      <ModalInvoice v-if="detailBillingData !== null" :data="detailBillingData" @close="detailBillingData = null" />

      <ViewFile v-if="receiptUrl !== ''" :url="receiptUrl" @close="receiptUrl = ''" />

      <Confirmation v-if="activateBillingData !== null" @close="activateBillingData = null" :message="activateMessage">
        <button v-if="activateBillingData.invoiceType === 'regular'" @click="activateBilling" type="button"
          class="btn btn-primary">
          Yes
        </button>
        <button v-else-if="activateBillingData.invoiceType === 'addon'" @click="activateAddOn" type="button"
          class="btn btn-primary">
          Yes
        </button>
        <button v-else-if="['extra','bulk'].indexOf(activateBillingData.invoiceType) > -1" @click="activateExtraQuota"
          type="button" class="btn btn-primary">
          Yes
        </button>
        <button v-else-if="activateBillingData.invoiceType === 'domain'" @click="activateDomain" type="button"
          class="btn btn-primary">
          Yes
        </button>
      </Confirmation>

      <ModalUploadBilling v-if="uploadTaxData !== null" :data="uploadTaxData"
        @upload-success="editBillingRegularSuccess" @close="uploadTaxData = null" />

      <ModalEditBilling v-if="billingRegularData !== null" :data="billingRegularData"
        @detail-promo="(x) => { promoData = x }" @edit-success="editBillingRegularSuccess"
        @close="billingRegularData = null" />

      <ModalEditBillingPAYG v-if="billingPaygData !== null" :data="billingPaygData"
        @edit-success="editBillingPaygSuccess" @close="billingPaygData = null" />

      <ModalEditBillingExtra v-if="billingExtraData !== null" :data="billingExtraData"
        @detail-promo="(x) => { promoData = x }" @edit-success="editBillingExtraSuccess"
        @close="billingExtraData = null" />

      <Confirmation v-if="billingDeleteData !== null" @close="billingDeleteData = null"
        :message="`Are you sure that you want to delete this invoice <strong>${billingDeleteData.invoice}</strong>?`">
        <button @click="deleteBilling(billingDeleteData)" type="button" class="btn btn-brand btn-brand-crm">
          Yes
        </button>
      </Confirmation>
    </template>

    <!-- Domain and Sender -->
    <template>
      <Modal v-if="showAddDomain" title="Add Domain" @close="closeAddDomain">
        <div>
          <div class="form-group">
            <label>Domain</label>
            <input v-model="newDomain" type="text" class="form-control" required>
          </div>
        </div>
        <template v-slot:footer>
          <button @click.prevent="closeAddDomain" type="button" class="btn btn-secondary"
            data-dismiss="modal">Close</button>
          <button @click.prevent="saveDomain" type="button" class="btn btn-primary">Add</button>
        </template>
      </Modal>

      <Confirmation v-if="domainData !== null" @close="domainData = null"
        :message="`Are you sure that you want to delete <strong>${domainData.domain}</strong>?`">
        <button @click="deleteDomain" type="button" class="btn btn-brand btn-brand-crm">
          Yes
        </button>
      </Confirmation>

      <Modal v-if="showAddSender" title="Add Sender" @close="closeSender">
        <div>
          <div class="form-group">
            <label>Sender Name</label>
            <input v-model="senderName" type="text" class="form-control">
          </div>
          <div class="form-group">
            <label>Sender Email</label>
            <input v-model="senderEmail" type="text" class="form-control">
          </div>
        </div>
        <template v-slot:footer>
          <button @click.prevent="closeSender" type="button" class="btn btn-secondary"
            data-dismiss="modal">Close</button>
          <button @click.prevent="saveSender" type="button" class="btn btn-primary">Add</button>
        </template>
      </Modal>

      <Confirmation v-if="senderDisable !== null" @close="senderDisable = null"
        :message="`Are you sure that you want to disable <strong>${senderDisable.email}</strong>?`">
        <button @click="disableSender" type="button" class="btn btn-brand btn-brand-crm">
          Yes
        </button>
      </Confirmation>

      <Confirmation v-if="senderVerify !== null" @close="senderVerify = null"
        :message="`Are you sure that you want to verify <strong>${senderVerify.name}</strong>?`">
        <button @click="verifySender" type="button" class="btn btn-primary">
          Yes
        </button>
      </Confirmation>

      <Confirmation v-if="senderDelete !== null" @close="senderDelete = null"
        :message="`Are you sure that you want to delete <strong>${senderDelete.email}</strong>?`">
        <button @click="deleteSender" type="button" class="btn btn-brand btn-brand-crm">
          Yes
        </button>
      </Confirmation>

      <Confirmation v-if="customDomainVerify !== null" @close="customDomainVerify = null"
        :message="`Are you sure that you want to verify domain <strong>${customDomainVerify.name}</strong>?`">
        <button @click="verifyCustomDomain" type="button" class="btn btn-brand btn-brand-crm">
          Yes
        </button>
      </Confirmation>

      <Confirmation v-if="customDomainActivate !== null" @close="customDomainActivate = null"
        :message="`Are you sure that you want to activate domain <strong>${customDomainActivate.name}</strong>?`">
        <button @click="activateCustomDomain" type="button" class="btn btn-primary">
          Yes
        </button>
      </Confirmation>

      <Confirmation v-if="customDomainDocument !== null" @close="customDomainDocument = null"
        :message="`Are you sure that you want to upload document domain <strong>${customDomainDocument.name}</strong>?`">
        <button @click="uploadCustomDomain" type="button" class="btn btn-brand btn-brand-crm">
          Yes
        </button>
      </Confirmation>
    </template>

    <!-- WhatsApp -->
    <template>
      <ModalWhatsapp v-if="showAddWhatsapp" @close="closeCreateWhatsapp" @create="createWhatsapp" />

      <Confirmation v-if="whatsappDelete !== null" @close="whatsappDelete = null"
        :message="`Are you sure to delete phone number <strong>${whatsappDelete.phone}</strong>?`">
        <button @click="deleteWhatsapp" type="button" class="btn btn-brand btn-brand-crm">
          Yes
        </button>
      </Confirmation>

      <ModalActivateWa v-if="whatsappData !== null" :whatsappData="whatsappData" @save-success="activateWaSuccess"
        @close="whatsappData = null" />

      <ModalWhatsappTemplate v-if="whatsappTemplate !== null" :data="whatsappTemplate"
        @close="whatsappTemplate = null" />
    </template>

    <!-- Setting -->
    <template>
      <Confirmation v-if="confirmEnableDowngrade" @close="closeEnableDowngrade"
        :message="`Are you sure to <strong>${dataEnableDowngrade ? 'Enable' : 'Disable'} Downgrade</strong> for <strong>${companyData.name}</strong>?`">
        <button @click="enableDowngrade" type="button" class="btn btn-brand btn-brand-crm">
          Yes
        </button>
      </Confirmation>

      <Confirmation v-if="confirmInternalUser" @close="closeInternalUser"
        :message="`Are you sure to <strong>${dataInternalUser ? 'SET' : 'UNSET'} Internal User</strong> for <strong>${companyData.name}</strong>?`">
        <button @click="internalUser" type="button" class="btn btn-brand btn-brand-crm">
          Yes
        </button>
      </Confirmation>

      <Confirmation v-if="confirmEmailWarning" @close="confirmEmailWarning = false"
        :message="`Are you sure that you want to send email suspend notification to <strong>${companyData.name}</strong> that have email owner <strong>${companyData.ownerEmail}</strong>?`">
        <button @click="sendEmailWarning" type="button" class="btn btn-warning btn-warning-crm">
          Yes
        </button>
      </Confirmation>

      <Confirmation v-if="confirmSuspend !== null" @close="confirmSuspend = null"
        :message="`Are you sure to ${confirmSuspend ? 'suspend' : 'unsuspend'} <strong>${companyData.name}</strong>?`">
        <button @click="suspendCompany(confirmSuspend)" type="button" class="btn btn-brand btn-brand-crm">
          Yes
        </button>
      </Confirmation>

      <Confirmation v-if="confirmClearHardbounce" @close="confirmClearHardbounce = false"
        :message="`Are you sure to <strong>Clear Hardbounce</strong>?`">
        <button @click="clearHardbounce" type="button" class="btn btn-brand btn-brand-crm">
          Yes
        </button>
      </Confirmation>

      <Confirmation v-if="confirmDeleteHardbounce" @close="confirmDeleteHardbounce = false"
        :message="`Are you sure to <strong>Delete Hardbounce</strong>?`">
        <button @click="deleteHardbounce" type="button" class="btn btn-brand btn-brand-crm">
          Yes
        </button>
      </Confirmation>

      <Confirmation v-if="confirmVerifyEmail" @close="confirmVerifyEmail = false"
        :message="`Are you sure to <strong>verify account owner</strong>?`">
        <button @click="verifyEmail" type="button" class="btn btn-primary">
          Yes
        </button>
      </Confirmation>

      <ModalGracePeriod v-if="showGracePeriod" :company="companyData" @success-update="gracePeriodUpdated"
        @close="showGracePeriod = false" />

      <ModalUpdateLegal v-if="showUpdateLegal" @success-update="legalDataUpdated" @close="showUpdateLegal = false" />

      <ModalDeleteAccount v-if="showDeleteAccount" :company="companyData" @close="showDeleteAccount = false" />
    </template>

    <template>
      <ModalPromoDetail v-if="promoData !== null" :detail="promoData" @close="promoData = null" />
    </template>

  </Layout>
</template>

<script>
  import Layout from "@/components/Layout"
  import SubHeader from "@/components/layout/SubHeader.vue"
  import Confirmation from "@/components/partial/Confirmation"
  import Modal from "@/components/partial/Modal"
  import Tabs from '@/components/partial/Tabs'
  import ViewFile from "@/components/partial/ViewFile"
  import TabProfile from "@/components/company/TabProfile.vue"
  // import UserState from "@/components/company/UserState"
  // import TeamMember from "@/components/company/TeamMember"
  // import Token from '@/components/company/ModalToken'
  import TabSubscription from "@/components/company/TabSubscription.vue"
  // import ModalExtraQuota from "@/components/company/ModalExtraQuota"
  // import TabBilling from "@/components/company/TabBilling.vue"
  // import Domain from "@/components/company/Domain"
  // import CustomDomain from "@/components/company/CustomDomain"
  // import EmailSentToTeam from "@/components/company/EmailSentToTeam"
  // import Sender from "@/components/company/Sender"
  // import TabActivity from "@/components/company/TabActivity"
  // import TabMeetingLog from "@/components/company/TabMeetingLog.vue"
  // import WhatsApp from "@/components/company/WhatsApp"
  // import TabSetting from "@/components/company/TabSetting.vue"
  import ModalMember from "@/components/company/ModalMember"
  import ModalPromoDetail from "@/components/company/ModalPromoDetail"
  import ModalExtendPlan from "@/components/company/ModalExtendPlan"
  import ModalExtendTrial from "@/components/company/ModalExtendTrial"
  import ModalInvoice from "@/components/company/ModalInvoice"
  import ModalGracePeriod from "@/components/company/ModalGracePeriod"
  import ModalCompanyNote from "@/components/company/ModalCompanyNote"
  import ModalCompanyPIC from "@/components/company/ModalCompanyPIC"
  import ModalEditBilling from "@/components/company/ModalEditBilling"
  import ModalUploadBilling from "@/components/company/ModalUploadBilling"
  import ModalEditBillingPAYG from "@/components/company/ModalEditBillingPAYG"
  import ModalEditBillingExtra from "@/components/company/ModalEditBillingExtra"
  import ModalTokenPayment from "@/components/company/ModalTokenPayment"
  import ModalWhatsapp from "@/components/company/ModalWhatsapp"
  import ModalWhatsappTemplate from "@/components/company/ModalWhatsappTemplate"
  import ModalActivateWa from "@/components/company/ModalActivateWa"
  import ModalUpdateLegal from "@/components/company/ModalUpdateLegal"
  import ModalDeleteAccount from "@/components/company/ModalDeleteAccount"
  import ModalBuyExtraQuota from "@/components/whitelabel/ModalBuyExtraQuota"
  import ModalBuyUpgradePlan from "@/components/whitelabel/ModalBuyUpgradePlan"
  import Features from "@/components/company/Features"
  import company from '@/factory/company'
  import payment from '@/factory/payment'
  import whatsapp from '@/factory/whatsapp'
  import fn from '@/library/fn'
  import RoleMixin from '@/components/RoleMixin'
  

  export default {
    mixins: [RoleMixin],
    components: {
      Confirmation,
      Modal,
      Layout,
      SubHeader,
      Tabs,
      ViewFile,
      TabProfile,
      // UserState,
      // TeamMember,
      TabSubscription,
      // ModalExtraQuota,
      // TabBilling,
      // Domain,
      // CustomDomain,
      // EmailSentToTeam,
      // Sender,
      // TabActivity,
      // TabMeetingLog,
      // WhatsApp,
      // TabSetting,
      ModalMember,
      ModalPromoDetail,
      ModalExtendPlan,
      ModalExtendTrial,
      ModalInvoice,
      ModalGracePeriod,
      ModalCompanyNote,
      ModalCompanyPIC,
      ModalEditBilling,
      ModalEditBillingPAYG,
      ModalEditBillingExtra,
      ModalTokenPayment,
      ModalWhatsapp,
      ModalWhatsappTemplate,
      ModalActivateWa,
      ModalUpdateLegal,
      ModalDeleteAccount,
      Features,
      ModalUploadBilling,
      ModalBuyExtraQuota,
      ModalBuyUpgradePlan
    },
    data() {
      return {
        title: "Tuesday, 14 Feb 2023",
        subtitle: "Dashboard",
        activeTab: "User State",
        loading: false,
        companyData: null,
        currentPlan: null,
        dataEnableDowngrade: null,
        confirmEnableDowngrade: false,
        dataInternalUser: null,
        confirmInternalUser: false,
        confirmEmailWarning: false,
        confirmSuspend: null,
        confirmClearHardbounce: false,
        confirmDeleteHardbounce: false,
        showGracePeriod: false,
        tokenPaymentData: null,
        companyNoteView: null,
        companyNoteData: null,
        companyPICdata: null,
        showManageMember: false,
        showDeleteMember: false,
        showOwnerMember: false,
        memberMode: 'add',
        memberData: null,
        tokenData: null,
        tokenDelete: null,
        extendPlanData: null,
        extendTrialData: null,
        activateBillingData: null,
        detailBillingData: null,
        billingPaygData: null,
        billingRegularData: null,
        billingExtraData: null,
        billingDeleteData: null,
        receiptUrl: '',
        reloadList: {
          keyPlan: 0,
          member: null,
          token: null,
          plan: null,
          payg: null,
          billing: null,
          extra: null,
          domain: null,
          customDomain: null,
          emailTeam: null,
          sender: null,
          whatsapp: null,
          legal: null
        },
        showExtraQuota: false,
        currentFeatures: null,
        subscriptionData: null,
        addonData: null,
        packetEditData: null,
        trialEditData: null,
        showAddDomain: false,
        newDomain: '',
        domainData: null,
        showAddSender: false,
        senderName: '',
        senderEmail: '',
        senderDisable: null,
        senderVerify: null,
        senderDelete: null,
        customDomainVerify: null,
        customDomainActivate: null,
        customDomainDocument: null,
        showAddWhatsapp: false,
        whatsappPhone: '',
        whatsappData: null,
        whatsappTemplate: null,
        whatsappDelete: null,
        promoData: null,
        confirmVerifyEmail: false,
        showUpdateLegal: false,
        verifiedData: null,
        uploadTaxData: null,
        showDeleteAccount: false,
        showBuyUpgradePlan: false,
      }
    },
    computed: {
      companyId () { return this.$route.params.companyId },
      activateMessage () {
        if (this.activateBillingData === null) return ''
        let message = `Are you sure you want to Activate `
        if (this.activateBillingData.invoiceType === 'regular') message += 'Billing?'
        else if (this.activateBillingData.invoiceType === 'addon') message += 'Add-on?'
        else if (this.activateBillingData.invoiceType === 'extra') message += 'Extra Quota?'
        else if (this.activateBillingData.invoiceType === 'bulk') message += 'Bulk Quota?'
        else if (this.activateBillingData.invoiceType === 'domain') message += 'Domain?'
        return message
      },
      isWhatsappEnable () {
        if (this.currentPlan === null) return false
        const wa = this.currentPlan.features.find(x => x.name === 'Whatsapp') || {enable: false}
        return wa.enable
      },
      tabs () {
        return [
          { menu: 'Profile' },
          { menu: 'Subscription' },
          // { menu: 'Billing' },
          // { menu: 'Activity' },
          // { menu: 'Meeting Log' },
          // { menu: 'Setting' },
        ]
      }
    },
    watch: {
      dataEnableDowngrade (val) {
        if (val !== null && this.companyData !== null && val !== this.companyData.enableDowngrade) {
          this.confirmEnableDowngrade = true
        }
      },
      dataInternalUser (val) {
        if (val !== null && this.companyData !== null && val !== this.companyData.internal) {
          this.confirmInternalUser = true
        }
      },
      '$route.query'() {
        this.getCompanyDetail()
        if(this.$route.query.subtab) this.checkTab()
      },
      $route () {
        this.checkTab()
      }
    },
    created () {
      this.checkTab()
      this.getCompanyDetail()
      this.getCompanyPlan()
    },
    methods: {
      checkTab () {
        switch (this.$route.query.tab) {
          case 'profile':
            this.activeTab = 'Profile'
            break
          case 'billing':
            this.activeTab = 'Billing'
            break
          case 'subscription':
            this.activeTab = 'Subscription'
            break
          case 'activity':
            this.activeTab = 'Activity'
            break
          case 'meeting':
            this.activeTab = 'Meeting Log'
            break
          case 'setting':
            this.activeTab = 'Setting'
            break
          case 'userstate':
            this.activeTab = 'User State'
            break
          case 'team':
            this.activeTab = 'Team Member'
            break
          case 'extra':
            this.activeTab = 'Extra Quota'
            break
          case 'domain':
            this.activeTab = 'Domain and Sender'
            break
          case 'whatsapp':
            this.activeTab = 'WhatsApp'
            break
          default:
            this.activeTab = 'Profile'
            this.$router.push({name: 'mtarget-company-detail', query: {tab: 'profile'}})
            break
        }
      },
      changeTab (tab) {
        this.activeTab = tab.menu
        switch (tab.menu) {
          case 'Profile':
            this.$router.push({name: 'mtarget-company-detail', query: {tab: 'profile'}})
            break
          case 'Billing':
            this.$router.push({name: 'mtarget-company-detail', query: {tab: 'billing'}})
            break
          case 'Subscription':
            this.$router.push({name: 'mtarget-company-detail', query: {tab: 'subscription'}})
            break
          case 'Activity':
            this.$router.push({name: 'mtarget-company-detail', query: {tab: 'activity'}})
            break
          case 'Meeting Log':
            this.$router.push({name: 'mtarget-company-detail', query: {tab: 'meeting'}})
            break
          case 'Setting':
            this.$router.push({name: 'mtarget-company-detail', query: {tab: 'setting'}})
            break
          case 'User State':
            this.$router.push({name: 'mtarget-company-detail', query: {tab: 'userstate'}})
            break
          case 'Team Member':
            this.$router.push({name: 'mtarget-company-detail', query: {tab: 'team'}})
            break
          case 'Extra Quota':
            this.$router.push({name: 'mtarget-company-detail', query: {tab: 'extra'}})
            break
          case 'Domain and Sender':
            this.$router.push({name: 'mtarget-company-detail', query: {tab: 'domain'}})
            break
          case 'WhatsApp':
            this.$router.push({name: 'mtarget-company-detail', query: {tab: 'whatsapp'}})
            break
          default:
            this.$router.push({name: 'mtarget-company-detail', query: {tab: 'profile'}})
            break
        }
      },
      getCompanyDetail () {
        company.getCompanyDetail(this.companyId)
          .then((res) => {
            this.companyData = res
            this.dataEnableDowngrade = res.enableDowngrade
            this.dataInternalUser = res.internal
          })
          .catch(err => this.$e(err))
      },
      getCompanyPlan () {
        payment.getPlan(this.companyId)
          .then((res) => {
            this.currentPlan = res
          })
          .catch(err => this.$e(err))
      },
      closeEnableDowngrade () {
        this.dataEnableDowngrade = this.companyData.enableDowngrade
        this.confirmEnableDowngrade = false
      },
      closeInternalUser () {
        this.dataInternalUser = this.companyData.internal
        this.confirmInternalUser = false
      },
      enableDowngrade () {
        company.enableDowngrade(this.companyId, this.dataEnableDowngrade)
          .then(() => {
            this.confirmEnableDowngrade = false
            this.getCompanyDetail()
            fn.notySuccess("Enable Downgrade successfully applied.")
          })
          .catch(err => this.$e(err))
      },
      internalUser () {
        company.setInternal(this.companyId, this.dataInternalUser)
          .then(() => {
            this.confirmInternalUser = false
            this.getCompanyDetail()
            fn.notySuccess("Internal User successfully applied.")
          })
          .catch(err => this.$e(err))
      },
      clearHardbounce () {
        company.clearHardbounce(this.companyId)
          .then((res) => {
            this.confirmClearHardbounce = false
            if (res) fn.notySuccess("Hardbounce successfully cleared.")
            else fn.notyWarn("Failed to clear Hardbounce.")
          })
          .catch(err => this.$e(err))
      },
      deleteHardbounce () {
        company.deleteHardbounce(this.companyId)
          .then((res) => {
            this.confirmDeleteHardbounce = false
            if (res) fn.notySuccess("Hardbounce successfully deleted.")
            else fn.notyWarn("Failed to delete Hardbounce.")
          })
          .catch(err => this.$e(err))
      },
      sendEmailWarning () {
        company.warnCompany(this.companyId)
          .then((res) => {
            this.confirmEmailWarning = false
            if (res) fn.notySuccess("Warning Email successfully sent.")
            else fn.notyWarn("Failed to send Warning Email.")
          })
          .catch(err => this.$e(err))
      },
      suspendCompany (suspend) {
        company.suspendCompany(this.companyId, suspend)
          .then((res) => {
            this.confirmSuspend = null
            if (res) {
              this.getCompanyDetail()
              if (suspend) fn.notySuccess("Company successfully suspended.")
              else fn.notySuccess("Company successfully unsuspended.")
            } else {
              if (suspend) fn.notyWarn("Failed to suspend company.")
              else fn.notyWarn("Failed to unsuspend company.")
            }
          })
          .catch(err => this.$e(err))
      },
      gracePeriodUpdated () {
        this.showGracePeriod = false
        this.getCompanyDetail()
      },
      companyNoteSaved () {
        this.companyNoteView = null
        this.getCompanyDetail()
      },
      companyPicSaved () {
        this.companyPICdata = null
        this.getCompanyDetail()
      },
      deleteCompanyNote () {
        company.deleteCompanyNote(this.companyId)
          .then((res) => {
            if (res) {
              fn.notySuccess("Company note successfully deleted.")
              this.companyNoteData = null
              this.getCompanyDetail()
            } else {
              this.companyNoteData = null
              fn.notyWarn("Failed to delete company note")
            }
          })
      },
      modalMemberAdd () {
        this.memberMode = 'add'
        this.memberData = null
        this.showManageMember = true
      },
      modalMemberEdit (data) {
        this.memberMode = 'edit'
        this.memberData = data
        this.showManageMember = true
      },
      saveMemberSuccess () {
        this.reloadList.member = Math.random().toString()
        this.showManageMember = false
        this.memberData = null
      },
      confirmMemberDelete (data) {
        this.memberData = data
        this.showDeleteMember = true
      },
      confirmMemberOwner (data) {
        this.memberData = data
        this.showOwnerMember = true
      },
      confirmDeleteAccount () {
        this.showDeleteAccount = true
      },
      deleteMember () {
        company.deleteMember(this.companyId, this.memberData.email)
          .then(() => {
            this.reloadList.member = Math.random().toString()
            this.showDeleteMember = false
            this.memberData = null
            fn.notySuccess("Member successfully deleted.")
          })
          .catch(err => this.$e(err))
      },
      setOwnerMember () {
        company.ownerMember(this.memberData.memberId)
          .then(() => {
            this.reloadList.member = Math.random().toString()
            this.showOwnerMember = false
            this.memberData = null
            fn.notySuccess("Member successfully set as owner.")
            this.getCompanyDetail()
          })
          .catch(err => this.$e(err))
      },
      deleteToken () {
        company.deleteToken(this.tokenDelete.id)
          .then(() => {
            this.reloadList.token = Math.random().toString()
            this.tokenDelete = null
            fn.notySuccess("Token successfully deleted.")
          })
          .catch(err => this.$e(err))
      },
      extendSuccess () {
        this.extendPlanData = null
        this.reloadList.billing = Math.random().toString()
      },
      extendTrialSuccess () {
        this.extendTrialData = null
        this.reloadList.userState = Math.random().toString()
      },
      confirmActivateBilling (invoiceType, invoice, file, type) {
        this.activateBillingData = { invoiceType, invoice, file, type }
      },
      activateBilling () {
        let data = new FormData()
        data.append('billingId', this.activateBillingData.invoice)
        data.append('file', this.activateBillingData.file)
        payment.activateBilling(data)
          .then(() => {
            if (this.activateBillingData.type === 'PAYASYOUGO') {
              this.reloadList.payg = Math.random().toString()
            } else {
              this.reloadList.billing = Math.random().toString()
            }
            this.activateBillingData = null
            fn.notySuccess("Billing successfully activated.")
          })
          .catch(err => this.$e(err))
      },
      activateAddOn () {
        let data = new FormData()
        data.append('billingId', this.activateBillingData.invoice)
        data.append('file', this.activateBillingData.file)
        payment.activateAddOn(data)
          .then(() => {
            this.activateBillingData = null
            this.reloadList.billing = Math.random().toString()
            fn.notySuccess("Add-on successfully activated.")
          })
          .catch(err => this.$e(err))
      },
      activateExtraQuota () {
        let data = new FormData()
        data.append('billingId', this.activateBillingData.invoice)
        data.append('file', this.activateBillingData.file)
        payment.activateExtraQuota(data)
          .then(() => {
            let type = 'Extra'
            if(this.activateBillingData.invoiceType === 'bulk') type = 'Bulk'
            this.activateBillingData = null
            this.reloadList.extra = Math.random().toString()
            fn.notySuccess(`${type} quota successfully activated.`)
          })
          .catch(err => this.$e(err))
      },
      activateDomain () {
        let data = new FormData()
        data.append('billingId', this.activateBillingData.invoice)
        data.append('file', this.activateBillingData.file)
        payment.activateDomain(data)
          .then(() => {
            this.activateBillingData = null
            this.reloadList.domain = Math.random().toString()
            fn.notySuccess("Domain successfully activated.")
          })
          .catch(err => this.$e(err))
      },
      editBillingPaygSuccess () {
        this.billingPaygData = null
        this.reloadList.payg = Math.random().toString()
      },
      editBillingRegularSuccess () {
        this.billingRegularData = null
        this.reloadList.billing = Math.random().toString()
      },
      editBillingExtraSuccess () {
        this.billingExtraData = null
        this.reloadList.extra = Math.random().toString()
      },
      deleteBilling (billing) {
        if (['PAYASYOUGO', 'ONETIME', 'SUBSCRIPTION'].includes(billing.type)) {
          this.deleteBillingRegular(billing)
        } else {
          this.deleteBillingExtra(billing)
        }
      },
      deleteBillingRegular (billing) {
        const data = {
          billingId: billing.invoice,
          teamId: billing.companyId
        }
        payment.deleteBilling(data)
          .then(() => {
            this.billingDeleteData = null
            if (billing.type === 'PAYASYOUGO') {
              this.reloadList.payg = Math.random().toString()
            } else {
              this.reloadList.billing = Math.random().toString()
            }
          })
          .catch(err => this.$e(err))
      },
      deleteBillingExtra (billing) {
        const data = {
          billingId: billing.invoice,
          teamId: billing.companyId
        }
        payment.deleteBillingExtra(data)
          .then(() => {
            this.billingDeleteData = null
            this.reloadList.extra = Math.random().toString()
          })
          .catch(err => this.$e(err))
      },
      saveSubscription () {
        payment.savePlan(this.subscriptionData)
          .then(() => {
            this.subscriptionData = null
            this.reloadList.plan = Math.random().toString()
            fn.notySuccess("New Subscription successfully created.")
            this.changeTab({menu: 'Billing'})
          })
          .catch(err => this.$e(err))
      },
      saveAddOn () {
        payment.saveAddOn(this.addonData)
          .then(() => {
            this.addonData = null
            fn.notySuccess("Add-on successfully saved.")
            this.changeTab({menu: 'Billing'})
          })
          .catch(err => this.$e(err))
      },
      editPacket () {
        payment.changePacket(this.packetEditData)
          .then((res) => {
            if (res) {
              fn.notySuccess("Packet successfully edited.")
              this.reloadList.keyPlan++
            } else {
              fn.notyError("Failed to edit Packet.")
            }
            this.packetEditData = null
          })
          .catch(err => this.$e(err))
      },
      editTrial () {
        payment.changeTrial(this.trialEditData)
          .then((res) => {
            if (res) {
              fn.notySuccess("Custom trial packet successfully updated.")
              this.reloadList.keyPlan++
            } else {
              fn.notyError("Failed to update custom trial packet.")
            }
            this.trialEditData = null
          })
          .catch(err => this.$e(err))
      },
      saveExtraSuccess () {
        this.showExtraQuota = false
        this.$router.push(`/clients/mtarget/jatis/${this.$route.params.companyId}?tab=billing&subtab=extra`)
      },
      closeAddDomain () {
        this.newDomain = ''
        this.showAddDomain = false
      },
      saveDomain () {
        if (this.newDomain === '') return true
        company.saveDomain(this.companyId, this.newDomain)
          .then(() => {
            this.closeAddDomain()
            this.reloadList.domain = Math.random().toString()
            fn.notySuccess("Domain successfully added.")
          })
          .catch(err => this.$e(err))
      },
      deleteDomain () {
        company.deleteDomain(this.domainData.id)
          .then(() => {
            this.domainData = null
            this.reloadList.domain = Math.random().toString()
            fn.notySuccess("Domain successfully deleted.")
          })
          .catch(err => this.$e(err))
      },
      closeSender () {
        this.senderName = ''
        this.senderEmail = ''
        this.showAddSender = false
      },
      saveSender () {
        let data = {
          name: this.senderName,
          email: this.senderEmail
        }
        company.saveSender(this.companyId, data)
          .then(() => {
            this.closeSender()
            this.reloadList.sender = Math.random().toString()
            fn.notySuccess("Sender successfully added.")
          })
          .catch(err => this.$e(err))
      },
      disableSender () {
        company.disableSender(this.companyId, this.senderDisable.senderId)
          .then(() => {
            this.senderDisable = null
            this.reloadList.sender = Math.random().toString()
            fn.notySuccess("Sender successfully disabled.")
          })
          .catch(err => this.$e(err))
      },
      verifySender () {
        company.verifySender(this.companyId, this.senderVerify.senderId)
          .then(() => {
            this.reloadList.sender = Math.random().toString()
            fn.notySuccess("Sender successfully verified.")
            this.senderVerify = null
          })
          .catch(err => this.$e(err))
      },
      deleteSender () {
        company.deleteSender(this.companyId, this.senderDelete.senderId)
          .then(() => {
            this.senderDelete = null
            this.reloadList.sender = Math.random().toString()
            fn.notySuccess("Sender successfully deleted.")
          })
          .catch(err => this.$e(err))
      },
      verifyCustomDomain () {
        const docs = []
        company.verifyCustomDomain(this.companyId, this.customDomainVerify.id, docs)
          .then(() => {
            this.customDomainVerify = null
            this.reloadList.customDomain = Math.random().toString()
            fn.notySuccess("Custom domain successfully verified.")
          })
          .catch(err => this.$e(err))
      },
      activateCustomDomain () {
        company.activateCustomDomain(this.companyId, this.customDomainActivate.id)
          .then(() => {
            this.customDomainActivate = null
            this.reloadList.customDomain = Math.random().toString()
            fn.notySuccess("Custom domain successfully activated.")
          })
          .catch(err => this.$e(err))
      },
      uploadCustomDomain () {
        company.documentCustomDomain(this.companyId, this.customDomainDocument.id)
          .then(() => {
            this.customDomainDocument = null
            this.reloadList.customDomain = Math.random().toString()
            fn.notySuccess("Document for custom domain successfully uploaded.")
          })
          .catch(err => this.$e(err))
      },
      closeCreateWhatsapp () {
        this.whatsappPhone = ''
        this.showAddWhatsapp = false
      },
      createWhatsapp (data) {
        whatsapp.createWhatsapp(data)
          .then(() => {
            this.closeCreateWhatsapp()
            this.reloadList.whatsapp = Math.random().toString()
            fn.notySuccess("New phone successfully added.")
          })
          .catch(err => this.$e(err))
      },
      deleteWhatsapp () {
        whatsapp.deleteWhatsapp(this.companyId, this.whatsappDelete.accountId)
          .then(() => {
            this.whatsappDelete = null
            this.reloadList.whatsapp = Math.random().toString()
            fn.notySuccess("WhatsApp phone successfully deleted.")
          })
          .catch(err => this.$e(err))
      },
      activateWaSuccess () {
        this.whatsappData = null
        this.reloadList.whatsapp = Math.random().toString()
      },
      verifyEmail () {
        company.verifyOwner(this.companyId)
          .then(() => {
            this.confirmVerifyEmail = false
            fn.notySuccess("Email account owner successfully verified.")
            return payment.getPaymentSetting(this.companyId)
          })
          .then(res => {
            this.verifiedData = res
          })
          .catch(err => this.$e(err))
      },
      legalDataUpdated () {
        this.showUpdateLegal = false
        this.reloadList.legal = Math.random().toString()
        this.getCompanyDetail()
      },
      openDataLeads () {
        if (this.companyData.leadId === '') return
        this.$router.push(`/user-growth/${this.companyData.leadId}`)
      }
    }
  }
</script>