<template>
  <div>
    <div class="kt-portlet kt-portlet--mobile rounded-12">
      <div class="kt-portlet__head kt-portlet__head--lg border-bottom">
        <div class="kt-portlet__head-label">
          <h3 class="kt-portlet__head-title">
            <span class="text-bold text-gray-700">
              Subscription Detail
            </span>
          </h3>
        </div>
        <div class="d-flex align-items-center">
          <button type="button" @click="showModalUpgradePlan = true" class="btn btn-brand btn-elevate btn-icon-sm my-1 ml-1">
            <UpdateSvg />
            Upgrade Plan
          </button>
        </div>
      </div>
      <div class="background-success-active text-sm font-weight-bold text-white d-flex align-items-center py-2 pl-4 mt-4">
          Current Subscription
          <Verifiedv2Svg class="ml-1" />
        </div>
      <div class="kt-portlet__body kt-portlet__body--md">
        <div class="row">
          <div class="col-3 text-sm font-weight-600 py-4">
            <div>Email Sent</div>
            <div class="font-weight-500 pt-2 text-caption">{{ data.emailSent || 'Empty' | formatNumber }}</div>
          </div>
          <div class="col-3 text-sm font-weight-600 py-4">
            <div>Subscription</div>
            <div class="font-weight-500 pt-2 text-caption">{{ data.subscription || 'Empty' }}</div>
          </div>
          <div class="col-3 text-sm font-weight-600 py-4">
            <div>Monthly Price</div>
            <div class="font-weight-500 pt-2 text-caption">IDR {{ data.monthlyPrice || 'Empty' | formatNumber }}</div>
          </div>
          <div class="col-3 text-sm font-weight-600 py-4">
            <div>PPN 11%</div>
            <div class="font-weight-500 pt-2 text-caption">IDR {{ data.subtotal * 11 / 100 || 'Empty' | formatNumber }}</div>
          </div>
          <div class="col-3 text-sm font-weight-600 py-4">
            <div>Domain Number</div>
            <div class="font-weight-500 pt-2 text-caption">{{ data.domain || 'Empty' | formatNumber }}</div>
          </div>
          <div class="col-3 text-sm font-weight-600 py-4">
            <div>Contact Limit</div>
            <div class="font-weight-500 pt-2 text-caption">{{ data.contactLimit === -1 ? 'Unlimited' : data.contactLimit || 'Empty' | formatNumber }}</div>
          </div>
          <div class="col-3 text-sm font-weight-600 py-4">
            <div>Subtotal Price</div>
            <div class="font-weight-500 pt-2 text-caption">IDR {{ data.subtotal || 'Empty' | formatNumber }}</div>
          </div>
          <div class="col-3 text-sm font-weight-600 py-4">
            <div>Total Price</div>
            <div class="font-weight-500 pt-2 text-caption">IDR {{ data.subtotal + (data.subtotal * 11 / 100) || 'Empty' | formatNumber }}</div>
          </div>
          <div class="col-3 text-sm font-weight-600 py-4">
            <div>Start Date</div>
            <div v-if="data.startDate" class="font-weight-500 pt-2 text-caption">{{ data.startDate | dtmType('DD MMM YYYY HH:mm') }}</div>
            <div v-else class="font-weight-500 pt-2 text-caption">Empty</div>
          </div>
          <div class="col-3 text-sm font-weight-600 py-4">
            <div>End Date</div>
            <div v-if="data.endDate" class="font-weight-500 pt-2 text-caption">{{ data.endDate | dtmType('DD MMM YYYY HH:mm') }}</div>
            <div v-else class="font-weight-500 pt-2 text-caption">Empty</div>
          </div>
        </div>
      </div>
    </div>
    
    <ModalBuyQuota
      title="Upgrade Plan"
      :extendedText="profile.company"
      :company="profile.company"
      v-if="showModalUpgradePlan"
      @close="showModalUpgradePlan = false"
    />
  </div>
</template>

<script>
  import UpdateSvg from '@/components/svg/UpdateSvg'
  import Verifiedv2Svg from '@/components/svg/Verifiedv2Svg.vue'
  import ModalBuyQuota from "@/components/whitelabel/ModalBuyQuota.vue"

  export default {
    components: {
      ModalBuyQuota,
      UpdateSvg,
      Verifiedv2Svg
    },
    props: {
      companyId: {
        default: 'companyId'
      },
      userId: {
        default: 'id'
      },
      data: {
        default: {},
      },
      profile () {
        return {
          default: {
            company: '',
          }
        }
      },
    },
    data() {
      return {
        showModalUpgradePlan: false,
      }
    },
  }
</script>