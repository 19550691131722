<template>
  <div>
    <div class="mb-4">
      <div class="row pb-4">
        <div class="col-auto d-flex">
          <h5 class="pr-8">Email Usage</h5>
          <span v-if="usage.plan.toLowerCase() !== 'pay as you grow'">
            <h5 v-if="almost" class="mb-0 font-weight-normal warn-color">
              <i class="la la-exclamation-circle font-weight-bold"/>
              <span class="pl-2">Almost hitting the limit</span>
            </h5>
            <h5 v-if="exceed" class="mb-0 font-weight-normal alert-color">
              <i class="la la-exclamation-circle font-weight-bold"/>
              <span class="pl-2">Hit the limit</span>
            </h5>
          </span>
        </div>
      </div>
      <div class="row pb-3">
        <div class="col-auto d-flex align-items-center">
          <h5>
            <span>Total Email Sent:</span>
            <span :class="limitCheck()"> {{ (usage.usage || 0) | formatNumber }}</span>
            <span v-if="dataType === 'production'">
              <span class="text-gray-500">/ {{ calculateQuota() | formatNumber }}</span>
              <span v-if="usage.plan.toLowerCase().includes('free')" class="text-gray-500"> (one-time)</span>
              <span v-else class="text-gray-500"> per Month</span>
            </span>
          </h5>
        </div>
      </div>
      <div class="row">
        <div class="col-auto d-flex align-items-center">
          <p v-if="firstSent" class="text-lg">First email sent at {{ moment(firstSent).format('D MMM YYYY HH:mm') }}</p>
          <p v-else class="text-lg">No email sent yet</p>
        </div>
        <div class="col">
          <div class="row justify-content-end">
            <div class="col-auto pl-0">
              <LastTimeRange v-model="period" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="chart-container position-relative">
      <LineChart :chartData="chart" :options="options" />
      <LoadingCircle v-if="loading" />
    </div>
  </div>
</template>

<script>
  import LineChart from '@/components/partial/charts/LineChart'
  import LoadingCircle from '@/components/partial/LoadingCircle'
  import moment from 'moment'
  import LastTimeRange from '@/components/partial/LastTimeRange.vue'
  // import fn from '@/library/fn'

  export default {
    components: { LineChart, LoadingCircle, LastTimeRange },
    props: ['chart', 'dataType', 'loading', 'usage', 'firstSent'],
    data() {
      return {
        almost: false,
        exceed: false,
        options: {
          responsive: true,
          maintainAspectRatio: false,
          title: {
            display: false,
            text: "Chart.js Line Chart"
          },
          tooltips: {
            mode: "index",
            intersect: false
          },
          hover: {
            mode: "nearest",
            intersect: true
          },
          legend: {
            display: false
          },
          scales: {
            yAxes: [{
              gridLines: {
                borderDash: [5, 5]
              },
              ticks: {
                beginAtZero: true
              }
            }],
            xAxes: [{
              gridLines: {
                display: false
              }
            }],
            x: {
              display: true,
              scaleLabel: {
                display: true,
                labelString: "Month"
              }
            },
            y: {
              display: true,
              scaleLabel: {
                display: true,
                labelString: "Value"
              }
            }
          }
        },
        period: null,
        data: null,
        moment,
      }
    },
    computed: {
      companyId () { return this.$route.params.companyId },
    },
    watch: {
      type () { this.getData() },
      period () { if (this.range !== 'Custom Range') this.$emit('periodChanged', this.period) }
    },
    methods: {
      limitCheck() {
        if (this.usage.usage >= this.usage.quota) {
          this.exceed = true
          return 'alert-color'
        }
        if (this.usage.usage >= this.usage.quota * 0.9) {
          this.almost = true
          return 'warn-color'
        }
        this.exceed = false
        this.almost = false
        return ''
      },
      calculateQuota() {
        if (this.usage.plan.toLowerCase() === 'free') {
          return 3000
        }
        return this.usage.quota
      }
    }
  }
</script>

<style lang="less" scoped>
.btn-export {
  margin-left: 24px;
}
.warn-color {
  color: #F1C40F;
}
.alert-color {
  color: #CF513D;
}
</style>
