<template>
  <div id="kt_header" class="kt-header kt-grid__item">

    <!-- begin:: Header Menu -->

    <!-- Uncomment this to display the close button of the panel
<button class="kt-header-menu-wrapper-close" id="kt_header_menu_mobile_close_btn"><i class="la la-close"></i></button>
-->
    <div class="kt-header-menu-wrapper">
      <div id="kt_header_menu" class="kt-header-menu kt-header-menu-mobile  kt-header-menu--layout-default ">
        <template v-if="showMenu">
          <h4 class="one-line">{{ dateNow }}</h4>
          <ul class="kt-menu__nav " v-if="crumb !== undefined">
            <li class="kt-menu__item">
              <router-link :to="crumb.to" class="kt-menu__link">
                <span class="kt-menu__link-text">{{ crumb.title }}</span>
              </router-link>
            </li>
          </ul>
        </template>
        <template v-else>
          <div class="header-logo">
            <router-link to="/">
              <img alt="Logo" :src="logo" width="130px" height="48px"/>
            </router-link>
          </div>
          <h4 class="one-line pl-4">{{ dateNow }}</h4>
        </template>
      </div>
    </div>

    <!-- end:: Header Menu -->

    <!-- begin:: Header Topbar -->
    <div class="kt-header__topbar">
      <div class="kt-header__topbar-item px-10">
        <div class="kt-header__topbar-wrapper align-items-center">
            <span class="kt-header__topbar-welcome kt-hidden-mobile">Need help? <a @click="contactTeam" class="btn-link cursor-pointer">Contact our team</a></span>
        </div>
      </div>

      <!--begin: User Bar -->
      <div class="kt-header__topbar-item kt-header__topbar-item--user">
        <div class="kt-header__topbar-wrapper" data-toggle="dropdown" data-offset="0px,0px">
          <div class="kt-header__topbar-user">
            <span class="kt-badge kt-badge--username kt-badge--unified-success kt-badge--lg kt-badge--rounded kt-badge--bold">
              {{ oneLetter }}
            </span>
          </div>
        </div>
        <div class="dropdown-menu dropdown-menu-fit dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround dropdown-menu-xl">

          <!--begin: Head -->
          <div class="kt-user-card kt-user-card--skin-dark kt-notification-item-padding-x">
            <div v-if="false" class="kt-user-card__avatar">
              <img alt="Pic" :src="userData.image" />

            </div>
            <div class="kt-user-card__name">
              <div>{{ userData.name }}</div>
              <div><span>{{ userData.email }}</span></div>
              <div><span>Last Login: {{ userData.lastLogin | dtmType('DD MMM YYYY HH:mm')}}</span></div>
              <div><span @click="$emit('change-password', userData)" class="change-password">Change Password</span></div>
            </div>
            <div class="kt-user-card__badge">
              <a href="#" tag="span" @click.prevent="$emit('setlog', true)" class="btn btn-danger btn-sm btn-bold btn-font-md">Logout</a>
            </div>
          </div>

          <!--end: Head -->

          <!--begin: Navigation -->
          <div class="kt-notification" v-if="false">
            <router-link to="/setting/basic" class="kt-notification__item">
              <div class="kt-notification__item-icon">
                <i class="flaticon2-calendar-3 kt-font-success"></i>
              </div>
              <div class="kt-notification__item-details">
                <div class="kt-notification__item-title kt-font-bold">
                  My Profile
                </div>
                <div class="kt-notification__item-time">
                  Account settings and more
                </div>
              </div>
            </router-link>
          </div>

          <!--end: Navigation -->
        </div>
      </div>

      <!--end: User Bar -->
    </div>

    <!-- end:: Header Topbar -->
  </div>
</template>

<script>
  import store from '@/store'
  import logomtarget from '@/assets/images/mtarget.png'
  import logojatis from '@/assets/images/jatis-dark.png'
  import moment from 'moment'

  export default {
    props: {
      crumb: {
        default: undefined
      },
      title: {
        default: ''
      },
      logout: {
        default: false
      },
      showMenu: {
        default: true
      }
    },
    data () {
      return {
        logomtarget,
        logojatis,
      }
    },
    computed: {
      company () { return process.env.VUE_APP_COMPANY },
      userData () { return store.state.userData },
      oneLetter () { return this.userData.name.substring(0, 1) },
      dateNow () { return moment(new Date).format('dddd, D MMM YYYY ') },
      logo () { 
        if (this.company === 'JATIS') return logojatis
        return logomtarget
      },
      email () { return process.env.VUE_APP_EMAIL_CONTACT_US },
    },
    methods: {
      contactTeam () {
        window.location.assign(`mailto:${this.email}`);
      }
    }
  }
</script>

<style lang="scss" scoped>
  @import "~@/assets/scss/_config.scss";
  .kt-header-menu {
    h4 {
      margin-bottom: 0;
      padding: 1rem 0;
      line-height: 1.8;
    }
  }
  #kt_header {
    .kt-user-card__name {
      color: kt-get($kt-font-color, text);
      span {
        font-size: 1rem;
        color: kt-get($kt-base-colors, label, 1);
      }
    }
  }
  .change-password {
    text-decoration: underline;
    cursor: pointer;
  }
</style>