<template>
  <Modal @close="$emit('close')" title="Upgrade Plan">
    <div class="text-center my-5">
      Are you sure want to <strong>Upgrade Plan</strong> {{ companyName }} ?
    </div>
    <template v-slot:footer>
      <button @click="$emit('close')" type="button" class="btn btn-secondary" data-dismiss="modal">Cancel</button>
      <button @click="mailto" type="button" class="btn btn-primary">Yes</button>
    </template>
  </Modal>
</template>
<script>
  import Modal from "@/components/partial/Modal"

  export default {
    props: {
      companyName: {
        default: ''
      },
      appName: {
        default: ''
      },
    },
    components: {
      Modal,
    },
    data() {
      return {
        appTitle: '',
      }
    },
    computed: {
      company () { return process.env.VUE_APP_COMPANY.toLowerCase() },
      email () { return process.env.VUE_APP_EMAIL_CONTACT_US },
    },
    methods: {
      mailto () {
        if (this.appName.toLowerCase() === 'mtarget') this.appTitle = 'MTARGET'
        if (this.appName.toLowerCase() === 'mailtarget') this.appTitle = 'Mailtarget'
        window.location.assign(`mailto:${this.email}?Subject=` + encodeURIComponent(`${this.appTitle} Upgrade Plan Jatis ${this.companyName}`));
      }
    }
  }
</script>