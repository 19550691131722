<template>
  <div class="d-flex align-items-center justify-content-between my-1">
    <template v-if="isCheckbox">
      <label v-if="disabled" class="kt-checkbox checkbox-custom-feature"
        :class="{
          'kt-checkbox--bold': mainFeature,
          'kt-checkbox--success': (checked && mainFeature),
          'kt-checkbox--primary': (checked && !mainFeature),
          'no-bold': !mainFeature,
          'kt-checkbox--disabled': disabled}">
        <input type="checkbox" v-model="checked" :disabled="disabled">
        {{ title }}
        <span></span>
      </label>
      <label v-else @click.stop class="kt-checkbox checkbox-custom-feature"
        :class="{
          'kt-checkbox--bold': mainFeature,
          'kt-checkbox--success': (checked && mainFeature),
          'kt-checkbox--primary': (checked && !mainFeature),
          'no-bold': !mainFeature,
          'kt-checkbox--disabled': disabled}">
        <input type="checkbox" v-model="checked" :disabled="disabled">
        {{ title }}
        <span></span>
      </label>
      <i v-if="mainFeature" class="la la-angle-down"></i>
    </template>
    <template v-else>
      <label class="mb-0" :class="{'no-bold': !mainFeature}">
        {{ title }}
      </label>
      <div :style="inputStyle" :class="{'kt-input-icon kt-input-icon--right': param}">
        <input v-model="input" @keypress="onlyNumber" :disabled="disabled" type="text" class="form-control form-control-sm">
        <span v-if="param" class="kt-input-icon__icon kt-input-icon__icon--right">
          <!-- <span><i class="la la-binoculars"></i></span> -->
          <span>{{ param }}</span>
        </span>
      </div>
    </template>
  </div>
</template>

<script>
  export default {
    props: ['value', 'title', 'limit', 'input-w', 'param', 'main-feature', 'disabled'],
    data () {
      return {
        checked: null,
        input: null
      }
    },
    created () {
      this.init()
    },
    watch: {
      value () { this.init() },
      limit () { this.init() },
      checked (val) { this.emitCheckbox(val) },
      input (val) { this.emitInput(val) }
    },
    computed: {
      isCheckbox () { return !this.title.toLowerCase().includes('limit') },
      inputStyle () {
        let width = 100
        if (this.inputW) width = this.inputW
        return `width: ${width}px; margin-left: 1rem;`
      }
    },
    methods: {
      init () {
        if (this.mainFeature) {
          this.checked = this.value
        } else {
          this.checked = this.value
          this.input = this.limit
        }
      },
      onlyNumber ($event) {
        let key = ($event.keyCode ? $event.keyCode : $event.which)
        if ((key < 48 || key > 57) && key !== 46 && key !== 45) {
          $event.preventDefault()
        }
      },
      emitCheckbox (val) {
        this.$emit('input', val)
      },
      emitInput (val) {
        if (val === '') {
          this.input = 0
        } else if (val === '0-') {
          this.input = '-'
        } else if (val !== '-') {
          this.$emit('limit', parseInt(val))
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
  .kt-checkbox.no-bold, label.no-bold {
    font-weight: normal;
  }
  .kt-checkbox.checkbox-custom-feature {
    margin-bottom: 0px;
    line-height: 32px;
  }
  .kt-checkbox > span {
    margin-top: 6px;
  }
</style>