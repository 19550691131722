<template>
  <Modal title="Activate WhatsApp" @close="$emit('close')">
    <div v-if="false">
      <div class="form-group">
        <label>Username</label>
        <input type="text" v-model="username" class="form-control" placeholder="Type here ...">
      </div>
      <div class="form-group">
        <label>Password</label>
        <input type="text" v-model="password" class="form-control" placeholder="Type here ...">
      </div>
      <div class="form-group">
        <label>Client ID</label>
        <input type="text" v-model="clientId" class="form-control" placeholder="Type here ...">
      </div>
      <div class="form-group">
        <label>Client Secret</label>
        <textarea type="text" v-model="clientSecret" class="form-control" placeholder="Type here ..."></textarea>
      </div>
    </div>
    <div>
      <p class="text-center">Are you want to activate this WhatsApp account?</p>
    </div>
    <template v-slot:footer>
      <button @click="$emit('close')" type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
      <button @click="activate" type="button" class="btn btn-primary">Activate</button>
      <slot></slot>
    </template>
  </Modal>
</template>

<script>
  import Modal from "@/components/partial/Modal"
  import whatsapp from '@/factory/whatsapp'
  import fn from '@/library/fn'

  export default {
    components: {
      Modal
    },
    props: ['whatsappData'],
    data () {
      return {
        username: '',
        password: '',
        clientId: '',
        clientSecret: ''
      }
    },
    computed: {
      companyId () { return this.$route.params.companyId }
    },
    methods: {
      activate () {
        whatsapp.activateWhatsapp(this.companyId, this.whatsappData.accountId)
          .then(() => {
            fn.notySuccess("Your WhatsApp successfully activated.")
            this.$emit('save-success')
          })
          .catch(err => this.$e(err))
      }
    }
  }
</script>