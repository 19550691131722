<template>
  <Modal :title="title" @close="$emit('close')" :size="'lg'">
    <div>
      <div class="form-group">
        <div class="row">
          <div class="col-6 row">
            <div class="col-12 col-sm-4">
              <label>Subscription Period</label>
            </div>
            <div class="col-6 col-sm-4">
              <select v-model="subscriptionPeriod" class="form-control">
                <option :value="1">Monthly</option>
                <option :value="12">Annually</option>
              </select>
            </div>
            <div class="col-6 col-sm-4">
              <select v-model="subscription" class="form-control">
                <option v-for="(data, i) in subscriptionOption" :key="i" :value="data">{{ data }} {{ (subscriptionPeriod === 1) ? 'Month' : 'Year'}}</option>
              </select>
            </div>
          </div>
          <div class="col-6 row ml-3">
            <div class="d-flex flex-column col-4">
              <label class="mb-0">Monthly price</label>
              <span class="text-caption font-weight-600">
                (IDR)
              </span>
            </div>
            <div class="col-8">
              <div class="input-group">
                <input type="number" class="form-control">
                <div class="input-group-append"><span class="input-group-text">/month</span></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- temporary comment period -->
      <!-- <div class="form-group">
        <div class="row align-items-center">
          <div class="col-12 col-sm-4">
            <label>Period</label>
          </div>
          <div class="col-12 col-sm">
            <div class="row">
              <div class="col">
                <DatePicker v-model="period" :customConfig="dateConfig" :disabled="disabledPeriod" />
              </div>
              <div class="col-2">
                <a @click="disabledPeriod = !disabledPeriod" title="Edit" class="btn btn-sm btn-clean btn-icon btn-icon-md"><i :class="disabledPeriod ? 'flaticon2-pen' : 'flaticon2-lock'"></i></a>
              </div>
            </div>
          </div>
        </div>
      </div> -->
      <div class="form-group">
        <div class="row">
          <div class="row col-6">
            <label class="col-4 my-auto">Start Date</label>
            <DatePicker
            :customConfig="{
            altFormat: 'DD MMM YYYY'
            }"
            :showIcon="true" 
            class="col-8"
            />
          </div>
          <div class="col-6 row ml-3">
            <div class="d-flex flex-column col-4">
              <label class="mb-0">Subtotal</label>
              <span class="text-caption font-weight-600">
                (IDR)
              </span>
            </div>
            <div class="col-8">
              <div class="input-group">
                <input type="number" class="form-control">
                <div class="input-group-append"><span class="input-group-text">/month</span></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="form-group">
        <div class="row">
          <div class="row col-6">
            <label class="col-4 my-auto">End Date</label>
            <div class="input-group col-8">
              <input type="number" class="form-control disabled" disabled>
            </div>
          </div>
          <div class="col-6 ml-3">
            <div class="row px-10">
              <label class="col-4 my-auto">Promo Code</label>
              <div class="col-8">
                <div class="input-group">
                  <input v-model="promoCode" @keydown.enter="checkPromoCode" type="text" class="form-control">
                  <div class="input-group-append">
                    <button @click="checkPromoCode" class="btn btn-primary btn-block px-10">Validate</button>
                  </div>
                </div>
              </div>
            </div>
            <div v-if="detailPromo !== null" class="row">
              <div class="col-12 col-sm-4">
              </div>
              <div class="col-12 col-sm">
                <div class="row">
                  <div class="col-12">
                    <div>
                      {{ detailPromo.type }} : 
                      <span v-if="detailPromo.type === 'VOUCHER'" class="text-bold">{{ detailPromo.voucher | curr }}</span>
                      <span v-if="detailPromo.type === 'DISCOUNT'" class="text-bold">{{ detailPromo.discount }}% (Max: {{ detailPromo.maxDiscount }})</span>
                    </div>
                    <div><a class="cursor-pointer text-success" @click="$emit('detail-promo', detailPromo)">Click here</a> to see promo detail.</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="form-group">
        <div class="row">
          <div class="row col-12">
            <label id="prepend-total" class="col-2 my-auto">Total Price <span class="text-caption font-weight-600">(IDR)</span></label>
            <div class="input-group col px-2">
              <InputCurrency :value="price * subscriptionPeriod * subscription" aria-describedby="prepend-total" />
              <div class="input-group-append"><span class="input-group-text px-10">PPN 11%</span></div>
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="form-group">
        <div class="row">
          <div class="col-12 col-sm-4">
            <label>Price</label>
          </div>
          <div class="col-12 col-sm">
            <div class="input-group">
              <InputCurrency v-model="price" aria-describedby="append-month" />
              <div class="input-group-append"><span class="input-group-text" id="append-month">/month</span></div>
            </div>
            <br>
            <div class="input-group">
              <div class="input-group-prepend"><span class="input-group-text" id="prepend-total">Total price</span></div>
              <InputCurrency :value="price * subscriptionPeriod * subscription" aria-describedby="prepend-total" :disabled="true" />
            </div>
          </div>
        </div>
      </div> -->
      <div v-if="data.items.length > 0" class="form-group">
        <div class="row">
          <div class="col-12 col-sm-4">
            <label>Extra Services</label>
          </div>
          <div class="col-12 col-sm">
            <InputCurrency :value="extraServices" :disabled="true" />
          </div>
        </div>
      </div>
      <!-- <div class="form-group">
        <div class="row align-items-center">
          <div class="col-12 col-sm-4">
            <label>Total</label>
          </div>
          <div class="col-12 col-sm">
            <InputCurrency v-model="total" :disabled="true" />
          </div>
        </div>
      </div> -->
      <!-- <div class="form-group">
        <div class="row align-items-center">
          <div class="col-12 col-sm-4">
            <label>Total + PPN {{data.taxPercentage }}%</label>
          </div>
          <div class="col-12 col-sm">
            <InputCurrency v-model="totalPPN" :disabled="true" />
          </div>
        </div>
      </div> -->
      <!-- <div class="form-group">
        <div class="row align-items-center">
          <div class="col-12 col-sm-4">
            <label>Promo Code</label>
          </div>
          <div class="col-12 col-sm">
            <div class="row">
              <div class="col-12 col-sm">
                <input v-model="promoCode" @keydown.enter="checkPromoCode" type="text" class="form-control">
              </div>
              <div class="col-12 col-sm-4">
                <button @click="checkPromoCode" class="btn btn-primary btn-block">Check</button>
              </div>
            </div>
          </div>
        </div>
        <div v-if="detailPromo !== null" class="row">
          <div class="col-12 col-sm-4">
          </div>
          <div class="col-12 col-sm">
            <div class="row">
              <div class="col-12">
                <div>
                  {{ detailPromo.type }} : 
                  <span v-if="detailPromo.type === 'VOUCHER'" class="text-bold">{{ detailPromo.voucher | curr }}</span>
                  <span v-if="detailPromo.type === 'DISCOUNT'" class="text-bold">{{ detailPromo.discount }}% (Max: {{ detailPromo.maxDiscount }})</span>
                </div>
                <div><a class="cursor-pointer text-success" @click="$emit('detail-promo', detailPromo)">Click here</a> to see promo detail.</div>
              </div>
            </div>
          </div>
        </div>
      </div> -->
    </div>
    <template v-slot:footer>
      <button @click.prevent="$emit('close')" type="button" class="btn btn-secondary" :class="{wait: saving}" data-dismiss="modal">Close</button>
      <button @click.prevent="edit" type="button" class="btn btn-primary" :class="{wait: saving}">Submit</button>
    </template>
  </Modal>
</template>

<script>
  import Modal from '@/components/partial/Modal'
  import DatePicker from '@/components/partial/DatePicker'
  import InputCurrency from '@/components/partial/InputCurrency'
  import payment from '@/factory/payment'
  import promo from '@/factory/promo'
  import fn from '@/library/fn'
  import moment from 'moment'

  export default {
    components: {
      Modal,
      DatePicker,
      InputCurrency
    },
    props: ['data'],
    data () {
      return {
        title: 'Edit Billing Regular',
        period: [],
        dateConfig: {
          enableTime: false,
          mode: 'range',
          minDate: null
        },
        promoCode: '',
        detailPromo: null,
        subscriptionPeriod: null,
        subscription: null,
        price: null,
        total: 0,
        totalPPN: 0,
        saving: false,
        disabledPeriod: true
      }
    },
    computed: {
      subscriptionOption () {
        return (this.subscriptionPeriod === 1) ? [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11] : [1, 2, 3, 4, 5]
      },
      extraServices () {
        return this.data.items.reduce((prev, curr) => prev + curr.price, 0)
      }
    },
    watch: {
      total (val) {
        this.totalPPN = val * (100 + this.data.taxPercentage) / 100
      },
      subscriptionPeriod (val, old) {
        if (old !== null) {
          if (this.subscription === 1) {
            this.setPeriod()
            this.calculateTotal()
          }
          this.subscription = 1
        }
      },
      subscription (val, old) {
        if (old !== null) {
          this.setPeriod()
          this.calculateTotal()
        }
      },
      price (val, old) { if (old !== null) this.calculateTotal() }
    },
    created () {
      this.init()
    },
    methods: {
      init () {
        let period = this.data.period.split('-')
        let start = period[0].trim().split(' ')
        let end = period[1].trim().split(' ')
        start = moment().year(start[2]).month(start[1]).date(start[0]).format('x')
        end = moment().year(end[2]).month(end[1]).date(end[0]).format('x')
        this.period = [parseInt(start), parseInt(end)]
        this.subscriptionPeriod = (this.data.subscription < 12) ? 1 : 12
        this.subscription = (this.subscriptionPeriod === 1) ? this.data.subscription : this.data.subscription/12
        this.price = this.data.packet.price
        this.total = this.data.subTotal
        this.promoCode = (this.data.promoData !== null) ? this.data.promoData.promoCode : ''
        this.detailPromo = this.data.promoData
        if (this.promoCode !== '') this.checkPromoCode()
      },
      setPeriod () {
        if (this.period[0]) {
          const end = moment(this.period[0]).add((this.subscriptionPeriod * this.subscription), 'months').subtract(1, 'days').endOf('day').format('x')
          this.period = [this.period[0], parseInt(end)]
        }
      },
      calculateTotal () {
        this.total = (this.subscriptionPeriod * this.subscription * this.price) + this.extraServices
      },
      checkPromoCode () {
        promo.checkPromo(this.promoCode.toUpperCase(), this.data.companyId)
          .then((res) => {
            this.detailPromo = res
          })
          .catch((err) => {
            this.detailPromo = null
            this.$e(err)
          })
      },
      edit () {
        let period = this.data.period.split('-')
        let start = period[0].trim().split(' ')
        let end = period[1].trim().split(' ')
        start = moment().year(start[2]).month(start[1]).date(start[0]).format('x')
        end = moment().year(end[2]).month(end[1]).date(end[0]).format('x')
        let periodStart = (parseInt(start) !== this.period[0]) ? this.period[0] : null
        let periodEnd = (parseInt(end) !== this.period[1]) ? this.period[1] : null
        let subscription = (this.subscriptionPeriod === 1) ? this.subscription : this.subscription*12
        let data = {
          billingId: this.data.invoice,
          periodStart: periodStart,
          periodEnd: periodEnd,
          total: parseInt(this.total),
          subscription: subscription,
          promoCode: this.promoCode
        }
        this.saving = true
        payment.editBilling(data)
          .then((res) => {
            if (res) {
              fn.notySuccess("Billing successfully edited.")
              this.$emit('edit-success')
            } else {
              fn.notyError("Failed to edit Billing.")
              this.$emit('close')
            }
            this.saving = false
          })
          .catch((err) => {
            this.saving = false
            this.$e(err)
          })
      }
    }
  }
</script>
