<template>
  <Modal :title="title" @close="$emit('close')">
    <div>
      <div class="form-group">
        <div class="row align-items-center">
          <div class="col-12 col-sm-4">
            <label>Total</label>
          </div>
          <div class="col-12 col-sm">
            <InputCurrency v-model="total" />
          </div>
        </div>
      </div>
      <div class="form-group">
        <div class="row align-items-center">
          <div class="col-12 col-sm-4">
            <label>Total + PPN {{data.taxPercentage }}%</label>
          </div>
          <div class="col-12 col-sm">
            <InputCurrency v-model="totalPPN" :disabled="true" />
          </div>
        </div>
      </div>
      <div class="form-group">
        <div class="row align-items-center">
          <div class="col-12 col-sm-4">
            <label>Promo Code</label>
          </div>
          <div class="col-12 col-sm">
            <div class="row">
              <div class="col-12 col-sm">
                <input v-model="promoCode" @keydown.enter="checkPromoCode" type="text" class="form-control">
              </div>
              <div class="col-12 col-sm-4">
                <button @click="checkPromoCode" class="btn btn-primary btn-block">Check</button>
              </div>
            </div>
          </div>
        </div>
        <div v-if="detailPromo !== null" class="row">
          <div class="col-12 col-sm-4">
          </div>
          <div class="col-12 col-sm">
            <div class="row">
              <div class="col-12">
                <div>
                  {{ detailPromo.type }} : 
                  <span v-if="detailPromo.type === 'VOUCHER'" class="text-bold">{{ detailPromo.voucher | curr }}</span>
                  <span v-if="detailPromo.type === 'DISCOUNT'" class="text-bold">{{ detailPromo.discount }}% (Max: {{ detailPromo.maxDiscount }})</span>
                </div>
                <div><a class="cursor-pointer text-success" @click="$emit('detail-promo', detailPromo)">Click here</a> to see promo detail.</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <template v-slot:footer>
      <button @click.prevent="$emit('close')" type="button" class="btn btn-secondary" :class="{wait: saving}" data-dismiss="modal">Close</button>
      <button @click.prevent="edit" type="button" class="btn btn-primary" :class="{wait: saving}">Yes</button>
    </template>
  </Modal>
</template>

<script>
  import Modal from '@/components/partial/Modal'
  import InputCurrency from '@/components/partial/InputCurrency'
  import payment from '@/factory/payment'
  import promo from '@/factory/promo'
  import fn from '@/library/fn'

  export default {
    components: {
      Modal,
      InputCurrency
    },
    props: ['data'],
    data () {
      return {
        title: 'Edit Billing Extra',
        promoCode: '',
        detailPromo: null,
        total: 0,
        totalPPN: 0,
        saving: false
      }
    },
    watch: {
      total (val) {
        this.totalPPN = val * (100 + this.data.taxPercentage) / 100
      }
    },
    created () {
      this.init()
    },
    methods: {
      init () {
        this.total = this.data.subTotal
        this.promoCode = (this.data.promoData !== null) ? this.data.promoData.promoCode : ''
        this.detailPromo = this.data.promoData
        if (this.promoCode !== '') this.checkPromoCode()
      },
      checkPromoCode () {
        promo.checkPromo(this.promoCode.toUpperCase(), this.data.companyId)
          .then((res) => {
            this.detailPromo = res
          })
          .catch((err) => {
            this.detailPromo = null
            this.$e(err)
          })
      },
      edit () {
        let data = {
          billingId: this.data.invoice,
          total: parseInt(this.total),
          promoCode: this.promoCode
        }
        this.saving = true
        payment.editBillingExtra(data)
          .then((res) => {
            if (res) {
              fn.notySuccess("Billing successfully edited.")
              this.$emit('edit-success')
            } else {
              fn.notyError("Failed to edit Billing.")
              this.$emit('close')
            }
            this.saving = false
          })
          .catch((err) => {
            this.saving = false
            this.$e(err)
          })
      }
    }
  }
</script>
