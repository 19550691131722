import { render, staticRenderFns } from "./EmailAllocated.vue?vue&type=template&id=4ff7ad84&scoped=true&"
import script from "./EmailAllocated.vue?vue&type=script&lang=js&"
export * from "./EmailAllocated.vue?vue&type=script&lang=js&"
import style0 from "./EmailAllocated.vue?vue&type=style&index=0&id=4ff7ad84&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4ff7ad84",
  null
  
)

export default component.exports