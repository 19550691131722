<template>
  <div class="btn-group btn-group" role="group" aria-label="...">
    <DatePicker
      v-model="period"
      :customConfig="{
        mode: 'range',
        enableTime: false,
        minDate: null,
        wrap: true
      }"
      :customBtnClass="[size, {'btn-primary': (range === 'Custom Range')}]"
      :pickerHidden="true"
      @update:val="(x) => { textPeriod = x }">
      <template v-slot:button>
        <div class="input-group-prepend">
          <button @click="selectRange('Custom Range')" type="button" :disabled="isDisabled" class="btn btn-secondary ml-0" :class="[size, {'btn-primary': (range === 'Custom Range')}]" data-toggle>
            <i class="la la-calendar"></i> {{ buttonCustomValue }}
          </button>
        </div>
      </template>
    </DatePicker>

    <button @click="selectRange('Today')" type="button" class="btn btn-secondary ml-0" :disabled="isDisabled" :class="[size, {'btn-primary': (range === 'Today')}]">
      Today
    </button>
    <button @click="selectRange('Last 7 Days')" type="button" class="btn btn-secondary ml-0" :disabled="isDisabled" :class="[size, {'btn-primary': (range === 'Last 7 Days')}]">
      7D
    </button>
    <button @click="selectRange('Last 30 Days')" type="button" class="btn btn-secondary ml-0" :disabled="isDisabled" :class="[size, {'btn-primary': (range === 'Last 30 Days')}]">
      30D
    </button>
    <button @click="selectRange('Last 3 Months')" type="button" class="btn btn-secondary ml-0" :disabled="isDisabled" :class="[size, {'btn-primary': (range === 'Last 3 Months')}]">
      3M
    </button>
    <button @click="selectRange('Last 6 Months')" type="button" class="btn btn-secondary ml-0" :disabled="isDisabled" :class="[size, {'btn-primary': (range === 'Last 6 Months')}]">
      6M
    </button>
    <button @click="selectRange('Last 1 Year')" type="button" class="btn btn-secondary ml-0" :disabled="isDisabled" :class="[size, {'btn-primary': (range === 'Last 1 Year')}]">
      12M
    </button>
  </div>
</template>

<script>
  import DatePicker from './DatePicker.vue'
  import LastRangeMixin from './LastRangeMixin'

  export default {
    mixins: [LastRangeMixin],
    components: { DatePicker },
    props: {
      value: Array,
      propsRange: String,
      propsRangeOptions: Array,
      propsCustomRange: Object,
      propsStatus: String,
      disableOptions: Boolean,
      size: { default: 'btn-md' }
    },
    data () {
      return {
        last: null,
        range: 'Last 7 Days',
        period: [],
        textPeriod: '',
        isDisabled: false
      }
    },
    computed: {
      buttonCustomValue () {
        if (this.range === 'Custom Range') {
          if (Array.isArray(this.textPeriod) && this.textPeriod.length < 1) return 'Custom'
          return this.textPeriod || 'Custom'
        }
        return 'Custom'
      }
    },
    watch: {
      range (val) {
        // update range. ex: range "Last 7 Days"
        this.$emit('select', val)
      },
      period (val) {
        // update v-model period & range. ex: period [1234567890, 1234567890], range "Last 7 Days"
        this.$emit('input', val)
        this.$emit('select', this.range)
        this.$emit('update:period', val)
      },
      textPeriod (val) {
        // update text period custom date. ex: text period "2023-10-10 to 2023-11-10"
        this.$emit('update:text-period', val)
      },
      propsRange (val) {
        if (val !== this.range) {
          this.selectRange(val)
        }
      },
      value (val) {
        if (String(val) !== String(this.period)) {
          this.selectRange(val)
        }
      },
      disableOptions (val) {
        this.isDisabled = val
      }
      // propsStatus(val){
        // this.checkStatus(val)
      // }
    },
    mounted() {
      this.init()
      // this.checkStatus(this.propsStatus)
    },
    methods: {
      init () {
        if (this.propsCustomRange) this.customRange = this.propsCustomRange
        if (this.propsRangeOptions) this.rangeOptions = this.propsRangeOptions

        if (this.propsRange) {
          this.selectRange(this.propsRange)
          this.last = this.getLastCount(this.propsRange)
        } else if (Array.isArray(this.value)) {
          this.setRange(this.value)
        } else {
          this.selectRange(this.range)
          this.last = this.getLastCount(this.range)
        }
        this.$emit('set:period')
      }
      // checkStatus(value){
      //   if(value === 'Expired Soon'){
      //     this.isDisabled = true
      //   }else{
      //     this.isDisabled = false
      //   }
      // }
    }
  }
</script>
