<template>
  <Modal title="Set Company PIC" @close="$emit('close')">
    <div>
      <div class="form-group">
        <label>PIC</label>
        <Multiselect
          v-model="pic"
          :options="picList"
          :searchable="true"
          :multiple="false"
          track-by="name"
          label="name"
          selectLabel="Select"
          deselectLabel="Remove" />
      </div>
    </div>
    <template v-slot:footer>
      <button @click.prevent="$emit('close')" type="button" class="btn btn-secondary" :class="{wait: saving}" data-dismiss="modal">Close</button>
      <button @click.prevent="save" type="button" class="btn btn-primary" :class="{wait: saving}">Save</button>
    </template>
  </Modal>
</template>

<script>
  import Modal from '@/components/partial/Modal'
  import company from '@/factory/company'
  import user from '@/factory/user'
  import fn from '@/library/fn'
  import Multiselect from 'vue-multiselect'

  export default {
    components: {
      Modal,
      Multiselect
    },
    props: ['company'],
    data () {
      return {
        saving: false,
        picList: [],
        pic: null
      }
    },
    computed: {
      companyId () { return this.$route.params.companyId }
    },
    created () {
      this.init()
    },
    methods: {
      init () {
        this.getPic()
      },
      getPic () {
        const filter = {
          page: 1,
          size: 100,
          sort: 'name',
          search: '',
          leng: 0,
          count: 0
        }
        user.getUsers(filter)
          .then((res) => {
            this.picList = res.data
            const selectedPic = this.picList.find(x => x.email === this.company.pic)
            this.pic = (selectedPic === undefined) ? null : selectedPic
          })
          .catch(err => this.$e(err))
      },
      save () {
        this.saving = true
        let picId = ''
        let pic = ''
        if (this.pic !== null) {
          picId = this.pic.id
          pic = this.pic.email
        }
        company.setCompanyPIC(this.companyId, picId, pic)
          .then((res) => {
            if (res) fn.notySuccess("Company PIC successfully updated.")
            else fn.notyWarn("Failed to update company PIC.")
            this.saving = false
            this.$emit('save-success')
          })
          .catch((err) => {
            this.saving = false
            this.$e(err)
          })
      }
    }
  }
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
