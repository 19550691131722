<template>
  <Modal :title="title" @close="$emit('close')">
    <div class="input-group">
      <input type="text" :value="token" class="form-control" readonly placeholder="Token Payment">
      <div class="input-group-append">
        <a :href="token" target="_blank" class="btn btn-outline-success" type="button">Open</a>
        <button @click="copy" class="btn btn-success" type="button">Copy</button>
      </div>
    </div>
    <template v-slot:footer>
      <button @click="$emit('close')" type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
      <slot></slot>
    </template>
  </Modal>
</template>

<script>
  import Modal from "@/components/partial/Modal"
  import fn from '@/library/fn'

  export default {
    components: {
      Modal
    },
    props: {
      token: {
        default: ''
      },
      title: {
        default: 'Token Payment'
      }
    },
    methods: {
      copy () {
        const el = document.createElement('textarea')
        el.value = this.token
        el.setAttribute('readonly', '')
        el.style.position = 'absolute'
        el.style.left = '-9999px'
        document.body.appendChild(el)
        el.select()
        document.execCommand('copy')
        document.body.removeChild(el)
        fn.notySuccess("Payment link copied.")
      }
    }
  }
</script>