<template>
  <Modal @close="$emit('close')" :title="`${(mode === 'add') ? 'Add' : 'Edit'} Team Member`">
    <div>
      <div v-if="mode === 'edit' && data !== null && data.validate" class="form-group">
        <label>Fullname</label>
        <input v-model="form.fullname" type="text" class="form-control">
      </div>
      <div class="form-group">
        <label>Email</label>
        <input v-model="form.email" :disabled="mode === 'edit'" type="text" class="form-control">
        <VuelidateError label="Email Address" :field="v$.form.email" />
        <div v-if="error.email.status" class="mt-2 text-danger">
          {{ error.email.message }}
        </div>
      </div>
      <div v-if="mode === 'edit' && data !== null && data.validate" class="form-group">
        <label>Phone</label>
        <input v-model="form.phone" type="text" class="form-control">
      </div>
      <div class="form-group">
        <label>Role</label>
        <template v-if="app === 'mailtarget'">
          <div>New user will be able to access the entire dashboard, covering analytics, activities, and all features, with the exception of billing.</div>
        </template>
        <template v-if="app === 'mtarget'">
          <div>
            <label class="kt-option kt-option kt-option--plain">
              <span class="kt-option__control">
                <span class="kt-radio">
                  <input v-model="form.role" type="radio" name="role" value="CONTENT">
                  <span></span>
                </span>
              </span>
              <span class="kt-option__label">
                <span class="kt-option__head">
                  <span class="kt-option__title">CONTENT</span>
                </span>
                <span class="kt-option__body">
                  Can only create campaigns and view simple reports.
                </span>
              </span>		
            </label>
          </div>
          <div>
            <label class="kt-option kt-option kt-option--plain">
              <span class="kt-option__control">
                <span class="kt-radio">
                  <input v-model="form.role" type="radio" name="role" value="MARKETER">
                  <span></span>
                </span>
              </span>
              <span class="kt-option__label">
                <span class="kt-option__head">
                  <span class="kt-option__title">MARKETER</span>
                </span>
                <span class="kt-option__body">
                  Have full access to campaign management, but can't do billing activities and export data activities.
                </span>
              </span>		
            </label>
          </div>
          <div>
            <label class="kt-option kt-option kt-option--plain">
              <span class="kt-option__control">
                <span class="kt-radio">
                  <input v-model="form.role" type="radio" name="role" value="ADMIN">
                  <span></span>
                </span>
              </span>
              <span class="kt-option__label">
                <span class="kt-option__head">
                  <span class="kt-option__title">ADMIN</span>
                </span>
                <span class="kt-option__body">
                  Full access to MTARGET Account.
                </span>
              </span>		
            </label>
          </div>
        </template>
      </div>
    </div>
    <template v-slot:footer>
      <button @click="$emit('close')" type="button" class="btn btn-secondary" :class="{wait: saving}" :disabled="saving" data-dismiss="modal">Close</button>
      <button @click="save" type="button" class="btn btn-primary" :class="{wait: saving}" :disabled="saving">{{ (mode === 'add') ? 'Save' : 'Update' }}</button>
    </template>
  </Modal>
</template>

<script>
  import Modal from "@/components/partial/Modal"
  import company from '@/factory/company'
  import fn from '@/library/fn'
  import mailtarget from '@/factory/mailtarget'
  import { useVuelidate, required, email } from '@/library/vuelidate.js'

  export default {
    components: { Modal },
    props: {
      mode: {
        default: 'add'
      },
      data: {
        default: null
      },
      app: {
        default: 'mtarget'
      },
    },
    data () {
      return {
        form: {
          email: '',
          role: '',
          fullname: '',
          phone: ''
        },
        saving: false,
        error: {
          email: {
            status: false,
            message: ''
          }
        }
      }
    },
    computed: {
      companyId () { return this.$route.params.companyId }
    },
    validations () {
      return {
        form : {
          email: { required, email },
        }
      }
    },
    setup: () => ({ v$: useVuelidate() }),
    created () {
      this.init()
    },
    methods: {
      init () {
        if (this.mode === 'add') {
          this.form.email = ''
          this.form.role = ''
        } else if (this.mode === 'edit') {
          this.form.email = this.data.email
          this.form.role = this.data.role
          this.form.fullname = this.data.fullname
          this.form.phone = this.data.phone
        }
      },
      save () {
        if (this.mode === 'add') {
          if(this.app ==='mtarget'){
            this.inviteMember()
          } else if(this.app === 'mailtarget'){
            this.inviteMemberMailtarget()
          }
        } else if (this.mode === 'edit') {
          if(this.app ==='mtarget'){
            this.update()
          } else if(this.app === 'mailtarget'){
            this.updateMemberMailtarget()
          }
        }
      },
      inviteMember () {
        company.inviteMember(this.companyId, this.form.email, this.form.role)
          .then((res) => {
            if (res) fn.notySuccess("New member successfully invited.")
            else fn.notyWarn("Failed to invite member.")
            this.$emit('save-success')
          })
          .catch(err => this.$e(err))
      },
      update () {
        let data = {
          memberId: (this.data !== null) ? this.data.memberId : null,
          fullname: this.form.fullname,
          phone: this.form.phone,
          role: this.form.role
        }
        company.editMember(data)
          .then(() => {
            fn.notySuccess("Member successfully updated.")
            this.$emit('save-success')
          })
          .catch(err => this.$e(err))
      },
      inviteMemberMailtarget () {
        this.saving = true
        this.error.email.status = false
        this.v$.$validate()
          .then((valid) => {
            if (!valid) {
              this.saving = false
              return fn.notyWarn('Invalid form. Please complete the required fields.')
            }
            return mailtarget.inviteTeamMember(this.companyId, { email: this.form.email })
          })        
          .then((res) => {
            if (res) {
              fn.notySuccess("New member successfully invited.")
              this.saving = false
              this.$emit('save-success')
            }
          })
          .catch((err) => {
            this.saving = false
            if(err.code === 'email already exist'){
              this.error.email.status = true
              this.error.email.message = 'This email address already have account on mailtarget'
            } else {
              this.error.email.status = true
              this.error.email.message = err.code
            }
          })
      },
      updateMemberMailtarget () {
        this.$emit('save-success')
      }
    }
  }
</script>