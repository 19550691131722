<template>
  <Modal @close="$emit('close')" title="Create API Key">
    <div>
      <div class="form-group">
        <label>API Key Name</label>
        <input v-model="form.name" placeholder="name" type="text" class="form-control">
      </div>
      <div class="form-group">
        <label>Subaccount</label>
        <input placeholder="Primary" disabled type="text" class="form-control">
      </div>
      <div class="form-group">
        <label>Choose API permission</label>
<!--        <div>-->
<!--          <label class="kt-option kt-option kt-option&#45;&#45;plain">-->
<!--            <span class="kt-option__control">-->
<!--              <span class="kt-radio">-->
<!--                <input v-model="form.role" type="radio" name="role" value="CONTENT">-->
<!--                <span></span>-->
<!--              </span>-->
<!--            </span>-->
<!--            <span class="kt-option__label">-->
<!--              <span class="kt-option__head">-->
<!--                <span class="kt-option__title">Full access</span>-->
<!--              </span>-->
<!--            </span>-->
<!--          </label>-->
<!--        </div>-->
        <div>
          <label class="kt-option kt-option kt-option--plain">
            <span class="kt-option__control">
              <span class="kt-radio">
                <input v-model="form.permissionId" type="radio" name="role" :value="1">
                <span></span>
              </span>
            </span>
            <span class="kt-option__label">
              <span class="kt-option__head">
                <span class="kt-option__title">Full access</span>
              </span>
            </span>
          </label>
        </div>
      </div>
      <div class="form-group">
        <label>Allowed IPs</label>
        <input v-model="form.allowedIp" placeholder="192.168.1.1, ..." type="text" class="form-control mb-2">
        <div><AlertSvg /> Leaving the field blank will allow access by valid API keys from any IP address.</div>
      </div>
    </div>
    <template v-slot:footer>
      <button @click="$emit('close')" :disabled="disabled" type="button" class="btn btn-outline-primary" data-dismiss="modal">Cancel</button>
      <button @click="createApiKey" type="button" class="btn btn-primary" :class="{wait: disabled}" :disabled="disabled">Create API Key</button>
    </template>
  </Modal>
</template>

<script>
  import Modal from "@/components/partial/Modal"
  import fn from '@/library/fn'
  import AlertSvg from "@/components/svg/AlertSvg.vue"
  import mailtarget from "@/factory/mailtarget"
  
  export default {
    components: {AlertSvg, Modal },
    props: ['partnerId', 'companyId'],
    data () {
      return {
        form: {
          name: '',
          permissionId: 1,
          allowedIp: '',
        },
        disabled: false
      }
    },
    methods: {
      createApiKey () {
        this.disabled = true
        this.form.name = this.form.name.trim()
        this.form.permissionIds = [this.form.permissionId]
        mailtarget.createWhitelabelApiKey(this.partnerId, this.companyId, this.form)
          .then(() => {
            this.disabled = false
            fn.notySuccess("API Key successfully created.")
            this.$emit('created')
          })
          .catch((err) => {
            this.$e(err)
            this.disabled = false
          })
      },
    }
  }
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
