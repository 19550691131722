<template>
  <div class="kt-pagination kt-pagination--info w-100">
    <ul class="kt-pagination__links">
      <li @click="navigate(filter.page - 1)" class="kt-pagination__link--first" v-if="showFirst">
        <a href="#"><i class="flaticon2-back kt-font-info"></i></a>
      </li>

      <!-- <li
        v-for="item in list"
        :key="item"
        @click.prevent="navigate(item)"
        :class="{'kt-pagination__link--active' : item === filter.page}">
        <a href="#">{{ item }}</a>
      </li> -->
      <template>
        <span class="pagination__desc font-weight-600 text-gray-500">{{ desc }}</span>
      </template>

      <!-- <li @click="navigate(totPages)" class="kt-pagination__link--last" v-if="showLast">
        <a href="#"><i class="flaticon2-fast-next kt-font-info"></i></a>
      </li> -->
      <li @click="navigate(filter.page + 1)" class="kt-pagination__link--last" v-if="showLast">
        <a href="#"><i class="flaticon2-next kt-font-info"></i></a>
      </li>
    </ul>

    <div class="kt-pagination__toolbar">
      <!-- <template v-if="!search">
        <span class="pagination__desc">{{ desc }}</span>
      </template> -->
      <!-- <input v-if="search" @keydown.enter="navSearch" type="text" class="form-control" v-model="searchText" placeholder="Search">
      <span @click="searching" class="btn text-primary" :class="search ? 'flaticon2-delete' : 'flaticon2-search'"></span> -->

      <select v-model="size" class="form-control kt-font-info" style="width: 60px" v-if="perpage" @change="resize">
        <option value="4">4</option>
        <option value="10">10</option>
        <option value="25">25</option>
        <option value="100">100</option>
      </select>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      filter: {
        default () {
          return {
            count: 0,
            page: 1,
            size: 10,
            search: ''
          }
        }
      },
      perpage: {
        default: true
      }
    },
    data () {
      return {
        search: false,
        size: 10,
        searchText: ''
      }
    },
    computed: {
      showFirst () {
        return this.filter.page > 1
      },
      showLast () {
        return this.filter.page < this.totPages
      },
      totPages () { 
        return Math.ceil(this.filter.count / this.filter.size) },
      list () {
        let list = []
        for (let i = 1; i <= this.totPages; i++) {
          if (Math.abs(this.filter.page - i ) > 2) continue
          list.push(i)
        }
        if (list.length <= 1) return []
        return list
      },
      desc () {
        let str = ''
        if (!this.showLast) str += `Showing ${this.filter.count} of ${this.filter.count} records`
        else if (this.filter.count > 0) str += `Showing ${this.filter.size * this.filter.page} of ${this.filter.count} records`
        else str += 'no result'
        if (this.filter.search) str += ` for '${this.filter.search}'`
        return str
      }
    },
    created () {
      this.init()
    },
    methods: {
      init () {
        this.searchText = this.filter.search
        this.size = this.filter.size
      },
      navigate (page) {
        this.$emit('navigate', 'p', parseInt(page))
      },
      navSearch () {
        this.$emit('navigate', 's', this.searchText)
        this.search = false
      },
      resize () {
        this.$emit('navigate', 'r', this.size)
      },
      searching () {
        this.search = !this.search
      }
    }
  }
</script>

<style lang="scss" scoped>
  .kt-pagination .kt-pagination__toolbar .form-control {
    padding: 0 5px;
    max-width: 150px;
  }
</style>