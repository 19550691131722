<template>
  <div>
    <div v-if="features !== null" class="row">
      <div v-for="(data, i) in features" :key="i" :class="itemClass">
        <!-- <div class="accordion accordion-toggle-plus mb-3" :id="`accordion-feat-${i}`">
         <div class="card">
            <div class="card-header" :id="`heading-feat-${i}`">
              <div class="card-title py-1" :class="{collapsed: !open}" data-toggle="collapse" :data-target="`#collapse-feat-${i}`" :aria-expanded="!!open" :aria-controls="`collapse-feat-${i}`">
                <CheckBoxFeature
                  v-model="data.enable"
                  :title="data.name"
                  :disabled="disabled"
                  :main-feature="true" />
              </div>
            </div>
            <div :id="`collapse-feat-${i}`" class="collapse" :class="{show: !!open}" :aria-labelledby="`heading-feat-${i}`" :data-parent="`#accordion-feat-${i}`">
              <div v-if="data.subFeatures.length > 0" class="card-body pt-2 pb-0">
                <div class="ml-4">
                  <template v-for="(sub, j) in data.subFeatures">
                    <CheckBoxFeature
                      :key="j"
                      v-model="sub.enable"
                      :title="sub.text"
                      :limit="sub.limit"
                      @limit="(x) => {sub.limit = x}"
                      :disabled="disabled || !data.enable" />
                  </template>
                </div>
              </div>
            </div>
          </div>
        </div> -->
        <div class="dropdown features">
          <div class="parent-features px-3 py-2 w-100 rounded" type="button" :id="`feature-${i}`" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            <CheckBoxFeature
              v-model="data.enable"
              :title="data.name"
              :disabled="disabled"
              :main-feature="true" />
          </div>
          <div v-if="data.subFeatures.length > 0" class="dropdown-menu sub-features w-100 py-0" :aria-labelledby="`feature-${i}`">
            <div class="sub-features-container">
              <div v-for="(sub, j) in data.subFeatures" :key="j" class="dropdown-item px-3 py-1">
                <CheckBoxFeature
                  v-model="sub.enable"
                  :title="sub.text"
                  :limit="sub.limit"
                  @limit="(x) => {sub.limit = x}"
                  :disabled="disabled || !data.enable" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import CheckBoxFeature from '@/components/partial/CheckBoxFeature'

  export default {
    components: { CheckBoxFeature },
    props: {
      value: { default: null },
      disabled: { default: false },
      open: { default: false },
      itemClass: { default: 'col-12 col-sm-6 px-0' }
    },
    data () {
      return {
        features: null
      }
    },
    watch: {
      value () { this.init() },
      features (val, old) { if (old !== null) this.$emit('input', val) }
    },
    created () {
      this.init()
    },
    methods: {
      init () {
        this.features = this.value
      }
    }
  }
</script>
