import { helpers } from '@vuelidate/validators'
import uv from '@vuelidate/core'
import {
  required as _required,
  numeric as _numeric,
  minLength as _minLength,
  maxLength as _maxLength,
  sameAs as _sameAs,
  between as _between,
  url as _url,
  requiredIf as _requiredIf,
  email as _email
} from '@vuelidate/validators'

export const required = helpers.withMessage(`{0} is required`, _required)
export const email = helpers.withMessage('{0} is not a valid email', _email)
export const numeric = helpers.withMessage('{0} must be numeric', _numeric)
export const url = helpers.withMessage('{0} must be a valid url', _url)
export const minLength = (min) => helpers.withMessage(`{0} should be at least ${min} long`, _minLength(min))
export const maxLength = (max) => helpers.withMessage(`{0} maximum length allowed is ${max} long`, _maxLength(max))
export const between = (min, max) => helpers.withMessage(`{0} value must be between ${min} and ${max}`, _between(min, max))
export const sameAs = (equalTo, otherName) => helpers.withMessage(`{0} must be equal to ${otherName}`, _sameAs(equalTo, otherName))
export const exactLength = (lem) => helpers.withMessage(`{0} digits must be equal to ${lem}`, (value) => {
  return !helpers.req(value) || lem === helpers.len(value)
})

export const phoneNumber = helpers.withMessage('{0} is not valid phone number', helpers.regex(/^\+[\d ]+-\d{5,15}$/))
export const phoneNumberEdit = helpers.withMessage('{0} is not valid phone number', helpers.regex(/^(^\+62|62|^08|^\+|^0|\+)(\d{3,4}-?){2}\d{3,4}$/))
export const strongPassword = helpers.withMessage('{0} must contain at least: 1 uppercase letter, 1 lowercase letter, 1 number, and one special character (E.g. , . _ & ? etc)', helpers.regex(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?])(?=.{8,})/))
export const useVuelidate = uv

export const requiredIf = (reference) => helpers.withMessage('{0} is required', _requiredIf(reference))
export const shouldTrue = helpers.withMessage('{0} must be accepted', (value) => { return value === true })
export const domain = helpers.withMessage('{0} is not a valid domain', helpers.regex(/^(?!-)(?!.*-$)(?:[A-Za-z0-9-]{1,63}\.){1,}(?:[A-Za-z]{2,})+$/))
