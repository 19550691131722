<template>
  <div class="progress-wrapper">
    <div v-for="(x,k) in progressStep" :key="k" class="progress" :class="[type]" :style="`width: ${x.width}%;`">
      <div class="progress-bar" :style="`width: ${x.percent}%;`"></div>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      percent: { default: 0 },
      mode: { default: '' },
      step: { default: 1 },
      type: { default: 'progress--dark' }
    },
    computed: {
      progressStep () {
        let ps = []
        const step = this.step
        let totpc = this.percent * step
        for (let i = 1; i <= step; i++) {
          if (totpc < 0) totpc = 0
          const x = totpc >= 100 ? 100 : totpc
          ps.push({ width: 100 / step, percent: x})
          totpc -= 100
        }
        return ps
      }
    }
  }
</script>

<style lang="scss" scoped>
  @import "~@/assets/scss/_config.scss";

  .progress-wrapper {
    display: flex;
  }
  .progress {
    margin-right: 2px;
    height: auto;
    background-color: #BACFFF;
  }

  @mixin progress-bar-theme($progress-color) {
    .progress-bar {
      background-color: $progress-color;
    }
  }
  .progress {
    // State Colors
    @each $name, $color in $kt-state-colors {
      // default state 
      &.progress--#{$name} {
        @include progress-bar-theme(
          kt-get($color, base)
        );
      }
    } 
  }
  .progress-bar-bg-warning > .progress {
    background-color: #E9B500;
  }
</style>
