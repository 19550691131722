<template>
  <Modal :title="title" @close="$emit('close')">
    <p class="text-center" v-html="message" :style="{'text-align': align}"></p>
    <template v-slot:footer>
      <button @click="$emit('close')" type="button" class="btn btn-outline-primary" data-dismiss="modal">Close</button>
      <slot></slot>
    </template>
  </Modal>
</template>

<script>
  import Modal from './Modal'
  export default {
    components: {
      Modal
    },
    props: {
      message: {
        default: 'Are you sure?'
      },
      align: {
        default: 'left'
      },
      title: {
        default: 'Confirmation'
      }
    }
  }
</script>