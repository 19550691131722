<template>
  <span class="kt-switch kt-switch--outline kt-switch--icon kt-switch--success">
    <label>
      <input type="checkbox" checked="checked" :value="true" v-model="val" @change="$emit('input', val)">
      <span></span>
    </label>
  </span>
</template>

<script>
  export default {
    props: {
      value: {
        default: true
      }
    },
    data () {
      return {
        val: true
      }
    },
    created () {
      this.val = this.value
    }
  }
</script>