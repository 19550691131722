<template>
  <div>
    <div class="row justify-content-between mb-2">
      <div class="col-auto">
        <div class="text-bold mb-2">{{ type }}</div>
        <div>{{ titleTime }} on {{ time | dtmType('DD MMM YYYY') }}</div>
      </div>
      <div class="col-auto text-right">
        <div class="mb-2">{{ used | curr }}/{{ quota | curr }}</div>
        <div>{{ Math.round(percentage) || 0 }}% Has been used</div>
      </div>
    </div>
    <div>
      <ProgressBar :percent="percentage" mode="active" class="flex-grow-1" style="height: 8px;" />
      <div v-if="unpaid" class="mt-2"> <span class="la la-info-circle la-lg text-info"></span> There is a billing that hasn't been paid yet. <span class="cursor-pointer text-warning" @click="goTo">Check Billing</span> </div>
    </div>
  </div>
</template>

<script>
  import ProgressBar from '@/components/partial/ProgressBar'

  export default {
    components: { ProgressBar },
    props: {
      status: { default: 'active' },
      icon: { default: null },
      type: { default: '' },
      percentage: { default: 0 },
      time: { default: 0 },
      used: { default: 0 },
      quota: { default: 0 },
      titleTime: { default: 'Last Updated' },
      unpaid: { default: false },
    },
    methods: {
      goTo() {
       this.$router.push(`/clients/mtarget/${this.$route.params.companyId}?tab=billing&subtab=extra`)
      }
    }
  }
</script>

<style lang="scss" scoped>
  @import "~@/assets/scss/_config.scss";

  .extra-quota-item {
    display: flex;
    align-items: center;
    padding: 1rem;
    border: 1px solid kt-get($kt-base-colors, label, 1);
    border-radius: 5px;
    .detail-box {
      display: flex;
    }
    .quota-icon {
      margin-right: 1rem;
      i {
        font-size: 32px;
      }
    }
    .quota-detail {
      div {
        font-weight: bold;
      }
      span {
        font-style: italic;
      }
    }
    .quota-usage {
      flex-grow: 1;
      margin-left: 3rem;
      .quota-progress {
        display: flex;
        align-items: center;
      }
      .usage {
        min-width: 80px;
        // text-align: right;
      }
    }
    &.inactive {
      background-color: kt-get($kt-base-colors, shape, 1);
      border: 1px solid kt-get($kt-base-colors, shape, 2);
    }
  }
  @media only screen and (max-width: kt-get($kt-media-breakpoints, sm)) {
    .extra-quota-item {
      flex-direction: column;
      .detail-box {
        width: 100%;
        margin-bottom: .5rem;
      }
      .quota-usage {
        width: 100%;
      }
      .quota-usage {
        margin-left: 0;
      }
    }
  }
  .circle-info {
    width: 20px;
    height: 20px;
    background-color: #237AB3;
  }
</style>
