<template>
  <Modal title="Add WhatsApp Phone" @close="close">
    <div>
      <div class="form-group">
        <label>WhatsApp Phone *</label>
        <input type="text" v-model="form.phone" class="form-control" placeholder="Ex: +6281234567890">
      </div>
      <div class="form-group">
        <label>Business Name *</label>
        <input type="text" v-model="form.displayName" class="form-control" placeholder="Type here">
      </div>
      <div class="form-group">
        <label>Company Address</label>
        <input type="text" v-model="form.companyAddress" class="form-control" placeholder="Type here">
      </div>
      <div class="form-group">
        <label>Company Email</label>
        <input type="text" v-model="form.companyEmail" class="form-control" placeholder="Type here">
      </div>
      <div class="form-group">
        <label>Website</label>
        <input type="text" v-model="form.website" class="form-control" placeholder="Type here">
      </div>
      <div class="form-group">
        <label>Link to Logo</label>
        <input type="text" v-model="form.linkToLogo" class="form-control" placeholder="Type here">
      </div>
    </div>
    <template v-slot:footer>
      <button @click.prevent="close" type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
      <button @click.prevent="createWhatsapp" type="button" class="btn btn-primary">Add Phone</button>
    </template>
  </Modal>
</template>

<script>
  import Modal from "@/components/partial/Modal"

  export default {
    components: {
      Modal
    },
    data () {
      return {
        form: {
          companyId: '',
          phone: '',
          displayName: '',
          companyAddress: '',
          companyEmail: '',
          website: '',
          linkToLogo: ''
        }
      }
    },
    computed: {
      companyId () { return this.$route.params.companyId }
    },
    created () {
      this.form.companyId = this.companyId
    },
    methods: {
      close () {
        this.$emit('close')
      },
      createWhatsapp () {
        this.$emit('create', this.form)
      }
    }
  }
</script>
