<template>
  <Modal :title="title" @close="close">
    <div v-if="step === 'info'">
      <div class="alert alert-outline-danger kt-bg-light-danger" role="alert">
        <div class="alert-icon"><i class="flaticon2-information kt-font-danger"></i></div>
        <div class="alert-text text-dark">
          Please note, this action will permanently remove the account from {{ app === 'mtarget' ? 'MTARGET' : 'mailtarget' }} system.
        </div>
      </div>
      <div class="text-center">Are you sure want to <span class="text-bold text-danger">Delete</span> user <span class="text-bold">{{ app === 'mtarget' ? company.ownerEmail : company.email }}</span>?</div>
    </div>
    <div v-else-if="step === 'confirm'">
      <div class="alert alert-outline-danger kt-bg-light-danger" role="alert">
        <div class="alert-icon"><i class="flaticon2-information kt-font-danger"></i></div>
        <div class="alert-text text-dark">
          You need to authorize this action with entering your CRM account password to continue.
        </div>
      </div>
      <VeeOb ref="form" tag="div" class="row">
        <div class="col-sm-12">
          <Vee tag="div" vid="password" rules="required|min:8" name="Password" v-slot="{ errors }" class="form-group">
            <label>Password <span class="text-red-60">*</span></label>
            <input v-model="password" type="password" class="form-control" placeholder="Type your password here.">
            <span class="text-warning">{{ errors[0] }}</span>
          </Vee>
        </div>
      </VeeOb>
    </div>
    <div v-else-if="step === 'finish'">
      <div class="text-center"><span class="text-bold">{{ app === 'mtarget' ? company.ownerEmail : company.email }}</span> is successfully <span class="text-bold text-danger">deleted</span></div>
    </div>
    <template v-slot:footer>
      <template v-if="step === 'info'">
        <button @click.prevent="close" type="button" class="btn btn-secondary" :class="{wait: loading}" data-dismiss="modal">Close</button>
        <button @click.prevent="step = 'confirm'" type="button" class="btn btn-primary" :class="{wait: loading}">Yes</button>
      </template>
      <template v-else-if="step === 'confirm'">
        <button @click.prevent="toInfo" type="button" class="btn btn-secondary" :class="{wait: loading}" data-dismiss="modal">Cancel</button>
        <button @click.prevent="deleteAccount" type="button" class="btn btn-danger" :disabled="!canDelete || loading">Delete Account</button>
      </template>
      <template v-else-if="step === 'finish'">
        <router-link v-if="app === 'mtarget'" to="/clients" class="btn btn-primary">Close</router-link>
        <router-link v-else to="/mailtarget" class="btn btn-primary">Close</router-link>
      </template>
    </template>
  </Modal>
</template>

<script>
  import Modal from '@/components/partial/Modal'
  import company from '@/factory/company'
  import user from '@/factory/user'
  import mailtarget from '@/factory/mailtarget'
  import fn from '@/library/fn'

  export default {
    components: {
      Modal,
    },
    props: {
      app: {
        default: 'mtarget',
      },
      company: {
        default: null,
      },
    },
    data () {
      return {
        loading: false,
        step: 'info', // info, confirm, finish
        password: '',
      }
    },
    computed: {
      title () {
        if (this.step === 'info') return 'Delete Account'
        return (this.step === 'confirm') ? 'Confirmation' : 'User Deleted'
      },
      canDelete () {
        if (!this.password) return false
        return this.password !== ''
      }
    },
    methods: {
      close () {
        this.$emit('close')
      },
      toInfo () {
        this.step = 'info'
        this.password = ''
        this.confirmPassword = ''
      },
      deleteAccount () {
        this.loading = true
        if(this.$props.app === 'mtarget'){
          this.$refs.form.validate()
            .then(res => {
              if (!res) {
                fn.notyWarn('validation')
                return Promise.reject()
              }
              return company.deleteAccount(this.company.companyId, this.password)
            })
            .then((res) => {
              if (res) {
                this.step = 'finish'
              } else {
                fn.notyError("Failed to delete company.")
              }
            })
            .catch(err => {
              this.$e(err)
            })
            .finally(() => {
              this.loading = false
            })
        } else if (this.$props.app === 'mailtarget') {
          this.$refs.form.validate()
            .then(res => {
              if (!res) {
                fn.notyWarn('validation')
                return Promise.reject()
              }
              return user.checkPassword({ password: this.password })
            })
            .then((res) => {
              if (res) {
                this.deleteAccountMailtarget()
              } else {
                fn.notyError("The password is incorrect!")
                this.loading = false
              }
            })
            .catch(err => {
              this.$e(err)
              this.loading = false
            })
        }        
      },
      deleteAccountMailtarget () {
        mailtarget.deleteUser(this.$route.params.userId)
          .then((res) => {
            if (res) {
              this.step = 'finish'
            } else {
              fn.notyError("Failed to delete company.")
            }
          })
          .catch(err => {
            this.$e(err)
          })
          .finally(() => {
            this.loading = false
          })
      }
    }
  }
</script>
