<template>
  <div class="row">
    <div class="col-12">
      <div class="kt-portlet kt-portlet--mobile">
        <div class="kt-portlet__head kt-portlet__head--lg border-bottom">
          <div class="kt-portlet__head-label">
            <h3 class="kt-portlet__head-title">
              <span class="text-bold text-gray-700">
                Current Subscription
              </span>
            </h3>
          </div>

          <div class="kt-portlet__head-toolbar my-1">
            <div class="kt-portlet__head-wrapper">
              <div class="kt-portlet__head-actions d-flex">
                <button @click="$emit('show-features', currentPlan.features)" class="btn btn-md btn-outline-primary btn-icon-sm mr-2">
                  <i class="flaticon-list"></i>
                  Show Features
                </button>
                <div class="dropdown">
                  <button
                    type="button"
                    class="btn btn-primary btn-icon-sm dropdown-toggle"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false">
                    <i class="la la-arrow-circle-up"></i>
                    {{ upgradeType || 'Upgrade Plan' }}
                  </button>
                  <div class="dropdown-menu dropdown-menu-fit dropdown-menu-right dropdown-custom-fw">
                    <ul class="kt-nav">
                      <li class="kt-nav__section kt-nav__section--first">
                        <span class="kt-nav__section-text">
                          Subscription Type
                        </span>
                      </li>
                      <li v-for="(data, i) in upgradeTypeOption" :key="i" @click="selectUpgradeType(data)" class="kt-nav__item">
                        <a class="kt-nav__link">
                          <span class="kt-nav__link-text" style="text-transform: capitalize;">{{ data.toLowerCase() }}</span>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="kt-portlet__body kt-portlet__body--lg">
          <template v-if="currentPlan !== null">
            <div class="form-group"><strong>Package Version  {{ currentPlan.packetVersion }}</strong></div>
            <div class="row">
              <div class="col-12 col-md-6">
                <div class="form-group">
                  <div class="row">
                    <div class="col-12 col-md-4 col-xl-2">
                      <div class="d-flex align-items-center h-100 text-bold">Type</div>
                    </div>
                    <div class="col-12 col-md-8 col-xl-10 pl-xl-5">
                      <input v-if="currentPlan.type === 'custom'" :value="`Custom Plan`" type="text" class="form-control" disabled>
                      <input v-else-if="currentPlan.type === 'payasyougo'" :value="`Pay As You Grow`" type="text" class="form-control" disabled>
                      <input v-else :value="`Default Plan`" type="text" class="form-control" disabled>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12 col-md-6 pl-5">
                <div class="form-group">
                  <div class="row">
                    <div class="col-12 col-md-4 col-xl-2">
                      <div class="d-flex align-items-center h-100 text-bold">Package</div>
                    </div>
                    <div class="col-12 col-md-8 col-xl-10 pl-xl-5">
                      <input :value="currentPlan.packet | replace" type="text" class="form-control text-capitalize" disabled>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <template v-if="currentPlan.type === 'payasyougo'">
              <div class="row">
                <div class="col-12 col-md-6">
                  <div class="form-group">
                    <div class="row">
                      <div class="col-12 col-md-4 col-xl-2">
                        <div class="d-flex align-items-center h-100 text-bold">Cut Off Period</div>
                      </div>
                      <div class="col-12 col-md-8 col-xl-10 pl-xl-5">
                        <input :value="currentPlan.cutOffPeriod" type="text" class="form-control" disabled>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-12 col-md-6 pl-5">
                  <div class="form-group">
                    <div class="row">
                      <div class="col-12 col-md-4 col-xl-2">
                        <div class="d-flex align-items-center h-100 text-bold">Price /email</div>
                      </div>
                      <div class="col-12 col-md-8 col-xl-10 pl-xl-5">
                        <input :value="currentPlan.emailPrice" type="text" class="form-control" disabled>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-12 col-md-6">
                  <div class="form-group">
                    <div class="row">
                      <div class="col-12 col-md-4 col-xl-2">
                        <div class="d-flex align-items-center h-100 text-bold">Min. Fair Usage</div>
                      </div>
                      <div class="col-12 col-md-8 col-xl-10 pl-xl-5">
                        <input :value="currentPlan.minFairUsage | curr" type="text" class="form-control" disabled>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-12 col-md-6 pl-5">
                  <div class="form-group">
                    <div class="row">
                      <div class="col-12 col-md-4 col-xl-2">
                        <div class="d-flex align-items-center h-100 text-bold">Minimum Payment</div>
                      </div>
                      <div class="col-12 col-md-8 col-xl-10 pl-xl-5">
                        <input :value="(currentPlan.emailPrice * currentPlan.minFairUsage) | curr" type="text" class="form-control" disabled>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </template>
            <template v-else>
              <div class="row">
                <div class="col-12 col-md-6">
                  <div class="form-group">
                    <div class="row">
                      <div class="col-12 col-md-4 col-xl-2">
                        <div class="d-flex align-items-center h-100 text-bold">Email Quota</div>
                      </div>
                      <div class="col-12 col-md-8 col-xl-10 pl-xl-5">
                        <input :value="`${currentPlan.emailLimit === -1 ? 'Unlimited' : currentPlan.emailLimit | curr }`" type="text" class="form-control" disabled>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-12 col-md-6 pl-5">
                  <div class="form-group">
                    <div class="row">
                      <div class="col-12 col-md-4 col-xl-2">
                        <div class="d-flex align-items-center h-100 text-bold">Contact Limit</div>
                      </div>
                      <div class="col-12 col-md-8 col-xl-10 pl-xl-5">
                        <input v-if="currentPlan.contactLimit === -1" :value="`Unlimited`" type="text" class="form-control" disabled>
                        <input v-else :value="currentPlan.contactLimit | curr" type="text" class="form-control" disabled>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-12 col-md-6">
                  <div class="form-group">
                    <div class="row">
                      <div class="col-12 col-md-4 col-xl-2">
                        <div class="d-flex align-items-center h-100 text-bold">Subscription</div>
                      </div>
                      <div class="col-12 col-md-8 col-xl-10 pl-xl-5">
                        <input :value="(currentPlan.subscription >= 12) ? 'Annually' : 'Monthly'" type="text" class="form-control" disabled>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-12 col-md-6 pl-5">
                  <div class="form-group">
                    <div class="row">
                      <div class="col-12 col-md-4 col-xl-2">
                        <div class="d-flex align-items-center h-100 text-bold">Subscription Period</div>
                      </div>
                      <div class="col-12 col-md-8 col-xl-10 pl-xl-5">
                        <input :value="currentPlan.period" type="text" class="form-control" disabled>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-12 col-md-6">
                  <div class="form-group">
                    <div class="row">
                      <div class="col-12 col-md-4 col-xl-2">
                        <div class="d-flex align-items-center h-100 text-bold">Monthly Price</div>
                      </div>
                      <div class="col-12 col-md-8 col-xl-10 pl-xl-5">
                        <div class="input-group">
                          <input :value="`${currentPlan.monthlyPrice | curr}/month`" type="text" class="form-control" aria-describedby="current-monthly-price" disabled>
                          <!-- <div class="input-group-append"><span class="input-group-text" id="current-monthly-price">/month</span></div> -->
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-12 col-md-6 pl-5">
                  <div class="form-group">
                    <div class="row">
                      <div class="col-12 col-md-4 col-xl-2">
                        <div class="d-flex align-items-center h-100 text-bold">Annually Price</div>
                      </div>
                      <div class="col-12 col-md-8 col-xl-10 pl-xl-5">
                        <div class="input-group">
                          <input :value="currentPlan.yearlyPrice | curr" type="text" class="form-control" aria-describedby="current-annually-price" disabled>
                          <!-- <div class="input-group-append"><span class="input-group-text" id="current-annually-price">/month</span></div> -->
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </template>
          </template>
        </div>
      </div>
    </div>
    <div ref="changeSubs" v-if="upgradeType !== ''" class="col-12">
      <div class="kt-portlet kt-portlet--mobile">
        <div class="kt-portlet__head kt-portlet__head--lg">
          <div class="kt-portlet__head-label">
            <h3 v-if="isCustomPlan || isDefaultPlan" class="kt-portlet__head-title">
              Change Subscription: <span class="text-primary">{{ isDefaultPlan ? 'Default Plan' : 'Custom Plan' }}</span>
            </h3>
            <h3 v-if="isPayAsYouGrow" class="kt-portlet__head-title">
              Change Subscription: <span class="text-primary">PAY AS YOU GROW</span>
            </h3>
            <h3 v-if="isAddOn" class="kt-portlet__head-title">
              Create <span class="text-primary">Add-On</span>
            </h3>
            <h3 v-if="isEditPacket" class="kt-portlet__head-title">
              <span class="text-danger">EDIT PACKET</span> <span v-if="typeType === 'payasyougo'" class="text-primary">PAY AS YOU GO</span>
            </h3>
            <h3 v-if="isCustomTrial" class="kt-portlet__head-title">
              <span class="text-primary">EDIT PACKET TRIAL</span>
            </h3>
          </div>

          <div class="kt-portlet__head-toolbar my-1">
            <div class="kt-portlet__head-wrapper">
              <div class="kt-portlet__head-actions d-flex">
                <button @click="selectUpgradeType('')" class="btn btn-outline-danger btn-icon-sm">
                  <i class="la la-times"></i> Cancel
                </button>
              </div>
            </div>
          </div>
        </div>

        <div class="kt-portlet__body kt-portlet__body--lg">
          <template v-if="isPayAsYouGrow || (isEditPacket && typeType === 'payasyougo')">
            <div class="form-group">
              <div class="row align-items-center">
                <div class="col-12 col-sm-4">
                  <span>Package Version</span>
                </div>
                <div class="col-12 col-sm-4">
                  <strong>{{ packageVersion }}</strong>
                </div>
              </div>
            </div>
            <div class="form-group">
              <div class="row align-items-center">
                <div class="col-12 col-sm-4">
                  <span>Base Package</span>
                </div>
                <div class="col-12 col-sm-8">
                  <select v-model="selectedPackage" class="form-control text-capitalize">
                    <option v-for="(data, i) in packageOptionByVersion" :key="i" :value="data">{{ data.type | replace }}</option>
                  </select>
                </div>
              </div>
            </div>
            <div class="form-group">
              <div class="row align-items-center">
                <div class="col-12 col-sm-4">
                  <span>Cut Off Period</span>
                </div>
                <div class="col-6 col-sm-4">
                  <input type="text" disabled value="Monthly, every" class="form-control">
                </div>
                <div class="col-6 col-sm-4">
                  <select v-model="cutOffPeriod" class="form-control" :disabled="selectedPackage === null && !isEditPacket">
                    <option v-for="i in 28" :key="i" :value="i">{{ i }}</option>
                  </select>
                </div>
              </div>
            </div>
            <div class="form-group">
              <div class="row align-items-center">
                <div class="col-12 col-sm-4">
                  <span>Price /email</span>
                </div>
                <div class="col-6 col-sm-4">
                  <InputCurrency v-model="emailPrice" :toFixed="2" :disabled="selectedPackage === null && !isEditPacket" />
                </div>
                <div class="col-6 col-sm-4">
                  <input type="text" class="form-control" :value="`/email`" disabled>
                </div>
              </div>
            </div>
            <div class="form-group">
              <div class="row align-items-center">
                <div class="col-12 col-sm-4">
                  <span>Min. Fair Usage</span>
                </div>
                <div class="col-12 col-sm-8">
                  <InputCurrency v-model="minFairUsage" :disabled="selectedPackage === null && !isEditPacket" prefix="" :toFixed="0" />
                </div>
              </div>
            </div>
            <div class="form-group">
              <div class="row align-items-center">
                <div class="col-12 col-sm-4">
                  <span>Minimum Payment</span>
                </div>
                <div class="col-12 col-sm-8">
                  <div class="row">
                    <div class="col-12 col-sm">
                      <InputCurrency v-model="minPricePayAsYouGo" :disabled="true" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="form-group">
              <div class="row">
                <div class="col-12 col-sm-4">
                  <span>Note</span>
                </div>
                <div class="col-12 col-sm-8">
                  <textarea v-model="note" rows="1" class="form-control" :disabled="selectedPackage === null && !isEditPacket"></textarea>
                </div>
              </div>
            </div>
            <div class="border-top border-aqua mb-4"></div>
          </template>
          <template v-if="isCustomPlan || isDefaultPlan || (isEditPacket && typeType !== 'payasyougo') || isCustomTrial">
            <div class="form-group">
              <div class="row align-items-center">
                <div class="col-12 col-sm-4">
                  <span>Package Version</span>
                </div>
                <div class="col-12 col-sm-4">
                  <strong>{{ packageVersion }}</strong>
                  <!-- <select v-model="packageVersion" class="form-control" disabled>
                    <option v-for="(data, i) in packageVersionOption" :key="i" :value="data">{{ data }}</option>
                  </select> -->
                </div>
              </div>
            </div>
            <div class="form-group">
              <div class="row align-items-center">
                <div class="col-12 col-sm-4">
                  <span>Base Package</span>
                </div>
                <div class="col-12 col-sm-8">
                  <select v-model="selectedPackage" class="form-control text-capitalize">
                    <option v-for="(data, i) in packageOptionByVersion" :key="i" :value="data">{{ data.type | replace }}</option>
                  </select>
                </div>
              </div>
            </div>
            <div v-if="isDefaultPlan" class="form-group">
              <div class="row align-items-center">
                <div class="col-12 col-sm-4">
                  <span>Price by Period</span>
                </div>
                <div class="col-6 col-sm-4">
                  <select v-model="priceByPeriod" :disabled="selectedPackage === null" class="form-control">
                    <option :value="1">Monthly</option>
                    <option :value="12">Annually</option>
                  </select>
                </div>
                <div class="col-6 col-sm-4">
                  <input type="text" class="form-control" :value="`IDR ${monthlyPrice} / month`" disabled>
                </div>
              </div>
            </div>
            <div class="form-group" v-if="!isCustomTrial">
              <div class="row align-items-center">
                <div class="col-12 col-sm-4">
                  <span>Subscription Period</span>
                </div>
                <div class="col-6 col-sm-5">
                  <select v-model="subscriptionPeriod" :disabled="selectedPackage === null" class="form-control">
                    <option :value="1">Monthly</option>
                    <option :value="12">Annually</option>
                  </select>
                </div>
                <div class="col-6 col-sm-3">
                  <input v-model="subscription" @keypress="onlyNumber" :disabled="selectedPackage === null" type="text" class="form-control">
                  <select v-if="false" v-model="subscription" :disabled="selectedPackage === null" class="form-control">
                    <option v-for="(data, i) in subscriptionOption" :key="i" :value="data">{{ data }} {{ (subscriptionPeriod === 1) ? 'Month' : 'Year'}}</option>
                  </select>
                </div>
              </div>
            </div>
            <div v-if="!isEditPacket && !isCustomTrial" class="form-group">
              <div class="row align-items-center">
                <div class="col-12 col-sm-4">
                  <span>Promo Code</span>
                </div>
                <div class="col-8 col-sm-5">
                  <input v-model="promoCode" @keydown.enter="checkPromoCode" :disabled="selectedPackage === null" type="text" class="form-control">
                </div>
                <div class="col-4 col-sm-3">
                  <button @click="checkPromoCode" :disabled="selectedPackage === null" class="btn btn-md btn-primary btn-block">Check</button>
                </div>
              </div>
              <div v-if="detailPromo !== null" class="row">
                <div class="col-12 col-sm-4">
                </div>
                <div class="col-12 col-sm-8">
                  <div>
                    {{ detailPromo.type }} : 
                    <span v-if="detailPromo.type === 'VOUCHER'" class="text-bold">{{ detailPromo.voucher | curr }}</span>
                    <span v-if="detailPromo.type === 'DISCOUNT'" class="text-bold">{{ detailPromo.discount }}% (Max: {{ detailPromo.maxDiscount }})</span>
                  </div>
                  <div><a class="cursor-pointer text-success" @click="$emit('detail-promo', detailPromo)">Click here</a> to see promo detail.</div>
                </div>
              </div>
            </div>
            <div v-if="isEditPacket" class="form-group">
              <div class="row align-items-center">
                <div class="col-12 col-sm-4">
                  <span>Susbscription Period</span>
                </div>
                <div class="col-12 col-sm-8">
                  <DatePicker v-model="editPeriod" :customConfig="{enableTime: false, minDate: null, mode: 'range'}" />
                </div>
              </div>
            </div>
            <div v-if="isCustomTrial" class="form-group">
              <div class="row align-items-center">
                <div class="col-12 col-sm-4">
                  <span>Free trial end date</span>
                </div>
                <div class="col-12 col-sm-8">
                  <DatePicker v-model="editPeriod" :customConfig="{enableTime: false, minDate: null, mode: 'range'}" />
                </div>
              </div>
            </div>
            <div class="form-group">
              <div class="row">
                <div class="col-12 col-sm-4">
                  <span>Note</span>
                </div>
                <div class="col-12 col-sm-8">
                  <textarea v-model="note" rows="1" class="form-control"></textarea>
                </div>
              </div>
            </div>
            <div class="border-top border-aqua mb-4"></div>
            <div class="form-group">
              <div class="mb-3">
                <h5>Detail Feature</h5>
              </div>
              <div class="row mb-3">
                <div class="col-md-6">
                  <label>Contact Limit</label>
                  <InputCurrency v-model="detailFeature.contactLimit" @keypress="onlyNumber" :disabled="true" prefix="" :toFixed="0" />
                </div>
                <div class="col-md-6">
                  <label>Email Quota</label>
                  <InputCurrency v-model="detailFeature.emailQuota" @keypress="onlyNumber" :disabled="isDefaultPlan || selectedPackage === null" prefix="" :toFixed="0" />
                </div>
              </div>
              <div class="row" v-if="!isCustomTrial">
                <div class="col-md-6">
                  <label>Price Monthly</label>
                  <InputCurrency v-model="detailFeature.monthlyPrice" @keypress="onlyNumber" :disabled="isDefaultPlan || selectedPackage === null" />
                </div>
                <div class="col-md-6">
                  <label>Price Annually</label>
                  <InputCurrency v-model="detailFeature.yearlyPrice" @keypress="onlyNumber" :disabled="isDefaultPlan || selectedPackage === null" />
                </div>
              </div>
            </div>
          </template>
          <template v-if="isAddOn">
            <div class="form-group">
              <div class="mb-3">
                <h5>Detail Feature</h5>
              </div>
              <div class="row">
                <div class="col-sm-6">
                  <label>ADD-ON PRICE <span class="text-danger">/MONTH</span></label>
                  <InputCurrency v-model="addonPrice" @keypress="onlyNumber" />
                </div>
                <div class="col-sm-6">
                  <label>Email Quota</label>
                  <InputCurrency v-model="addonEmailQuota" @keypress="onlyNumber" prefix="" :toFixed="0" />
                </div>
              </div>
            </div>
          </template>
          <template v-if="isCustomPlan || isDefaultPlan || isAddOn">
            <div class="form-group">
              <div class="mb-3">
                <h5>Extra Service</h5>
              </div>
              <div v-if="extraService.length > 0" class="row">
                <div class="col-sm-6">
                  <label>Name</label>
                </div>
                <div class="col-sm-6">
                  <label>Price</label>
                </div>
              </div>
              <div v-for="(service, i) in extraService" :key="i" class="row mb-2">
                <div class="col-sm-6">
                  <input v-model="service.name" type="text" class="form-control">
                </div>
                <div class="col-sm-6">
                  <div class="row">
                    <div class="col">
                      <InputCurrency v-model="service.price" @keypress="onlyNumber" />
                    </div>
                    <div class="d-flex align-items-center">
                      <button @click="deleteService(i)" class="btn btn-sm btn-clean btn-icon btn-icon-md">
                        <i class="flaticon2-trash text-danger"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-12">
                  <button @click="addService" class="btn btn-sm btn-outline-success">Add Service</button>
                </div>
              </div>
            </div>
          </template>
          <div class="form-group">
            <div class="row">
              <div class="col">
                <div class="accordion accordion-toggle-plus" id="accordionExample3">
                  <div class="card">
                    <div class="card-header" id="headingOne3">
                      <div class="card-title" data-toggle="collapse" data-target="#collapseOne3" aria-expanded="true" aria-controls="collapseOne3">
                        Detail Features
                      </div>
                    </div>
                    <div id="collapseOne3" class="collapse show" :class="{show: accordionFeatures}" aria-labelledby="headingOne3" data-parent="#accordionExample3" style="">
                      <div v-if="features !== null" class="card-body">
                        <Features v-model="features" :disabled="isDefaultPlan" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12 text-right">
              <button v-if="isCustomPlan || isDefaultPlan || isPayAsYouGrow" @click="confirmSubcription" :disabled="selectedPackage === null" class="btn btn-md btn-primary ml-2">Create Subscription</button>
              <button v-if="isAddOn" @click="confirmAddon" class="btn btn-md btn-primary ml-2">Save Add On</button>
              <button v-if="isEditPacket" @click="confirmEdit" class="btn btn-md btn-primary ml-2">Update Packet</button>
              <button v-if="isCustomTrial" @click="confirmEditTrial" class="btn btn-md btn-primary ml-2">Update Custom Trial</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  // import CheckBoxFeature from '@/components/partial/CheckBoxFeature'
  import Features from "@/components/company/Features"
  import DatePicker from '@/components/partial/DatePicker'
  import InputCurrency from '@/components/partial/InputCurrency'
  import common from '@/factory/common'
  import payment from '@/factory/payment'
  import promo from '@/factory/promo'
  import setting from '@/factory/setting'
  import { cloneDeep } from 'lodash'
  import RoleMixin from '@/components/RoleMixin'
  import moment from 'moment'

  export default {
    mixins: [RoleMixin],
    components: { Features, DatePicker, InputCurrency },
    props: ['reloadPlan'],
    data() {
      return {
        upgradeType: '',
        upgradeTypeOption: ['DEFAULT PLAN', 'CUSTOM PLAN', 'PAY AS YOU GROW', 'ADD-ON'],
        addonPrice: 0,
        addonEmailQuota: 0,
        packageVersion: "4.0",
        packageVersionOption: ["4.0"],
        selectedPackage: null,
        priceByPeriod: 1,
        subscriptionPeriod: 1,
        subscription: 1,
        promoCode: "",
        note: "",
        cutOffPeriod: 21,
        emailPrice: 0,
        minFairUsage: 0,
        minPricePayAsYouGo: 0,
        detailFeature: {
          contactLimit: 0,
          emailQuota: 0,
          monthlyPrice: 0,
          yearlyPrice: 0
        },
        features: null,
        accordionFeatures: false,
        currentPlan: null,
        detailPromo: null,
        allPacket: [],
        editPeriod: [],
        extraService: []
      }
    },
    watch: {
      selectedPackage (val, old) {
        if (val !== null) this.features = cloneDeep(val.features)
        this.detailFeature.contactLimit = (val !== null) ? this.contactLimit(val) : 0
        this.detailFeature.emailQuota = (val !== null) ? val.emailLimit : 0
        this.detailFeature.monthlyPrice = (val !== null) ? val.monthlyPrice : 0
        this.detailFeature.yearlyPrice = (val !== null) ? val.yearlyPrice : 0
        if ((this.isEditPacket || this.isDefaultPlan) && old === null && (this.currentPlan.type !== 'custom')) {
          this.features = cloneDeep(this.currentPlan.features)
          this.detailFeature.contactLimit = this.contactLimit(this.currentPlan)
          this.detailFeature.emailQuota = this.currentPlan.emailLimit
          this.detailFeature.monthlyPrice = this.currentPlan.monthlyPrice
          this.detailFeature.yearlyPrice = this.currentPlan.yearlyPrice
        }
      },
      emailPrice (val) { this.updateTotal(val, 'price') },
      minFairUsage (val) { this.updateTotal(val, 'usage') },
      reloadPlan () { this.getCompanyPlan() }
    },
    computed: {
      companyId () { return this.$route.params.companyId },
      isDefaultPlan () { return this.upgradeType === 'DEFAULT PLAN' },
      isCustomPlan () { return this.upgradeType === 'CUSTOM PLAN' },
      isPayAsYouGrow () { return this.upgradeType === 'PAY AS YOU GROW' },
      isAddOn () { return this.upgradeType === 'ADD-ON' },
      isEditPacket () { return this.upgradeType === 'EDIT PACKET' },
      isCustomTrial () { return this.upgradeType === 'CUSTOM TRIAL' },
      typeType () {
        let type = ''
        if (this.isCustomPlan) {
          type = 'custom'
        } else if (this.isDefaultPlan) {
          type = 'regular'
        } else if (this.isPayAsYouGrow) {
          type = 'payasyougo'
        } else if (this.isEditPacket) {
          type = (this.currentPlan !== null) ? this.currentPlan.type : ''
        }
        return type
      },
      packageOptionByVersion () {
        return this.allPacket.filter((val) => {
          return val.version === this.packageVersion
        })
      },
      monthlyPrice () {
        if (this.selectedPackage === null) return 0
        return (this.priceByPeriod === 1) ? this.selectedPackage.monthlyPrice : this.selectedPackage.yearlyPrice
      },
      subscriptionOption () {
        return (this.subscriptionPeriod === 1) ? [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11] : [1, 2, 3, 4, 5]
      }
    },
    created () {
      this.init()
    },
    methods: {
      init () {
        if (this.roleAdmin || this.roleRevenueAdmin) this.upgradeTypeOption.push("EDIT PACKET")
        this.getSettingVersion()
        this.getAllPacket()
      },
      onlyNumber ($event) {
        let key = ($event.keyCode ? $event.keyCode : $event.which)
        if ((key < 48 || key > 57) && key !== 46 && key !== 45) {
          $event.preventDefault()
        }
      },
      contactLimit (val) {
        if (val.contactLimit !== undefined) return val.contactLimit
        if (!Array.isArray(val.features))
          return 0

        const contact = val.features.find(x => x.name === 'Contact Management')
        if (!contact)
          return 0

        if (!Array.isArray(contact.subFeatures))
          return 0

        const limit = contact.subFeatures.find(x => x.name === 'contactLimit')
        return limit ? limit.limit : 0
      },
      updateTotal (val, type) {
        if (val === '' || val.toString().indexOf('-') > -1 || val.toString().search(/^0/gi) > -1) {
          this.minPricePayAsYouGo = 0
        } else if (type === 'price') {
          this.minPricePayAsYouGo = parseFloat(val) * parseInt(this.minFairUsage)
        } else if (type === 'usage') {
          this.minPricePayAsYouGo = parseInt(val) * parseFloat(this.emailPrice)
        }
      },
      addService () {
        this.extraService.push({name: '', price: 0})
      },
      deleteService (index) {
        this.extraService.splice(index, 1)
      },
      getSettingVersion () {
        setting.getVersion()
          .then((res) => {
            this.packageVersion = res.packetVersion
          })
          .catch(err => this.$e(err))
      },
      getAllPacket () {
        common.getPacket()
          .then((res) => {
            this.allPacket = res.data
            this.getCompanyPlan()
          })
          .catch(err => this.$e(err))
      },
      getCompanyPlan () {
        payment.getPlan(this.companyId)
          .then((res) => {
            this.currentPlan = res
            if (res.group === 'free') this.upgradeTypeOption.push("CUSTOM TRIAL")
          })
          .catch(err => this.$e(err))
      },
      selectUpgradeType (type) {
        if (type === 'DEFAULT PLAN') {
          this.subscriptionPeriod = (this.currentPlan.subscription < 12) ? 1 : 12
          this.priceByPeriod = this.subscriptionPeriod
          this.subscription = (this.subscriptionPeriod === 1) ? this.currentPlan.subscription : this.currentPlan.subscription/12
          let filterPackage = this.allPacket.filter((val) => val.type === this.currentPlan.packet && val.version === this.currentPlan.packetVersion)
          this.selectedPackage = null
          setTimeout(() => {
            this.selectedPackage = (filterPackage.length > 0) ? filterPackage[0] : null
          })
          this.promoCode = ''
        } else if (type === 'CUSTOM PLAN') {
          this.features = null
          this.subscriptionPeriod = 1
          this.priceByPeriod = 1
          this.subscription = 1
          this.selectedPackage = null
          this.promoCode = ''
        } else if (type === 'PAY AS YOU GO') {
          this.features = null
          this.subscriptionPeriod = 1
          this.priceByPeriod = 1
          this.subscription = 1
          this.selectedPackage = null
          this.promoCode = ''
          this.cutOffPeriod = 21
          this.emailPrice = 0
          this.minFairUsage = 0
          this.minPricePayAsYouGo = 0
        } else if (type === 'ADD-ON') {
          this.features = cloneDeep(this.currentPlan.features)
          this.addonPrice = 0
          this.addonEmailQuota = this.currentPlan.emailLimit
          this.accordionFeatures = true
        } else if (type === 'EDIT PACKET') {
          this.subscriptionPeriod = (this.currentPlan.subscription < 12) ? 1 : 12
          this.priceByPeriod = this.subscriptionPeriod
          this.subscription = (this.subscriptionPeriod === 1) ? this.currentPlan.subscription : this.currentPlan.subscription/12
          const filterPackage = this.allPacket.filter((val) => val.type === this.currentPlan.packet && val.version === this.currentPlan.packetVersion)
          this.selectedPackage = null
          setTimeout(() => {
            this.selectedPackage = (filterPackage.length > 0) ? filterPackage[0] : null
          })
          const period = this.currentPlan.period.split('-')
          let start = 0
          let end = 0
          if (period[0]) {
            start = period[0].trim().split(' ')
            start = moment().year(start[2]).month(start[1]).date(start[0]).format('x')
          }
          if (period[1]) {
            end = period[1].trim().split(' ')
            end = moment().year(end[2]).month(end[1]).date(end[0]).format('x')
          }
          this.editPeriod = [parseInt(start), parseInt(end)]
          this.promoCode = ''
          this.features = cloneDeep(this.currentPlan.features)
          this.accordionFeatures = true
          if (this.currentPlan.type === 'payasyougo') {
            this.cutOffPeriod = this.currentPlan.cutOffPeriod
            this.emailPrice = this.currentPlan.emailPrice
            this.minFairUsage = this.currentPlan.minFairUsage
          }
        } else if (type === 'CUSTOM TRIAL') {
          this.features = cloneDeep(this.currentPlan.features)
          let filterPackage = this.allPacket.filter((val) => val.type === this.currentPlan.packet && val.version === this.currentPlan.packetVersion)
          this.selectedPackage = null
          setTimeout(() => {
            this.selectedPackage = (filterPackage.length > 0) ? filterPackage[0] : null
          })
        }
        this.extraService = []
        this.upgradeType = type
        this.$emit('select-upgrade', type)
        this.$nextTick(() => {
          this.$refs['changeSubs'].scrollIntoView({ behavior: 'smooth' })
        })
      },
      checkPromoCode () {
        promo.checkPromo(this.promoCode.toUpperCase(), this.companyId)
          .then((res) => {
            this.detailPromo = res
          })
          .catch((err) => {
            this.$e(err)
            this.detailPromo = null
          })
      },
      confirmSubcription () {
        let packetId = (this.selectedPackage !== null) ? this.selectedPackage.packetId : undefined
        let subscription = (this.subscriptionPeriod === 1) ? this.subscription : this.subscription*12
        this.extraService.forEach((val) => {
          val.price = parseInt(val.price)
        })
        let data = {
          teamId: this.companyId,
          packetId: packetId,
          subscription: subscription,
          features: this.features,
          emailLimit: parseInt(this.detailFeature.emailQuota),
          monthlyPrice: parseInt(this.detailFeature.monthlyPrice),
          yearlyPrice: parseInt(this.detailFeature.yearlyPrice),
          promoCode: this.promoCode,
          note: this.note,
          items: this.extraService,
          type: this.typeType,
          cutOffPeriod: this.cutOffPeriod,
          emailPrice: this.emailPrice,
          minFairUsage: this.minFairUsage
        }
        this.$emit('confirm-subscription', data)
      },
      confirmAddon () {
        this.extraService.forEach((val) => {
          val.price = parseInt(val.price)
        })
        let data = {
          teamId: this.companyId,
          price: parseInt(this.addonPrice),
          features: this.features,
          emailQuota: parseInt(this.addonEmailQuota),
          items: this.extraService
        }
        this.$emit('confirm-addon', data)
      },
      confirmEdit () {
        const packetId = (this.selectedPackage !== null) ? this.selectedPackage.packetId : undefined
        const subscription = (this.subscriptionPeriod === 1) ? this.subscription : this.subscription*12
        const period = this.currentPlan.period.split('-')
        let start = 0
        let end = 0
        if (period[0]) {
          start = period[0].trim().split(' ')
          start = moment().year(start[2]).month(start[1]).date(start[0]).format('x')
        }
        if (period[1]) {
          end = period[1].trim().split(' ')
          end = moment().year(end[2]).month(end[1]).date(end[0]).format('x')
        }
        const periodStart = (parseInt(start) !== this.editPeriod[0]) ? this.editPeriod[0] : null
        const periodEnd = (parseInt(end) !== this.editPeriod[1]) ? this.editPeriod[1] : null
        const data = {
          teamId: this.companyId,
          packetId: packetId,
          subscription: subscription,
          custom: this.isCustomPlan,
          features: this.features,
          emailLimit: parseInt(this.detailFeature.emailQuota),
          contactLimit: parseInt(this.detailFeature.contactLimit),
          monthlyPrice: parseInt(this.detailFeature.monthlyPrice),
          yearlyPrice: parseInt(this.detailFeature.yearlyPrice),
          periodStart: periodStart,
          periodEnd: periodEnd,
          note: this.note,
          type: this.typeType,
          cutOffPeriod: this.cutOffPeriod,
          emailPrice: this.emailPrice,
          minFairUsage: this.minFairUsage
        }
        this.$emit('confirm-edit', data)
      },
      confirmEditTrial () {
        let packetId = (this.selectedPackage !== null) ? this.selectedPackage.packetId : undefined
        let data = {
          teamId: this.companyId,
          packetId: packetId,
          features: this.features,
          emailLimit: parseInt(this.detailFeature.emailQuota),
          periodStart: this.editPeriod[0] || null,
          periodEnd: this.editPeriod[1]
        }
        this.$emit('confirm-edit-trial', data)
      }
    }
  }
</script>

<style lang="scss" scoped>
  .custom-package-content {
    /* css animation */
    overflow: hidden;
    max-height: 0;
    opacity: 0;
    /* add browser prefixes */
    transition: all 0.3s ease;
  }
  .custom-package-open {
    max-height: 500px;
    opacity: 1;
  }
  .dropdown-custom-fw {
    width: 100%;
  }
  .form-control{
    font-weight: 500;
  }

</style>