<template>
  <Layout :title="title" :subtitle="subtitle" :loading="loading" :show-menu="false">
    <template v-slot:sub-subheader>
      <SubHeader :title="subtitle" />
    </template>

    <div class="row">
      <div class="col-12">
        <div class="kt-portlet kt-portlet--mobile">
          <div class="kt-portlet__head kt-portlet__head--lg d-block">
            <div class="d-flex justify-content-between">
              <div class="kt-portlet__head-label flex-wrap my-3">
                <h3 class="kt-portlet__head-title">History Quota</h3>
              </div>
              <div class="kt-portlet__head-toolbar my-3">
                <button type="button" class="btn btn-brand btn-elevate btn-icon-sm my-1 ml-1" @click="showModalBuyQuota = true">
                  <i class="la la-plus pr-0"></i> Buy Quota
                </button>
              </div>
            </div>
          </div>

          <div v-if="list.length > 0" class="kt-portlet__body kt-portlet__body--fit">
            <div class="table-responsive pl-4">
              <table class="table table-hover">
                <thead>
                  <tr>
                    <th>Purchased Date</th>
                    <th>Purchased Amount</th>
                    <th>Added Email Quota</th>
                    <th>Carry Forward Quota</th>
                    <th>Total Email Quota</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="item in list" :key="item.id">
                    <td class="align-middle py-4">
                      {{ item.purchasedDate | dtmType('DD MMM YYYY HH:mm') }}
                    </td>
                    <td class="align-middle">
                      IDR {{ item.amount | curr }}
                    </td>
                    <td class="align-middle">
                      {{ item.quota | curr }}
                    </td>
                    <td class="align-middle">
                      {{ item.carryForwardQuota | curr }}
                    </td>
                    <td class="align-middle">
                      {{ item.totalEmailQuota | curr }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <div v-else class="my-4">
            <div class="d-flex flex-column justify-content-center align-items-center">
              <DocumentSvg class="w-image my-4" />
              <span class="text-caption my-4">
                There are no history quota
              </span>
            </div>
          </div>

          <div v-if="list.length > 0" class="kt-portlet__foot kt-portlet__foot--sm">
            <Pagination @navigate="navigate" :filter="filter" />
          </div>
        </div>
      </div>
    </div>

    <ModalBuyQuota
      v-if="showModalBuyQuota"
      @close="showModalBuyQuota = false"
    />

  </Layout>
</template>
<script>
  import Layout from "@/components/Layout.vue"
  import SubHeader from "@/components/layout/SubHeader"
  import Pagination from '@/components/partial/Pagination'
  import PaginationMixin from '@/components/PaginationMixin'
  import DocumentSvg from '@/components/svg/DocumentSvg.vue'
  import ModalBuyQuota from "@/components/whitelabel/ModalBuyQuota.vue"
  import whitelabel from "@/factory/whitelabel"

  export default {
    mixins: [PaginationMixin],
    components: {
      Layout,
      Pagination,
      DocumentSvg,
      SubHeader,
      ModalBuyQuota
    },
    data() {
      return {
        title: "History Quota",
        subtitle: "History Quota",
        loading: true,
        list: [],
        showModalBuyQuota: false,
        filter: {
          count: 0,
          page: 1,
          leng: 0,
          size: 10,
        },
        defaultOrder: '-purchasedDate'
      }
    },
    computed: {
      appName () { return this.$route.params.appName.toLowerCase() }
    },
    watch: {},
    created () {},
    methods: {
      get () {
        whitelabel.getResellerQuotaHistory('jatis', this.appName, this.filter)
          .then((res) => {
            this.list = res.data
            this.filter.count = res.count
            this.loading = false
          })
          .catch(err => this.$e(err))
      },
    }
  }
</script>