<template>
  <Layout :has-subtitle="false" :loading="loading" :show-menu="false">
    <template v-slot:sub-subheader>
      <SubHeader hasSubDetail="true" class="mb-0">
        <template v-slot:subDetail>{{ data.profile.email }}</template>
        <template v-slot:sub>
          <slot name="left-menu"></slot>
        </template>
        <slot name="right-menu"></slot>
      </SubHeader>
      <div class="kt-subheader kt-grid__item" id="kt_subheader-sub" :style="{ minHeight: '45px' }">
        <div class="kt-container kt-container--fluid">
          <Tabs
            class="nav-tabs-bold nav-tabs-line-success nav-tabs-line-2x flex-grow-1 mb-0"
            :tabs="tabs"
            :active="activeTab"
            @select="changeTab" />
        </div>
      </div>
    </template>

    <div class="row">
      <div class="col-12">
        <template v-if="activeTab === 'Profile'">
          <TabProfileMailtarget :companyId="$route.params.companyId" :userId="$route.params.userId" :data="data.profile" @reload-data="getProfile" />
        </template>
        <template v-if="activeTab === 'Subscription'">
          <TabSubscriptionMailtarget :companyId="$route.params.companyId" :userId="$route.params.userId" :data="data.subscription" :profile="data.profile" />
        </template>
        <template v-if="activeTab === 'API Management'">
          <TabApiManagement :companyId="$route.params.companyId" :userId="$route.params.userId" :data="data.subscription" />
        </template>
      </div>
    </div>
  </Layout>
</template>

<script>
  import Layout from '@/components/Layout'
  import SubHeader from "@/components/layout/SubHeader.vue"
  import Tabs from '@/components/partial/Tabs'
  import TabProfileMailtarget from '@/components/mailtarget/TabProfileMailtarget.vue'
  import TabSubscriptionMailtarget from '@/components/mailtarget/TabSubscriptionMailtarget.vue'
  import mailtarget from '@/factory/mailtarget'
  import TabApiManagement from "@/components/mailtarget/TabApiManagement.vue"
  
  export default {
    components: {
      TabApiManagement,
      Layout,
      SubHeader,
      Tabs,
      TabProfileMailtarget,
      TabSubscriptionMailtarget,
    },
    data() {
      return {
        activeTab: 'Profile',
        data: {
          profile: {},
          subscription: {},
        },
        loading: false,
      }
    },
    computed: {
      tabs() {
        return [
          { menu: 'Profile' },
          { menu: 'Subscription' },
          { menu: 'API Management' },
        ]
      }
    },
    watch: {
      '$route.query'() {
        this.checkTab()
      },
    },
    created () {
      this.checkTab()
      this.getProfile()
      this.getSubscription()
    },
    methods: {
      getProfile () {
        mailtarget.getClientDetail(this.$route.params.userId)
          .then((response) => {
            this.data.profile = response
          })
          .catch(err => this.$e(err))
      },
      getSubscription () {
        mailtarget.getClientSubscription(this.$route.params.userId)
          .then((response)=>{
            this.data.subscription = response
          })
          .catch(err => this.$e(err))
      },
      checkTab () {
        switch (this.$route.query.tab) {
          case 'profile':
            this.activeTab = 'Profile'
            break
          case 'subscription':
            this.activeTab = 'Subscription'
            break
          case 'setting':
            this.activeTab = 'Setting'
            break
          case 'api-management':
            this.activeTab = 'API Management'
            break
          default:
            this.activeTab = 'Profile'
            this.$router.push({name: 'mailtarget-company-detail', query: {tab: 'profile'}})
            break
        }
      },
      changeTab (tab) {
        this.activeTab = tab.menu
        switch (tab.menu) {
          case 'Profile':
            this.$router.push({name: 'mailtarget-company-detail', query: {tab: 'profile'}})
            break
          case 'Subscription':
            this.$router.push({name: 'mailtarget-company-detail', query: {tab: 'subscription'}})
            break
          case 'Setting':
            this.$router.push({name: 'mailtarget-company-detail', query: {tab: 'setting'}})
            break
          case 'API Management':
            this.$router.push({name: 'mailtarget-company-detail', query: {tab: 'api-management'}})
            break
          default:
            this.$router.push({name: 'mailtarget-company-detail', query: {tab: 'profile'}})
            break
        }
      },
    }
  }
</script>