<template>
  <Layout :title="title" :subtitle="subtitle" :loading="loading" :show-menu="false">
    <template v-slot:sub-subheader>
      <SubHeader :title="subtitle" />
    </template>

    <div class="row">
      <div class="col-12">
        <div class="kt-portlet kt-portlet--mobile">
          <div class="kt-portlet__head kt-portlet__head--lg d-block">
            <div class="d-flex justify-content-between">
              <div class="kt-portlet__head-label flex-wrap my-3">
                <h3 class="kt-portlet__head-title">Email Quota Allocated</h3>
              </div>
              <div class="kt-portlet__head-toolbar my-3">
                <button type="button" class="btn btn-outline-primary btn-elevate btn-icon-sm my-1 ml-1"
                  @click="exportEmailUsage">
                  <i class="flaticon-download" />Export
                </button>
              </div>
            </div>
          </div>

          <div class="kt-portlet__body">
            <div class="row">
              <div class="col-2">
                <div class="card-reseller-usage card-reseller-usage-primary">
                  <div class="card-title">Total Email Quota</div>
                  <div class="count">{{ quota.total | curr }}</div>
                </div>
              </div>
              <div class="col-2">
                <div class="card-reseller-usage card-reseller-usage-success">
                  <div class="card-title">Total Email Allocated</div>
                  <div class="count">{{ quota.quotaAllocated | curr }}</div>
                </div>
              </div>
              <div class="col-2">
                <div class="card-reseller-usage card-reseller-usage-warning">
                  <div class="card-title">Email(s) Left</div>
                  <div class="count">{{ quota.quotaLeft | curr }}</div>
                </div>
              </div>
              <div class="col-6 progress-container">
                <ProgressBar :percent="percentage(quota.quotaAllocated, quota.total)" class="progress-bar-bg-warning"
                  type="progress--success" mode="active" style="height: 12px;" />
              </div>
            </div>
            <div class="kt-portlet__separator my-4"></div>
            <div class="row">
              <div class="col-6">
                <div class="chart-container position-relative py-4">
                  <canvas id="chartUsage" class="mx-auto"></canvas>
                  <p v-if="!loadingUsage" class="chart-title text-gray-700 font-weight-500"
                    style="transform: translate(-50%, -100%);">
                    Total
                    Email Allocated</p>
                  <h1 v-if="!loadingUsage" class="chart-count font-weight-700"
                    style="transform: translate(-50%, 20%); font-size: 24px;">{{
                    quota.quotaAllocated |
                    formatNumber }}</h1>
                </div>
              </div>
              <div class="col-6 d-flex align-items-center text-md">
                <div v-if="!loadingUsage" class="w-100">
                  <h5>Detail</h5>
                  <div v-for="(item,i) in stats" :key="i" class="d-flex justify-content-between my-4">
                    <div class="d-inline-flex align-items-center">
                      <div class="box-legend" :style="`background-color: ${backgroundColor[i]}`"></div>
                      <p class="mb-0 pl-3">{{ item.name }}</p>
                      <p class="text-caption mb-0 pl-3">{{ percentage( item.quota, quota.quotaAllocated) }}%</p>
                    </div>
                    <div class="d-inline-flex align-items-center">
                      <p class="text-gray-800 font-weight-700 mb-0 mr-3">{{ item.quota | formatNumber }}</p>
                      <i @click="gotoDetail(item)" class="la la-angle-right text-link cursor-pointer"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </Layout>
</template>
<script>
  import Layout from "@/components/Layout.vue"
  import SubHeader from "@/components/layout/SubHeader"
  import whitelabel from "@/factory/whitelabel"
  import ProgressBar from '@/components/partial/ProgressBar'
  import Chart from 'chart.js'
  import mailtarget from "@/factory/mailtarget"
  
  export default {
    mixins: [],
    components: {
      Layout,
      SubHeader,
      ProgressBar,
    },
    data() {
      return {
        title: "Email Usage",
        subtitle: "Email Usage",
        loading: true,
        loadingUsage: true,
        quota: {
          total: 0,
          quotaLeft: 0,
          quotaAllocated: 0,
          updatedOn: 0,
          lastPurchasedAt: 0,
        },
        chartComp: null,
        stats: [],
        backgroundColor: [ "#3882AE", "#338B59", "#FFD770", "#FFAEA4", "#DF9947", "#80DFA7" ]
      }
    },
    computed: {
      appName () { return this.$route.params.appName.toLowerCase() },
    },
    created() {
      this.getQuota()
      this.getData()
    },
    methods: {
      getQuota() {
        whitelabel.getResellerQuota('jatis', this.appName)
          .then((res) => {
            this.quota.total = res.total
            this.quota.quotaLeft = res.quotaLeft
            this.quota.quotaAllocated = res.quotaAllocated
            this.quota.updatedOn = res.updatedOn
            this.quota.lastPurchasedAt = res.lastPurchasedAt
            this.loading = false
          })
          .catch(err => this.$e(err))
      },
      exportEmailUsage () {
        whitelabel.getResellerQuotaStatsExport()
          .then((res) => {
            const url = window.URL.createObjectURL(new Blob([res]))
            const link = document.createElement('a')
            link.href = url
            link.setAttribute('download', `email-allocated.csv`)
            document.body.appendChild(link)
            link.click()
            document.body.removeChild(link)
          })
          .catch(err => this.$e(err))
      },
      percentage(count, limit) {
        return Math.round((count / limit) * 100)
      },
      getData() {
        let api = mailtarget.getResellerQuotaStats()
        if (this.appName === 'mtarget') api = whitelabel.getResellerQuotaStats()
        api
          .then((res) => {
            this.stats = res
            this.setData()
          })
          .catch(err => this.$e(err))
      },
      setData() {
        this.loadingUsage = false
        let target = document.getElementById('chartUsage')
        if (target === null) return false
        let mapChartId = target.getContext('2d')
        if (mapChartId === null) return false
  
        if (this.chartComp !== null) {
          this.chartComp.data.labels = this.stats.map(item => item.name)
          this.chartComp.data.datasets = [{
            data: this.stats.map(item => item.quota),
            backgroundColor: this.backgroundColor,
            label: "Email Usage"
          }]
          this.chartComp.update()
          return
        }
  
        this.chartComp = new Chart(mapChartId, {
          type: 'doughnut',
          data: {
            labels: this.stats.map(item => item.name),
            datasets: [{
              data: this.stats.map(item => item.quota),
              backgroundColor: this.backgroundColor,
              label: "Email Usage"
            }]
          },
          options: {
            cutoutPercentage: 70,
            responsive: true,
            legend: {
              display: false,
              position: "bottom"
            },
            animation: {
              animateScale: true,
              animateRotate: true
            },
            tooltips: {
              callbacks: {
                label: function (tooltipItem, data) {
                  let name = data.labels[tooltipItem.index]
                  let label = data.datasets[0].data[tooltipItem.index] || 0;
                  if (label) {
                    label = new Intl.NumberFormat('en-US').format(label)
                  }
                  return name + ': ' +  label;
                }
              }
            }
          },
        })
      },
      gotoDetail (user) {
        if (!user.companyId || user.companyId === 0) return
        return this.$router.push(`/clients/${this.appName}/jatis/${user.companyId}/${user.userId || ''}`)
      },
    }
  }
</script>

<style lang="scss" scoped>
  .progress-container{
    display: grid;
    align-items: center;
  }
</style>