<template>
  <input type="text" class="form-control" v-model="displayValue" @blur="isInputActive = false" @focus="isInputActive = true" :disabled="disabled" :placeholder="placeholder" @keypress="onlyNumber" />
</template>

<script>
  // https://jsfiddle.net/mani04/bgzhw68m/
  export default {
    name: 'InputCurrency',
    props: {
      value: {
        default: 0
      },
      disabled: {
        default: false
      },
      prefix: {
        default: 'IDR '
      },
      thousand: {
        default: ','
      },
      decimal: {
        default: '.'
      },
      toFixed: {
        default: 2
      },
      placeholder: {
        default: ''
      },
    },
    data () {
      return {
        isInputActive: false
      }
    },
    computed: {
        displayValue: {
            get: function() {
                if (this.isInputActive) {
                    // Cursor is inside the input field. unformat display value for user
                    return this.value.toString()
                } else {
                    // User is not modifying now. Format display value for user interface
                    return this.prefix + this.value.toFixed(this.toFixed).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, `$1${this.thousand}`)
                }
            },
            set: function(modifiedValue) {
                // Recalculate value after ignoring "$" and "," in user input
                let newValue = parseFloat(modifiedValue.replace(/[^\d-.]/g, ""))
                // Ensure that it is not NaN
                if (isNaN(newValue)) {
                    newValue = 0
                }
                // Note: we cannot set this.value as it is a "prop". It needs to be passed to parent component
                // $emit the event so that parent component gets it
                this.$emit('input', newValue)
                this.$emit('change', newValue)
            }
        }
    },
    methods: {
      onlyNumber ($event) {
        let key = ($event.keyCode ? $event.keyCode : $event.which)
        if ((key < 48 || key > 57) && key !== 46 && key !== 45) {
          $event.preventDefault()
        }
      },
    }
  }
</script>
