<template>
  <Layout :title="title" :has-subtitle="false" :loading="loading" :show-menu="false">
    <div class="d-flex align-items-center kt-space-between">
      <div class="app-switch-tab-section">
        <div
          v-for="(item, key) in appTabs"
          :key="key"
          @click="$router.push(`/dashboard/${item.value}`)"
          class="app-switch-tab"
          :class="appName === item.value ? 'app-tab-active' : 'app-tab-default'">
          <component class="w19px" :is="item.logo"/>
          <p class="font-weight-500 min-w-80px">{{ item.title }}</p>
          <p v-if="appName === item.value" class="app-tab-prefix font-italic">selected</p>
          <p v-else class="app-tab-prefix not-selected">Show data <ArrowNext /></p>
        </div>
      </div>
      <p class="font-italic note-asterisk">*Client list adjusts based on your platform selection.</p>
    </div>
    <MtargetCompanyCrm v-if="appName === 'mtarget'" />
    <MailtargetCompanyCrm v-if="appName === 'mailtarget'" />
  </Layout>
</template>

<script>
import MtargetSvg from "@/components/svg/MtargetSvg.vue"
import MailtargetSvg from "@/components/svg/MailtargetSvg.vue"
import Layout from "@/components/Layout.vue"
import MtargetCompanyCrm from "@/views/MtargetCompanyCrm.vue"
import MailtargetCompanyCrm from "@/views/MailtargetCompanyCrm.vue"
import ArrowRightSvg from "@/components/svg/ArrowRightSvg.vue"
import ArrowNext from "@/components/svg/ArrowNext.vue"


export default {
  name: "MainMenu",
  components: {ArrowNext, ArrowRightSvg, MailtargetCompanyCrm, MtargetCompanyCrm, Layout },
  data() {
    return {
      title: "Client",
      loading: false,
      appTabs: [
        { value: 'mtarget', title: 'MTARGET', logo: MtargetSvg },
        { value: 'mailtarget', title: 'Mailtarget', logo: MailtargetSvg },
      ],
    }
  },
  computed: {
    appName () { return this.$route.params.appName.toLowerCase() }
  },
}
</script>

<style lang="scss" scoped>
.app-switch-tab-section {
  display: flex;
  column-gap: 16px;
  margin: 0 0 24px;
}
.app-switch-tab {
  border-radius: 4px;
  border-width: 0 0 3px 0;
  border-style: solid;
  padding: 12px 16px;
  width: fit-content;
  display: flex;
  align-items: center;
  column-gap: 8px;
  cursor: pointer;
}
.app-switch-tab > p {
  min-width: 80px;
  margin: 0;
  padding: 0;
}
.app-switch-tab > .not-selected {
  color: #5F5CF1;
}
.app-switch-tab > .app-tab-prefix {
  font-size: 10px;
  text-align: end;
}
.app-tab-default {
  background-color: #ffffff;
  border-color: #ffffff;
}
.app-tab-active {
  background-color: #E7E6FF;
  border-color: #5F5CF1;
}
.w19px {
  width: 19px;
}
.note-asterisk {
  color: #B5B5C3;
  font-size: 14px;
}
</style>