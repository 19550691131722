<template>
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M6.1417 3L5 4.175L8.7085 8L5 11.825L6.1417 13L11 8L6.1417 3Z" fill="#5F5CF1"/>
  </svg>

</template>

<script>
  export default {
    props: {
      lol: {
        default: undefined
      }
    }
  }
</script>