<template>
  <div :class="kelas" class="kt-aside  kt-aside--fixed  kt-grid__item kt-grid kt-grid--desktop kt-grid--hor-desktop" id="kt_aside">

    <!-- begin:: Aside -->
    <div class="kt-aside__brand kt-grid__item " id="kt_aside_brand">
      <div class="kt-aside__brand-logo">
        <router-link to="/">
          <img alt="Logo" :src="logo"/>
        </router-link>
      </div>
      <div class="kt-aside__brand-tools">
        <button class="kt-aside__brand-aside-toggler" @click="toggle">
          <span><MenuArrowCloseSvg/></span>
          <span><MenuArrowOpenSvg/></span>
        </button>
      </div>
    </div>

    <!-- end:: Aside -->

    <Scroll>
      <div
        v-if="false"
        class="profile-card kt-user-card kt-user-card--skin-dark kt-notification-item-padding-x">
        <div class="kt-user-card__avatar">
        <span class="kt-badge kt-badge--lg kt-badge--rounded kt-badge--bold kt-font-success">
          {{ oneLetter }}
        </span>
        </div>
        <div class="kt-user-card__name">
          {{ userData.name }}
        </div>
      </div>

      <!-- begin:: Aside Menu -->
      <div class="kt-aside-menu-wrapper kt-grid__item kt-grid__item--fluid" id="kt_aside_menu_wrapper">
        <div id="kt_aside_menu" class="kt-aside-menu " data-ktmenu-vertical="1" data-ktmenu-scroll="1" data-ktmenu-dropdown-timeout="500">
          <ul class="kt-menu__nav ">
            <router-link v-if="allowFeature.menu.enable && allowFeature.menu.dashboard" :to="`/dashboard/${this.appName}`" tag="li" class="kt-menu__item" aria-haspopup="true">
              <span class="kt-menu__link">
                <span class="kt-menu__link-icon">
                  <Layout4BlocksSvg/>
                </span>
                <span class="kt-menu__link-text">Dashboard</span>
              </span>
            </router-link>

            <template v-if="allowFeature.menu.enable">
              <li class="kt-menu__section ">
                <h4 class="kt-menu__section-text">Menu</h4>
                <i class="kt-menu__section-icon flaticon-more-v2"></i>
              </li>

              <li v-if="allowFeature.menu.company && !roleOnlySupport"  class="kt-menu__item kt-menu__item--submenu" :class="clientCollapse? 'kt-menu__item--open' : ''" aria-haspopup="true">
                <span class="kt-menu__link kt-menu__toggle" @click="clientCollapse = !clientCollapse">
                  <span class="kt-menu__link-icon">
                    <DashboardSvg/>
                  </span>
                  <span class="kt-menu__link-text">Client</span>
                  <i class="kt-menu__ver-arrow la la-angle-right" />
                </span>
                <div :style="clientCollapse ? 'height: auto' : 'height: 0px; overflow: hidden;'" class="kt-menu__submenu"><span class="kt-menu__arrow"></span>
                  <ul class="kt-menu__subnav">
                    <li class="kt-menu__item" :class="$route.name.includes('clients') || $route.name.includes('company-detail') ? 'kt-menu__item--active' : ''">
                      <router-link to="/clients" class="kt-menu__link"  aria-haspopup="true">
                        <div class="pl-4 d-flex align-items-center">
                          <MtargetSvg />
                          <span class="ml-3 kt-menu__link-text">MTARGET</span>
                        </div>
                      </router-link>
                    </li>
                    <li class="kt-menu__item" :class="$route.name.includes('mailtarget') ? 'kt-menu__item--active' : ''">
                      <router-link to="/mailtarget" class="kt-menu__link"  aria-haspopup="true">
                        <div class="pl-4 d-flex align-items-center">
                          <MailtargetSvg />
                          <span class="ml-3 kt-menu__link-text">mailtarget</span>
                        </div>
                      </router-link>
                    </li>
                    <li class="kt-menu__item" :class="$route.name.includes('sonar') ? 'kt-menu__item--active' : ''">
                      <router-link to="/company-sonar" class="kt-menu__link"  aria-haspopup="true">
                        <div class="pl-4 d-flex align-items-center">
                          <SonarSvg />
                          <span class="ml-3 kt-menu__link-text">SONAR</span>
                        </div>
                      </router-link>
                    </li>
                  </ul>
                </div>
              </li>
              <router-link v-if="allowFeature.menu.conversion" :to="`/conversion`" tag="li" class="kt-menu__item" aria-haspopup="true">
                <span class="kt-menu__link">
                  <span class="kt-menu__link-icon">
                    <BucketSvg/>
                  </span>
                  <span class="kt-menu__link-text">Conversion</span>
                </span>
              </router-link>
              <router-link
                v-if="allowFeature.menu.userGrowth"
                :to="`/user-growth`"
                tag="li"
                class="kt-menu__item"
                :class="{'router-link-active' : $route.name === 'funnel-report'}"
                aria-haspopup="true">
                <span class="kt-menu__link">
                  <span class="kt-menu__link-icon">
                    <i class="flaticon-presentation-1"></i>
                  </span>
                  <span class="kt-menu__link-text">User Growth</span>
                </span>
              </router-link>
              <router-link v-if="allowFeature.menu.payment && (roleAdmin || roleFinance)" :to="`/payment`" tag="li" class="kt-menu__item" aria-haspopup="true">
                <span class="kt-menu__link">
                  <span class="kt-menu__link-icon">
                    <ChartBarSvg/>
                  </span>
                  <span class="kt-menu__link-text">Payment</span>
                </span>
              </router-link>
              <router-link v-if="allowFeature.menu.promo && (roleAdmin || roleRevenue || roleGrowth)" :to="`/promo`" tag="li" class="kt-menu__item" aria-haspopup="true">
                <span class="kt-menu__link">
                  <span class="kt-menu__link-icon">
                    <BriefcaseSvg/>
                  </span>
                  <span class="kt-menu__link-text">Promo</span>
                </span>
              </router-link>
              <router-link v-if="allowFeature.menu.customerService" :to="`/customer-service`" tag="li" class="kt-menu__item" aria-haspopup="true">
                <span class="kt-menu__link">
                  <span class="kt-menu__link-icon">
                    <CouponSvg/>
                  </span>
                  <span class="kt-menu__link-text">Customer Service</span>
                </span>
              </router-link>
              <router-link v-if="allowFeature.menu.support" :to="`/support`" tag="li" class="kt-menu__item kt-menu__item--submenu" :class="$route.name.includes('support') || $route.name.includes('Meetings') ? 'kt-menu__item--open' : ''" aria-haspopup="true">
                <span class="kt-menu__link kt-menu__toggle">
                  <span class="kt-menu__link-icon">
                    <GroupChatSvg/>
                  </span>
                  <span class="kt-menu__link-text">Support</span>
                  <i class="kt-menu__ver-arrow la la-angle-right" />
                </span>
                <div v-if="$route.name.includes('support') || $route.name.includes('Meetings')" class="ml-4 kt-menu__submenu">
                <ul class="kt-menu__subnav">
                  <li class="kt-menu__item " aria-haspopup="true">
                    <router-link :to="`/meetings-activity`" tag="li" class="kt-menu__item" aria-haspopup="true">
                      <span class="ml-4 kt-menu__link">
                        <span class="ml-4 kt-menu__link-text">Meetings</span>
                      </span>
                    </router-link>
                    <!-- <router-link to="/meetings-activity" class="kt-menu__link ">
                      <i class="kt-menu__link-bullet kt-menu__link-bullet--dot"></i>
                      <span class="kt-menu__link-text">Meeting Activity</span>
                    </router-link> -->
                  </li>
                </ul>
              </div>
              </router-link>
              <router-link v-if="allowFeature.menu.package && (roleAdmin || roleDeveloper)" :to="`/package`" tag="li" class="kt-menu__item" aria-haspopup="true">
                <span class="kt-menu__link">
                  <span class="kt-menu__link-icon">
                    <CouponSvg/>
                  </span>
                  <span class="kt-menu__link-text">Package</span>
                </span>
              </router-link>
              <router-link v-if="allowFeature.menu.clientLogo && (roleAdmin || roleDeveloper || roleGrowth)" :to="`/client-logo`" tag="li" class="kt-menu__item" aria-haspopup="true">
                <span class="kt-menu__link">
                  <span class="kt-menu__link-icon">
                    <CouponSvg/>
                  </span>
                  <span class="kt-menu__link-text">Client Logo</span>
                </span>
              </router-link>
              <router-link v-if="allowFeature.menu.template && (roleAdmin || roleDeveloper || roleGrowth)" :to="`/template`" tag="li" class="kt-menu__item" aria-haspopup="true">
                <span class="kt-menu__link">
                  <span class="kt-menu__link-icon">
                    <CouponSvg/>
                  </span>
                  <span class="kt-menu__link-text">Template</span>
                </span>
              </router-link>
              <router-link v-if="allowFeature.menu.dataSurvey && (roleAdmin || roleGrowth)" :to="`/data-survey`" tag="li" class="kt-menu__item" aria-haspopup="true">
                <span class="kt-menu__link">
                  <span class="kt-menu__link-icon">
                    <CouponSvg/>
                  </span>
                  <span class="kt-menu__link-text">Data Survey</span>
                </span>
              </router-link>
            </template>

            <template v-if="allowFeature.more.enable">
              <li class="kt-menu__section">
                <h4 class="kt-menu__section-text">More</h4>
                <i class="kt-menu__section-icon flaticon-more-v2"></i>
              </li>

              <router-link v-if="allowFeature.more.support" :to="`/settings`" tag="li" class="kt-menu__item" aria-haspopup="true">
                <span class="kt-menu__link">
                  <span class="kt-menu__link-icon">
                    <SettingSvg/>
                  </span>
                  <span class="kt-menu__link-text">Settings</span>
                </span>
              </router-link>
            </template>

            <template v-if="allowFeature.developer.enable">
              <li v-if="roleAdmin || roleDeveloper" class="kt-menu__section ">
                <h4 class="kt-menu__section-text">Developer</h4>
                <i class="kt-menu__section-icon flaticon-more-v2"></i>
              </li>

              <router-link v-if="allowFeature.developer.scenario && (roleAdmin)" :to="`/scenario`" tag="li" class="kt-menu__item" aria-haspopup="true">
                <span class="kt-menu__link">
                  <span class="kt-menu__link-icon">
                    <DashboardSvg/>
                  </span>
                  <span class="kt-menu__link-text">Scenario</span>
                </span>
              </router-link>
              <router-link v-if="allowFeature.developer.blockedIp && (roleAdmin || roleDeveloper)" :to="`/blocked-ip`" tag="li" class="kt-menu__item" aria-haspopup="true">
                <span class="kt-menu__link">
                  <span class="kt-menu__link-icon">
                    <DashboardSvg/>
                  </span>
                  <span class="kt-menu__link-text">Blocked IP</span>
                </span>
              </router-link>
              <router-link v-if="allowFeature.developer.invalidDomain && (roleAdmin || roleDeveloper)" :to="`/invalid-domain`" tag="li" class="kt-menu__item" aria-haspopup="true">
                <span class="kt-menu__link">
                  <span class="kt-menu__link-icon">
                    <CloudySvg/>
                  </span>
                  <span class="kt-menu__link-text">Invalid Domain</span>
                </span>
              </router-link>
              <router-link v-if="allowFeature.developer.cmsActivity && roleAdmin" :to="`/cms-activity`" tag="li" class="kt-menu__item" aria-haspopup="true">
                <span class="kt-menu__link">
                  <span class="kt-menu__link-icon">
                    <CloudySvg/>
                  </span>
                  <span class="kt-menu__link-text">CMS Activity</span>
                </span>
              </router-link>
              <router-link v-if="allowFeature.developer.setting && (roleAdmin || roleDeveloper)" :to="`/setting`" tag="li" class="kt-menu__item" aria-haspopup="true">
                <span class="kt-menu__link">
                  <span class="kt-menu__link-icon">
                    <CloudySvg/>
                  </span>
                  <span class="kt-menu__link-text">Setting</span>
                </span>
              </router-link>
              <!-- <router-link :to="`/affiliate`" tag="li" class="kt-menu__item" aria-haspopup="true">
                <span class="kt-menu__link">
                  <span class="kt-menu__link-icon">
                    <CloudySvg/>
                  </span>
                  <span class="kt-menu__link-text">Affiliate</span>
                </span>
              </router-link> -->
              <router-link v-if="allowFeature.developer.user && roleAdmin" :to="`/user`" tag="li" class="kt-menu__item" aria-haspopup="true">
                <span class="kt-menu__link">
                  <span class="kt-menu__link-icon">
                    <CloudySvg/>
                  </span>
                  <span class="kt-menu__link-text">User</span>
                </span>
              </router-link>
            </template>

          </ul>
        </div>
      </div>

      <!-- end:: Aside Menu -->
    </Scroll>


  </div>
</template>

<script>
  import DashboardSvg from '@/components/svg/DashboardSvg'
  import MenuArrowCloseSvg from '@/components/svg/MenuArrowCloseSvg'
  import MenuArrowOpenSvg from '@/components/svg/MenuArrowOpenSvg'
  import logo from '@/assets/images/logo.png'
  import store from '@/store'
  import { each } from 'lodash'
  import CouponSvg from '@/components/svg/CouponSvg'
  import BriefcaseSvg from '@/components/svg/BriefcaseSvg'
  import ChartBarSvg from '@/components/svg/ChartBarSvg'
  import CloudySvg from '@/components/svg/CloudySvg'
  import RoleMixin from '@/components/RoleMixin'
  import useConfig from '@/library/useConfig'

  import Layout4BlocksSvg from '@/components/svg/Layout4BlocksSvg'
  import BucketSvg from '@/components/svg/BucketSvg'
  import GroupChatSvg from '@/components/svg/GroupChatSvg'
  import SettingSvg from '@/components/svg/SettingSvg'
  import MailtargetSvg from '@/components/svg/MailtargetSvg'
  import SonarSvg from '@/components/svg/SonarSvg'
  import MtargetSvg from '@/components/svg/MtargetSvg'
  
  export default {
    mixins: [RoleMixin],
    data () {
      return {
        logo,
        sub: {
          setting: false
        },
        clientCollapse: false,
      }
    },
    components: {
      CloudySvg,
      ChartBarSvg,
      BriefcaseSvg,
      CouponSvg,
      DashboardSvg,
      MenuArrowOpenSvg,
      MenuArrowCloseSvg,
      Layout4BlocksSvg,
      BucketSvg,
      GroupChatSvg,
      SettingSvg,
      MailtargetSvg,
      SonarSvg,
      MtargetSvg,
    },
    created () {
      this.checkClientCollapse()
    },
    computed: {
      allowFeature () { return useConfig() },
      asideMinimize () { return store.state.asideMinimize },
      mobileAsideMaximize () { return store.state.mobileAsideMaximize },
      kelas () { return this.mobileAsideMaximize ? 'kt-aside--on' : ''},
      userData () { return store.state.userData },
      oneLetter () { return this.userData.name.substring(0, 1) },
      appName () { return this.$route.params.appName },
    },
    methods: {
      set () {
        const name = this.$route.name.split('-')[0]
        each(this.sub, (item, key) => {
          if (name === key) this.sub[key] = true
        })
      },
      toggle () {
        store.commit('SET_ASIDE', !this.asideMinimize)
      },
      checkClientCollapse () {
        if(this.$route.name.includes('clients') || this.$route.name.includes('company-detail') || this.$route.name.includes('sonar') || this.$route.name.includes('mailtarget')) {
          this.clientCollapse = true
        } else {
          this.clientCollapse = false
        }
      },
    }
  }
</script>

<style lang="scss" scoped>
  @import "~@/assets/scss/_config.scss";
  .kt-aside__brand-logo img {
    height: 40px;
  }
  .kt-aside-menu .kt-menu__nav .kt-menu__inner, .kt-aside-menu .kt-menu__nav .kt-menu__submenu {
    display: block;
  }
  .profile-card {
    background-color: kt-get($kt-base-colors, label, 4);
  }
  .kt-user-card__name {
    div {
      font-size: 1.1rem;
      text-overflow: ellipsis;
      width: 160px;
      white-space: nowrap;
      overflow: hidden;
    }
  }
</style>