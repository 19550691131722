<template>
  <div class="loading">
    <div class="progress progress-sm">
      <div class="progress-bar progress-bar-animated progress-bar-striped" :style="`width: ${width}%`"></div>
    </div>
  </div>
</template>

<script>
  export default {
    data () {
      return {
        width: 0
      }
    },
    created () {
      this.addWidth()
    },
    methods: {
      addWidth () {
        const w = this.width + this.getRandomInt(4, 14)
        if (w > 95) return
        this.width = w
        setTimeout(() => {
          this.addWidth()
        }, this.getRandomInt(1, 3) * 1000)
      },
      getRandomInt(min, max) {
        min = Math.ceil(min);
        max = Math.floor(max);
        return Math.floor(Math.random() * (max - min + 1)) + min;
      }
    }
  }
</script>

<style lang="scss" scoped>
  .loading {
    position: fixed;
    top: 0;
    left: 0;
    height: 4px;
    width: 100%;
    z-index: 100;
    .progress {
      height: 4px;
      border-radius: 0;
      background-color: transparent;
      .progress-bar {
        border-radius: 0;
      }
    }
  }
</style>