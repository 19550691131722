<template>
  <Modal :title="title" @close="$emit('close')">
    <p>Are you sure you want to <strong>SET GRACE PERIOD</strong> for this Company?</p>
    <div>
      <div class="form-group">
        <div class="row align-items-center">
          <div class="col-12 col-sm-4">
            <label>End Date</label>
          </div>
          <div class="col-12 col-sm">
            <DatePicker v-model="endDate" />
          </div>
        </div>
      </div>
    </div>
    <template v-slot:footer>
      <button @click.prevent="$emit('close')" type="button" class="btn btn-secondary" :class="{wait: saving}" data-dismiss="modal">Close</button>
      <button @click.prevent="gracePeriod" type="button" class="btn btn-primary" :class="{wait: saving}">Yes</button>
    </template>
  </Modal>
</template>

<script>
  import Modal from '@/components/partial/Modal'
  import DatePicker from '@/components/partial/DatePicker'
  import company from '@/factory/company'
  import fn from '@/library/fn'

  export default {
    components: {
      Modal,
      DatePicker
    },
    data () {
      return {
        title: 'Set Grace Period',
        endDate: null,
        saving: false
      }
    },
    computed: {
      companyId () { return this.$route.params.companyId }
    },
    methods: {
      gracePeriod () {
        company.setGracePeriod(this.companyId, this.endDate)
          .then(res => {
            if (res) fn.notySuccess("Grace period successfully updated.")
            else fn.notyWarn("Failed to update grace period.")
            this.$emit('success-update')
          })
          .catch(err => this.$e(err))
      }
    }
  }
</script>
