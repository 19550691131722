<template>
  <div id="kt_header_mobile" class="kt-header-mobile kt-header-mobile--fixed">
    <div class="kt-header-mobile__logo">
      <router-link to="/">
        <img alt="Logo ccuuuy" :src="logo" />
      </router-link>
    </div>
    <div class="kt-header-mobile__toolbar">
      <button @click="toggleHeader" class="kt-header-mobile__topbar-toggler"><UserSvg/></button>
      <button v-if="showMenu" @click="toggle" class="kt-header-mobile__topbar-toggler"><i class="flaticon-more"></i></button>
    </div>
  </div>
</template>

<script>
  import UserSvg from '@/components/svg/UserSvg'
  import store from '@/store'
  import logomtarget from '@/assets/images/mtarget.png'
  import logojatis from '@/assets/images/jatis-light.png'

  export default {
    props: {
      showMenu: {
        default: true
      }
    },
    data () {
      return {
        logomtarget,
        logojatis,
      }
    },
    components: {
      UserSvg
    },
    computed: {
      mobileAsideMaximize () { return store.state.mobileAsideMaximize },
      mobileHeader () { return store.state.mobileHeader },
      company() { return process.env.VUE_APP_COMPANY },
      logo () {
        if (this.company === 'JATIS') return logojatis
        return logomtarget
      },
    },
    methods: {
      toggle () { store.commit('SET_MOB_MENU', !this.mobileAsideMaximize )},
      toggleHeader () { store.commit('SET_MOB_HEADER', !this.mobileHeader )}
    }
  }
</script>

<style lang="scss" scoped>
  .kt-header-mobile__logo img {
    height: 40px;
  }
</style>